import { FilterTypeEnum } from "../_types/FilterRule";
import { AntispamFilterData, AntispamFilterDataTypeEnum } from "./AntispamFilterData";
import { AntispamFilterModel } from "./AntispamFilterModel";

export const convertAntispamFilter = {
  fromDataToModel: (data: AntispamFilterData, isPrivateGroup: boolean): AntispamFilterModel => {
    return {
      isEnabled: data.isEnabled,
      rules: data.rules.map(rule => {
        const { type, forLong } = rule.ruleAction;

        const convertedType =
          type === AntispamFilterDataTypeEnum.Block
            ? isPrivateGroup
              ? FilterTypeEnum.Exclude
              : FilterTypeEnum.Block
            : (type as unknown as FilterTypeEnum);
        return {
          type: convertedType,
          forLong,
        };
      }),
      prohibitedTopics: data.prohibitedTopics,
      violationNotificationSettings: data.violationNotificationSettings,
      tgUsersWhiteListSettings: data.tgUsersWhiteListSettings,
      urlWhiteListSettings: data.urlWhiteListSettings,
      phraseWhiteListSettings: data.phraseWhiteListSettings,
    };
  },
  fromModelToData: (model: AntispamFilterModel): AntispamFilterData => {
    return {
      isEnabled: model.isEnabled,
      rules: model.rules.map((rule, index) => {
        const { type, forLong } = rule;

        return {
          violation: index + 1,
          ruleAction: {
            type:
              type === FilterTypeEnum.Exclude
                ? AntispamFilterDataTypeEnum.Block
                : (type as unknown as AntispamFilterDataTypeEnum),
            forLong,
          },
        };
      }),
      prohibitedTopics: model.prohibitedTopics,
      violationNotificationSettings: model.violationNotificationSettings,
      tgUsersWhiteListSettings: model.tgUsersWhiteListSettings,
      urlWhiteListSettings: model.urlWhiteListSettings,
      phraseWhiteListSettings: model.phraseWhiteListSettings,
    };
  },
};
