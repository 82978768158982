import { Box, Flex, Input, InputGroup, Switch, Text } from "@chakra-ui/react";
import s from "./DefaultSwitchableListLayout.module.scss";

interface Props {
  columnGap: string;
  title: React.ReactNode;
  description: string;
  isEnabled: boolean;
  onChangeSwitch: () => void;
  isDisabled: boolean;
  list: string;
  setList: (list: string) => void;
  isInvalid: boolean;
  invalidText: string;
  placeholderText: string;
}

export const DefaultSwitchableListLayout = ({
  columnGap,
  title,
  description,
  isEnabled,
  onChangeSwitch,
  isDisabled,
  list,
  setList,
  isInvalid,
  invalidText,
  placeholderText,
}: Props) => {
  return (
    <Flex columnGap={columnGap} rowGap="16px" justifyContent="space-between" direction="row">
      <Text maxW="164px" minW="164px" lineHeight="20px">
        {title}
      </Text>
      <Flex direction="column" w="100%" gap="2">
        <Flex gap="2" w="100%">
          <Switch
            className={s.switch}
            variant="dominoDefaultGreen"
            isChecked={isEnabled}
            onChange={onChangeSwitch}
            disabled={isDisabled}
          />
          <InputGroup flexDirection="column" gap="1">
            <Input
              onChange={e => setList(e.target.value)}
              value={list}
              disabled={isDisabled}
              isInvalid={isInvalid}
              placeholder={placeholderText}
            />
            {isInvalid && (
              <Box color="mainRed">
                <Text minH="16px" variant="small">
                  {invalidText}
                </Text>
              </Box>
            )}
          </InputGroup>
        </Flex>
        <Box>
          <Text variant="small" color="darkGrey">
            {description}
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};
