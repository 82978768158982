import React, { useRef, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import { BroadcastMessageTypeDescriminator, FileUploadType } from "../../BroadcastModel";
import {
  selectBroadcastIsNew,
  selectBroadcastMessage,
  selectCurrentFile,
  selectCustomVariables,
  uploadBroadcastFile,
} from "../../BroadcastSlice";
import { FilterParams } from "../../../complexFilter/ComplexFilterModel";
import { BroadcastFormTitle } from "../Broadcast/components/BroadcastFormTitle/BroadcastFormTitle";
import { BroadcastFormMessage } from "../Broadcast/components/BroadcastFormMessage/BroadcastFormMessage";
import { BroadcastFormButtons } from "../Broadcast/components/BroadcastFormButtons/BroadcastFormButtons";
import { RecipientListContainer } from "../ContactList/RecipientListContainer";

interface CommonProps {
  broadcastRecipientsFilters?: FilterParams[];
  setBroadcastFilter?: (brodcastRecipientFilters: FilterParams[]) => void;
}

interface EditProps extends CommonProps {
  broadcastRecipientsFilters?: FilterParams[];
  setBroadcastFilter?: (brodcastRecipientFilters: FilterParams[]) => void;
}

type Props = EditProps;

export const BroadcastForm = (props: Props) => {
  const dispatch = useAppDispatch();
  const currentFile = useAppSelector(selectCurrentFile);
  const variables = useAppSelector(selectCustomVariables);
  const textareaRef = useRef<HTMLDivElement>(null);
  const isBroadcastNew = useAppSelector(selectBroadcastIsNew);
  const message = useAppSelector(selectBroadcastMessage);
  const [messageInvalid] = useState(isBroadcastNew ? true : false);

  const uploadFile = (file: File, type: FileUploadType) => {
    dispatch(uploadBroadcastFile({ file: file, type }));
  };

  const checkIsSameFile = () => {
    if (message && currentFile && message?.typeDiscriminator !== BroadcastMessageTypeDescriminator.BroadcastTextMessage) {
      return message.fileId === currentFile.file.fileId;
    } else {
      return !currentFile;
    }
  };

  return (
    <>
      <BroadcastFormTitle />
      <RecipientListContainer />
      <BroadcastFormMessage
        variables={variables}
        textareaRef={textareaRef}
        messageInvalid={messageInvalid}
        uploadFile={uploadFile}
      />
      <BroadcastFormButtons messageInvalid={messageInvalid} dirty={!!checkIsSameFile()} />
    </>
  );
};
