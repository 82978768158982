import { Text } from "@chakra-ui/react";
import { RenderPlaceholderProps } from "slate-react";
import s from "./RenderPlaceholder.module.scss";

export const RenderPlaceholder = ({ attributes, children }: RenderPlaceholderProps) => {
  return (
    <Text variant="large" {...attributes} className={s.placeholder}>
      {children}
    </Text>
  );
};
