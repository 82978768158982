import { Button, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import PlusIcon from "../../../../assets/icons/plusIconTile.svg?react";
import { useState, useEffect } from "react";
import { useAppSelector } from "../../../../common/state/store";
import { selectCurrentBot } from "../../../sidebar/SidebarSlice";
import { useDocumentTitle } from "../../../../common/hooks/useDocumentTitle";
import { ICountTariffPlanFeature, TariffPlanFeatureTypeEnum } from "../../../../common/AppEnums";
import { selectOrganisation } from "../../../organisation/OrganisationSlice";
import { InputSearch } from "../../../../UI/molecules/inputSearch/InputSearch";
import useDebounce from "../../../../common/hooks/useDebounce";
import { constructSearchParams } from "../../../../common/utils/constructSearchParams";
import useGA from "../../../../common/ga/GAEventTracker";
import s from "./BotListHeader.module.scss";
import { BotAddEvents } from "../../../../common/ga/gaEventsEnums.ts/BotGAEventsEnums";
import { EventCategories } from "../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import { LimitStarPlanButton } from "../../../../UI/molecules/limitStarPlanButton/LimitStarPlanButton";
import { useSelectLimitPlan } from "../../../../common/hooks/useSelectLimitPlan";

export const BotListHeader = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "botList.botListHeader",
  });
  useDocumentTitle(t("Bots"), "Bots");
  const trackEvent = useGA(EventCategories.Bot);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentBot = useAppSelector(selectCurrentBot);
  const organisation = useAppSelector(selectOrganisation);
  const { IsUnlimited, MaxCount, isLoaded } = useSelectLimitPlan(TariffPlanFeatureTypeEnum.BotCount) as ICountTariffPlanFeature;

  const search = searchParams.get("search") ?? "";
  const [searchText, setSearchText] = useState(search);
  const debouncedSearchText = useDebounce(searchText, 500);
  const isAddBotLocation =
    window.location.pathname.includes("addTelegramBot") ||
    window.location.pathname.includes("addWebBot") ||
    window.location.pathname.includes("addbot");

  const handleAddBotClick = () => {
    navigate("/addbot");
    trackEvent(BotAddEvents.BotNewCreate);
  };

  useEffect(() => {
    constructSearchParams(search, debouncedSearchText, setSearchParams);
  }, [searchText, search, debouncedSearchText, setSearchParams]);

  return (
    <Flex className={s.headerContainer}>
      {currentBot && !isAddBotLocation ? (
        <>
          <Flex className={s.container}>
            <Text variant="h2" color="midDeepBlue" className={s.container_title}>
              {t("Bots")}
            </Text>
            <InputSearch
              inputGroupStyles={{ ml: "24px", w: "auto" }}
              placeholder={`${t("Search by bot name")}`}
              searchText={searchText}
              setSearchText={setSearchText}
            />
          </Flex>
          {!isLoaded || (!IsUnlimited && organisation?.totalBots && organisation?.totalBots >= MaxCount) ? (
            <LimitStarPlanButton
              buttonName="New Bot"
              keyPrefix="botList.botListHeader"
              popoverBodyDescription="Please upgrade your plan to add more Bots"
              variant="dominoViolet"
              dataPw="new-bot-button"
              iconFill={"white"}
            />
          ) : (
            <Button
              data-pw="addBotButton"
              className={s.addButton}
              onClick={handleAddBotClick}
              size="dominoMd"
              variant="dominoViolet"
            >
              <Flex gap={"8px"} align={"center"}>
                <PlusIcon />
                {t("New Bot")}
              </Flex>
            </Button>
          )}
        </>
      ) : (
        <>
          <Flex className={s.container}>
            <Text variant="h2" color="midDeepBlue" className={s.container_title}>
              {t("Bots")}
            </Text>
          </Flex>
        </>
      )}
    </Flex>
  );
};
