import { Button, Flex, Grid, GridItem, Icon, Text } from "@chakra-ui/react";
import TrashIcon from "../../../../../../../../../../../../../assets/icons/TrashGrey.svg?react";
import s from "../Rule.module.scss";
import { RuleProps } from "../Rule";
import { useTranslation } from "react-i18next";
import { Select, SelectProps } from "../../../../../../../../../../../../../UI/atoms/Select/Select";

interface Props extends Pick<RuleProps, "rule" | "position" | "handlers"> {
  restrictionSelect: Pick<SelectProps, "options" | "translattedOptions" | "onChange" | "value">;
  timeFramesAmountSelect: Pick<SelectProps, "options" | "translattedOptions" | "onChange" | "value">;
  timeFrameSelect: Pick<SelectProps, "options" | "translattedOptions" | "onChange" | "value">;
  isShownAddButton: boolean;
  isShownDeleteButton: boolean;
  maxRestrictionCount: number;
}

export const AdaptiveRuleLayout = ({
  rule,
  restrictionSelect,
  timeFramesAmountSelect,
  timeFrameSelect,
  position,
  handlers,
  isShownAddButton,
  isShownDeleteButton,
  maxRestrictionCount,
}: Props) => {
  const { forLong } = rule;
  const t = useTranslation("translation", {
    keyPrefix: "groupChat.groupChatInfo.ProfanityAndAntispamFilterPage",
  }).t;

  return (
    <Flex direction="column" rowGap="16px" w="100%" justifyContent="space-between">
      <Flex w="100%" columnGap="4px">
        <Grid alignItems="center" gridTemplateColumns="auto 2fr" gap="8px 16px" w="100%">
          <GridItem w="100%" minW="89px">
            <Text className={s.nowrap} variant="preLargeBold" color="newBlack">
              {position} {t("violation")}
            </Text>
          </GridItem>
          <GridItem w="100%" minW="0">
            <Select
              options={restrictionSelect.options}
              translattedOptions={restrictionSelect.translattedOptions}
              onChange={restrictionSelect.onChange}
              value={restrictionSelect.value}
            />
          </GridItem>
          {forLong && (
            <>
              <GridItem minW="89px">
                <Text className={s.nowrap} variant="medium" color="newBlack">
                  {t("a member for")}
                </Text>
              </GridItem>
              <GridItem>
                <Flex w="100%" columnGap="8px">
                  <Select
                    options={timeFramesAmountSelect.options}
                    onChange={timeFramesAmountSelect.onChange}
                    value={timeFramesAmountSelect.value}
                    shownItems={5}
                  />
                  <Select
                    options={timeFrameSelect.options}
                    translattedOptions={timeFrameSelect.translattedOptions}
                    onChange={timeFrameSelect.onChange}
                    value={timeFrameSelect.value}
                  />
                </Flex>
              </GridItem>
            </>
          )}
        </Grid>
        {isShownDeleteButton && (
          <Icon
            mt="3px"
            as={TrashIcon}
            className={s.icon}
            boxSize="30px"
            color="darkGrey"
            _hover={{ color: "mainRed", bgColor: "newGrey" }}
            onClick={() => handlers.delete(position)}
          />
        )}
      </Flex>
      {isShownAddButton && (
        <Flex direction="column" rowGap="8px">
          <Button variant="dominoDashedViolet" w="100%" onClick={handlers.add}>
            <Text>+ {t("Add restriction")}</Text>
          </Button>
          <Text variant="small" color="darkGrey">
            {t("Up to restrictions can be applied", { count: maxRestrictionCount })}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};
