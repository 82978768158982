import { Dispatch, SetStateAction } from "react";
import { convertTimeFrameToForLong } from "./utils";
import { TIME_FRAME_AMOUNTS, TIME_FRAMES } from "./consts";
import { TimeFramesEnum } from "./types";
import { FilterRule, FilterTypeEnum } from "../../../../../../_types/FilterRule";

interface HandleRuleChange {
  setRules: Dispatch<SetStateAction<FilterRule[]>>;
  restriction: FilterTypeEnum;
  position: number;
  timeFrameNumber: string;
  timeFrameValue: TimeFramesEnum;
}

export const handleRestriction =
  ({ position, setRules }: Pick<HandleRuleChange, "position" | "setRules">) =>
  (restriction: FilterTypeEnum) => {
    setRules(rules => {
      const updatedRules: FilterRule[] = [];
      const isChangedRuleBlock = restriction === FilterTypeEnum.Block || restriction === FilterTypeEnum.Exclude;

      rules.forEach((rule, index) => {
        const isRuleAfterBlock = isChangedRuleBlock && position < index + 1;
        if (isRuleAfterBlock) return;

        if (index + 1 === position) {
          updatedRules.push({
            type: restriction,
            forLong:
              restriction === FilterTypeEnum.Mute
                ? convertTimeFrameToForLong(Number(TIME_FRAME_AMOUNTS[TIME_FRAMES[0]][0]), TIME_FRAMES[0])
                : null,
          });
        } else {
          updatedRules.push(rule);
        }
      });

      return updatedRules;
    });
  };

export const handleRuleChange = ({ setRules, restriction, position, timeFrameNumber, timeFrameValue }: HandleRuleChange) => ({
  timeFrame: () => {
    if (restriction === FilterTypeEnum.Mute) {
      setRules(rules =>
        rules.map((rule, index) => {
          if (index + 1 === position) {
            return {
              ...rule,
              forLong: convertTimeFrameToForLong(Number(timeFrameNumber), timeFrameValue),
            };
          }

          return rule;
        }),
      );
    }
  },
});
