import { Box, Flex, Skeleton, useMediaQuery } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useGetLoadingState } from "../../common/loading/hooks/useGetLoadingState";
import { useAppDispatch } from "../../common/state/store";
import { selectBot } from "../sidebar/SidebarSlice";
import s from "./BotListContainer.module.scss";
import { BotListModel, BotModel } from "./BotListModel";
import { createBotNewFlow } from "./BotListSlice";
import { BotListItem } from "./components/BotListItem/BotListItem";
import { PaginatorWithSearchParams } from "../../common/paginator/components/PaginatorWithSearchParams";

interface Props {
  botList?: BotListModel;
  currentPage: number;
  search: string;
}

export const BotListContainer = ({ botList, currentPage, search }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "botList" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const isDesktop = useMediaQuery("(min-width: 1000px)")[0];
  const dispatch = useAppDispatch();
  const loadingBotList = useGetLoadingState("getBotList");
  const navigate = useNavigate();

  const createNewFlow = (botInfo: BotModel) => {
    dispatch(createBotNewFlow(botInfo));
  };

  const redirectToBotFlows = (bot: BotModel) => {
    dispatch(selectBot(bot));
    navigate("/automation/flows");
  };

  return (
    <>
      <Box className={s.botListContainer}>
        {botList?.items.length ? (
          <>
            <Box className={s.botListContent}>
              <Flex
                className={s.botListColumns}
                justifyContent={"space-between"}
                position={"absolute"}
                width={"100%"}
                height={"52px"}
              >
                <Box>{t("Bot")}</Box>
                <Box>{t("Username")}</Box>
                <Box>{t("Flows")}</Box>
                <Box></Box>
              </Flex>
              <Box
                className={`${s.itemsBox} ${botList.totalItems > 10 ? s.itemsBox_withPaginator : ""}`}
                overflowY={!(botList && !loadingBotList && botList.items.length === 0 && search === "") ? "auto" : "hidden"}
              >
                {botList.items.map((el: BotModel) => {
                  return (
                    <BotListItem
                      currentPage={currentPage}
                      key={el.id}
                      createNewFlow={createNewFlow}
                      redirectToBotFlows={redirectToBotFlows}
                      info={el}
                    />
                  );
                })}
              </Box>
            </Box>
            <PaginatorWithSearchParams sizeItems={10} data={botList} typeOfPage={"BotList"} />
          </>
        ) : (
          <Box className={s.noData}>
            <Box className={s.botListColumns} justifyContent={"space-between"} height={"52px"}>
              <Box>{t("Bot")}</Box>
              <Box>{t("Username")}</Box>
              <Box textAlign={"center"}>{t("Flows")}</Box>
              <Box></Box>
            </Box>
            {botList?.items.length === 0 ? (
              <Box p={"20px 24px"} bg={"white"} borderRadius={"8px"} textAlign={"center"}>
                {ct("No results found")}
              </Box>
            ) : (
              <Box mt={isDesktop ? "0" : "24px"}>
                {Array(10)
                  .fill(1)
                  .map((el, ind) => (
                    <Skeleton
                      key={ind}
                      data-pw="loadingFlow"
                      className={s.skeleton}
                      h={isDesktop ? "70px" : "140px"}
                      mb="10px"
                      speed={0.5}
                      borderRadius="12px"
                      startColor="line"
                      endColor="bgLight"
                    />
                  ))}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};
