import { call, put, select, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { ANTISPAM_FILTER_DEFAULT } from "../_components/FilterSettings/consts";
import { ConversationInfoModel, ConversationTypeEnum } from "../../../../../../conversation/ConversationModel";
import { selectConversationInfo } from "../../GroupChatConversation/components/GroupChatMessagesList/GroupChatMessagesListSlice";
import { antispamFilterSlice, selectAntispamFilter } from "./AntispamFilterSlice";
import { AntispamFilterData } from "./AntispamFilterData";
import { convertAntispamFilter } from "./utils";
import * as AntispamFilterApi from "./AntispamFilterApi";
import { beginScope, completeScope } from "../../../../../../../common/loading/LoadingStateActions";
import { AntispamFilterModel } from "./AntispamFilterModel";
import { GroupChatModel } from "../../../../../GroupChatModel";
import { selectGroupChatInfo } from "../../GroupChatInfo/GroupChatInfoSlice";
import { handleException } from "../../../../../../../common/SagaHelper";
import { notificationSlice } from "../../../../../../../common/notifications/NotificationSlice";
import { selectCurrentAntispamGroupCount, tariffPlanSlice } from "../../../../../../../common/tariffPlan/TariffPlanSlice";

export function* antispamFilterSaga() {
  yield takeLatest(antispamFilterSlice.actions.setAntispamFilter, setAntispamFilter);
  yield takeLatest(antispamFilterSlice.actions.toggleIsEnabled, toggleIsEnabled);
  yield takeLatest(antispamFilterSlice.actions.saveChanges, saveChanges);
}

function* setAntispamFilter(action: PayloadAction<AntispamFilterData | null>) {
  if (!action.payload) {
    yield put(antispamFilterSlice.actions.setAntispamFilterCompleted(ANTISPAM_FILTER_DEFAULT));
    return;
  }

  const conversationInfo: ConversationInfoModel | undefined = yield select(selectConversationInfo);
  const isPrivateGroup = conversationInfo?.conversationType === ConversationTypeEnum.Group;

  const antispamFilter = convertAntispamFilter.fromDataToModel(action.payload, isPrivateGroup);
  yield put(antispamFilterSlice.actions.setAntispamFilterCompleted(antispamFilter));
}

function* toggleIsEnabled(action: PayloadAction<boolean>) {
  try {
    yield put(beginScope("antispamFilter/toggleLoading"));

    const antispamFilter: AntispamFilterModel = yield select(selectAntispamFilter);
    const groupInfo: GroupChatModel = yield select(selectGroupChatInfo);

    yield call(AntispamFilterApi.changeAntispamFilter, {
      groupId: groupInfo.id,
      antispamFilter: { ...convertAntispamFilter.fromModelToData(antispamFilter), isEnabled: action.payload },
    });

    const currentAntispamGroupCount: number = yield select(selectCurrentAntispamGroupCount);
    yield put(antispamFilterSlice.actions.toggleIsEnabledCompleted(action.payload));
    yield put(
      tariffPlanSlice.actions.setCurrentAntispamGroupCount(
        action.payload ? currentAntispamGroupCount + 1 : currentAntispamGroupCount - 1,
      ),
    );
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("antispamFilter/toggleLoading"));
  }
}

function* saveChanges(action: PayloadAction<AntispamFilterModel>) {
  try {
    yield put(beginScope("antispamFilter/saveChanges"));

    const groupInfo: GroupChatModel = yield select(selectGroupChatInfo);

    const antispamFilter: AntispamFilterData = yield call(AntispamFilterApi.changeAntispamFilter, {
      groupId: groupInfo.id,
      antispamFilter: convertAntispamFilter.fromModelToData(action.payload),
    });

    const conversationInfo: ConversationInfoModel | undefined = yield select(selectConversationInfo);
    const isPrivateGroup = conversationInfo?.conversationType === ConversationTypeEnum.Group;

    const convertedAntispamFilter = convertAntispamFilter.fromDataToModel(antispamFilter, isPrivateGroup);
    yield put(antispamFilterSlice.actions.saveChangesCompleted(convertedAntispamFilter));
    yield put(
      notificationSlice.actions.notify({
        message: "Saved successfully!",
        type: "success",
      }),
    );
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("antispamFilter/saveChanges"));
  }
}
