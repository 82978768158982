import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../../../../../../../common/state/store";
import { Button, Flex, Icon, Skeleton, Text } from "@chakra-ui/react";
import s from "../../../../../GroupChatInfo.module.scss";
import { GroupChatListModel } from "../../../../../../../../../GroupChatModel";
import AntispamFilterIcon from "../../../../../../../../../../../assets/icons/antispamFilterIcon.svg?react";
import BackArrowIcon from "../../../../../../../../../../../assets/icons/backArrowIcon.svg?react";
import {
  selectIsAntispamFilterEnabled,
  setIsOpenAntispamFilter,
} from "../../../../../../Tabs/AntispamFilter/AntispamFilterSlice";
import { useSelectLimitPlan } from "../../../../../../../../../../../common/hooks/useSelectLimitPlan";
import { ICountTariffPlanFeature, TariffPlanFeatureTypeEnum } from "../../../../../../../../../../../common/AppEnums";
import { selectCurrentAntispamGroupCount } from "../../../../../../../../../../../common/tariffPlan/TariffPlanSlice";
import { LimitStarPlanButton } from "../../../../../../../../../../../UI/molecules/limitStarPlanButton/LimitStarPlanButton";

interface Props {
  loadingGroupChatInfo: boolean;
  groupChatList: GroupChatListModel | undefined;
}

export const AntispamFilterButton = ({ loadingGroupChatInfo, groupChatList }: Props) => {
  const commonWords = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const groupChatInfoTranslation = useTranslation("translation", { keyPrefix: "groupChat.groupChatInfo" }).t;
  const dispatch = useAppDispatch();
  const isEnabled = useAppSelector(selectIsAntispamFilterEnabled);
  const limitInfo = useSelectLimitPlan(TariffPlanFeatureTypeEnum.GroupAntispamCount) as ICountTariffPlanFeature;
  const currentAntispamGroupCount = useAppSelector(selectCurrentAntispamGroupCount);
  const isAvailable = limitInfo.IsUnlimited || currentAntispamGroupCount < limitInfo.MaxCount || isEnabled;

  return (
    <Skeleton isLoaded={!(loadingGroupChatInfo || groupChatList === undefined)}>
      <LimitStarPlanButton popoverBodyDescription="Please upgrade your plan to use AI anti-spam" canBeTriggered={!isAvailable}>
        <Button
          className={`${s.buttonInfo} ${s.buttonFirst}`}
          borderRadius="0px"
          w="100%"
          leftIcon={<Icon className={s.leftIcon} boxSize="20px" as={AntispamFilterIcon} />}
          rightIcon={<Icon className={s.rightIcon} transform="rotate(180deg)" p="3px" boxSize="20px" as={BackArrowIcon} />}
          onClick={() => {
            dispatch(setIsOpenAntispamFilter(true));
          }}
          isDisabled={!isAvailable}
        >
          <Flex w="100%" align="center" justifyContent="space-between">
            <Text variant="medium" color="newBlack">
              {groupChatInfoTranslation("AI anti-spam")}
            </Text>
            <Text variant="medium" color="var(--chakra-colors-darkGrey)">
              {isEnabled ? commonWords("On") : commonWords("Off")}
            </Text>
          </Flex>
        </Button>
      </LimitStarPlanButton>
    </Skeleton>
  );
};
