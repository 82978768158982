import { BaseEditor } from "slate";
import { ReactEditor } from "slate-react";
import { Entity } from "../../../common/formattedText/entity";

export interface TextFormatterInitialValue {
  text: string;
  entities?: Entity[];
}

export type EditorType = BaseEditor & ReactEditor & { charactersLeft: number; maxLength: number };
export type CustomText = {
  text: string;
  [TextMarks.Bold]?: boolean;
  [TextMarks.Italic]?: boolean;
  [TextMarks.Monospace]?: boolean;
};
export enum ElementTypes {
  Link = "text_link",
  Paragraph = "paragraph",
}

export type ParagraphElementType = {
  type: ElementTypes.Paragraph;
  children: CustomText[];
};
export type LinkElementType = {
  type: ElementTypes.Link;
  children: CustomText[];
  href: string;
};
export type CustomElement = ParagraphElementType | LinkElementType;

export enum TextMarks {
  Bold = "bold",
  Italic = "italic",
  Monospace = "code",
}

export type Selection = Pick<EditorType, "selection" | "children" | "insertText"> & { editor: EditorType };
