import { useCallback, useEffect, DragEvent, useState, useRef, ClipboardEvent } from "react";
import { useAppDispatch, useAppSelector } from "../../common/state/store";
import { Box, Flex, Text, Icon, useMediaQuery, Skeleton } from "@chakra-ui/react";
import { selectBot, selectCurrentBot } from "../sidebar/SidebarSlice";
import { MessagesList } from "./components/MesssagesList";
import {
  clearMessages,
  getConversationInfo,
  getConversationMessages,
  getConversationMessagesSucceed,
  getCustomVariables,
  selectConversationInfo,
  selectConversationMessage,
  selectConversationMessages,
  selectConversationMessageValidation,
  selectConversationVariables,
  sendConversationMessage,
  setConversationMessage,
  uploadMessageFile,
  unblockConversation,
  selectScrollToMessageId,
} from "./ConversationSlice";
import { useParams, useNavigate } from "react-router-dom";
import s from "./Conversation.module.scss";
import { MessageBox } from "./components/MessageBox/MessageBox";
import { ConversationSendMessageModel, FileUploadType } from "./ConversationModel";
import { Loading } from "../../common/loading/LoadingStateContainer";
import { OutgoingTypeDiscriminator } from "./ConversationData";
import { IMAGE_FILE_EXTS } from "../../common/AppEnums";
import { useTranslation } from "react-i18next";
import BackArrowMenu from "../../assets/images/downArrow.svg?react";
import EmptyChatIcon from "../../assets/icons/emptyChat.svg?react";
import DotMenuContainer from "../../UI/atoms/DotMenu/DotMenuContainer";
import { ConversationViewerContainer } from "../conversationViewer/ConversationViewerContainer";

export interface Props {
  onContactInfoOpen?: () => void;
}

export const ConversationContainer = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "conversation" });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const id = params["conversationId"];

  const currentBot = useAppSelector(selectCurrentBot);
  const messageList = useAppSelector(selectConversationMessages);
  const conversationInfo = useAppSelector(selectConversationInfo);
  const variables = useAppSelector(selectConversationVariables);
  const message = useAppSelector(selectConversationMessage);
  const messageValidation = useAppSelector(selectConversationMessageValidation);
  const scrollToMessageId = useAppSelector(selectScrollToMessageId);

  const [isDragging, setDragging] = useState(false);
  const [isTablet] = useMediaQuery("(min-width: 999px) and (max-width: 1269px)");
  const [isMobile] = useMediaQuery("(max-width: 999px)");
  const [isNoteActive, setIsNoteActive] = useState(false);
  const [replyMode, setReplyMode] = useState<{
    enabled: boolean;
    message?: {
      id: string;
      author: string;
      text?: string;
      photoFileId?: string;
      documentName?: string;
      contact?: string;
    };
  }>({ enabled: false });
  const isWebBot = currentBot?.channel === "Web";

  const myRef = useRef<HTMLDivElement>(null);
  const getMessages = useCallback(
    (lastId: string, searchValue?: string, currentSearchMessagesLength?: number, includeMessageId?: string) => {
      if (id) {
        dispatch(
          getConversationMessages({
            conversationId: id,
            lastId,
            searchValue,
            currentSearchMessagesLength,
            includeMessageId,
          }),
        );
      }
    },
    [dispatch, id],
  );

  const clearSearchParams = () => {
    if (messageList) {
      dispatch(getConversationMessagesSucceed({ ...messageList, isNextSearch: true, totalSearchItems: undefined }));
    }
  };

  useEffect(() => {
    if (currentBot && id) {
      dispatch(clearMessages());
      dispatch(
        setConversationMessage({
          text: "",
          typeDiscriminator: OutgoingTypeDiscriminator.OutgoingTextMessage,
        }),
      );
      dispatch(getConversationInfo({ conversationId: id }));
      getMessages("");
      dispatch(getCustomVariables({ botId: currentBot.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBot, dispatch, getMessages, id]);

  useEffect(() => {
    if (conversationInfo && currentBot && currentBot.id !== conversationInfo?.botId) {
      dispatch(selectBot(conversationInfo.botId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationInfo, currentBot]);

  const sendMessage = (message: ConversationSendMessageModel) => {
    if (!id) {
      return;
    }
    dispatch(sendConversationMessage({ conversationId: id, message }));
  };

  const setMessage = (message: ConversationSendMessageModel) => {
    dispatch(setConversationMessage(message));
  };

  useEffect(() => {
    setReplyMode({ enabled: false });
  }, [id]);

  const uploadFile = (file: File, type: FileUploadType) => {
    dispatch(uploadMessageFile({ file, type }));
  };

  const onDropFile = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);
    const file = event.dataTransfer?.files[0];
    if (file) {
      const [type, ext] = file.type.split("/");
      if (type === "image" && IMAGE_FILE_EXTS.includes(ext)) {
        uploadFile(file, FileUploadType.photo);
      } else {
        uploadFile(file, FileUploadType.document);
      }
    }
  };

  const handleDrag = function (e: DragEvent<HTMLDivElement>) {
    if (isNoteActive || isWebBot) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    if (!message.document && !message.photo) {
      if (e.type === "dragenter" || e.type === "dragover") {
        setDragging(true);
      } else if (e.type === "dragleave" || e.type === "dragend") {
        setDragging(false);
      }
    }
  };

  const handlePasteFromClipBoard = (e: ClipboardEvent<HTMLDivElement>) => {
    const { clipboardData } = e;
    if (clipboardData && clipboardData.files.length > 0) {
      const file = clipboardData?.files[0];

      if (file && !message.document && !message.photo) {
        e.preventDefault();
        if (file.type.match("^image/")) {
          uploadFile(file, FileUploadType.photo);
        } else {
          uploadFile(file, FileUploadType.document);
        }
      }
    }
  };

  const onUnblockConversation = () => {
    if (!conversationInfo) return;
    dispatch(unblockConversation({ conversationId: conversationInfo.id }));
  };

  if (!conversationInfo || !id) {
    return (
      <Skeleton
        speed={0.5}
        startColor="line"
        endColor="bgLight"
        borderRadius="4px"
        height="100%"
        width="100%"
        border="1px solid"
        borderColor="line"
      />
    );
  }

  return (
    <>
      <Loading scope="messageList" />

      <Box onDragEnter={handleDrag} className={`${s.conversationContainer} ${isDragging ? s.dragging : ""} `}>
        {(isMobile || isTablet) && (
          <Flex className={s.topContactInfo}>
            {isMobile && (
              <Flex
                alignItems={"cener"}
                ml={"-16px"}
                onClick={() =>
                  navigate(`/chats`, {
                    replace: true,
                  })
                }
              >
                <Icon as={BackArrowMenu} boxSize="42px" color="midDeepBlue" transform={"rotate(90deg)"} borderRadius={"4px"} />
              </Flex>
            )}
            <Box
              fontWeight={"bold"}
              flexGrow={"1"}
              maxW={"100%"}
              whiteSpace={"nowrap"}
              overflow={"hidden"}
              textOverflow={"ellipsis"}
            >
              <Text className={s.text}>{conversationInfo.name}</Text>
            </Box>
            {conversationInfo?.contact?.id && (
              <DotMenuContainer
                menuItems={[
                  {
                    MenuItem: {
                      icon: <></>,
                      text: t("Contact info"),
                      clickEvent: () => {
                        props.onContactInfoOpen?.();
                      },
                    },
                  },
                ]}
              />
            )}
          </Flex>
        )}
        <Flex flexDir={"column"} className={s.messageListContainer}>
          {messageList?.items?.length === 0 && (
            <Flex className={s.nothingYet}>
              <EmptyChatIcon />
              <Text variant="medium" color="darkGrey">
                {t("There's nothing here yet")}
              </Text>
            </Flex>
          )}
          {messageList?.items && (
            <MessagesList
              onReply={reply => setReplyMode(reply)}
              conversationInfo={conversationInfo}
              botName={currentBot?.username || "Unnamed Bot"}
              messageList={messageList}
              getMessages={getMessages}
              clearSearchParams={clearSearchParams}
              scrollToMessageId={scrollToMessageId}
            />
          )}
        </Flex>

        <Box className={`${s.sendMessageContainer} loaderContainer`} ref={myRef}>
          <ConversationViewerContainer id={id} getMessages={getMessages} />
          <MessageBox
            replyMode={replyMode}
            onCloseReply={() => setReplyMode({ enabled: false })}
            isNoteActive={isNoteActive}
            isBlocked={conversationInfo.isBlocked}
            onUnblockClick={onUnblockConversation}
            setIsNoteActive={setIsNoteActive}
            message={message}
            variables={variables}
            validation={messageValidation}
            onUploadFile={uploadFile}
            onSetMessage={setMessage}
            onSendMessage={sendMessage}
            onPasteFileFromClipboard={handlePasteFromClipBoard}
          ></MessageBox>
        </Box>
        {isDragging && !message.document && !message.photo && !conversationInfo.isBlocked && (
          <div
            className={s.dragFileElement}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={onDropFile}
          ></div>
        )}
        {isDragging && !message.document && !message.photo && !conversationInfo.isBlocked && (
          <div className={s.dragFileContainer}>
            <div className={s.dragFileContent}>
              <Text maxW="195px" variant="largeBold" color="white">
                {t("Drag and drop the file here to attach to the message")}
              </Text>
            </div>
          </div>
        )}
      </Box>
    </>
  );
};
