export enum AntispamFilterDataTypeEnum {
  Warning = "Warning",
  Mute = "Mute",
  Block = "Block",
}

interface AntispamFilterRule {
  violation: number;
  ruleAction: {
    type: AntispamFilterDataTypeEnum;
    forLong?: string | null; // "hh:mm" | "dd.mm:hh"
  };
}

export interface ViolationNotificationData {
  tgUsernames: string[];
  isEnabled: boolean;
}

export interface TGUsersWhiteListData {
  tgUsernames: string[];
  isEnabled: boolean;
}

export interface UrlWhiteListData {
  urls: string[];
  isEnabled: boolean;
}

export interface PhraseWhiteListData {
  phrases: string[];
  isEnabled: boolean;
}

export interface AntispamFilterData {
  isEnabled: boolean;
  rules: AntispamFilterRule[];
  prohibitedTopics: string[];
  violationNotificationSettings: ViolationNotificationData;
  tgUsersWhiteListSettings: TGUsersWhiteListData;
  urlWhiteListSettings: UrlWhiteListData;
  phraseWhiteListSettings: PhraseWhiteListData;
}
