import { Box, Button } from "@chakra-ui/react";
import { ConversationSendMessageModel, FileUploadType } from "../../ConversationModel";
import s from "./MessageBox.module.scss";
import ImageIcon from "../../../../assets/icons/imageChatIcon.svg?react";
import { ValidationModel } from "../../../../common/ErrorModel";
import { Loading } from "../../../../common/loading/LoadingStateContainer";
import { useTranslation } from "react-i18next";
import PaperClipIcon from "../../../../assets/icons/paperClipIcon.svg?react";
import NoteIcon from "../../../../assets/icons/noteIcon.svg?react";
import UnblockIcon from "../../../../assets/icons/revote.svg?react";
import { ClipboardEvent, Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { CustomVariableModel, IMAGE_FILE_EXTS } from "../../../../common/AppEnums";
import { useGetLoadingState } from "../../../../common/loading/hooks/useGetLoadingState";
import { OutgoingTypeDiscriminator } from "../../ConversationData";
import { useAppSelector } from "../../../../common/state/store";
import { selectCurrentBot } from "../../../sidebar/SidebarSlice";
import Attachments from "./components/Attachments/Attachments";
import { TextFormatterWithReply } from "../../../../UI/molecules/TextFormatter/TextFormatterWithReply/TextFormatterWithReply";

interface DefaultProps {
  message: ConversationSendMessageModel;
  variables?: CustomVariableModel[];
  validation?: ValidationModel;
  isNoteActive: boolean;
  isBlocked: boolean;
  setIsNoteActive: Dispatch<SetStateAction<boolean>>;
  replyMode: {
    enabled: boolean;
    message?: {
      id: string;
      author: string;
      text?: string;
      photoFileId?: string;
      documentName?: string;
      contact?: string;
    };
  };
}

interface EventProps {
  onSetMessage: (message: ConversationSendMessageModel) => void;
  onSendMessage: (message: ConversationSendMessageModel) => void;
  onUploadFile: (file: File, type: FileUploadType) => void;
  onCloseReply: () => void;
  onPasteFileFromClipboard: (e: ClipboardEvent<HTMLDivElement>) => void;
  onUnblockClick: () => void;
}

type Props = DefaultProps & EventProps;

export const MessageBox = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "conversation" });

  const inputMaxLength = props.message.photo == null && props.message.document == null ? 2000 : 1024;
  const textareaRef = useRef<HTMLDivElement>(null);
  const [localMessageText, setLocalMessageText] = useState("");
  const loadingFileUpload = useGetLoadingState("messageFileUpload");
  const isLoadingSendMessage = useGetLoadingState("sendMessage");
  const isLoadingUnblock = useGetLoadingState("unblockConversation");

  const [replyMode, setReplyMode] = useState<{
    enabled: boolean;
    message?: {
      id: string;
      author: string;
      text?: string;
      photoFileId?: string;
      documentName?: string;
      contact?: string;
    };
  }>(props.replyMode);
  const botInfo = useAppSelector(selectCurrentBot);

  const onSend = () => {
    if (props.validation?.errors.length && props.validation.errors.length > 0) return;

    if (props.isNoteActive) {
      props.onSendMessage({
        text: localMessageText,
        typeDiscriminator: OutgoingTypeDiscriminator.NoteTextConversation,
      });
    } else if (replyMode.enabled) {
      props.onSendMessage({
        ...props.message,
        text: localMessageText,
        replyToMessageId: replyMode.message?.id,
      });
    } else {
      props.onSendMessage({
        ...props.message,
        text: localMessageText,
      });
    }
    clearLocalMessage();
    props.onCloseReply();
    props.setIsNoteActive(false);
  };

  const clearLocalMessage = () => {
    setLocalMessageText("");
  };

  const onSetMessageText = (text: string) => {
    setLocalMessageText(text);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      props.onSetMessage({ ...props.message, text: localMessageText });
    }, 500);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localMessageText]);

  useEffect(() => {
    setLocalMessageText(props.message.text);
  }, [props.message]);

  useEffect(() => {
    if (props.isBlocked) {
      const updatedMessage = {
        ...props.message,
        text: "",
        photo: undefined,
        replyToMessageId: undefined,
        document: undefined,
      };
      props.onSetMessage(updatedMessage);
      props.setIsNoteActive(false);
      props.onCloseReply();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isBlocked]);

  useEffect(() => {
    setReplyMode(props.replyMode);
    if (props.replyMode.enabled) {
      setTimeout(() => {
        textareaRef?.current?.focus();
      }, 1);
      props.setIsNoteActive(false);
    }
  }, [props, props.replyMode]);

  useEffect(() => {
    if (props.isNoteActive) setReplyMode({ enabled: false });
  }, [props.isNoteActive]);

  const removeAttachedFile = () => {
    const updatedMessage = {
      ...props.message,
      photo: undefined,
      document: undefined,
    };
    props.onSetMessage(updatedMessage);
  };

  const isWebBot = botInfo?.channel === "Web";

  return (
    <Box className={`${s.messageBox} loaderContainer`}>
      <TextFormatterWithReply
        data-pw="message-textarea"
        maxLength={inputMaxLength}
        placeholder={
          props.isBlocked
            ? `${t("This chat is blocked for sending messages...")}`
            : replyMode.enabled
            ? `${t("Reply here")}`
            : !props.isNoteActive
            ? `${t("Write a message...")}`
            : `${t("Enter a personal note")}`
        }
        isDisabled={props.isBlocked}
        wrapperClassname={props.isNoteActive ? s.noteInputActive : s.inputWrapper}
        ref={textareaRef}
        replyMode={replyMode}
        onCloseReply={props.onCloseReply}
        botName={botInfo?.name}
        variables={!props.isNoteActive ? props.variables : undefined}
        handleChange={onSetMessageText}
        value={localMessageText}
        handleSubmit={onSend}
        isNoteActive={props.isNoteActive}
        onPaste={!props.isNoteActive ? (evt: ClipboardEvent<HTMLDivElement>) => props.onPasteFileFromClipboard(evt) : undefined}
      />
      <Loading scope="messageFileUpload" />

      {(props.message.document || props.message.photo) && (
        <Attachments removeAttachedFile={removeAttachedFile} message={props.message} />
      )}

      <Box className={s.boxActions}>
        <Box className={`${s.actionsWr}`}>
          {(Object.keys(FileUploadType) as Array<keyof typeof FileUploadType>).map(el => (
            <Box key={el}>
              <label
                data-pw={el === "photo" ? "image-btn" : "document-btn"}
                className={`${s.input} ${
                  props.message.document || props.message.photo || props.isNoteActive || isWebBot || props.isBlocked
                    ? s.disabledInput
                    : ""
                }`}
              >
                {el === "photo" ? (
                  <ImageIcon
                    className={`${s.actionIconItem} ${
                      props.message.document || props.message.photo || props.isBlocked ? s.disabled : ""
                    }`}
                  />
                ) : (
                  <PaperClipIcon
                    className={`${s.actionIconItem} ${
                      props.message.document || props.message.photo || props.isBlocked ? s.disabled : ""
                    }`}
                  />
                )}
                {!props.message.document && !props.message.photo && !props.isNoteActive && !isWebBot && (
                  <input
                    className={s.hidden}
                    disabled={props.isBlocked || isWebBot}
                    onChange={e => {
                      if (e.target.files) {
                        props.onUploadFile(e.target.files[0], el === "photo" ? FileUploadType.photo : FileUploadType.document);
                      }
                    }}
                    type="file"
                    accept={el === "photo" ? IMAGE_FILE_EXTS : ""}
                  />
                )}
              </label>
            </Box>
          ))}
        </Box>
        <Box
          ml="4px"
          w="24px"
          h="24px"
          onClick={() => {
            if (props.isBlocked) return;
            props.setIsNoteActive(!props.isNoteActive);
            removeAttachedFile();
          }}
          data-pw="note-btn"
        >
          <NoteIcon className={`${props.isBlocked ? s.noteDisabled : s.noteIcon} ${props.isNoteActive && s.noteActive}`} />
        </Box>
        <Box ml={"auto"}>
          {!isWebBot && props.isBlocked ? (
            <Button
              width={"100%"}
              variant="dominoOutlineRed"
              flexShrink={0}
              iconSpacing={"4px"}
              isLoading={isLoadingUnblock}
              loadingText={t("Unblock")}
              spinnerPlacement="start"
              leftIcon={<UnblockIcon color={"inherit"} />}
              onClick={props.onUnblockClick}
            >
              <span>{t("Unblock")}</span>
            </Button>
          ) : (
            <Button
              isDisabled={
                !props.validation ||
                !!props.validation.errors.length ||
                loadingFileUpload ||
                localMessageText.length > inputMaxLength
              }
              onClick={() => onSend()}
              size={"sm"}
              variant="dominoViolet"
              data-pw="send-btn"
              isLoading={isLoadingSendMessage}
              loadingText={t("Send")}
              spinnerPlacement="end"
            >
              <span>{t("Send")}</span>
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};
