import React from "react";
import s from "./AddNodePopup.module.scss";
import { LimitStarPlanButton } from "../../../../UI/molecules/limitStarPlanButton/LimitStarPlanButton";
import { Flex, Icon, Box } from "@chakra-ui/react";
import { NodeItem } from "./NodeItem";
import { FlowActionType, FlowModel, NodeModel, ViewNodeModel } from "../../FlowBuilderModel";
import sp from "../../../../common/tariffPlan/TariffPlanStarPlan.module.scss";
import StarPlan from "../../../../assets/icons/starPlan.svg?react";
import { useTranslation } from "react-i18next";
import { useSelectLimitPlan } from "../../../../common/hooks/useSelectLimitPlan";
import { INodeTariffPlanFeature, TariffPlanFeatureTypeEnum } from "../../../../common/AppEnums";

interface Props {
  addNode: (newNode: NodeModel) => void;
  onClose: () => void;
  flowBuilderWidth: number;
  flowBuilderHeight: number;
  item: ViewNodeModel;
  flow?: FlowModel;
  type?: TariffPlanFeatureTypeEnum;
}

export default function HOCNodeItem(props: Props) {
  const isDisabledNode = (type: keyof typeof FlowActionType) => {
    switch (type) {
      case FlowActionType.AiFlowAction:
        return !!props.flow?.nodes.find(el => el.data?.typeDiscriminator === FlowActionType.AiFlowAction);
      default:
        return false;
    }
  };
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const accessibility = useSelectLimitPlan(props.type) as INodeTariffPlanFeature;
  const { IsAvailable } = props.type ? accessibility : { IsAvailable: true };

  return (
    <>
      {!IsAvailable ? (
        <LimitStarPlanButton popoverBodyDescription="Please upgrade your plan to add more nodes in the Flow">
          <Flex className={s.starPlanItem}>
            <Flex align="center" gap="8px">
              <Icon className={s.svg} as={props.item.icon} />
              <Box maxW="110px" className={s.typeName}>
                {t(props.item.title)}
              </Box>
            </Flex>
            <Box className={`${sp.starPlanHover} ${s.starPlan}`}>
              <Icon boxSize="14px" as={StarPlan} />
            </Box>
          </Flex>
        </LimitStarPlanButton>
      ) : (
        <NodeItem
          key={props.item.typeDiscriminator}
          title={props.item.title}
          actionType={props.item.typeDiscriminator}
          iconType={props.item.icon}
          isDisabled={isDisabledNode(props.item.typeDiscriminator)}
          addNode={props.addNode}
          flowBuilderWidth={props.flowBuilderWidth}
          flowBuilderHeight={props.flowBuilderHeight}
        />
      )}
    </>
  );
}
