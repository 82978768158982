import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuList,
  Text,
} from "@chakra-ui/react";
import s from "../../../../ContactInfo.module.scss";
import { useTranslation } from "react-i18next";
import { AssignedItemModel } from "../../../../../conversation/ConversationModel";
import Magnifire from "../../../../../../assets/images/magnifire.svg?react";
import CrossIcon from "../../../../../../assets/icons/cross.svg?react";
import DownArrowMenu from "../../../../../../assets/images/downArrow.svg?react";
import { Dispatch, SetStateAction } from "react";
import { OrganisationUsersModel } from "../../../../../organisation/OrganisationModel";
import { CustomMenuItem } from "../../../../../../UI/molecules/customMenuItem/CustomMenuItem";

interface Props {
  assignState: { team?: AssignedItemModel | undefined; operator?: AssignedItemModel | undefined };
  onMenuClose: (type: "Team" | "Operator") => void;
  getAdditionalItems: (item: "Team" | "Operator") => void;
  onSearchTextChange: (value: string, type: "Team" | "Operator") => void;
  operatorSearchValue: string;
  setAssignState: Dispatch<SetStateAction<{ team?: AssignedItemModel | undefined; operator?: AssignedItemModel | undefined }>>;
  operators: OrganisationUsersModel | undefined;
  isDisabled?: boolean;
}

export const ContactInfoAssignedOperator = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "contactInfo" });
  return (
    <>
      <Heading fontSize={"16px"} fontWeight={"400"} mt="16px">
        {t("Assigned Operator")}
      </Heading>
      <Menu
        preventOverflow={false}
        matchWidth={true}
        variant="dominoGroupedMenu"
        gutter={0}
        onClose={() => props.onMenuClose("Operator")}
      >
        {({ isOpen, onClose }) => (
          <>
            <MenuButton
              _active={{ borderColor: "line", backgroundColor: "white" }}
              as={Button}
              isDisabled={props.isDisabled}
              variant="dominoDefaultMenuBtn"
              className={s.menuButton}
              data-pw="assigned-operator-dropdown"
              isActive={isOpen}
            >
              {props.assignState.operator?.name ? (
                <Text className={s.text} fontSize={"15px"}>
                  {props.assignState.operator.name}
                </Text>
              ) : (
                <Text fontSize={"15px"} color={"darkGrey"}>
                  {t("Select")}
                </Text>
              )}
            </MenuButton>
            <MenuList w="100%" position="absolute" top="-37px">
              <Box className={s.dropdownSearchBox}>
                <InputGroup w="auto">
                  <InputLeftElement pointerEvents="none">
                    <Magnifire />
                  </InputLeftElement>
                  <Input
                    isInvalid={false}
                    autoFocus
                    value={props.operatorSearchValue}
                    placeholder={t(`Search by Operators`) ?? ""}
                    onChange={e => props.onSearchTextChange(e.target.value, "Operator")}
                    data-pw="operator-search"
                  />
                  <InputRightElement right="10px" top="50%" mt={"-11px"} boxSize="22px">
                    {props.operatorSearchValue && (
                      <CrossIcon
                        className={s.crossIcon}
                        cursor="pointer"
                        onClick={() => props.onSearchTextChange("", "Operator")}
                      />
                    )}
                  </InputRightElement>
                </InputGroup>
              </Box>
              <Box overflow="scroll" maxH="300px">
                <CustomMenuItem
                  height={{ base: "40px", lg: "40px" }}
                  fontSize="15px"
                  lineHeight="18px"
                  onClick={() => {
                    props.setAssignState(prevState => {
                      return { ...prevState, operator: { id: "", name: "" } };
                    });
                    onClose();
                  }}
                  data-pw="not-set"
                >
                  {t("Not set")}
                </CustomMenuItem>
                {props.operators?.items.map((el, i) => {
                  return (
                    <CustomMenuItem
                      key={i}
                      height={{ base: "40px", lg: "40px" }}
                      fontSize="15px"
                      lineHeight="18px"
                      onClick={() => {
                        props.setAssignState(prevState => {
                          return { ...prevState, operator: { id: el.userId, name: el.username } };
                        });
                        onClose();
                      }}
                      data-pw={el.username}
                    >
                      {el.username}
                    </CustomMenuItem>
                  );
                })}
                {props.operators && props.operators.currentPage < props.operators.totalPages && (
                  <CustomMenuItem
                    closeOnSelect={false}
                    onClick={() => props.getAdditionalItems("Operator")}
                    height={{ base: "40px", lg: "40px" }}
                    fontSize="15px"
                    lineHeight="18px"
                    data-pw="show-more"
                  >
                    <Flex alignItems={"center"} justifyContent={"center"} w={"100%"} cursor={"pointer"}>
                      <div>{t("Show more")}</div>
                      <Icon as={DownArrowMenu} boxSize="26px" color="midDeepBlue" />
                    </Flex>
                  </CustomMenuItem>
                )}
              </Box>
            </MenuList>
          </>
        )}
      </Menu>
    </>
  );
};
