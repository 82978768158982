import React from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import s from "../../BroadcastList.module.scss";
import { Box, Text, Card, CardBody, Flex, Icon, Skeleton } from "@chakra-ui/react";
import RedTrashIcon from "../../../../assets/icons/trash.svg?react";
import DuplicateIcon from "../../../../assets/icons/copyIconMenu.svg?react";
import CancelIcon from "../../../../assets/icons/cancelIcon.svg?react";
import ChatIcon from "../../../../assets/icons/sidebar/chatIcon.svg?react";
import GroupChat from "../../../../assets/icons/sidebar/groupChat.svg?react";
import { BroadcastItemModel, BroadcastListModel, BroadcastStatus } from "../../BroadcastListModel";
import { StatusTag } from "./components/StatusTag/StatusTag";
import { useTranslation } from "react-i18next";
import * as vars from "../../../layout/LayoutHelper/ResolutionConst";
import { useGAWithCustomParameters } from "../../../../common/ga/GAEventTracker";
import DotMenuContainer from "../../../../UI/atoms/DotMenu/DotMenuContainer";
import { setTitleStatusIsNew, setTitleValidation } from "../../../broadcast/BroadcastSlice";
import { useAppDispatch } from "../../../../common/state/store";
import { EventCategories } from "../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import { BroadcastDirection } from "../../../broadcast/BroadcastModel";
import { TariffPlanFeatureTypeEnum, INodeTariffPlanFeature } from "../../../../common/AppEnums";
import { useSelectLimitPlan } from "../../../../common/hooks/useSelectLimitPlan";
import { useGetLoadingState } from "../../../../common/loading/hooks/useGetLoadingState";
import { useSmoothLoading } from "../../../../common/hooks/useSmoothLoading";

interface Props {
  broadcastList?: BroadcastListModel;
  currentPage: number;
  onBroadcastDeleteClick: (id: string, itemName: string) => void;
  onBroadcastCancelClick: (id: string, itemName: string) => void;
  onDuplicateClick: (id: string) => void;
  search: string;
}

export const BroadcastList = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "broadcastList" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const locale = localStorage.getItem("i18nextLng");

  const checkCancelable = (status: BroadcastStatus) => status === BroadcastStatus.Scheduled || status === BroadcastStatus.Sending;
  const checkDeletable = (status: BroadcastStatus) => status === BroadcastStatus.Draft;

  const broadcastEditStatuses = ["Draft"];
  const groupListLimit = useSelectLimitPlan(TariffPlanFeatureTypeEnum.BroadcastForGroupsFeature);
  const { IsAvailable } = groupListLimit as INodeTariffPlanFeature;

  const urlNaviagation = (broadcastStatus: string) => {
    return broadcastEditStatuses.find(el => el === broadcastStatus) ? "edit" : "view";
  };

  const selectBroadcast = (broadcast: BroadcastItemModel) => () => {
    trackEvent("BroadcastEdit");
    navigate(`/broadcasts/${broadcast.id}/${urlNaviagation(broadcast.status)}`, {
      state: { page: props.currentPage },
    });
    dispatch(setTitleStatusIsNew(false));
    dispatch(setTitleValidation(false));
    // dispatch(setBroadcastDirection(broadcast.direction));
  };

  const isDefaultLoading = useGetLoadingState("default");
  const isBroadcastsLoading = useGetLoadingState("getBroadcasts");
  const isLoading = useSmoothLoading([isDefaultLoading, isBroadcastsLoading], 50);
  const trackEvent = useGAWithCustomParameters(EventCategories.Broadcast);

  return (
    <Box position={"relative"} h="100%">
      <Box className={`${s.broadcastListItemGrid} ${s.broadcastListFilter}`} position="absolute">
        <Text noOfLines={1}>{t("Name")}</Text>
        <Text noOfLines={2}>{t("Recipients count")}</Text>
        <Text mr="36px" noOfLines={1}>
          {t("Status")}
        </Text>
        <Text mr="36px" noOfLines={1}>
          {t("Scheduled")}
        </Text>
        <div />
      </Box>
      <Box
        className={s.listDesktop}
        height={window.innerWidth > vars.TABLET_VIEW ? `calc(100vh - ${vars.HEADER}px)` : "100%"}
        overflowY={!(props?.broadcastList?.items.length === 0 && props.search === "") ? "scroll" : "hidden"}
        paddingBottom={"64px"}
        paddingTop={window.innerWidth > vars.TABLET_VIEW ? "52px" : "0"}
      >
        {isLoading ? (
          <>
            {Array(10)
              .fill(1)
              .map((el, ind) => (
                <Skeleton key={ind} h="70px" mb="10px" speed={0.5} borderRadius="12px" startColor="line" endColor="bgLight" />
              ))}
          </>
        ) : (
          props.broadcastList?.items.map(broadcast => {
            return (
              <Card className={s.desktopCard} key={broadcast.id} variant="dominoListTile">
                <CardBody onClick={selectBroadcast(broadcast)} data-pw="broadcast-item">
                  <Box className={`${s.broadcastListItemGrid}`}>
                    <Icon as={broadcast.direction === BroadcastDirection.TelegramGroup ? GroupChat : ChatIcon} boxSize="20px" />
                    <Text data-pw="broadcast-item-name" noOfLines={1}>
                      {broadcast.name}
                    </Text>
                    <Text data-pw="broadcast-item-recipients-count" mr="36px" noOfLines={2}>
                      {broadcast.recipientsCount}
                    </Text>
                    <StatusTag variant="dominoSubtle" broadcastStatus={broadcast.status} />
                    <Text data-pw="broadcast-item-scheduled" noOfLines={1}>
                      {broadcast.scheduledDate ?? broadcast.startedDate
                        ? dayjs(broadcast.scheduledDate ?? broadcast.startedDate).format("DD.MM.YYYY")
                        : "n/a"}{" "}
                      {(broadcast.scheduledDate ?? broadcast.startedDate) && (
                        <b className={s.greyColor}>
                          {dayjs(broadcast.scheduledDate ?? broadcast.startedDate)
                            .locale(locale || "en")
                            .format(locale === "en" ? "h:mm a" : "HH:mm")}
                        </b>
                      )}
                    </Text>
                    <Box>
                      <DotMenuContainer
                        key={broadcast.id}
                        menuItems={[
                          {
                            isActive: IsAvailable ? true : broadcast.direction !== BroadcastDirection.TelegramGroup,
                            MenuItem: {
                              icon: <DuplicateIcon width="18px" height="18px" />,
                              text: t("Duplicate"),
                              clickEvent: e => {
                                e.stopPropagation();
                                trackEvent("BroadcastMenu", "", { broadcast_event: "Dublicate" });
                                props.onDuplicateClick(broadcast.id);
                              },
                              isBroadcast: true,
                            },
                          },
                          {
                            MenuItem: checkCancelable(broadcast.status)
                              ? {
                                  icon: <CancelIcon color="#EB5038" width="18px" height="18px" />,
                                  text: ct("Cancel"),
                                  clickEvent: e => {
                                    e.stopPropagation();
                                    props.onBroadcastCancelClick(broadcast.id, broadcast.name);
                                  },
                                }
                              : undefined,
                          },
                          {
                            MenuItem: checkDeletable(broadcast.status)
                              ? {
                                  icon: <RedTrashIcon width="18px" height="18px" />,
                                  text: ct("Delete"),
                                  clickEvent: e => {
                                    e.stopPropagation();
                                    props.onBroadcastDeleteClick(broadcast.id, broadcast.name);
                                  },
                                  color: "mainRed",
                                }
                              : undefined,
                          },
                        ]}
                      />
                    </Box>
                  </Box>
                </CardBody>
              </Card>
            );
          })
        )}
      </Box>
      <Box
        width={"100%"}
        className={s.listMobile}
        overflowY={!(props?.broadcastList?.items.length === 0 && props.search === "") ? "scroll" : "hidden"}
        paddingBottom={"60px"}
      >
        {isLoading ? (
          <>
            {Array(4)
              .fill(1)
              .map((el, ind) => (
                <Skeleton key={ind} h="182px" mb="10px" speed={0.5} borderRadius="12px" startColor="line" endColor="bgLight" />
              ))}
          </>
        ) : (
          props.broadcastList?.items.map(broadcast => (
            <Card className={s.mobileCard} key={`${broadcast.id}`} variant="dominoListTile">
              <CardBody px={{ base: "18px", sm: "24px" }} onClick={selectBroadcast(broadcast)} data-pw={broadcast.name}>
                <Box className={`${s.mobileBroadcastListItemGrid}`}>
                  <Box>
                    <Flex align="center" gap="4px" mb={"8px"} mr={"15px"}>
                      <Icon as={broadcast.direction === BroadcastDirection.TelegramGroup ? GroupChat : ChatIcon} boxSize="20px" />
                      <Text noOfLines={1}>{broadcast.name}</Text>
                    </Flex>

                    <StatusTag variant="dominoSubtle" broadcastStatus={broadcast.status} />

                    <Flex mt="8px" gap={{ base: "8px", sm: "20px" }}>
                      <Text className={s.mobileText} noOfLines={2}>
                        {t("Recipients count")}
                      </Text>
                      <Text mr="36px" noOfLines={2}>
                        {broadcast.recipientsCount}
                      </Text>
                    </Flex>
                    <Flex mt="8px" gap={{ base: "8px", sm: "20px" }}>
                      <Text className={s.mobileText} noOfLines={1}>
                        {t("Scheduled")}
                      </Text>
                      <Text noOfLines={1}>
                        {broadcast.scheduledDate ?? broadcast.startedDate
                          ? dayjs(broadcast.scheduledDate ?? broadcast.startedDate).format("DD.MM.YYYY")
                          : "n/a"}{" "}
                        {(broadcast.scheduledDate ?? broadcast.startedDate) && (
                          <b className={s.greyColor}>{dayjs(broadcast.scheduledDate ?? broadcast.startedDate).format("HH:mm")}</b>
                        )}
                      </Text>
                    </Flex>
                  </Box>
                  <Box>
                    <Box className={s.modal}>
                      <DotMenuContainer
                        key={broadcast.id}
                        menuItems={[
                          {
                            isActive: IsAvailable ? true : broadcast.direction !== BroadcastDirection.TelegramGroup,
                            MenuItem: {
                              icon: <DuplicateIcon width="18px" height="18px" />,
                              text: t("Duplicate"),
                              clickEvent: e => {
                                e.stopPropagation();
                                trackEvent("BroadcastMenu", "", { broadcast_event: "Dublicate" });
                                props.onDuplicateClick(broadcast.id);
                              },
                            },
                          },
                          {
                            MenuItem: checkCancelable(broadcast.status)
                              ? {
                                  icon: <CancelIcon color="#EB5038" width="18px" height="18px" />,
                                  text: ct("Cancel"),
                                  clickEvent: e => {
                                    e.stopPropagation();
                                    props.onBroadcastCancelClick(broadcast.id, broadcast.name);
                                  },
                                }
                              : undefined,
                          },
                          {
                            MenuItem: checkDeletable(broadcast.status)
                              ? {
                                  icon: <RedTrashIcon width="18px" height="18px" />,
                                  text: ct("Delete"),
                                  clickEvent: e => {
                                    e.stopPropagation();
                                    props.onBroadcastDeleteClick(broadcast.id, broadcast.name);
                                  },
                                  color: "mainRed",
                                }
                              : undefined,
                          },
                        ]}
                      />
                    </Box>
                  </Box>
                </Box>
              </CardBody>
            </Card>
          ))
        )}
      </Box>
    </Box>
  );
};
