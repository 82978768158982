import { Button, Icon, Menu, MenuButton, MenuItem, MenuList, Portal, Text, Tooltip } from "@chakra-ui/react";
import ChevronDownIcon from "../../../../../../../../../assets/icons/chevronChatList.svg?react";
import ReplyIcon from "../../../../../../../../../assets/icons/reply.svg?react";
import TrashIcon from "../../../../../../../../../assets/icons/trash.svg?react";
import { useTranslation } from "react-i18next";
import { ReactElement, ReactNode, useState } from "react";
import { useMobileView } from "../../../../../../../../layout/LayoutHelper/ResolutionHooks";
import { getDateDifference } from "../../../../../../../../../common/utils/getDateDifference";
import { MessageDeleteModal } from "../../../../../MessageDeleteModal/MessageDeleteModal";
import { messageDeleteModal } from "./handlers";
import { useAppDispatch, useAppSelector } from "../../../../../../../../../common/state/store";
import { selectConversationInfo } from "../../../../../../../ConversationSlice";
import { ConversationMessageModel } from "../../../../../../../ConversationModel";

type MenuItemType = {
  icon: ReactElement;
  text: string | ReactNode;
  onClick: () => void;
  isDisabled?: boolean;
  disableReason?: string;
};

interface Props {
  onReply: () => void;
  possibleToReply: boolean;
  message: ConversationMessageModel;
  messageHover: boolean;
}

const TELEGRAM_DELETE_LIMIT = 48; // in hours

export const ContextMenu = ({ onReply, possibleToReply, message, messageHover }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "conversation" });
  const dispatch = useAppDispatch();
  const isMobile = useMobileView();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const conversationInfo = useAppSelector(selectConversationInfo);

  const deleteModalActions = messageDeleteModal({
    conversationInfo,
    dispatch,
    messageId: message.id,
    setIsModalOpen: setIsDeleteModalOpen,
  });

  const possibleToDelete = getDateDifference(new Date(message.date), new Date(), "h") < TELEGRAM_DELETE_LIMIT;

  const menuItems: MenuItemType[] = [
    {
      icon: <Icon boxSize="20px" as={ReplyIcon} />,
      text: t("Reply"),
      onClick: onReply,
      isDisabled: !possibleToReply,
    },
    {
      icon: <Icon boxSize="20px" as={TrashIcon} />,
      text: (
        <Text variant="medium" color="mainRed">
          {t("Delete")}
        </Text>
      ),
      onClick: deleteModalActions.onOpen,
      isDisabled: !possibleToDelete,
      disableReason: !possibleToDelete ? t("Why can't delete") : undefined,
    },
  ];

  const MenuListRenderer = () => {
    return (
      <MenuList zIndex={2}>
        {menuItems.map((menuItem, index) => {
          const { icon, onClick, text, isDisabled, disableReason } = menuItem;
          //MenuItem can't be replaced because of the tooltip
          return (
            <Tooltip label={disableReason} variant={"dominoLight"} key={index}>
              <MenuItem icon={icon} onClick={onClick} isDisabled={isDisabled}>
                {typeof text === "string" ? <Text variant="medium">{text}</Text> : text}
              </MenuItem>
            </Tooltip>
          );
        })}
      </MenuList>
    );
  };

  return (
    <>
      {messageHover && (
        <Menu variant="dominoDotsMenu">
          <MenuButton variant="dominoGhost" as={Button} minWidth="20px" p="0" position="relative" left="4px">
            <Icon as={ChevronDownIcon} boxSize="16px" w="16px" p="2px" bgColor="newGrey" borderRadius="50%" />
          </MenuButton>
          {isMobile ? (
            <MenuListRenderer />
          ) : (
            <Portal>
              <MenuListRenderer />
            </Portal>
          )}
        </Menu>
      )}

      <MessageDeleteModal
        isOpen={isDeleteModalOpen}
        onConfirm={deleteModalActions.onConfirm}
        onClose={deleteModalActions.onClose}
      />
    </>
  );
};
