import { Menu, MenuButton, IconButton, Icon, MenuList, Box, Flex, SystemStyleObject } from "@chakra-ui/react";
import DotMenu from "../../../assets/icons/dotMenuIcon.svg?react";
import StarPlan from "../../../assets/icons/starFilled.svg?react";
import sp from "../../../common/tariffPlan/TariffPlanStarPlan.module.scss";
import { ReactElement } from "react";
import { LimitStarPlanButton } from "../../molecules/limitStarPlanButton/LimitStarPlanButton";
import { CustomMenuItem } from "../../molecules/customMenuItem/CustomMenuItem";

interface MenuItemDot {
  icon?: ReactElement;
  text: string;
  clickEvent: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.TouchEvent<HTMLButtonElement> | React.DragEvent<HTMLButtonElement>,
  ) => void;
  color?: string;
  isDisabled?: boolean;
  isBroadcast?: boolean;
}

export type MenuItemsDot = {
  isActive?: boolean;
  MenuItem?: MenuItemDot;
}[];

interface Props {
  MenuHover?: SystemStyleObject;
  menuItems: MenuItemsDot;
}

export default function DotMenuContainer({ menuItems, MenuHover = { bg: "lightDeepBlue", fill: "midDeepBlue" } }: Props) {
  return (
    <Menu autoSelect={false} size="max" variant="dominoDotsMenu">
      {({ isOpen, onClose }) => (
        <>
          <MenuButton
            onClick={e => e.stopPropagation()}
            fill="darkGrey"
            _hover={MenuHover}
            as={IconButton}
            variant="iconDotTheme"
            icon={<Icon as={DotMenu} boxSize="24px" />}
            data-pw="flow-menu-button"
          />
          <MenuList
            onTouchStart={e => e.stopPropagation()}
            onMouseDown={e => e.stopPropagation()}
            onDragStart={e => e.stopPropagation()}
          >
            {menuItems.map((el, index) => {
              if (el.MenuItem === undefined) {
                return <></>;
              } else if (el.isActive || el.isActive === undefined) {
                return (
                  <CustomMenuItem
                    key={index}
                    onClick={e => {
                      el.MenuItem?.clickEvent(e);
                      onClose();
                    }}
                    iconStyle={{ mr: "0.75rem" }}
                    fontSize="15px"
                    h="36px"
                    p="8px"
                    icon={el.MenuItem.icon}
                    data-pw="edit-flow"
                    color={el.MenuItem.color || ""}
                    isDisabled={el.MenuItem.isDisabled}
                  >
                    {el.MenuItem.text}
                  </CustomMenuItem>
                );
              } else {
                return (
                  <Box key={index}>
                    <LimitStarPlanButton
                      popoverBodyDescription={
                        el.MenuItem?.isBroadcast
                          ? "Please upgrade your plan to create and send broadcasts to group chats"
                          : "Please upgrade your plan to create more Flows"
                      }
                    >
                      <Box className={sp.boxStarHover}>
                        <CustomMenuItem fontSize="15px" h="36px" p="8px" onClick={e => e.stopPropagation()} data-pw="edit-flow">
                          <Flex w="100%" align="center">
                            <Icon className={sp.starPlanHover} stroke="#3E5E95" boxSize="20px" as={StarPlan} />
                            {el.MenuItem?.text}
                          </Flex>
                        </CustomMenuItem>
                      </Box>
                    </LimitStarPlanButton>
                  </Box>
                );
              }
            })}
          </MenuList>
        </>
      )}
    </Menu>
  );
}
