import { useAppSelector } from "../../../../../common/state/store";
import { selectCustomVariables } from "../../../FlowBuilderSlice";
import s from "./ConditionNode.module.scss";
import { ActionNodeProps } from "../ActionNodeProps";
import { ConditionFlowActionModel, HandleNegativeTypes, HandlePositiveTypes } from "../../../FlowBuilderModel";
import { useTranslation } from "react-i18next";
import { Flex, Icon, Text } from "@chakra-ui/react";
import { Handle, Position } from "reactflow";
import ConditionIcon from "../../../../../assets/icons/conditionIcon.svg?react";
import { addTranslationPrefix, getVariableType } from "../../../../../common/utils/convertPascalCaseToText";
import { formatDateTimeToView } from "../../../../../common/utils/formatDate";
import i18next from "i18next";

export const ConditionNode = (props: { actionData: ActionNodeProps }) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const cft = useTranslation("translation", {
    keyPrefix: "complexFilter.conditionsByType",
  }).t;
  const data = props.actionData.node.flowAction as ConditionFlowActionModel;
  const variables = useAppSelector(selectCustomVariables);
  const isFalseHandleActive = props.actionData.isEdgeInHandle(props.actionData.node.id, HandleNegativeTypes.false);
  const conditionValue = (conditionCustomVariableId: string) =>
    variables?.find(variable => variable.id === conditionCustomVariableId)?.key;

  const valueVariable = (valueCustomVariableId: string) =>
    variables?.find(variable => variable.id === valueCustomVariableId)?.key;

  const typeOfVariable = (valueCustomVariableId: string) => {
    return variables?.find(variable => variable.id === valueCustomVariableId)?.type;
  };
  const locale = i18next.language;

  return (
    <Flex flexDirection="column" alignItems="center">
      <div className={s.header}>
        <Icon as={ConditionIcon} fill="midDeepBlue" boxSize="28px" />
        <div className={s.textBlock}>
          <div className={s.type}>{t("Condition")}</div>
        </div>
      </div>
      {data?.conditions.map((el, index) => {
        const isTrueHandleActive = props.actionData.isEdgeInHandle(
          props.actionData.node.id,
          `${el.id}${HandlePositiveTypes.true}`,
        );
        if (el.conditionCustomVariableId) {
          return (
            <Flex key={index} flexWrap="wrap" className={s.wrapper}>
              <Text className={s.highlightedWord}>{conditionValue(el.conditionCustomVariableId)}&nbsp;</Text>
              {el.condition
                ? cft(addTranslationPrefix(el.condition, getVariableType(variables, el.conditionCustomVariableId) || ""))
                : ""}
              &nbsp;
              {el.value?.length ? (
                <Text className={s.highlightedWord} color="black !important">
                  {formatDateTimeToView(typeOfVariable(el.conditionCustomVariableId ?? ""), el.value, locale || "ru")}
                </Text>
              ) : (
                <Text className={s.highlightedWord}>{valueVariable(el.valueCustomVariableId ?? "")}</Text>
              )}
              <Handle
                className={`${s.handleStyle} ${s.handleStyleOk} ${isTrueHandleActive ? s.activeOk : ""}`}
                type="source"
                isValidConnection={() => !isTrueHandleActive}
                position={Position.Right}
                id={`${el.id}${HandlePositiveTypes.true}`}
                isConnectable={!isTrueHandleActive}
              />
            </Flex>
          );
        }
        return (
          <div key={index} className={s.noCondition}>
            <h4>{t("Add Condition")}</h4>
          </div>
        );
      })}
      <div className={s.nodeFooter}>
        <Handle
          className={`${s.handleStyle} ${s.handleStyleError} ${isFalseHandleActive ? s.activeError : ""}`}
          type="source"
          isValidConnection={() => !isFalseHandleActive}
          position={Position.Right}
          id={HandleNegativeTypes.false}
          isConnectable={!isFalseHandleActive}
        />
        <p>{t("Does not meet condition")}</p>
      </div>
    </Flex>
  );
};
