import React, { useState } from "react";
import {
  Box,
  BoxProps,
  Button,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import StarPlan from "../../../assets/icons/starFilled.svg?react";
import { TariffPlanRedirectButton } from "../../../common/tariffPlan/TariffPlanRedirectButton";
import s from "./LimitStarPlanButton.module.scss";

interface StarPlanButtonProps {
  buttonName?: string;
  keyPrefix?: string;
  popoverBodyDescription: string;
  dataPw?: string;
  variant?: string;
  iconFill?: string;
  iconStroke?: string;
  children?: React.ReactNode;
  boxProps?: BoxProps;
  canBeTriggered?: boolean;
}

export const LimitStarPlanButton: React.FC<StarPlanButtonProps> = ({
  popoverBodyDescription,
  buttonName,
  keyPrefix,
  dataPw,
  variant,
  iconFill,
  iconStroke,
  children,
  boxProps,
  canBeTriggered = true,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: keyPrefix });
  const tp = useTranslation("translation", { keyPrefix: "tariffPlan" }).t;
  const [isOpen, setIsOpen] = useState(false);
  let timeoutId: NodeJS.Timeout | null = null;

  const openPopover = () => {
    if (timeoutId) clearTimeout(timeoutId);
    setIsOpen(true);
  };

  const closePopover = () => {
    timeoutId = setTimeout(() => {
      setIsOpen(false);
    }, 100);
  };

  return (
    <Popover isOpen={canBeTriggered && isOpen} onClose={() => setIsOpen(false)} trigger={"hover"}>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            {children ? (
              <Box {...boxProps} onMouseEnter={openPopover} onMouseLeave={closePopover}>
                {children}
              </Box>
            ) : (
              <Button
                gap="8px"
                size="sm"
                variant={variant}
                data-pw={dataPw}
                onMouseEnter={openPopover}
                onMouseLeave={closePopover}
                _focus={{ boxShadow: "none" }}
              >
                <Icon
                  stroke={iconStroke ? iconStroke : "none"}
                  fill={iconFill}
                  as={StarPlan}
                  className={s.starPlan}
                  boxSize="20px"
                />
                {t(buttonName || "")}
              </Button>
            )}
          </PopoverTrigger>
          <Portal>
            <PopoverContent
              className={s.popoverContent}
              onMouseEnter={openPopover}
              onMouseLeave={closePopover}
              _focus={{ boxShadow: "none" }}
            >
              <PopoverArrow className={s.popoverArrow} />
              <Flex className={s.popoverTextContainer}>
                <PopoverHeader className={s.popoverHeader}>{tp("Advanced feature")}</PopoverHeader>
                <PopoverBody className={s.popoverBody}>{tp(popoverBodyDescription)}</PopoverBody>
                <TariffPlanRedirectButton onClose={onClose} />
              </Flex>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};
