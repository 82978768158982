import s from "../../../UserProfile.module.scss";
import { Box, Button, Flex, Menu, MenuButton, MenuList } from "@chakra-ui/react";
import { UserNotificationsProvider } from "../../../../../common/user/UserModel";
import { UserState } from "../../../../../common/user/UserState";
import { BotListModel } from "../../../../dashboard/DashboardModel";
import { useTranslation } from "react-i18next";
import { NotificationBotsModel, NotificationSettingsModel } from "../../../UserProfileModel";
import ArrowDropIcon from "../../../../../assets/icons/arrowDrop.svg?react";
import { selectLinkedAccounts } from "../../../../../common/user/UserSlice";
import { useAppSelector } from "../../../../../common/state/store";
import { useEffect, useState } from "react";
import { CustomMenuItem } from "../../../../../UI/molecules/customMenuItem/CustomMenuItem";

interface Props {
  userProfile: UserState;
  bots: BotListModel | undefined;
  telegramBots: NotificationBotsModel[] | undefined;
  setBot: (notification?: NotificationSettingsModel) => void;
  setNewBot: (notification: NotificationSettingsModel) => void;
  disableBot: (disabledNotification: NotificationSettingsModel) => void;
  getPage: (page: number) => void;
  notification?: NotificationSettingsModel;
  organizationId: string;
}

export const UserProfilePushItem = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "userProfile" });

  const linkedAccounts = useAppSelector(selectLinkedAccounts);
  const isTelegramAuth = linkedAccounts.length > 0;
  const getInitialNowBot = (notification?: NotificationSettingsModel) => {
    if (notification === undefined) {
      return { name: "None", type: UserNotificationsProvider.none };
    } else if (notification.provider === UserNotificationsProvider.none) {
      return { name: "None", type: UserNotificationsProvider.none };
    } else if (notification.provider === UserNotificationsProvider.dominoBot) {
      return {
        name: "Domino Bot",
        type: UserNotificationsProvider.dominoBot,
      };
    } else if (notification.provider === UserNotificationsProvider.customBot) {
      const findElem = props.telegramBots?.find(el => el.id === notification.customBotId);
      return findElem
        ? {
            name: findElem.name,
            type: UserNotificationsProvider.customBot,
          }
        : {
            name: "None",
            type: UserNotificationsProvider.none,
          };
    } else {
      return { name: "None", type: UserNotificationsProvider.none };
    }
  };

  function setBot(botId: string) {
    if (props.notification) {
      if (botId === "") {
        const notification: NotificationSettingsModel = {
          ...props.notification,
          provider: UserNotificationsProvider.dominoBot,
        };
        props.setBot(notification);
      } else if (botId === "none") {
        const notification: NotificationSettingsModel = {
          provider: UserNotificationsProvider.none,
          customBotId: botId,
          organizationId: props.organizationId,
        };
        props.disableBot(notification);
      } else {
        const notification: NotificationSettingsModel = {
          ...props.notification,
          provider: UserNotificationsProvider.customBot,
          customBotId: botId,
        };
        props.setBot(notification);
      }
    }
  }

  const [nowBot, setBotNow] = useState<{ name: string; type: UserNotificationsProvider } | null>(null);

  useEffect(() => {
    if (!nowBot && props.notification !== undefined) {
      setBotNow(getInitialNowBot(props.notification));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.notification]);

  return (
    <>
      <Box>
        {props.notification && isTelegramAuth ? (
          <div className={s.contentItem}>
            <Menu
              matchWidth
              variant={{ base: "dominoProfileAdaptiveMenu", sm: "dominoProfileMenu" }}
              gutter={0}
              offset={[-30, 0]}
            >
              {({ isOpen, onClose }) => (
                <>
                  <MenuButton
                    variant="profileButton"
                    minW={{ base: "200px !important", sm: "250px !important" }}
                    isActive={isOpen}
                    as={Button}
                    onClick={e => e.stopPropagation()}
                    rightIcon={<ArrowDropIcon height="20px" width="20px" />}
                  >
                    <Flex maxW={"500px"}>
                      <Box w="100%" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                        {nowBot?.type === UserNotificationsProvider.none || nowBot?.type === UserNotificationsProvider.dominoBot
                          ? t(nowBot?.name)
                          : nowBot?.name}
                      </Box>
                    </Flex>
                  </MenuButton>
                  <MenuList overflow="auto !important" w={{ base: "200px", sm: "250px" }} maxH="250px" ml={"30px"}>
                    <CustomMenuItem
                      onClick={e => {
                        setBotNow({ name: "None", type: UserNotificationsProvider.none });
                        setBot("none");
                        onClose();
                      }}
                    >
                      {t("None")}
                    </CustomMenuItem>
                    <CustomMenuItem
                      onClick={e => {
                        setBotNow({
                          name: "Domino Bot",
                          type: UserNotificationsProvider.dominoBot,
                        });
                        setBot("");
                        onClose();
                      }}
                    >
                      {t("Domino Bot")}
                    </CustomMenuItem>
                    {props.telegramBots &&
                      props.telegramBots.length > 0 &&
                      props.telegramBots.map(bot => {
                        if (props.notification?.organizationId === bot.organizationId) {
                          return (
                            <CustomMenuItem
                              onClick={e => {
                                setBotNow({
                                  name: bot.name,
                                  type: UserNotificationsProvider.customBot,
                                });
                                setBot(bot.id);
                                onClose();
                              }}
                              key={bot.id}
                            >
                              {bot.name}
                            </CustomMenuItem>
                          );
                        }
                        return null;
                      })}
                  </MenuList>
                </>
              )}
            </Menu>
          </div>
        ) : (
          <div className={s.contentItem}>
            <Menu
              matchWidth
              variant={{ base: "dominoProfileAdaptiveMenu", md: "dominoProfileMenu" }}
              gutter={0}
              offset={[-30, 0]}
            >
              {({ isOpen, onClose }) => (
                <>
                  <MenuButton
                    variant="profileButton"
                    minW={{ base: "200px !important", sm: "250px !important" }}
                    isActive={isOpen}
                    as={Button}
                    onClick={e => e.stopPropagation()}
                    rightIcon={<ArrowDropIcon />}
                  >
                    <Flex maxW={"500px"}>
                      <Box w="100%" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                        {t("None")}
                      </Box>
                    </Flex>
                  </MenuButton>
                  <MenuList overflow="auto !important" w={{ base: "200px", sm: "250px" }} maxH="250px" ml={"30px"}>
                    <CustomMenuItem onClick={onClose}>{t("None")}</CustomMenuItem>
                  </MenuList>
                </>
              )}
            </Menu>
          </div>
        )}
      </Box>
    </>
  );
};
