import { Dispatch, SetStateAction, useState, useEffect, useRef, useCallback } from "react";
import { Flex, Text, Skeleton } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AppSettings } from "../../../../../common/AppSettings";
import LeftArrow from "../../../../../assets/icons/arrowLeft.svg?react";
import CrossIcon from "../../../../../assets/icons/cross.svg?react";
import { useGetLoadingState } from "../../../../../common/loading/hooks/useGetLoadingState";
import { useAppDispatch, useAppSelector } from "../../../../../common/state/store";
import { getFiles, selectFiles } from "../../../ContactInfoSlice";
import s from "./ContactMediaFiles.module.scss";
import ImageViewer from "../../../../../UI/atoms/imageViewer/ImageViewer";
import { MEDIA_PER_PAGE } from "../../../../../common/paginator/paginatorSizes";

declare const appSettings: AppSettings;
interface Props {
  conversationId: string;
  mediaFilesCount: number | undefined;
  setOpenMedia: Dispatch<SetStateAction<boolean>>;
}

export default function ContactMediaFiles({ conversationId, mediaFilesCount, setOpenMedia }: Props) {
  const { t } = useTranslation("translation", { keyPrefix: "contactInfo" });
  const mediaFiles = useAppSelector(selectFiles);
  const isLoading = useGetLoadingState("getFiles");
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(mediaFiles?.currentPage || 1);
  const mediaFilesListRef = useRef<null | HTMLDivElement>(null);
  const array = Array.from({ length: mediaFilesCount ?? 30 }, (_, index) => index + 1);

  useEffect(() => {
    dispatch(getFiles({ conversationId, attachments: "attachmentsMedia", page, size: MEDIA_PER_PAGE }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId, page]);

  const handleMediaFilesClose = () => {
    setOpenMedia(false);
  };

  const handleScroll = useCallback(
    (event: Event) => {
      if (isLoading) return;

      const target = event.target as HTMLDivElement;
      if (!target) return;

      const { scrollTop, clientHeight, scrollHeight } = target;

      if (mediaFiles && mediaFiles.totalPages > page) {
        if (scrollHeight - scrollTop - 20 <= clientHeight) {
          setPage(prevPage => prevPage + 1);
        }
      }
    },
    [page, mediaFiles, isLoading],
  );

  useEffect(() => {
    const mediaFilesList = mediaFilesListRef.current;

    if (mediaFilesList) {
      mediaFilesList.addEventListener("scroll", handleScroll);
      return () => {
        mediaFilesList.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  return (
    <Flex className={s.mediaContainer}>
      <Flex className={s.mediaGroup}>
        <Flex className={s.titleContainer}>
          <LeftArrow className={s.leftArrow} onClick={handleMediaFilesClose} />
          <Text variant="largeBold">{t("Media files")}</Text>
        </Flex>
        <CrossIcon className={s.crossIcon} onClick={handleMediaFilesClose} />
      </Flex>
      <Flex className={s.filesContainer} ref={mediaFilesListRef} mb={"100px"}>
        {isLoading
          ? array.map(el => <Skeleton className={s.skeleton} key={el} />)
          : mediaFiles?.items.map((item, index) => (
              <Skeleton className={s.skeleton} key={index} isLoaded={!isLoading}>
                <ImageViewer
                  key={index}
                  alt={`${item.fileName}`}
                  loading="lazy"
                  src={`${appSettings.apiBaseUrl}/file/${item.fileId}`}
                  className={s.mediaBox}
                  objectFit="cover"
                  layerStyle={"fill"}
                />
              </Skeleton>
            ))}
      </Flex>
    </Flex>
  );
}
