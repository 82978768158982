import { ReactNode, useState } from "react";
import { Flex, Tooltip } from "@chakra-ui/react";

// eslint-disable-next-line
export const TooltipWithTouch = ({ children, display = "flex", ...restToolTipProps }: { children: ReactNode; display?: string; [key: string]: any }) => {
  const [isLabelOpen, setIsLabelOpen] = useState(false);

  return (
    <Tooltip isOpen={isLabelOpen} {...restToolTipProps}>
      <Flex
        onMouseEnter={() => setIsLabelOpen(true)}
        onMouseLeave={() => setIsLabelOpen(false)}
        onClick={() => setIsLabelOpen(true)}
        align="center"
        display={display}
      >
        {children}
      </Flex>
    </Tooltip>
  );
};
