import { useMemo } from "react";
import { RenderElementProps, useSelected } from "slate-react";
import s from "./LinkElement.module.scss";
import { LinkElementType } from "../../../types";

// Put this at the start and end of an inline component to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
const InlineChromiumBugfix = () => (
  <span contentEditable={false} style={{ fontSize: 0 }}>
    {String.fromCodePoint(160) /* Non-breaking space */}
  </span>
);

export const LinkElement = ({ attributes, children, element }: RenderElementProps) => {
  const narrowElement = element as LinkElementType;
  const selected = useSelected();

  const safeUrl = useMemo(() => {
    let parsedUrl: URL | null = null;
    try {
      parsedUrl = new URL(narrowElement.href);
    } catch {}
    if (parsedUrl) {
      return parsedUrl.href;
    }
    return "about:blank";
  }, [narrowElement.href]);

  return (
    <a {...attributes} href={safeUrl} className={`${s.link} ${selected ? s.link_edit : ""}`}>
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </a>
  );
};
