import { useNowWidthView } from "../../../../../../../../../layout/LayoutHelper/ResolutionHooks";
import { useContext } from "react";
import { FilterContext } from "../../FilterProvider";
import { MINIMAL_SCREEN_SIZE_FOR_DEFAULT_LAYOUT } from "../../consts";
import { DefaultSwitchableListLayout } from "./components/DefaultSwitchableListLayout";
import { AdaptiveSwitchableListLayout } from "./components/AdaptiveSwitchableListLayout";

interface Props {
  title: React.ReactNode;
  description: string;
  isEnabled: boolean;
  onChangeSwitch: () => void;
  isDisabled: boolean;
  list: string;
  setList: (list: string) => void;
  isInvalid: boolean;
  invalidText: string;
  placeholderText: string;
}

export const FilterSwitchableList = ({
  title,
  description,
  isEnabled,
  onChangeSwitch,
  isDisabled,
  list,
  setList,
  isInvalid,
  invalidText,
  placeholderText,
}: Props) => {
  const nowWidth = useNowWidthView();
  const context = useContext(FilterContext);
  const columnGap = (context ? context.restrictionInfoNodeWidth - 164 - 178 : 16) + "px";
  const isDefaultLayout = nowWidth >= MINIMAL_SCREEN_SIZE_FOR_DEFAULT_LAYOUT;

  return (
    <>
      {isDefaultLayout ? (
        <DefaultSwitchableListLayout
          columnGap={columnGap}
          title={title}
          description={description}
          isEnabled={isEnabled}
          onChangeSwitch={onChangeSwitch}
          isDisabled={isDisabled}
          list={list}
          setList={setList}
          isInvalid={isInvalid}
          invalidText={invalidText}
          placeholderText={placeholderText}
        />
      ) : (
        <AdaptiveSwitchableListLayout
          title={title}
          description={description}
          isEnabled={isEnabled}
          onChangeSwitch={onChangeSwitch}
          isDisabled={isDisabled}
          list={list}
          setList={setList}
          isInvalid={isInvalid}
          invalidText={invalidText}
          placeholderText={placeholderText}
        />
      )}
    </>
  );
};
