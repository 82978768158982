import * as FlowBuilderApi from "../../components/flowBuilder/FlowBuilderApi";
import i18n from "../i18next/i18n";
import { call, put, takeLatest } from "redux-saga/effects";
import { begin, beginScope, complete, completeScope } from "../loading/LoadingStateActions";
import { userSlice } from "./UserSlice";
import { handleException } from "../SagaHelper";
import { getUserProfile, syncUserLocale } from "./UserApi";
import { getUserProfileBillingPlan } from "../../components/userProfile/UserProfileApi";
import { setUserId } from "../ga/GAEventTracker";
import { UserData, UserSyncedLocaleData } from "./UserData";
import { LanguageCodeEnum, UserModel } from "./UserModel";
import { UserBillingPlanData } from "../../components/userProfile/UserProfileData";
import { UserBillingPlanModel } from "../../components/userProfile/UserProfileModel";
import { OperatorData } from "../../components/flowBuilder/FlowBuilderData";
import { PayloadAction } from "@reduxjs/toolkit";

export function* userSaga() {
  yield takeLatest(userSlice.actions.getProfile, getProfile);
  yield takeLatest(userSlice.actions.syncLocale, syncLocale);
  yield takeLatest(userSlice.actions.getBillingPlan, getBillingPlan);
}

function* getProfile() {
  try {
    yield put(beginScope("getProfile"));

    const data: UserData = yield call(getUserProfile);
    const model: UserModel = { ...data };

    const userId = model.id;
    userId !== undefined && setUserId(userId);

    const language = data.locale;
    i18n.changeLanguage(language);
    yield put(userSlice.actions.setLanguageCompleted(language));
    yield put(userSlice.actions.getProfileCompleted(model));
    const operator: OperatorData = yield call(FlowBuilderApi.getOperatorTitle, model.id);
    if (operator.roles) {
      yield put(userSlice.actions.setRoles(operator.roles));
    }
  } catch (e: unknown) {
    yield put(userSlice.actions.setLanguageCompleted(LanguageCodeEnum.en));
    yield handleException(e);
  } finally {
    yield put(completeScope("getProfile"));
  }
}

function* syncLocale(action: PayloadAction<string>) {
  yield put(begin());
  try {
    const syncedLocaleData: UserSyncedLocaleData = yield call(syncUserLocale, action.payload);
    const language = syncedLocaleData.locale;
    i18n.changeLanguage(language);
    yield put(userSlice.actions.setLanguageCompleted(language));
  } catch (e: unknown) {
    yield put(userSlice.actions.setLanguageCompleted(LanguageCodeEnum.en));
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

function* getBillingPlan() {
  try {
    const data: UserBillingPlanData = yield call(getUserProfileBillingPlan);
    const model: UserBillingPlanModel = { ...data };
    yield put(userSlice.actions.getBillingPlanSucceed(model));
  } catch (e: unknown) {
    yield handleException(e);
  }
}
