export const getFloaterProps = (arrowLength: number, arrowSpread: number) => ({
  disableAnimation: true,
  styles: {
    arrow: {
      length: arrowLength,
      spread: arrowSpread,
    },
  },
});

export const buttonBackStyles = {
  buttonBack: {
    marginLeft: 0,
    marginRight: "auto",
    order: "-1",
  },
};

export const botMenuTooltipStyles = {
  tooltipFooter: {
    justifyContent: "center",
  },
  tooltipFooterSpacer: {
    flex: "0 1 auto",
  },
  buttonNext: {
    padding: "10px 16px",
  },
};

export const tooltipContentStyle = {
  tooltipContent: {
    padding: "8px 0 0px 0",
    textAlign: "left" as const,
    fontSize: "13px",
    minHeight: "56px",
  },
  tooltip: {
    transform: "translateY(-20%)",
  },
  overlay: {
    zIndex: 0,
  },
};

export const customStyles = (tooltipWidth?: string, isHideNextButton?: boolean) => {
  return {
    spotlight: {
      borderRadius: "8px",
    },
    options: {
      arrowColor: "var(--chakra-colors-black)",
      overlayColor: "rgba(35, 43, 57, 0.5)",
      zIndex: 12000,
      display: "contents",
      position: "absolute",
    },
    tooltipFooter: {
      margin: 0,
      padding: 0,
    },

    tooltip: {
      backgroundColor: "var(--chakra-colors-black)",
      color: "white",
      borderRadius: "8px",
      padding: "20px",
      width: tooltipWidth || "336px",
      minHeight: "148px",
    },
    tooltipTitle: {
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "20px",
      textAlign: "left" as const,
    },
    tooltipContent: {
      padding: "8px 0 16px 0",
      textAlign: "left" as const,
      fontSize: "13px",
      minHeight: "56px",
    },
    buttonClose: {
      color: "var(--chakra-colors-greyDisable)",
      height: "11.5px",
      width: "11.5px",
      padding: "14.25px",
    },
    buttonNext: {
      backgroundColor: "var(--chakra-colors-mainPurple)",
      color: "white",
      borderRadius: "8px",
      padding: "8px 16px",
      fontSize: "13px",
      minWidth: "83px",
      display: isHideNextButton ? "none" : "auto",
    },
    buttonBack: {
      color: "var(--chakra-colors-greyDisable)",
      alignSelf: "flex-end",
      fontSize: "13px",
      paddingLeft: 0,
    },
    buttonSkip: {
      color: "var(--chakra-colors-greyDisable)",
      fontSize: "13px",
      lineHeight: "16px",
      paddingLeft: 0,
    },
    beacon: {
      display: "none",
    },
  };
};
