import { httpPost } from "../../../../../../../common/BaseApi";
import { AntispamFilterData } from "./AntispamFilterData";

interface ChangeAntispamFilter {
  antispamFilter: AntispamFilterData;
  groupId: string;
}
export const changeAntispamFilter = ({ antispamFilter, groupId }: ChangeAntispamFilter) => {
  return httpPost(`/api/group/${groupId}/antispam`, antispamFilter);
};
