import { httpGet, httpPost, httpPut } from "../../common/BaseApi";
import { ContactVariableModel } from "./ContactInfoModel";

export const getContactInfo = (params: { contactId: string; botId: string }) => {
  return httpGet(`/api/contact/${params.contactId}/bot/${params.botId}`);
};

export const getContactInfoFiles = (conversationId: string) => {
  return httpGet(`/api/conversation/${conversationId}/attachments`);
};

export const getFiles = (conversationId: string, attachments: string, page?: number, size?: number) => {
  return httpGet(`/api/conversation/${conversationId}/${attachments}?page=${page}&size=${size}`);
};

export const getContactVariables = (contactId: string, size: string, page: string) => {
  return httpGet(`/api/customVariable/values/contact/${contactId}?size=${size}&page=${page}`);
};

export const changeContactVariable = (contactId: string, contactVariable: ContactVariableModel) => {
  return httpPut(`/api/customVariable/values/contact/${contactId}/variable/${contactVariable.id}`, contactVariable);
};

export const stopFlow = (conversationId: string, flowExecutionId: string) => {
  return httpPost(`/api/conversation/${conversationId}/stopFlow/${flowExecutionId}`, null);
};

export const getOperators = (page: number, filter?: string) => {
  return httpGet(`/api/organization/user?page=${page}&size=10${filter ? `&filter=${filter}` : ""}`);
};

export const getTeams = (page: number, filter?: string) => {
  return httpGet(`/api/team?page=${page}&size=10${filter ? `&filter=${filter}` : ""}`);
};
