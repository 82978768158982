import { Container, FormControl, Flex, Box, FormLabel, Icon, Text, Button, Skeleton } from "@chakra-ui/react";
import s from "../Broadcast/Broadcast.module.scss";
import sp from "../../../../common/tariffPlan/TariffPlanStarPlan.module.scss";
import StarPlan from "../../../../assets/icons/starFilled.svg?react";
import { RecipientList } from "./RecipientList";
import { useTranslation } from "react-i18next";
import { ICountTariffPlanFeature, INodeTariffPlanFeature, TariffPlanFeatureTypeEnum } from "../../../../common/AppEnums";
import { useSelector } from "react-redux";
import { useAppSelector, useAppDispatch, RootState } from "../../../../common/state/store";
import { selectBroadcastDirection, selectContactList, selectRecipientList, setBroadcastDirection } from "./RecipientListSlice";
import { selectBroadcast, selectBroadcastIsNew, selectCustomVariables } from "../../BroadcastSlice";
import { FilterParams } from "../../../complexFilter/ComplexFilterModel";
import { memo, useState } from "react";
import { BroadcastDirection } from "../../BroadcastModel";
import { LimitStarPlanButton } from "../../../../UI/molecules/limitStarPlanButton/LimitStarPlanButton";
import { useSelectLimitPlan } from "../../../../common/hooks/useSelectLimitPlan";
import { useGetLoadingState } from "../../../../common/loading/hooks/useGetLoadingState";
import { useSmoothLoading } from "../../../../common/hooks/useSmoothLoading";

export const RecipientListContainer = memo(function RecipientListContainer() {
  const { t } = useTranslation("translation", { keyPrefix: "broadcast" });
  const tp = useTranslation("translation", { keyPrefix: "tariffPlan" }).t;
  const dispatch = useAppDispatch();
  const { MaxCount, IsUnlimited } = useSelectLimitPlan(TariffPlanFeatureTypeEnum.BroadcastContacts) as ICountTariffPlanFeature;
  const groupListLimit = useSelectLimitPlan(TariffPlanFeatureTypeEnum.BroadcastForGroupsFeature);
  const { IsAvailable } = groupListLimit as INodeTariffPlanFeature;
  const [broadcastFilter, setBroadcastFilter] = useState<FilterParams[]>([{}]);
  const isContactListLoading = useGetLoadingState("contactList2");
  const isDefaultLoading = useGetLoadingState("default");
  const isBroadcastLoading = useGetLoadingState("Broadcast");
  const isLoading = useSmoothLoading([isDefaultLoading, isBroadcastLoading], 50);

  const variables = useAppSelector(selectCustomVariables);
  const contactList = useAppSelector(selectContactList);
  const recipientList = useSelector((state: RootState) => state.app.broadcastState.recipientList);
  const recipients = useAppSelector(selectRecipientList);
  const recipientListItems = recipients?.items;
  const isListEmpty = !recipientListItems || Object.keys(recipientListItems).length === 0;
  const brodcastDirection = useAppSelector(selectBroadcastDirection);
  const isBroadcastForContact = brodcastDirection === BroadcastDirection.TelegramContact;
  const isBroadcastForGroup = brodcastDirection === BroadcastDirection.TelegramGroup;

  const broadcast = useAppSelector(selectBroadcast);
  const isBroadcastNew = useAppSelector(selectBroadcastIsNew);

  const broadcastListLabel = recipientList?.recipients
    ? t("Recipients list")
    : isBroadcastForContact
    ? t("Contact list")
    : t("Group list");

  const isContactListButtonDisabled =
    isContactListLoading || (!isBroadcastNew && broadcast.direction !== BroadcastDirection.TelegramContact);
  const isGroupChatButtonDisabled =
    isContactListLoading || (!isBroadcastNew && broadcast.direction !== BroadcastDirection.TelegramGroup);

  const handleDirectionButtonClick = (value: BroadcastDirection) => {
    dispatch(setBroadcastDirection(value));
  };

  return (
    <Container variant="dominoContentBlock">
      <FormControl className={`${s.broadcastListItemGrid} ${s.broadcastSheduleAdaptive}`}>
        <FormLabel>{t("Audience")}</FormLabel>
        <Box className={s.scheduleBoxMobile}>
          <Skeleton isLoaded={!isLoading} startColor="line" endColor="bgLight" borderRadius="8px">
            <Flex>
              <Button
                className={`${s.formTitleButtons} ${s.sendImmediatelyButton}`}
                variant={isBroadcastForContact ? "dominoViolet" : "dominoOutlineViolet"}
                isDisabled={isContactListButtonDisabled}
                onClick={() => handleDirectionButtonClick(BroadcastDirection.TelegramContact)}
                data-pw="contact-list-button"
              >
                {t("Contact list")}
              </Button>
              {IsAvailable ? (
                <Button
                  className={`${s.formTitleButtons} ${s.scheduleButton}`}
                  variant={isBroadcastForGroup ? "dominoViolet" : "dominoOutlineViolet"}
                  isDisabled={isGroupChatButtonDisabled}
                  onClick={() => handleDirectionButtonClick(BroadcastDirection.TelegramGroup)}
                  data-pw="group-chats-button"
                >
                  {t("Group list")}
                </Button>
              ) : (
                <LimitStarPlanButton
                  boxProps={{ w: "100%" }}
                  popoverBodyDescription="Please upgrade your plan to create and send broadcasts to group chats"
                >
                  <Button
                    className={`${s.formTitleButtons} ${s.scheduleButton}`}
                    variant={isBroadcastForGroup ? "dominoViolet" : "dominoOutlineViolet"}
                    isDisabled={isGroupChatButtonDisabled}
                    data-pw="group-chats-button"
                  >
                    <Icon
                      fill={isBroadcastForGroup ? "white" : "#6D5BF7"}
                      as={StarPlan}
                      className={s.starPlan}
                      boxSize="20px"
                      mr="4px"
                    />
                    {t("Group list")}
                  </Button>
                </LimitStarPlanButton>
              )}
            </Flex>
          </Skeleton>
        </Box>
      </FormControl>
      <FormControl className={`${s.broadcastListItemGrid} ${s.broadcastListItemGridStart} ${s.broadcastAdaptiveContactList}`}>
        {!contactList ? (
          <Box>
            <Skeleton speed={0.5} borderRadius="12px" startColor="line" endColor="bgLight" width="130px" height="34px" />
          </Box>
        ) : !IsUnlimited &&
          isBroadcastForContact &&
          contactList?.totalItems &&
          contactList?.totalItems > Number(MaxCount) &&
          !isListEmpty ? (
          <Flex flexDir="column">
            <Box w="max-content">
              <LimitStarPlanButton popoverBodyDescription="Please upgrade your plan to select more recipients">
                <Flex className={sp.boxHover}>
                  <Box>
                    <FormLabel>{t("Contact list")}</FormLabel>
                  </Box>
                  <Box mt="2px">
                    <Icon as={StarPlan} boxSize="20px" ml="-4px" />
                  </Box>
                </Flex>
              </LimitStarPlanButton>
            </Box>
            <Box w="60%">
              <Text fontSize={15} color={"#8592A3"}>
                {tp(`No more than ${MaxCount} recipients on your current plan`)}
              </Text>
            </Box>
          </Flex>
        ) : (
          <FormLabel opacity={isListEmpty ? "0" : "1"}>{broadcastListLabel}</FormLabel>
        )}
        <RecipientList
          variables={variables}
          broadcastRecipientsFilter={broadcastFilter}
          setBroadcastFilter={setBroadcastFilter}
          isActive={isContactListLoading}
        />
        <Flex id="filter-container" className={s.flexFilter}></Flex>
      </FormControl>
    </Container>
  );
});
