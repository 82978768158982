import { Text } from "@chakra-ui/react";
import { RenderLeafProps } from "slate-react";
import { useTranslation } from "react-i18next";
import s from "./RenderLeafs.module.scss";
import { useAppDispatch } from "../../../../../common/state/store";
import { TextMarks } from "../../types";
import { notify } from "../../../../../common/notifications/NotificationSlice";

export const RenderLeafs = ({ attributes, children, leaf }: RenderLeafProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "commonWords" });
  const dispatch = useAppDispatch();

  return (
    <Text
      variant="large"
      as={leaf[TextMarks.Monospace] ? "code" : "span"}
      {...attributes}
      className={`${s.text} ${leaf[TextMarks.Bold] ? s.bold : ""}
      ${leaf[TextMarks.Italic] ? s.italic : ""}
      ${leaf[TextMarks.Monospace] ? s.code : ""}`}
      onClick={() => {
        if (leaf[TextMarks.Monospace]) {
          navigator.clipboard.writeText(leaf.text);
          dispatch(
            notify({
              message: t("Copied to clipboard"),
              type: "success",
            }),
          );
        }
      }}
    >
      {children}
    </Text>
  );
};
