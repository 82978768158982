import { Descendant } from "slate";
import { ElementTypes, TextMarks } from "./types";

export const SLATE_INITIAL_VALUE: Descendant[] = [{ type: ElementTypes.Paragraph, children: [{ text: "" }] }];
export const FORMAT_HOTKEYS = {
  "mod+b": TextMarks.Bold,
  "mod+i": TextMarks.Italic,
  "mod+`": TextMarks.Monospace,
};
export const FORMATS_BORDERS: Record<TextMarks, string> = {
  [TextMarks.Bold]: "**",
  [TextMarks.Italic]: "__",
  [TextMarks.Monospace]: "``",
};
export const CONFLICTED_GROUPS: TextMarks[][] = [[TextMarks.Bold, TextMarks.Italic], [TextMarks.Monospace]];
export const DIVIDER_BETWEEN_WORDS = " ";
export const DIVIDER_BETWEEN_PARAGRAPHS = "\n";
export const TAG_SYMBOL = "@";
export const TEXT_FORMATTER_WRAPPER_ID = "text_formatter_wrapper_id";
