import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../common/state/store";
import { getTokenByQuery } from "../login/LoginSlice";

export const AuthorizationSuccessful = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const accessToken = searchParams.get("accessToken");
    const refreshToken = searchParams.get("refreshToken");
    if (!accessToken || !refreshToken) return;

    dispatch(getTokenByQuery({ accessToken, refreshToken: atob(refreshToken) }));
  }, [dispatch, searchParams]);

  return <></>;
};
