import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../../../common/state/store";
import { ANTISPAM_FILTER_DEFAULT } from "../_components/FilterSettings/consts";
import { AntispamFilterData } from "./AntispamFilterData";
import { AntispamFilterModel } from "./AntispamFilterModel";

export interface AntispamFilterState {
  isOpenAntispamFilter: boolean;
  isToggleLoading: boolean;
  isLoadData: boolean;
  antispamFilter: AntispamFilterModel;
}

const initialState: AntispamFilterState = {
  isOpenAntispamFilter: false,
  isToggleLoading: false,
  isLoadData: false,
  antispamFilter: ANTISPAM_FILTER_DEFAULT,
};

export const antispamFilterSlice = createSlice({
  name: "antispamFilterSlice",
  initialState,
  reducers: {
    setIsOpenAntispamFilter: (state, action: PayloadAction<boolean>) => {
      state.isOpenAntispamFilter = action.payload;
    },
    setAntispamFilter: (state, action: PayloadAction<AntispamFilterData | null>) => {
      return {
        ...state,
      };
    },
    setAntispamFilterCompleted: (state, action: PayloadAction<AntispamFilterModel>) => {
      state.antispamFilter = action.payload || ANTISPAM_FILTER_DEFAULT;
      state.isLoadData = true;
    },
    toggleIsEnabled: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
      };
    },
    toggleIsEnabledCompleted: (state, action: PayloadAction<boolean>) => {
      state.antispamFilter.isEnabled = action.payload;
    },
    saveChanges: (state, action: PayloadAction<AntispamFilterModel>) => {
      return {
        ...state,
      };
    },
    saveChangesCompleted: (state, action: PayloadAction<AntispamFilterModel>) => {
      state.antispamFilter = action.payload;
    },
    changeIsLoadData: (state, action: PayloadAction<boolean>) => {
      state.isLoadData = action.payload;
    },
  },
});

export const {
  setIsOpenAntispamFilter,
  setAntispamFilter,
  setAntispamFilterCompleted,
  toggleIsEnabled,
  saveChanges,
  saveChangesCompleted,
  changeIsLoadData,
} = antispamFilterSlice.actions;

export const selectIsAntispamFilterEnabled = (state: RootState) => state.app.antispamFilterState.antispamFilter.isEnabled;
export const selectIsOpenAntispamFilter = (state: RootState) => state.app.antispamFilterState.isOpenAntispamFilter;
export const selectAntispamFilter = (state: RootState) => state.app.antispamFilterState.antispamFilter;
export const selectAntispamFilterIsLoadedData = (state: RootState) => state.app.antispamFilterState.isLoadData;

export default antispamFilterSlice.reducer;
