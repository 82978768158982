import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Menu,
  MenuButton,
  MenuList,
  Box,
  Flex,
  Icon,
  Alert,
  useDisclosure,
} from "@chakra-ui/react";
import AlertIcon from "../../../../assets/icons/alertIcon.svg?react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import { selectLanguage } from "../../../../common/user/UserSlice";
import { clearBotVariablesInFlowState, copyFlow, getBotVariablesInFlow } from "../../AutomationSlice";
import { BotListModel, BotModel } from "../../../sidebar/SidebarModel";
import DownArrowMenu from "../../../../assets/images/downArrow.svg?react";
import { CustomVariableModel } from "../../../../common/AppEnums";
import s from "./CopyFlowPopup.module.scss";
import { useGetLoadingState } from "../../../../common/loading/hooks/useGetLoadingState";
import { EventCategories } from "../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import { useGAWithCustomParameters } from "../../../../common/ga/GAEventTracker";
import { FlowEvents } from "../../../../common/ga/gaEventsEnums.ts/FlowGAEventsEnums";
import { CustomMenuItem } from "../../../../UI/molecules/customMenuItem/CustomMenuItem";

interface Props {
  copyPopupState: { id: string; title: string };
  botList: BotListModel | undefined;
  botVariablesInFlow?: CustomVariableModel[];
  onClose: () => void;
  getAdditionalBots: () => void;
}

export const CopyFlowPopup = (props: Props) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "flowList.copyFlow",
  });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const isCopyFlow = useGetLoadingState("copyFlow");
  const dispatch = useAppDispatch();
  const [chosenBot, setChosenBot] = useState<BotModel>();
  const lng = useAppSelector(selectLanguage);
  const trackEvent = useGAWithCustomParameters(EventCategories.Flow);
  const { onClose: onCloseMenu, isOpen, onOpen } = useDisclosure();

  const onClose = () => {
    dispatch(clearBotVariablesInFlowState());
    props.onClose();
  };

  const onFlowCopy = () => {
    if (chosenBot) {
      dispatch(copyFlow({ flowId: props.copyPopupState.id, botId: chosenBot.id, locale: lng }));
    }
  };
  if (!props.botList) {
    return <></>;
  }

  const onSelectBot = (bot: BotModel) => {
    dispatch(getBotVariablesInFlow({ flowId: props.copyPopupState.id, targetBotId: bot.id }));
    setChosenBot(bot);
  };

  return (
    <Modal isOpen={true} onClose={props.onClose} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("Copy Flow")}</ModalHeader>
        <ModalCloseButton borderRadius={"50%"} color={"darkGrey"} _hover={{ bg: "defaultGrey", color: "mainPurple" }} />
        <ModalBody paddingTop={"0"}>
          <Text variant="large">
            {t("You are going to copy")} <b>{props.copyPopupState.title}</b> {t("to Bot")}:
          </Text>
          <Menu variant="dominoMenu" onOpen={onOpen} isOpen={isOpen} onClose={onCloseMenu} matchWidth>
            <MenuButton
              as={Button}
              mt="10px"
              variant="dominoDefaultMenuBtn"
              _active={{ borderColor: "blueLink", backgroundColor: "white" }}
              onClick={e => e.stopPropagation()}
              color={!!chosenBot ? "black" : "darkGrey"}
            >
              {chosenBot?.name ?? ct("Select")}
            </MenuButton>
            <MenuList w="100%" maxH="260px">
              <Box flexGrow={1} overflowY={"auto"} p={0}>
                {props.botList.items.length ? (
                  props.botList.items.map((el: BotModel) => {
                    return (
                      <CustomMenuItem
                        key={el.id}
                        onClick={() => {
                          onSelectBot(el);
                          onCloseMenu();
                        }}
                        height={{ base: "36px", lg: "36px" }}
                      >
                        <Flex
                          flexDirection="row"
                          alignItems={"center"}
                          maxWidth={"100%"}
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          fontSize={{ base: "14px", lg: "15px" }}
                        >
                          {el.name}
                        </Flex>
                      </CustomMenuItem>
                    );
                  })
                ) : (
                  <CustomMenuItem>{t("No Bots")}...</CustomMenuItem>
                )}
                {props.botList.currentPage < props.botList.totalPages && (
                  <CustomMenuItem onClick={props.getAdditionalBots} height={{ base: "36px", lg: "36px" }}>
                    <Flex
                      onClick={props.getAdditionalBots}
                      alignItems={"center"}
                      justifyContent={"center"}
                      w={"100%"}
                      cursor={"pointer"}
                    >
                      <div>{t("show more")}</div>
                      <Icon as={DownArrowMenu} boxSize="26px" color="midDeepBlue" />
                    </Flex>
                  </CustomMenuItem>
                )}
              </Box>
            </MenuList>
          </Menu>
          {!!chosenBot && !!props.botVariablesInFlow?.length && (
            <Alert variant="dominoWarningAlertVariant" status="warning" alignItems="flex-start" mt="20px">
              <Icon as={AlertIcon} boxSize="24px" mr="21px" />
              <Text maxW="90%">
                {t("These bot fields will be created in")}{" "}
                <b>
                  {" "}
                  {chosenBot.name}: <p></p>
                </b>
                {props.botVariablesInFlow.map((el, index, array) => {
                  return (
                    <span className={s.highlightedWord} key={el.id}>
                      {el.key}
                      {index !== array.length - 1 && ", "}
                    </span>
                  );
                })}
              </Text>
            </Alert>
          )}
        </ModalBody>
        <ModalFooter paddingBottom="32px" justifyContent="space-between">
          <Button
            variant="dominoOutlineViolet"
            onClick={() => {
              onClose();
              trackEvent(FlowEvents.FlowCopyCanceled);
            }}
          >
            {ct("Cancel")}
          </Button>
          <Button
            spinnerPlacement={"end"}
            isLoading={isCopyFlow}
            isDisabled={!chosenBot}
            loadingText={ct("Copy")}
            variant="dominoViolet"
            mr={3}
            onClick={() => {
              onFlowCopy();
              trackEvent(FlowEvents.FlowCopyConfirmed, "", { bot: chosenBot?.name ?? "" });
            }}
          >
            {ct("Copy")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
