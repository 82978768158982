import s from "./AddNodePopup.module.scss";
import { extrasNodes, NodeModel, telegramNodes, webBotExtrasNodes, webBotTelegramNodes } from "../../FlowBuilderModel";
import { Box, Text, PopoverCloseButton, PopoverContent, SimpleGrid } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { TariffPlanFeatureTypeEnum } from "../../../../common/AppEnums";
import { NodeItem } from "./NodeItem";
import { useAppSelector } from "../../../../common/state/store";
import { selectFlow } from "../../FlowBuilderSlice";
import { selectCurrentBot } from "../../../sidebar/SidebarSlice";
import HOCNodeItem from "./HOCNodeItem";

interface Props {
  addNode: (newNode: NodeModel) => void;
  onClose: () => void;
  flowBuilderWidth: number;
  flowBuilderHeight: number;
}

export const AddNodePopup = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });

  const typeIsAccessability = (title: string) => {
    switch (title) {
      case "HTTP request":
        return TariffPlanFeatureTypeEnum.NodeHttpRequest;
      case "Delay":
        return TariffPlanFeatureTypeEnum.NodeDelay;
      case "Start Flow":
        return TariffPlanFeatureTypeEnum.NodeStartFlow;
      case "AI Assistant":
        return TariffPlanFeatureTypeEnum.NodeAiAgent;
      case "Condition":
        return TariffPlanFeatureTypeEnum.NodeCondition;
      case "System Action":
        return TariffPlanFeatureTypeEnum.NodeSystemAction;
      case "Assign":
        return TariffPlanFeatureTypeEnum.NodeAssignment;
      case "Calendar":
        return TariffPlanFeatureTypeEnum.NodeAppointments;
      case "Payment":
        return TariffPlanFeatureTypeEnum.NodePayment;
      case "Catalog":
        return TariffPlanFeatureTypeEnum.NodeShoppingCart;
      default:
        break;
    }
  };
  const flow = useAppSelector(selectFlow);
  const currentBot = useAppSelector(selectCurrentBot);

  return (
    <PopoverContent width={["300px", "auto", "auto"]} padding={"16px"}>
      <Text variant="largeBold" paddingBottom="12px">
        {currentBot?.channel !== "Web" ? t("Telegram") : t("Widget")}
      </Text>
      <PopoverCloseButton data-pw="close-button" onClick={props.onClose} className={s.closeIcon} />
      <SimpleGrid columns={[1, 2, 2]} spacing={[5, 5, 5]} gap="8px">
        {(currentBot?.channel !== "Web" ? telegramNodes : webBotTelegramNodes).map(el => (
          <NodeItem
            key={el.typeDiscriminator}
            title={el.title}
            actionType={el.typeDiscriminator}
            iconType={el.icon}
            addNode={props.addNode}
            flowBuilderWidth={props.flowBuilderWidth}
            flowBuilderHeight={props.flowBuilderHeight}
          />
        ))}
      </SimpleGrid>
      <Box className={s.extras}>
        <Text variant="largeBold" paddingBottom="12px">
          {t("Extras")}
        </Text>
        <SimpleGrid columns={[1, 2, 2]} spacing={[5, 5, 5]} gap="8px">
          {(currentBot?.channel !== "Web" ? extrasNodes : webBotExtrasNodes).map(el => {
            const type = typeIsAccessability(el.title);
            return <HOCNodeItem key={el.typeDiscriminator} flow={flow} item={el} type={type} {...props} />;
          })}
        </SimpleGrid>
      </Box>
    </PopoverContent>
  );
};
