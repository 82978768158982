import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Flex,
  Grid,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Show,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import DoubleArrowIcon from "../../../../../assets/icons/automationDoubleArrowIcon.svg?react";
import CommandTriggerIcon from "../../../../../assets/icons/commandTriggerIcon.svg?react";
import Star from "../../../../../assets/icons/starForCommand.svg?react";
import StarHover from "../../../../../assets/icons/starPlan.svg?react";
import RedTrashIcon from "../../../../../assets/icons/trash.svg?react";
import EditIcon from "../../../../../assets/icons/editIcon.svg?react";
import { useAppDispatch, useAppSelector } from "../../../../../common/state/store";
import { MenuCommandModel } from "../../../BotModel";
import { deleteBotMenuCommand, editBotMenuCommand } from "../../../BotSlice";
import s from "./MenuCommandsItem.module.scss";
import useGA from "../../../../../common/ga/GAEventTracker";
import { BotCommandsEvents } from "../../../../../common/ga/gaEventsEnums.ts/BotGAEventsEnums";
import { EventCategories } from "../../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import DotMenuContainer from "../../../../../UI/atoms/DotMenu/DotMenuContainer";
import EditCommandModal from "./EditCommandModal/EditCommandModal";
import { DeleteModal } from "../../../../../UI/molecules/deleteModal/DeleteModal";
import { LimitStarPlanButton } from "../../../../../UI/molecules/limitStarPlanButton/LimitStarPlanButton";
import { selectTours, setSwitchElement, startTour } from "../../../../onboardingTour/OnboardingToursSlice";
import { TourNames } from "../../../../onboardingTour/OnboardingTourEnums";

interface Props {
  botId: string;
  commandItem: MenuCommandModel;
}

export default function MenuCommandsItem({ commandItem, botId }: Props) {
  const { t } = useTranslation("translation", { keyPrefix: "bot" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const tm = useTranslation("translation", { keyPrefix: "bot.menuCommands" }).t;
  const trackEvent = useGA(EventCategories.Bot);
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: commandItem.id });
  const dispatch = useAppDispatch();
  const tours = useAppSelector(selectTours);
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const [editItem, setEditItem] = useState<MenuCommandModel | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();
  const starText = ["Build with Domino constructor", "Создано в конструкторе Domino"];
  const deleteModalTitle = `${t("Delete Command")} "${commandItem.command}"?`;
  const [isHovered, setIsHovered] = useState(false);
  const botMenuTour = tours?.find(tour => tour.tourName === TourNames.BotMenuTourName);

  useEffect(() => {
    setEditItem(commandItem);
  }, [commandItem]);

  useEffect(() => {
    onSaveClick(editItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editItem?.isActive]);

  function validateCommand(editItem: MenuCommandModel, editItemCommand: string, editItemDescription: string) {
    const validCommandNameRegex = /^([a-z-0-9_]*)$/;
    if (
      editItemCommand.length < 1 ||
      editItemCommand.length > 32 ||
      (editItemCommand.length > 0 && !editItemCommand.match(validCommandNameRegex))
    ) {
      setEditItem({ ...editItem, command: commandItem.command ?? "" });
    }

    if (editItemDescription.length < 2 || editItemDescription.length > 256 || !editItemDescription.length) {
      setEditItem({ ...editItem, description: commandItem.description ?? "" });
    }
  }

  const onSaveClick = (editItem: MenuCommandModel | null) => {
    if (!editItem) return;
    if (
      editItem?.description === commandItem.description &&
      editItem.command === commandItem.command &&
      editItem.isActive === commandItem.isActive
    ) {
      return;
    }

    validateCommand(editItem, editItem.command, editItem.description);

    dispatch(editBotMenuCommand({ botId, data: editItem }));
  };

  const handleSwitchChange = (e: ChangeEvent) => {
    if (!editItem) return;
    setEditItem({ ...editItem, isActive: !editItem.isActive });
    trackEvent(BotCommandsEvents.BotCommandsInteract);

    if (!botMenuTour?.isCompleted && editItem?.isActive) {
      dispatch(setSwitchElement(e.target.parentElement as HTMLElement));
      dispatch(startTour(TourNames.BotMenuTourName));
    }
  };

  const onDeleteClick = () => {
    if (editItem) {
      dispatch(deleteBotMenuCommand({ botId, commandId: editItem.id }));
    }
  };

  return (
    <>
      {editItem !== null && (
        <Flex
          className={`${s.wrapper} ${commandItem.commandType === "System" ? s.activeSystem : s.active}`}
          position="relative"
          ref={setNodeRef}
          style={style}
          direction="row"
        >
          <Box {...attributes} {...listeners} className={s.dragArea} data-pw="duble-arrow">
            <DoubleArrowIcon className={`${s.dragIcon}`} />
          </Box>
          <Show breakpoint="(min-width: 768px)">
            <Grid
              flex={1}
              gap="24px"
              border={
                commandItem.commandType === "System"
                  ? "1px solid rgba(133, 146, 163, 0.20)"
                  : "1px solid var(--chakra-colors-newGrey)"
              }
              borderRadius="8px"
              background={`${commandItem.commandType === "System" && "var(--chakra-colors-newGrey) !important"}`}
              className={s.item}
            >
              <InputGroup>
                <InputLeftElement>
                  <CommandTriggerIcon width="20px" height="20px" color="#4EAA4A" />
                </InputLeftElement>
                <Input
                  className={`${commandItem.commandType === "System" ? s.systemInput : s.inputField}`}
                  isDisabled={commandItem.commandType === "System"}
                  pl="40px"
                  _disabled={{ color: "black" }}
                  borderColor="transparent"
                  backgroundColor={commandItem.commandType === "System" ? "none" : "white"}
                  value={editItem?.command}
                  onChange={e => {
                    setEditItem({ ...editItem, command: e.target.value });
                  }}
                  onBlur={() => onSaveClick(editItem)}
                />
              </InputGroup>
              <InputGroup>
                <Input
                  className={`${commandItem.commandType === "System" ? s.systemInput : s.inputField} ${
                    commandItem.isActive ? s.inputActive : s.inputInactive
                  }`}
                  color={"#232B39"}
                  isDisabled={commandItem.commandType === "System"}
                  borderColor="transparent"
                  backgroundColor={commandItem.commandType === "System" ? "none" : "white"}
                  p="8px"
                  placeholder={t("Add description")}
                  value={commandItem.commandType === "System" ? tm(editItem?.description) : editItem?.description}
                  onChange={e => {
                    setEditItem({ ...editItem, description: e.target.value });
                  }}
                  onBlur={() => onSaveClick(editItem)}
                  textOverflow="ellipsis"
                  pr="25px"
                />
                <InputRightElement>
                  {commandItem.commandType === "System" && starText.includes(commandItem.description) && (
                    <LimitStarPlanButton popoverBodyDescription="Please upgrade your plan to disable the command">
                      <Flex onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                        {isHovered ? <StarHover width="16px" height="16px" /> : <Star />}
                      </Flex>
                    </LimitStarPlanButton>
                  )}
                </InputRightElement>
              </InputGroup>
              {commandItem.commandType === "System" && starText.includes(commandItem.description) ? (
                <Switch isChecked={editItem?.isActive} justifySelf="center" variant="dominoDisabled" onChange={undefined} />
              ) : (
                <Switch
                  isChecked={editItem?.isActive}
                  justifySelf="center"
                  variant="dominoDefaultGreen"
                  onChange={handleSwitchChange}
                />
              )}
              {editItem.commandType === "System" ? (
                <Text background="#DCE7FB" p="2px 4px" borderRadius="4px">
                  System
                </Text>
              ) : (
                <Flex align="center" justify="center">
                  <DotMenuContainer
                    menuItems={[
                      {
                        MenuItem: {
                          color: "mainRed",
                          icon: <RedTrashIcon width="18px" height="18px" />,
                          text: ct("Delete"),
                          clickEvent: e => {
                            e.stopPropagation();
                            onOpenDelete();
                          },
                        },
                      },
                    ]}
                  />
                </Flex>
              )}
            </Grid>
          </Show>

          <Show breakpoint="(max-width: 767px)">
            <Flex
              border={
                commandItem.commandType === "System"
                  ? "1px solid rgba(133, 146, 163, 0.20)"
                  : "1px solid var(--chakra-colors-newGrey)"
              }
              background={`${commandItem.commandType === "System" && "var(--chakra-colors-newGrey) !important"}`}
              className={s.item}
            >
              <Flex w="100%" borderBottom="1px solid #DCE7FB" justify="space-between" h="36px">
                <Flex gap="4px" w="calc(100vw - 165px)">
                  <Flex minW="20px">
                    <CommandTriggerIcon width="20px" height="20px" color="#4EAA4A" />
                  </Flex>
                  <Flex w="111%">
                    <Text
                      fontSize="16px"
                      lineHeight="20px"
                      fontWeight="500"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      {editItem?.command}
                    </Text>
                  </Flex>
                </Flex>
                <Flex gap="8px">
                  {editItem.commandType === "System" && (
                    <Text background="#DCE7FB" p="2px 4px" borderRadius="4px" fontSize="13px" lineHeight="16px" h="max-content">
                      System
                    </Text>
                  )}
                  {commandItem.commandType === "System" && starText.includes(commandItem.description) ? (
                    <Switch isChecked={editItem?.isActive} justifySelf="center" variant="dominoDisabled" onChange={undefined} />
                  ) : (
                    <Switch
                      isChecked={editItem?.isActive}
                      justifySelf="center"
                      height="fit-content"
                      variant="dominoDefaultGreen"
                      onChange={handleSwitchChange}
                    />
                  )}
                </Flex>
              </Flex>
              <Flex w="100%" justify="space-between" h="36px" align="end">
                <Flex fontSize="15px" lineHeight="20px" w="100%">
                  <Flex gap="8px" w="calc(100vw - 117px)">
                    <Text color="#8592A3">{t("Description")}</Text>
                    <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                      {commandItem.commandType === "System" ? tm(editItem?.description) : editItem?.description}
                    </Text>
                  </Flex>
                </Flex>
                {commandItem.commandType === "System" && starText.includes(commandItem.description) && (
                  <LimitStarPlanButton popoverBodyDescription="Please upgrade your plan to disable the command">
                    <Flex onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                      {isHovered ? <StarHover width="16px" height="16px" /> : <Star />}
                    </Flex>
                  </LimitStarPlanButton>
                )}
                {commandItem.commandType !== "System" && (
                  <Flex mb="-5px">
                    <DotMenuContainer
                      menuItems={[
                        {
                          MenuItem: {
                            icon: <EditIcon width="18px" height="18px" color={"black"} />,
                            text: ct("Edit"),
                            clickEvent: () => onOpen(),
                          },
                        },
                        {
                          MenuItem: {
                            color: "mainRed",
                            icon: <RedTrashIcon width="18px" height="18px" />,
                            text: ct("Delete"),
                            clickEvent: e => {
                              e.stopPropagation();
                              onOpenDelete();
                            },
                          },
                        },
                      ]}
                    />
                  </Flex>
                )}
              </Flex>
            </Flex>
            <EditCommandModal isOpen={isOpen} onClose={onClose} botId={botId} commandItem={commandItem} />
          </Show>
          <DeleteModal isOpen={isOpenDelete} onClose={onCloseDelete} onDelete={onDeleteClick} title={deleteModalTitle} />
        </Flex>
      )}
    </>
  );
}
