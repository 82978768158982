import { call, put, select, takeLatest } from "redux-saga/effects";
import { profanityFilterSlice, selectProfanityFilter } from "./ProfanityFilterSlice";
import { handleException } from "../../../../../../../common/SagaHelper";
import { PayloadAction } from "@reduxjs/toolkit";
import * as ProfanityFilterApi from "./ProfanityFilterApi";
import { ProfanityFilterModel } from "./ProfanityFilterModel";
import { selectGroupChatInfo } from "../../GroupChatInfo/GroupChatInfoSlice";
import { GroupChatModel } from "../../../../../GroupChatModel";
import { beginScope, completeScope } from "../../../../../../../common/loading/LoadingStateActions";
import { notificationSlice } from "../../../../../../../common/notifications/NotificationSlice";
import { ProfanityFilterData } from "./ProfanityFilterData";
import { convertProfanityFilter } from "./utils";
import { selectConversationInfo } from "../../GroupChatConversation/components/GroupChatMessagesList/GroupChatMessagesListSlice";
import { ConversationInfoModel, ConversationTypeEnum } from "../../../../../../conversation/ConversationModel";
import { EventCategories } from "../../../../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import GTM from "../../../../../../../common/ga/GAEventTracker";
import { GroupsChatProfanityEvents } from "../../../../../../../common/ga/gaEventsEnums.ts/GroupGAEventsEnums";
import { PROFANITY_FILTER_DEFAULT } from "../_components/FilterSettings/consts";

export function* profanityFilterSaga() {
  yield takeLatest(profanityFilterSlice.actions.setProfanityFilter, setProfanityFilter);
  yield takeLatest(profanityFilterSlice.actions.toggleIsEnabled, toggleIsEnabled);
  yield takeLatest(profanityFilterSlice.actions.saveChanges, saveChanges);
}

function* setProfanityFilter(action: PayloadAction<ProfanityFilterData | null>) {
  if (!action.payload) {
    yield put(profanityFilterSlice.actions.setProfanityFilterCompleted(PROFANITY_FILTER_DEFAULT));
    return;
  }

  const conversationInfo: ConversationInfoModel | undefined = yield select(selectConversationInfo);
  const isPrivateGroup = conversationInfo?.conversationType === ConversationTypeEnum.Group;

  const profanityFilter = convertProfanityFilter.fromDataToModel(action.payload, isPrivateGroup);
  yield put(profanityFilterSlice.actions.setProfanityFilterCompleted(profanityFilter));
}

function* toggleIsEnabled(action: PayloadAction<boolean>) {
  try {
    yield put(beginScope("profanityFilter/toggleLoading"));

    const profanityFilter: ProfanityFilterModel = yield select(selectProfanityFilter);
    const groupInfo: GroupChatModel = yield select(selectGroupChatInfo);

    yield call(ProfanityFilterApi.changeProfanityFilter, {
      groupId: groupInfo.id,
      profanityFilter: { ...convertProfanityFilter.fromModelToData(profanityFilter), isEnabled: action.payload },
    });

    yield put(profanityFilterSlice.actions.toggleIsEnabledCompleted(action.payload));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("profanityFilter/toggleLoading"));
  }
}

function* saveChanges(action: PayloadAction<ProfanityFilterModel>) {
  try {
    yield put(beginScope("profanityFilter/saveChanges"));

    const groupInfo: GroupChatModel = yield select(selectGroupChatInfo);

    const profanityFilter: ProfanityFilterData = yield call(ProfanityFilterApi.changeProfanityFilter, {
      groupId: groupInfo.id,
      profanityFilter: convertProfanityFilter.fromModelToData(action.payload),
    });

    const conversationInfo: ConversationInfoModel | undefined = yield select(selectConversationInfo);
    const isPrivateGroup = conversationInfo?.conversationType === ConversationTypeEnum.Group;

    const convertedProfanityFilter = convertProfanityFilter.fromDataToModel(profanityFilter, isPrivateGroup);
    yield put(profanityFilterSlice.actions.saveChangesCompleted(convertedProfanityFilter));
    yield put(
      notificationSlice.actions.notify({
        message: "Saved successfully!",
        type: "success",
      }),
    );
    const trackEvent = GTM(EventCategories.Group);
    trackEvent(GroupsChatProfanityEvents.GroupsChatProfanitySaveFinish);
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("profanityFilter/saveChanges"));
  }
}
