import { QuestionOutlineIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Text,
  Flex,
  FormControl,
  Image,
  Textarea,
  Box,
  Menu,
  MenuButton,
  MenuList,
  ButtonGroup,
  forwardRef,
} from "@chakra-ui/react";
import CrossIcon from "../../../../assets/icons/cross.svg?react";
import DownArrow from "../../../../assets/images/downArrow.svg";
import { useState } from "react";
import s from "./AddVariableModal.module.scss";
import { CustomVariableScope, CustomVariableType, AddVariablePopupModel } from "../AddVariableModel";
import { useTranslation } from "react-i18next";
import { BooleanVariableValues, CustomVariableModel } from "../../../../common/AppEnums";
import { customDateInput, DatePicker } from "../../../../UI/atoms/datePicker/DatePicker";
import dayjs from "dayjs";
import { TooltipWithTouch } from "../../../../common/tooltipWithTouch/TooltipWithTouch";
import useGA from "../../../../common/ga/GAEventTracker";
import { BotFieldsEvents } from "../../../../common/ga/gaEventsEnums.ts/BotGAEventsEnums";
import { EventCategories } from "../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import { CustomMenuItem } from "../../../../UI/molecules/customMenuItem/CustomMenuItem";
import { numberValidated } from "../../../contact/components/EditContact/EditContactValidation";

type Props = {
  isOpen: boolean;
  onClose: () => void;
} & AddVariablePopupModel;

export const AddVariablePopup = (props: Props) => {
  const isValueNecessary = props.scope === CustomVariableScope.Bot;
  const { t } = useTranslation("translation");
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const vpe = useTranslation("translation", { keyPrefix: "flow.addVariablePopup" }).t;
  const vt = useTranslation("translation", { keyPrefix: "variableTypes" }).t;
  const trackEvent = useGA(EventCategories.Bot);
  const [newCustomVariableInfo, setNewCustomVariableInfo] = useState<CustomVariableModel>({
    key: "",
    type: CustomVariableType.Text,
    scope: props.scope ?? CustomVariableScope.Flow,
    ...(isValueNecessary && { value: "" }),
    description: "",
    containerId: props.botId ?? props.flowId ?? null,
  });
  const [nameError, setNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [valueError, setValueError] = useState("");
  const CustomInput = forwardRef(customDateInput);
  const locale = localStorage.getItem("i18nextLng");

  const onCreate = () => {
    if (!fieldValidation()) {
      return;
    }
    props?.onCreate?.(newCustomVariableInfo);
  };

  const onModalClose = () => {
    props.onClose();
    trackEvent(BotFieldsEvents.BotFieldsCancel);
  };

  const validateName = (value: string) => {
    const maxWidth = 25;
    const allowedSymbols = /^[а-яА-ЯёЁA-Za-z0-9-_]+$/;
    if (!value.replace(/\s/g, "").length) {
      setNameError("Name Field is Required");
      return false;
    }
    if (value.length > maxWidth) {
      setNameError("Name must be less than 25 characters long");
      return false;
    }
    if (!value.match(allowedSymbols)) {
      setNameError("Name can contain only letters, numbers, hyphens and underscores");
      return false;
    }
    setNameError("");
    return true;
  };

  const validateDescription = (value: string | undefined) => {
    const maxWidth = 250;
    setDescriptionError("");
    if (!value) {
      return true;
    }
    if (value.length > maxWidth) {
      setDescriptionError(vpe("errors.Description must be less than 250 characters long"));
      return false;
    }
    if (!value.replace(/\s/g, "").length) {
      setDescriptionError(vpe("errors.Description can't contain only spaces"));
      return false;
    }
    return true;
  };

  const validateValue = (value: string | undefined) => {
    const type = newCustomVariableInfo.type;
    let isValid = true;
    let valueError = "";

    if (type === CustomVariableType.Boolean && value) {
      isValid = ["True", "False"].includes(value);
      valueError = isValid ? "" : "Boolean Field Should Be true or false";
    }
    if (type === CustomVariableType.Number && value) {
      isValid = numberValidated(value);
      valueError = isValid ? "" : "Number Field Should contains only numbers";
    }

    setValueError(valueError);
    return isValid;
  };

  const fieldValidation = () => {
    const nameValidation = validateName(newCustomVariableInfo.key);
    const descriptionValidation = validateDescription(newCustomVariableInfo.description);
    const valueValidation =
      newCustomVariableInfo.scope === CustomVariableScope.Bot ? validateValue(newCustomVariableInfo.value) : true;
    return !(!nameValidation || !descriptionValidation || !valueValidation);
  };

  const customVariableScopeOnChange = (scope: CustomVariableScope) => {
    const containerId = scope === CustomVariableScope.Flow ? props.flowId : null;
    if (containerId !== undefined) {
      setNewCustomVariableInfo({
        ...newCustomVariableInfo,
        scope: scope,
        containerId,
      });
    }
  };

  const getPlaceholder = () => {
    const type = newCustomVariableInfo.type;
    if (type === CustomVariableType.Array) {
      return t("flow.addVariablePopup.Enter array values, separated by commas");
    }
    if (type === CustomVariableType.Number) {
      return t("flow.addVariablePopup.Enter digits");
    }
    return t("flow.addVariablePopup.Enter text");
  };

  const isFlow = newCustomVariableInfo.scope === CustomVariableScope.Flow;

  const getValueInput = () => {
    switch (newCustomVariableInfo.type) {
      case CustomVariableType.DateTime: {
        return (
          <DatePicker
            dateFormat={locale !== "en" ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy h:mm a"}
            wrapperClassName={s.datePicker}
            selected={newCustomVariableInfo.value ? new Date(newCustomVariableInfo.value) : null}
            onChange={scheduledDate => {
              setNewCustomVariableInfo({
                ...newCustomVariableInfo,
                value: dayjs(scheduledDate).isValid() ? dayjs(scheduledDate).format("YYYY-MM-DDTHH:mmZZ") : "",
              });
            }}
            strictParsing
            placeholderText={`${ct("placeholderDateTime")}`}
            showTimeSelect
            customInput={<CustomInput h={"36px"} />}
          />
        );
      }
      case CustomVariableType.Date: {
        return (
          <DatePicker
            wrapperClassName={s.datePicker}
            dateFormat="dd.MM.yyyy"
            selected={newCustomVariableInfo.value ? new Date(newCustomVariableInfo.value) : null}
            onChange={scheduledDate => {
              setNewCustomVariableInfo({
                ...newCustomVariableInfo,
                value: dayjs(scheduledDate).isValid() ? dayjs(scheduledDate).format("YYYY-MM-DD") : "",
              });
            }}
            strictParsing
            placeholderText={`${ct("placeholderDate")}`}
            customInput={<CustomInput h={"36px"} />}
          />
        );
      }
      case CustomVariableType.Boolean: {
        return (
          <Menu preventOverflow={false} matchWidth={true} variant="dominoDotsMenu" gutter={0}>
            {({ isOpen, onClose }) => (
              <>
                <MenuButton className={s.menuButton} onClick={e => e.stopPropagation()}>
                  <Flex justifyContent={"space-between"} alignItems={"center"}>
                    <Flex w={"100%"} justifyContent="space-between" alignItems={"center"} ml={"1%"}>
                      {newCustomVariableInfo.value?.length ? ct(newCustomVariableInfo.value) : ct("Not Set")}
                      <Image boxSize={"24px"} src={DownArrow}></Image>
                    </Flex>
                  </Flex>
                </MenuButton>
                <MenuList>
                  {Object.keys(BooleanVariableValues).map((el, i) => {
                    return (
                      <CustomMenuItem
                        key={i}
                        value={el}
                        onClick={() => {
                          setNewCustomVariableInfo({
                            ...newCustomVariableInfo,
                            value: el !== BooleanVariableValues["Not Set"] ? el : "",
                          });
                          onClose();
                        }}
                      >
                        {ct(el)}
                      </CustomMenuItem>
                    );
                  })}
                </MenuList>
              </>
            )}
          </Menu>
        );
      }
      case CustomVariableType.Array:
      case CustomVariableType.Number:
      case CustomVariableType.Text:
      default: {
        return (
          <Input
            h={"36px"}
            value={newCustomVariableInfo.value}
            onChange={e =>
              setNewCustomVariableInfo({
                ...newCustomVariableInfo,
                value: e.target.value,
              })
            }
            onBlur={() => validateValue(newCustomVariableInfo.value)}
            type={newCustomVariableInfo.type}
            placeholder={getPlaceholder()}
            data-pw="fieldValue"
          />
        );
      }
    }
  };

  return (
    <>
      <Modal returnFocusOnClose size={"lg"} isCentered={true} isOpen={props.isOpen} onClose={props.onClose}>
        <ModalOverlay bg="none" backdropFilter="blur(2px)" />
        <ModalContent mx={"5%"} w="460px">
          <ModalHeader />
          <ModalCloseButton borderRadius={"50%"} color={"darkGrey"} _hover={{ bg: "defaultGrey", color: "mainPurple" }}>
            <CrossIcon />
          </ModalCloseButton>
          <ModalBody p="4px 32px">
            <Flex alignItems={"center"} flexDirection={"column"}>
              <Text variant="h1">
                {newCustomVariableInfo.scope === CustomVariableScope.Flow
                  ? t("flow.addVariablePopup.Create New Variable")
                  : t("flow.addVariablePopup.Create New Field")}
              </Text>
              {!props.scope && (
                <FormControl>
                  <Flex mt="24px" w={"100%"} justifyContent={"center"} alignItems={"center"}>
                    <ButtonGroup bg="line" borderRadius="10px" p="4px" width="100%">
                      <Button
                        width="100%"
                        variant={isFlow ? "dominoViolet" : "dominoGhostViolet"}
                        onClick={() => customVariableScopeOnChange(CustomVariableScope.Flow)}
                      >
                        {t("flow.addVariablePopup.Flow")}
                      </Button>
                      <Button
                        width="100%"
                        variant={isFlow ? "dominoGhostViolet" : "dominoViolet"}
                        onClick={() => customVariableScopeOnChange(CustomVariableScope.Contact)}
                      >
                        {t("flow.addVariablePopup.Contact")}
                      </Button>
                    </ButtonGroup>
                  </Flex>
                </FormControl>
              )}
              <Text variant="medium" color="darkGrey" my="24px">
                {newCustomVariableInfo.scope === CustomVariableScope.Flow &&
                  t(
                    "flow.addVariablePopup.Custom conversation variables let you save info related to the specific conversation with bot.",
                  )}
                {newCustomVariableInfo.scope === CustomVariableScope.Bot &&
                  t(
                    "flow.addVariablePopup.Custom bot fields let you save info about your bot that can be used with different contacts.",
                  )}
                {newCustomVariableInfo.scope === CustomVariableScope.Contact &&
                  t(
                    "flow.addVariablePopup.Custom contact fields let you save info about your contacts that can be used by different bots.",
                  )}
              </Text>
              <FormControl>
                <Text mb="12px" variant="medium" display={"flex"} alignItems={"center"}>
                  {newCustomVariableInfo.scope === CustomVariableScope.Flow
                    ? `${t("flow.addVariablePopup.Name")}`
                    : `${t("flow.addVariablePopup.Name field")}`}{" "}
                  &nbsp;
                  <TooltipWithTouch
                    variant="dominoLight"
                    placement="top"
                    label={t("flow.addVariablePopup.Name can contain only letters, numbers, hyphens and underscores")}
                  >
                    <QuestionOutlineIcon boxSize="14px" color="darkGrey" _hover={{ color: "darkPurple" }} />
                  </TooltipWithTouch>
                </Text>
                <Input
                  h={"36px"}
                  value={newCustomVariableInfo.key}
                  maxLength={25}
                  data-pw="inputName"
                  placeholder={
                    newCustomVariableInfo.scope === CustomVariableScope.Flow
                      ? `${t("flow.addVariablePopup.Enter name")}`
                      : `${t("flow.addVariablePopup.Enter name field")}`
                  }
                  onChange={e =>
                    setNewCustomVariableInfo({
                      ...newCustomVariableInfo,
                      key: e.target.value.replace(/\s/g, ""),
                    })
                  }
                  onBlur={() => validateName(newCustomVariableInfo.key)}
                  type="text"
                />
                <Box color="#f92222">
                  <Text minH="16px" variant="small">
                    {nameError && t(`flow.addVariablePopup.errors.${nameError}`)}
                  </Text>
                </Box>
              </FormControl>
              <FormControl my={"10px"}>
                <Text mb="12px" variant="medium" display={"flex"} alignItems={"center"}>
                  {t("flow.addVariablePopup.Reply Type")} &nbsp;
                  <TooltipWithTouch
                    variant="dominoLight"
                    placement="top"
                    label={t(
                      "flow.addVariablePopup.Choose the type of the field according to the nature of data that you want to store. String means any amount of text, boolean means yes/no/undefined states, while number and date are self-explanatory.",
                    )}
                  >
                    <QuestionOutlineIcon boxSize="14px" color="darkGrey" _hover={{ color: "darkPurple" }} />
                  </TooltipWithTouch>
                </Text>
                <Menu matchWidth={true} variant="dominoDotsMenu" gutter={0}>
                  {({ isOpen, onClose }) => (
                    <>
                      <MenuButton className={s.menuButton} onClick={e => e.stopPropagation()}>
                        <Flex justifyContent={"space-between"} alignItems={"center"}>
                          <Flex w={"90%"} alignItems={"center"} ml={"1%"} data-pw="fieldType">
                            {vt(newCustomVariableInfo.type)}
                          </Flex>
                          <Image boxSize={"24px"} src={DownArrow}></Image>
                        </Flex>
                      </MenuButton>
                      <MenuList data-pw="button-group">
                        {/* {props.scope === CustomVariableScope.Contact
                    ? Object.keys(CustomVariableType).map((el, i) => {
                        return (
                          <MenuItem
                            key={i}
                            value={el}
                            onClick={() =>
                              setNewCustomVariableInfo({
                                ...newCustomVariableInfo,
                                ...(isValueNecessary && { value: "" }),
                                type: CustomVariableType[el as keyof typeof CustomVariableType],
                              })
                            }
                          >
                            {vt(el)}
                          </MenuItem>
                        );
                      })
                    :  */}
                        {Object.keys(CustomVariableType)
                          .filter(k => k !== CustomVariableType.Order)
                          .map((el, i) => {
                            return (
                              <CustomMenuItem
                                key={i}
                                value={el}
                                data-pw={`${el}-type`}
                                onClick={() => {
                                  setNewCustomVariableInfo({
                                    ...newCustomVariableInfo,
                                    ...(isValueNecessary && { value: "" }),
                                    type: CustomVariableType[el as keyof typeof CustomVariableType],
                                  });
                                  trackEvent(BotFieldsEvents.BotFieldsType);
                                  onClose();
                                }}
                              >
                                {vt(el)}
                              </CustomMenuItem>
                            );
                          })}
                      </MenuList>
                    </>
                  )}
                </Menu>
              </FormControl>
              {isValueNecessary && (
                <Box width="100%">
                  <Text my="10px" variant="medium" display={"flex"} alignItems={"center"}>
                    {t("flow.addVariablePopup.Value")} &nbsp;
                    <TooltipWithTouch
                      variant="dominoLight"
                      placement="top"
                      label={t("flow.addVariablePopup.Value should match the type")}
                    >
                      <QuestionOutlineIcon boxSize="14px" color="darkGrey" _hover={{ color: "darkPurple" }} />
                    </TooltipWithTouch>
                  </Text>
                  {getValueInput()}
                  <Box color="#f92222">
                    <Text minH="16px" variant="small">
                      {valueError && t(`flow.addVariablePopup.errors.${valueError}`)}
                    </Text>
                  </Box>
                </Box>
              )}
              <FormControl my={"10px"}>
                <Text mb="12px" variant="medium">
                  {t("flow.addVariablePopup.Description (Optional)")}
                </Text>
                <Textarea
                  variant="dominoOutline"
                  placeholder={t("flow.addVariablePopup.Enter field’s description")}
                  resize={"none"}
                  value={newCustomVariableInfo.description}
                  maxLength={250}
                  data-pw="descriptionField"
                  onChange={e =>
                    setNewCustomVariableInfo({
                      ...newCustomVariableInfo,
                      description: e.target.value,
                    })
                  }
                />
                <Box color="#f92222">
                  <Text variant="small">{descriptionError}</Text>
                </Box>
              </FormControl>
            </Flex>
          </ModalBody>

          <ModalFooter p="16px 32px 32px">
            <Flex w={"100%"} justifyContent={"space-between"}>
              <Button onClick={onModalClose} variant="dominoOutlineViolet" data-pw="createFieldCancel">
                {t("commonWords.Cancel")}
              </Button>
              <Button
                variant="dominoViolet"
                onClick={() => {
                  trackEvent(BotFieldsEvents.BotFieldsCreate);
                  onCreate();
                }}
                colorScheme={`${newCustomVariableInfo.scope === CustomVariableScope.Flow ? "blue" : "whatsapp"}`}
                mr={3}
                data-pw="createField"
              >
                {t("commonWords.Create")}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
