import { Dispatch, SetStateAction } from "react";
import { saveChanges } from "../../ProfanityFilter/ProfanityFilterSlice";
import { saveChanges as saveAntispamChanges } from "../../AntispamFilter/AntispamFilterSlice";
import { AnyAction } from "@reduxjs/toolkit";
import { ANTISPAM_FILTER_DEFAULT, CUSTOM_SPLITTER, PROFANITY_FILTER_DEFAULT } from "./consts";
import { GroupsChatProfanityEvents } from "../../../../../../../../common/ga/gaEventsEnums.ts/GroupGAEventsEnums";
import { ProfanityFilterModel, ProfanityFilterRule } from "../../ProfanityFilter/ProfanityFilterModel";
import { FilterRule } from "../../_types/FilterRule";
import { AntispamFilterModel } from "../../AntispamFilter/AntispamFilterModel";
import {
  PhraseWhiteList,
  UrlWhiteList,
  UsersWhiteListNotification,
  ViolationNotification,
} from "../../_types/AntispamFilterTypes";
import { validateLinkList, validatePhraseWhiteList, validateUserList } from "../../AntispamFilter/components/helpers";

interface ButtonsHandlers {
  profanityFilter: ProfanityFilterModel;
  rulesCopy: ProfanityFilterRule[];
  customProfanities: string;
  dispatch: Dispatch<AnyAction>;
  setCustomProfanities: Dispatch<SetStateAction<string>>;
  setRulesCopy: Dispatch<SetStateAction<ProfanityFilterRule[]>>;
  setWasReset: Dispatch<SetStateAction<boolean>>;
  trackEvent: (action: string, label?: string, data?: unknown) => void;
}
export interface ButtonsHandlersReturnedValue {
  cancelChanges: () => void;
  resetToDefault: () => void;
  saveChanges: () => void;
}

export const profanityFilterButtonsHandlers = ({
  profanityFilter,
  rulesCopy,
  customProfanities,
  dispatch,
  setCustomProfanities,
  setRulesCopy,
  setWasReset,
  trackEvent,
}: ButtonsHandlers): ButtonsHandlersReturnedValue => ({
  cancelChanges: () => {
    setRulesCopy(profanityFilter.rules);
    setCustomProfanities(profanityFilter.customProfanities.join(CUSTOM_SPLITTER));

    setWasReset(false);
    trackEvent(GroupsChatProfanityEvents.GroupsChatProfanityCancel);
  },
  resetToDefault: () => {
    setRulesCopy(PROFANITY_FILTER_DEFAULT.rules);
    setCustomProfanities(PROFANITY_FILTER_DEFAULT.customProfanities.join(CUSTOM_SPLITTER));

    setWasReset(true);
    trackEvent(GroupsChatProfanityEvents.GroupsChatProfanityReset);
  },
  saveChanges: () => {
    const parsedCustomProfanities = customProfanities.trim().split(CUSTOM_SPLITTER);
    dispatch(
      saveChanges({
        ...profanityFilter,
        rules: rulesCopy,
        customProfanities: parsedCustomProfanities,
      }),
    );
    setWasReset(false);
    trackEvent(GroupsChatProfanityEvents.GroupsChatProfanitySave, "", { customProfanities: parsedCustomProfanities });
  },
});

interface AntispamButtonsHandlers {
  antispamFilter: AntispamFilterModel;
  rules: FilterRule[];
  setRules: Dispatch<SetStateAction<FilterRule[]>>;
  prohibitedTopics: string[];
  setProhibitedTopics: Dispatch<SetStateAction<string[]>>;
  violationNotification: ViolationNotification;
  setViolationNotification: Dispatch<SetStateAction<ViolationNotification>>;
  usersWhiteList: UsersWhiteListNotification;
  setUsersWhiteList: Dispatch<SetStateAction<UsersWhiteListNotification>>;
  urlWhiteList: UrlWhiteList;
  setUrlWhiteList: Dispatch<SetStateAction<UrlWhiteList>>;
  phraseWhiteList: PhraseWhiteList;
  setPhraseWhiteList: Dispatch<SetStateAction<PhraseWhiteList>>;
  setWasReset: Dispatch<SetStateAction<boolean>>;
  setIsInvalidViolationNotification: Dispatch<SetStateAction<boolean>>;
  setIsInvalidUsersWhiteList: Dispatch<SetStateAction<boolean>>;
  setIsInvalidLinkWhitelist: Dispatch<SetStateAction<boolean>>;
  setIsInvalidPhraseWhiteList: Dispatch<SetStateAction<boolean>>;
  setIsInvalidProhibitedTopics: Dispatch<SetStateAction<boolean>>;
  dispatch: Dispatch<AnyAction>;
}
export interface AntispamButtonsHandlersReturnedValue {
  cancelChanges: () => void;
  resetToDefault: () => void;
  saveChanges: () => void;
}

export const antispamFilterButtonsHandlers = ({
  antispamFilter,
  rules,
  setRules,
  prohibitedTopics,
  setProhibitedTopics,
  violationNotification,
  setViolationNotification,
  usersWhiteList,
  setUsersWhiteList,
  urlWhiteList,
  setUrlWhiteList,
  phraseWhiteList,
  setPhraseWhiteList,
  setWasReset,
  setIsInvalidViolationNotification,
  setIsInvalidUsersWhiteList,
  setIsInvalidLinkWhitelist,
  setIsInvalidPhraseWhiteList,
  setIsInvalidProhibitedTopics,
  dispatch,
}: AntispamButtonsHandlers): AntispamButtonsHandlersReturnedValue => ({
  cancelChanges: () => {
    setRules(antispamFilter.rules);
    setProhibitedTopics(antispamFilter.prohibitedTopics);
    setViolationNotification({
      tgUsernames: antispamFilter.violationNotificationSettings.tgUsernames.join(CUSTOM_SPLITTER),
      isEnabled: antispamFilter.violationNotificationSettings.isEnabled,
    });
    setUsersWhiteList({
      tgUsernames: antispamFilter.tgUsersWhiteListSettings.tgUsernames.join(CUSTOM_SPLITTER),
      isEnabled: antispamFilter.tgUsersWhiteListSettings.isEnabled,
    });
    setUrlWhiteList({
      urls: antispamFilter.urlWhiteListSettings.urls.join(CUSTOM_SPLITTER),
      isEnabled: antispamFilter.urlWhiteListSettings.isEnabled,
    });
    setPhraseWhiteList({
      phrases: antispamFilter.phraseWhiteListSettings.phrases.join(CUSTOM_SPLITTER),
      isEnabled: antispamFilter.phraseWhiteListSettings.isEnabled,
    });

    setWasReset(false);
  },
  resetToDefault: () => {
    setRules(ANTISPAM_FILTER_DEFAULT.rules);
    setProhibitedTopics(ANTISPAM_FILTER_DEFAULT.prohibitedTopics);
    setViolationNotification({
      tgUsernames: ANTISPAM_FILTER_DEFAULT.violationNotificationSettings.tgUsernames.join(CUSTOM_SPLITTER),
      isEnabled: ANTISPAM_FILTER_DEFAULT.violationNotificationSettings.isEnabled,
    });
    setUsersWhiteList({
      tgUsernames: ANTISPAM_FILTER_DEFAULT.tgUsersWhiteListSettings.tgUsernames.join(CUSTOM_SPLITTER),
      isEnabled: ANTISPAM_FILTER_DEFAULT.tgUsersWhiteListSettings.isEnabled,
    });
    setUrlWhiteList({
      urls: ANTISPAM_FILTER_DEFAULT.urlWhiteListSettings.urls.join(CUSTOM_SPLITTER),
      isEnabled: ANTISPAM_FILTER_DEFAULT.urlWhiteListSettings.isEnabled,
    });
    setPhraseWhiteList({
      phrases: ANTISPAM_FILTER_DEFAULT.phraseWhiteListSettings.phrases.join(CUSTOM_SPLITTER),
      isEnabled: ANTISPAM_FILTER_DEFAULT.phraseWhiteListSettings.isEnabled,
    });

    setWasReset(true);
  },
  saveChanges: () => {
    const isInvalidProhibitedTopics = prohibitedTopics.length === 0;
    const isInvalidViolationNotification = !validateUserList(violationNotification.tgUsernames);
    const isInvalidUsersWhiteList = !validateUserList(usersWhiteList.tgUsernames);
    const isInvalidUrlWhiteList = !validateLinkList(urlWhiteList.urls);
    const isInvalidPhraseWhiteList = !validatePhraseWhiteList(phraseWhiteList.phrases);

    if (
      isInvalidProhibitedTopics ||
      isInvalidViolationNotification ||
      isInvalidUsersWhiteList ||
      isInvalidUrlWhiteList ||
      isInvalidPhraseWhiteList
    ) {
      if (isInvalidProhibitedTopics) setIsInvalidProhibitedTopics(true);
      if (isInvalidViolationNotification) setIsInvalidViolationNotification(true);
      if (isInvalidUsersWhiteList) setIsInvalidUsersWhiteList(true);
      if (isInvalidUrlWhiteList) setIsInvalidLinkWhitelist(true);
      if (isInvalidPhraseWhiteList) setIsInvalidPhraseWhiteList(true);
      return;
    }

    const currentTGUsernamesForViolationNotification =
      violationNotification.tgUsernames === "" ? [] : violationNotification.tgUsernames.split(CUSTOM_SPLITTER);
    const currentTGUsernamesForWhiteList =
      usersWhiteList.tgUsernames === "" ? [] : usersWhiteList.tgUsernames.split(CUSTOM_SPLITTER);
    const currentUrlsForWhiteList = urlWhiteList.urls === "" ? [] : urlWhiteList.urls.split(CUSTOM_SPLITTER);
    const currentPhrasesForWhiteList = phraseWhiteList.phrases === "" ? [] : phraseWhiteList.phrases.split(CUSTOM_SPLITTER);

    const isEnabledViolationNotification =
      violationNotification.isEnabled && currentTGUsernamesForViolationNotification.length === 0
        ? false
        : violationNotification.isEnabled;
    const isEnabledUsersWhiteList =
      usersWhiteList.isEnabled && currentTGUsernamesForWhiteList.length === 0 ? false : usersWhiteList.isEnabled;
    const isEnabledUrlWhiteList = urlWhiteList.isEnabled && currentUrlsForWhiteList.length === 0 ? false : urlWhiteList.isEnabled;
    const isEnabledPhraseWhiteList =
      phraseWhiteList.isEnabled && currentPhrasesForWhiteList.length === 0 ? false : phraseWhiteList.isEnabled;

    if (isEnabledViolationNotification !== violationNotification.isEnabled) {
      setViolationNotification({
        ...violationNotification,
        isEnabled: isEnabledViolationNotification,
      });
    }
    if (isEnabledUsersWhiteList !== usersWhiteList.isEnabled) {
      setUsersWhiteList({
        ...usersWhiteList,
        isEnabled: isEnabledUsersWhiteList,
      });
    }
    if (isEnabledUrlWhiteList !== urlWhiteList.isEnabled) {
      setUrlWhiteList({
        ...urlWhiteList,
        isEnabled: isEnabledUrlWhiteList,
      });
    }
    if (isEnabledPhraseWhiteList !== phraseWhiteList.isEnabled) {
      setPhraseWhiteList({
        ...phraseWhiteList,
        isEnabled: isEnabledPhraseWhiteList,
      });
    }

    const data: AntispamFilterModel = {
      ...antispamFilter,
      rules,
      prohibitedTopics,
      violationNotificationSettings: {
        tgUsernames: currentTGUsernamesForViolationNotification,
        isEnabled: isEnabledViolationNotification,
      },
      tgUsersWhiteListSettings: {
        tgUsernames: currentTGUsernamesForWhiteList,
        isEnabled: isEnabledUsersWhiteList,
      },
      urlWhiteListSettings: {
        urls: currentUrlsForWhiteList,
        isEnabled: isEnabledUrlWhiteList,
      },
      phraseWhiteListSettings: {
        phrases: currentPhrasesForWhiteList,
        isEnabled: isEnabledPhraseWhiteList,
      },
    };
    dispatch(saveAntispamChanges(data));
    setWasReset(false);
  },
});
