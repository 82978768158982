import {
  selectIsCustomProfanitiesValid,
  selectProfanityFilter,
  selectProfanityFilterisLoadedData,
} from "../../ProfanityFilterSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../../../../common/state/store";
import { useEffect, useState } from "react";
import { ProfanityFilterRule } from "../../ProfanityFilterModel";
import { useGAWithCustomParameters } from "../../../../../../../../../common/ga/GAEventTracker";
import { EventCategories } from "../../../../../../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import { FilterSettings } from "../../../_components/FilterSettings/FilterSettings";
import { FilterButtons } from "../../../_components/FilterSettings/components/FilterButtons/FilterButtons";
import { FilterInfo } from "../../../_components/FilterSettings/components/FilterInfo/FilterInfo";
import { useTranslation } from "react-i18next";
import { FilterRules } from "../../../_components/FilterSettings/components/FilterRules/FilterRules";
import { FilterCustomProfanities } from "../../../_components/FilterSettings/components/FilterCustomProfanities/FilterCustomProfanities";
import { CUSTOM_SPLITTER, MAXIMAL_RESTRICTION_COUNT, PROFANITY_FILTER_DEFAULT } from "../../../_components/FilterSettings/consts";
import { isFilterEqual } from "../../../_components/FilterSettings/utils";
import { profanityFilterButtonsHandlers } from "../../../_components/FilterSettings/handlers";
import { useGetLoadingState } from "../../../../../../../../../common/loading/hooks/useGetLoadingState";

export const ProfanityFilterSettings = () => {
  const dispatch = useAppDispatch();
  const profanityFilter = useAppSelector(selectProfanityFilter);
  const isLoadData = useAppSelector(selectProfanityFilterisLoadedData);
  const trackEvent = useGAWithCustomParameters(EventCategories.Group);
  const isSaveChangesLoading = useGetLoadingState("profanityFilter/saveChanges");
  const isCustomProfanitiesValid = useAppSelector(selectIsCustomProfanitiesValid);

  const { t } = useTranslation("translation", {
    keyPrefix: "groupChat.groupChatInfo.ProfanityAndAntispamFilterPage",
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [rulesCopy, setRulesCopy] = useState<ProfanityFilterRule[]>(structuredClone(profanityFilter.rules));
  const [isRulesWasChanged, setIsRulesWasChanged] = useState<boolean>(false);

  useEffect(() => {
    setIsRulesWasChanged(!isFilterEqual.rules(profanityFilter.rules, rulesCopy));
  }, [profanityFilter.rules, rulesCopy]);

  const [customProfanities, setCustomProfanities] = useState<string>(profanityFilter.customProfanities.join(CUSTOM_SPLITTER));
  const [isCustomProfanitiesWasChanged, setIsCustomProfanitiesWasChanges] = useState<boolean>(false);
  useEffect(() => {
    setIsCustomProfanitiesWasChanges(!isFilterEqual.customProfanities(profanityFilter.customProfanities, customProfanities));
  }, [profanityFilter.customProfanities, customProfanities]);

  const [wasReset, setWasReset] = useState<boolean>(false);

  const wasChanges = isRulesWasChanged || isCustomProfanitiesWasChanged || wasReset;

  useEffect(() => {
    if (!isLoadData) {
      setIsRulesWasChanged(false);
      setIsCustomProfanitiesWasChanges(false);
    }
  }, [isLoadData]);
  const buttonsHandlers = profanityFilterButtonsHandlers({
    rulesCopy,
    profanityFilter,
    customProfanities,
    dispatch,
    setCustomProfanities,
    setRulesCopy,
    setWasReset,
    trackEvent,
  });

  const isDisabledSaveButton = !wasChanges || isSaveChangesLoading || !isCustomProfanitiesValid;

  const isDisabledResetButton =
    wasChanges ||
    (isFilterEqual.rules(profanityFilter.rules, PROFANITY_FILTER_DEFAULT.rules) &&
      isFilterEqual.customProfanities(
        profanityFilter.customProfanities,
        PROFANITY_FILTER_DEFAULT.customProfanities.join(CUSTOM_SPLITTER),
      ));

  const blocks = [
    <FilterInfo
      key="ProfanityFilterInfo"
      title={t("To tackle the use of obscene language in group text messages")}
      description={t("The bot automatically deletes any inappropriate messages", { count: MAXIMAL_RESTRICTION_COUNT })}
    />,
    <FilterRules key="ProfanityFilterRules" rules={rulesCopy} setRules={setRulesCopy} />,
    <FilterCustomProfanities
      key="FilterCustomProfanities"
      customProfanities={customProfanities}
      setCustomProfanities={setCustomProfanities}
    />,
    <FilterButtons
      key="ProfanityFilterButtons"
      wasChanges={wasChanges}
      buttonsHandlers={buttonsHandlers}
      isDisabledResetButton={isDisabledResetButton}
      isDisabledSaveButton={isDisabledSaveButton}
    />,
  ];

  return <FilterSettings blocks={blocks} />;
};
