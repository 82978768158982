import { Box, Flex } from "@chakra-ui/react";
import { ProtectedHeaderContainer } from "../../../../headers/protectedHeaderContainer/ProtectedHeaderContainer";
import { BroadcastListHeader } from "../../../../broadcastList/components/BroadcastListHeader/BroadcastListHeader";
import { BroadcastListContainer } from "../../../../broadcastList/BroadcastListContainer";
import s from "./BroadcastListPage.module.scss";
import { MOBILE_VIEW, TABLET_VIEW } from "../../../LayoutHelper/ResolutionConst";
import { useAppSelector } from "../../../../../common/state/store";
import { selectIsOrganizationTrial } from "../../../../organisation/OrganisationSlice";
import { useNowWidthView } from "../../../LayoutHelper/ResolutionHooks";

export const BroadcastListPage = () => {
  const isTrial = useAppSelector(selectIsOrganizationTrial);
  const nowWidth = useNowWidthView();

  return (
    <Flex className={s.pageContainer}>
      <ProtectedHeaderContainer>
        <BroadcastListHeader />
      </ProtectedHeaderContainer>
      <Box
        bg="bgLight"
        height={
          nowWidth <= TABLET_VIEW
            ? `calc(100dvh - ${isTrial && nowWidth <= MOBILE_VIEW ? "249px" : "189px"})`
            : "calc(100dvh - 60px)"
        }
        className={s.pageContent}
      >
        <BroadcastListContainer />
      </Box>
    </Flex>
  );
};
