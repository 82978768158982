import { ProfanityFilterRule } from "../ProfanityFilter/ProfanityFilterModel";
import { AntispamFilterRule } from "../AntispamFilter/AntispamFilterModel";

export enum FilterTypeEnum {
  Warning = "Warning",
  Mute = "Mute",
  Block = "Block",
  Exclude = "Exclude",
}

export type FilterRule = ProfanityFilterRule | AntispamFilterRule;
