import { Flex } from "@chakra-ui/react";
import { Rule } from "./components/Rule/Rule";
import { profanityFilterRulesButtons } from "./handlers";
import { Dispatch, SetStateAction } from "react";
import { FilterRule } from "../../../../_types/FilterRule";
import { useNowWidthView } from "../../../../../../../../../layout/LayoutHelper/ResolutionHooks";

interface Props {
  rules: FilterRule[];
  setRules: Dispatch<SetStateAction<FilterRule[]>>;
}

export const FilterRules = ({ rules, setRules }: Props) => {
  const handlers = profanityFilterRulesButtons({ setRules });
  const nowWidth = useNowWidthView();

  return (
    <Flex direction="column" rowGap="16px">
      {rules.map((rule, index) => (
        <Rule
          rule={rule}
          position={index + 1}
          key={rule.type + index}
          handlers={handlers}
          setRules={setRules}
          rules={rules}
          nowWidth={nowWidth}
        />
      ))}
    </Flex>
  );
};
