import { forwardRef, useEffect, useState } from "react";
import { TextFormatter, TextFormatterProps } from "../TextFormatter";
import { Box } from "@chakra-ui/react";
import { Selection } from "../types";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import { getTaggedOperators, selectTaggedOperators } from "../../../../components/conversation/ConversationSlice";
import { ReplyMode } from "./ReplyMode";
import { TagOperatorPopover } from "./TagOperatorPopover";
import { checkTagSelected } from "./utils";
import { DIVIDER_BETWEEN_WORDS } from "../consts";
import { Editor } from "slate";
import { ReactEditor } from "slate-react";
import s from "./TextFormatterWithReply.module.scss";

export interface TextFormatterWithReplyProps extends TextFormatterProps {
  replyMode: {
    enabled: boolean;
    message?: {
      id: string;
      author: string;
      text?: string;
      photoFileId?: string;
      documentName?: string;
      contact?: string;
    };
  };
  botName?: string;
  onCloseReply: () => void;
  isNoteActive?: boolean;
}

export const TextFormatterWithReply = forwardRef<HTMLDivElement, TextFormatterWithReplyProps>(function TextFormatterWithReply(
  props,
  ref,
) {
  const { replyMode, botName, onCloseReply, wrapperClassname, isNoteActive, ...rest } = props;
  const dispatch = useAppDispatch();
  const usernames = useAppSelector(selectTaggedOperators);

  const [selection, setSelection] = useState<Selection | null>(null);
  const handleSelection = (selection: Selection) => setSelection(selection);

  const [isShownTagPopover, setIsShownTagPopover] = useState<boolean>(false);
  const [tagFill, setTagFill] = useState<string>("");
  useEffect(() => {
    if (!isNoteActive) return;
    if (!selection || !selection.selection?.anchor.path) return;

    const tagSelected = checkTagSelected(selection);
    if (!tagSelected) return;
    const { tagFill, isTagSelected } = tagSelected;

    setTagFill(tagFill.length ? tagFill.slice(1) : "");
    setIsShownTagPopover(isTagSelected);
  }, [selection, isNoteActive]);

  useEffect(() => {
    if (isShownTagPopover) {
      dispatch(getTaggedOperators({ name: tagFill }));
    }
  }, [dispatch, isShownTagPopover, tagFill]);

  const handleClickOperator = (username: string) => {
    selection?.insertText(username.slice(tagFill.length) + DIVIDER_BETWEEN_WORDS);
    setIsShownTagPopover(false);
    if (selection) {
      ReactEditor.focus(selection.editor);
      Editor.end(selection.editor, []);
    }
  };
  const expandWrapperClassname = `${wrapperClassname ? [wrapperClassname] : undefined}
  ${replyMode.enabled ? s.withReply : s.replyOff}`;

  return (
    <>
      <TagOperatorPopover
        usernames={usernames}
        isShownTagPopover={isShownTagPopover}
        isNoteActive={isNoteActive}
        handleClick={handleClickOperator}
      >
        <Box className={s.hoverWrapper}>
          {replyMode.enabled && replyMode.message && (
            <ReplyMode replyMode={replyMode} onCloseReply={onCloseReply} botName={botName} />
          )}
          <TextFormatter wrapperClassname={expandWrapperClassname} handleSelection={handleSelection} ref={ref} {...rest} />
        </Box>
      </TagOperatorPopover>
    </>
  );
});
