import React, { useState } from "react";
import Viewer from "react-viewer";
import { Image, ImageProps } from "@chakra-ui/react";
import ViewerProps from "react-viewer/lib/ViewerProps";
import s from "./ImageViewer.module.scss";

interface Props extends ImageProps {
  ViewerProps?: ViewerProps;
}

export default function ImageViewer({ ViewerProps, ...props }: Props) {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Viewer
        {...ViewerProps}
        visible={visible}
        className={s.viewerClass}
        onClose={() => setVisible(false)}
        images={ViewerProps?.images || [{ src: props.src || "", alt: props.alt }]}
        drag={ViewerProps?.drag || false}
        attribute={ViewerProps?.attribute || false}
        scalable={ViewerProps?.scalable || false}
        loop={ViewerProps?.loop || false}
        noNavbar={ViewerProps?.noNavbar || true}
        changeable={ViewerProps?.changeable || false}
      />
      <Image onClick={() => setVisible(true)} cursor={props.cursor || "pointer"} {...props} />
    </>
  );
}
