import { Box, Flex, Text, Button, Menu, MenuButton, Icon, MenuList, Tooltip } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import DownArrowMenu from "../../assets/images/downArrow.svg?react";
import DateRange from "../../UI/molecules/dateRange/DateRange";
import { useEffect, useState } from "react";
import {
  getBots,
  getSentMessages,
  getSentReceivedMessages,
  getUTMStatistics,
  selectBots,
  selectSentMessages,
  selectSentReceivedMessages,
  selectUTMStatistics,
} from "./DashboardSlice";
import { useAppDispatch, useAppSelector } from "../../common/state/store";
import DashboardItem from "./components/DashboardItem/DashboardItem";
import { EnumTypeDateRange, DashboardDateModel, DashboardLocalStorageModel, SelectedBotModel } from "./DashboardModel";
import dayjs from "dayjs";
import { selectLanguage } from "../../common/user/UserSlice";
import s from "./DashboardContainer.module.scss";
import { getDashboardFromStorage } from "./DashboardSaga";
import { useLocation } from "react-router-dom";
import { CustomMenuItem } from "../../UI/molecules/customMenuItem/CustomMenuItem";

export default function DashboardContainer() {
  const [initialState] = useState<DashboardLocalStorageModel>(
    getDashboardFromStorage() ?? {
      date: {
        from: getStartDateDay(7).toDateString(),
        to: new Date().toDateString(),
        type: EnumTypeDateRange.Week,
      },
      bot: {
        id: "",
        name: "",
      },
    },
  );
  const location = useLocation();

  const dispatch = useAppDispatch();
  const language = useAppSelector(selectLanguage);
  const { t } = useTranslation("translation", { keyPrefix: "dashboard" });
  const botList = useAppSelector(selectBots);
  const [selectedBot, setSelectedBot] = useState<SelectedBotModel>({
    id: location.state?.botId || initialState.bot.id,
    name: location.state?.botName || initialState.bot.name,
  });
  const [UTMTab, setUTMTab] = useState("Total");

  const [dataDate, setDataDate] = useState<DashboardDateModel>({
    from: new Date(initialState.date.from),
    to: new Date(initialState.date.to),
    typeDate: initialState.date.type,
  });

  const sentMessagesSelector = useAppSelector(selectSentMessages);
  const sentReceivedMessagesSelector = useAppSelector(selectSentReceivedMessages);
  const sentUTMStatistics = useAppSelector(selectUTMStatistics);
  useEffect(() => {
    dispatch(getBots(1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dayjs.locale(language);
    dispatch(
      getSentMessages({
        date: {
          from: dayjs(dataDate.from.toString()).format("YYYY-MM-DD"),
          to: dayjs(dataDate.to.toString()).format("YYYY-MM-DD"),
          type: dataDate.typeDate,
        },
        bot: {
          id: selectedBot.id,
          name: selectedBot.name,
        },
      }),
    );
    dispatch(
      getSentReceivedMessages({
        date: {
          from: dayjs(dataDate.from.toString()).format("YYYY-MM-DD"),
          to: dayjs(dataDate.to.toString()).format("YYYY-MM-DD"),
          type: dataDate.typeDate,
        },
        bot: {
          id: selectedBot.id,
          name: selectedBot.name,
        },
      }),
    );
    dispatch(
      getUTMStatistics({
        date: {
          from: dayjs(dataDate.from.toString()).format("YYYY-MM-DD"),
          to: dayjs(dataDate.to.toString()).format("YYYY-MM-DD"),
          type: dataDate.typeDate,
        },
        bot: {
          id: selectedBot.id,
          name: selectedBot.name,
        },
        isUnique: UTMTab === "Unique",
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDate.to, selectedBot]);

  useEffect(() => {
    dispatch(
      getUTMStatistics({
        date: {
          from: dayjs(dataDate.from.toString()).format("YYYY-MM-DD"),
          to: dayjs(dataDate.to.toString()).format("YYYY-MM-DD"),
          type: dataDate.typeDate,
        },
        bot: {
          id: selectedBot.id,
          name: selectedBot.name,
        },
        isUnique: UTMTab === "Unique",
      }),
    );
    // eslint-disable-next-line
  }, [UTMTab]);

  const getAdditionalBots = () => {
    if (botList) {
      dispatch(getBots(botList?.currentPage + 1));
    }
  };

  const onDateRangeChange = (startDate: Date | null, endDate: Date | null) => {
    if (startDate) {
      setDataDate({
        from: startDate,
        to: dataDate.to,
        typeDate: EnumTypeDateRange.CustomRange,
      });
    }
    if (endDate) {
      setDataDate({
        from: dataDate.from,
        to: endDate,
        typeDate: EnumTypeDateRange.CustomRange,
      });
    }
  };

  function getStartDateDay(n: number) {
    const nowDate = new Date();
    const date = nowDate;
    date.setDate(nowDate.getDate() - n);
    return date;
  }

  function getStartDateMonth(n: number) {
    const nowDate = new Date();
    const date = nowDate;
    date.setMonth(nowDate.getMonth() - n);
    return date;
  }

  return (
    <>
      <Box className={s.dashboardContainer} justifyContent={{ base: "flex-start", lg: "space-between" }}>
        <Flex className={s.dateContainer} px={{ base: "16px", lg: "32px" }}>
          <Box zIndex={4}>
            <Menu variant={{ base: "dominoDotsAdaptiveMenu", md: "dominoDotsMenu" }} gutter={0} offset={[-30, 0]}>
              {({ isOpen, onClose }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={Button}
                    variant="dashboardBar"
                    _hover={{
                      ".icon": {
                        backgroundColor: `${isOpen ? "#6D5BF7" : "#DCE7FB"}`,
                      },
                    }}
                    onClick={e => e.stopPropagation()}
                    leftIcon={
                      <Icon
                        className="icon"
                        as={DownArrowMenu}
                        boxSize="24px"
                        color={isOpen ? "white" : "black"}
                        borderRadius={"4px"}
                        _hover={{
                          backgroundColor: `${isOpen ? "#6D5BF7" : "#DCE7FB"}`,
                        }}
                        transition="ease-out .2s"
                        bg={isOpen ? "#6D5BF7" : ""}
                      />
                    }
                  >
                    {/* <Flex maxW={"500px"}>
                      <Box w="100%" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                        {!selectedBot.name ? t("All Bots") : selectedBot.name}
                      </Box>
                    </Flex> */}
                    <Text wordBreak="break-all" className={s.textBotMenuButton} noOfLines={1}>
                      {!selectedBot.name ? t("All Bots") : selectedBot.name}
                    </Text>
                  </MenuButton>
                  <MenuList overflow="auto !important" w="190px" maxH="250px" ml={"25px"}>
                    <CustomMenuItem
                      color={"black"}
                      onClick={() => {
                        setSelectedBot({
                          id: "",
                          name: "",
                        });
                        onClose();
                      }}
                    >
                      {t("All Bots")}
                    </CustomMenuItem>
                    {!!botList?.items.length &&
                      botList.items.map(item => (
                        <CustomMenuItem
                          color={"black"}
                          key={item.id}
                          onClick={() => {
                            setSelectedBot({
                              id: item.id,
                              name: item.name,
                            });
                            onClose();
                          }}
                        >
                          <Tooltip
                            label={item.name}
                            placement="top"
                            isDisabled={item.name.length <= 22}
                            variant={"dominoChat"}
                            bg={"#FEF6DC"}
                            hasArrow={true}
                            openDelay={100}
                          >
                            <Text className={s.checkboxText} wordBreak="break-all" noOfLines={1}>
                              {item.name}
                            </Text>
                          </Tooltip>
                        </CustomMenuItem>
                      ))}
                    {!!botList?.items.length && botList.currentPage < botList.totalPages && (
                      <CustomMenuItem closeOnSelect={false} onClick={getAdditionalBots} h="36px">
                        <Flex
                          alignItems={"center"}
                          justifyContent={"center"}
                          w={"100%"}
                          color={"black"}
                          cursor={"pointer"}
                          fontSize={{ base: "14px", lg: "15px" }}
                        >
                          <div>{t("show more")}</div>
                          <Icon as={DownArrowMenu} boxSize="26px" color="midDeepBlue" />
                        </Flex>
                      </CustomMenuItem>
                    )}
                  </MenuList>
                </>
              )}
            </Menu>
          </Box>
          <Flex className={s.dateControl} gap={"16px"} alignItems="center">
            <Box className={s.mainDate}>
              <Flex>
                <Button
                  variant={dataDate.typeDate === EnumTypeDateRange.Week ? "dominoViolet" : "dominoOutlineGray"}
                  borderTopRightRadius={0}
                  borderBottomRightRadius={0}
                  w="100%"
                  onClick={() =>
                    setDataDate({
                      from: getStartDateDay(7),
                      to: new Date(),
                      typeDate: EnumTypeDateRange.Week,
                    })
                  }
                >
                  {t("dashboardDateRange.Week")}
                </Button>
                <Button
                  variant={dataDate.typeDate === EnumTypeDateRange.Month ? "dominoViolet" : "dominoOutlineGray"}
                  borderRadius={0}
                  w="100%"
                  onClick={() =>
                    setDataDate({
                      from: getStartDateMonth(1),
                      to: new Date(),
                      typeDate: EnumTypeDateRange.Month,
                    })
                  }
                >
                  {t("dashboardDateRange.Month")}
                </Button>
                <Button
                  variant={dataDate.typeDate === EnumTypeDateRange.ThreeMonth ? "dominoViolet" : "dominoOutlineGray"}
                  borderRadius={0}
                  w="100%"
                  onClick={() =>
                    setDataDate({
                      from: getStartDateMonth(3),
                      to: new Date(),
                      typeDate: EnumTypeDateRange.ThreeMonth,
                    })
                  }
                >
                  {t("dashboardDateRange.ThreeMonth")}
                </Button>
                <Button
                  variant={dataDate.typeDate === EnumTypeDateRange.SixMonth ? "dominoViolet" : "dominoOutlineGray"}
                  borderTopLeftRadius={0}
                  borderBottomLeftRadius={0}
                  w="100%"
                  onClick={() =>
                    setDataDate({
                      from: getStartDateMonth(6),
                      to: new Date(),
                      typeDate: EnumTypeDateRange.SixMonth,
                    })
                  }
                >
                  {t("dashboardDateRange.SixMonth")}
                </Button>
              </Flex>
            </Box>
            <Box className={s.mobileDate}>
              <Menu variant="dominoDotsMenu" gutter={0} offset={[-30, 0]}>
                {({ isOpen, onClose }) => (
                  <>
                    <Menu variant="dominoDotsMenu" gutter={0} offset={[-26, -2]}>
                      {({ isOpen, onClose }) => (
                        <>
                          <MenuButton as={Text} className={s.retryText} color={isOpen ? "blueLink" : ""}>
                            {t(`dashboardDateRange.${dataDate.typeDate}`)}
                          </MenuButton>

                          <MenuList ml={"25px"}>
                            <CustomMenuItem
                              color={"black"}
                              onClick={() => {
                                setDataDate({
                                  from: getStartDateDay(7),
                                  to: new Date(),
                                  typeDate: EnumTypeDateRange.Week,
                                });
                                onClose();
                              }}
                            >
                              {t("dashboardDateRange.Week")}
                            </CustomMenuItem>
                            <CustomMenuItem
                              color={"black"}
                              onClick={() => {
                                setDataDate({
                                  from: getStartDateMonth(1),
                                  to: new Date(),
                                  typeDate: EnumTypeDateRange.Month,
                                });
                                onClose();
                              }}
                            >
                              {t("dashboardDateRange.Month")}
                            </CustomMenuItem>{" "}
                            <CustomMenuItem
                              color={"black"}
                              onClick={() => {
                                setDataDate({
                                  from: getStartDateMonth(3),
                                  to: new Date(),
                                  typeDate: EnumTypeDateRange.ThreeMonth,
                                });
                                onClose();
                              }}
                            >
                              {t("dashboardDateRange.ThreeMonth")}
                            </CustomMenuItem>{" "}
                            <CustomMenuItem
                              color={"black"}
                              onClick={() => {
                                setDataDate({
                                  from: getStartDateMonth(6),
                                  to: new Date(),
                                  typeDate: EnumTypeDateRange.SixMonth,
                                });
                                onClose();
                              }}
                            >
                              {t("dashboardDateRange.SixMonth")}
                            </CustomMenuItem>
                          </MenuList>
                        </>
                      )}
                    </Menu>
                  </>
                )}
              </Menu>
            </Box>
            <DateRange
              onDateRangeChange={onDateRangeChange}
              startDate={dataDate.from}
              endDate={dataDate.to}
              setTypeDate={setDataDate}
            />
          </Flex>
        </Flex>
        <Box overflowY="auto" pt={"24px"} px={{ base: "16px", lg: "32px" }} flex={1}>
          <DashboardItem
            key={"UTM Tag Clicks"}
            isTranslate={false}
            label={"UTM Tag Clicks"}
            totalElement={
              <Flex minW="182px" gap="16px">
                <Text
                  onClick={() => setUTMTab("Total")}
                  className={`${s.tab} ${UTMTab === "Total" && s.active}`}
                  textAlign={{ base: "right", md: "left" }}
                  variant={"largeBold"}
                >
                  {t("Total")} {sentUTMStatistics?.totalCount}
                </Text>
                <Text
                  onClick={() => setUTMTab("Unique")}
                  className={`${s.tab} ${UTMTab === "Unique" && s.active}`}
                  textAlign={{ base: "right", md: "left" }}
                  variant={"largeBold"}
                >
                  {t("Unique")} {sentUTMStatistics?.totalUniqueCount}
                </Text>
              </Flex>
            }
            total={sentUTMStatistics?.totalCount}
            labelCheckbox={"Clicks"}
            origins={
              (location.state?.utmTag
                ? sentUTMStatistics?.totals.map(el => {
                    return { ...el, checked: el.label === location.state.utmTag };
                  })
                : sentUTMStatistics?.totals) || []
            }
            dataChart={sentUTMStatistics?.data}
            isLoading={!sentUTMStatistics?.data}
          />
          <DashboardItem
            key={"Sent messages"}
            label={"Sent messages"}
            totalElement={
              <Text textAlign={{ base: "right", md: "left" }} width={"182px"} variant={"largeBold"}>
                {t("Total")} {sentMessagesSelector?.total}
              </Text>
            }
            total={sentMessagesSelector?.total}
            labelCheckbox={"Messages"}
            origins={sentMessagesSelector?.totals || []}
            dataChart={sentMessagesSelector?.data}
            isLoading={!sentMessagesSelector?.data}
          />
          <DashboardItem
            key={"Messages statistic"}
            label={"Messages statistic"}
            totalElement={
              <Text textAlign={{ base: "right", md: "left" }} width={"182px"} variant={"largeBold"}>
                {t("Total")} {sentReceivedMessagesSelector?.total}
              </Text>
            }
            total={sentReceivedMessagesSelector?.total}
            labelCheckbox={"Messages"}
            origins={sentReceivedMessagesSelector?.totals || []}
            dataChart={sentReceivedMessagesSelector?.data}
            isLoading={!sentReceivedMessagesSelector?.data}
          />
        </Box>
      </Box>
    </>
  );
}
