import { Popover, PopoverContent, PopoverTrigger } from "@chakra-ui/react";
import { TextFormatterWithReplyProps } from "./TextFormatterWithReply";
import { TaggedOperatorModel } from "../../../../components/conversation/ConversationModel";
import s from "./TagOperatorPopover.module.scss";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface Props extends Pick<TextFormatterWithReplyProps, "isNoteActive"> {
  isShownTagPopover: boolean;
  usernames: TaggedOperatorModel[] | undefined;
  children: ReactElement;
  handleClick: (username: string) => void;
}

export const TagOperatorPopover = ({ isShownTagPopover, usernames, isNoteActive, children, handleClick }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "conversation" });

  return (
    <Popover isOpen={!!(isNoteActive && isShownTagPopover)} autoFocus={false} matchWidth placement="auto">
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent className={s.assignedUsersPopover}>
        {usernames && usernames.length ? (
          usernames?.map(item => (
            <div key={item.id} className={s.popoverItem} onClick={() => handleClick(item.username)}>
              {item.username}
            </div>
          ))
        ) : (
          <div className={s.popoverItem}>{t("No operators")}</div>
        )}
      </PopoverContent>
    </Popover>
  );
};
