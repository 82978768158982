import { Text, Button } from "@chakra-ui/react";
import s from "./AddNewItemButton.module.scss";

interface Props {
  addNewItem: () => void;
  addNewItemButtonText: string;
}

export const AddNewItemButton = ({ addNewItem, addNewItemButtonText }: Props) => (
  <Button className={s.addItemButtonWrp} onClick={() => addNewItem()} data-pw="add-item-button">
    <Text variant="medium" className={s.addItemButton}>
      {addNewItemButtonText}
    </Text>
  </Button>
);
