import s from "./VideoNodePopup.module.scss";
import sm from "../NodeEditPopup.module.scss";
import {
  CustomVariablesModel,
  NodeModel,
  FlowModel,
  SendVideoFlowActionModel,
  NodeValidationProp,
  SendPhotoFlowActionModel,
  FlowActionModel,
} from "../../../FlowBuilderModel";
import { useAppDispatch, useAppSelector } from "../../../../../common/state/store";
import { editNode, saveNode, selectCustomVariables, selectNodeEditorState } from "../../../FlowBuilderSlice";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  FormControl,
  Icon,
  Flex,
  Text,
  Button,
  DrawerFooter,
} from "@chakra-ui/react";
import { ButtonsList } from "../ButtonsList";
import { Loading } from "../../../../../common/loading/LoadingStateContainer";
import { useTranslation } from "react-i18next";
import VideoIcon from "../../../../../assets/icons/videoIcon.svg?react";
import { ButtonModel, ReplyKeyboardButtonModel } from "../../../../../common/AppButtonsModel";
import { Fallback } from "../Fallback";
import { getButtonsWithExactType } from "../../../utils";
import { NodeEditorFooter } from "../NodeEditorFooter/NodeEditorFooter";
import { DragAndDropContainer, handleDataChange, handleDrag } from "../DragAndDropContainer";
import { useMemo, useState } from "react";
import { TextFormatterWithExpandable } from "../../../../../UI/molecules/TextFormatter/TextFormatterWithExpandable";
import { escapeMarkdown } from "../../../../../common/utils/escapeMarkdown";

interface Props {
  addButton: (button: ButtonModel | ReplyKeyboardButtonModel) => void;
  deleteButton: (buttonId: string) => void;
  setButtons: (buttons: ButtonModel[][] | ReplyKeyboardButtonModel[][]) => void;
  addCustomVariableText: (variable: CustomVariablesModel, variableMaxLength: number) => void;
  editButton: (button: ButtonModel | ReplyKeyboardButtonModel) => void;
  dataChange: (flowAction: FlowActionModel) => void;
  flow: FlowModel;
  node: NodeModel;
  validate: (flowAction: FlowActionModel) => void;
  validationInfo: NodeValidationProp;
}

export const VideoNodePopup = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const placeholder = useTranslation("translation", { keyPrefix: "flow.editNodePopup" }).t;
  const dispatch = useAppDispatch();
  const currentNode = useAppSelector(selectNodeEditorState);
  const variables = useAppSelector(selectCustomVariables);
  const [isDragging, setDragging] = useState(false);
  const flowAction = props.node.flowAction as SendVideoFlowActionModel;

  const initialValue = useMemo(() => {
    if (flowAction.entities && flowAction.entities.length > 0) {
      return { text: flowAction.text ?? "", entities: flowAction.entities };
    } else {
      const [text, entities] = escapeMarkdown(flowAction.text ?? "");
      return { text, entities };
    }
  }, [flowAction.entities, flowAction.text]);

  if (!currentNode) {
    return <></>;
  }

  const fieldTypeExt = ".mp4,.mkv,.avi";
  const fileSizeMB = 50;
  const fileSizeB = fileSizeMB * 1024 * 1024;

  const onDataChange = (text: string, file?: File) => {
    handleDataChange({ file, dispatch, currentNode, fieldTypeExt, fileSizeB });
    const newData: SendVideoFlowActionModel = {
      ...flowAction,
      text,
      entities: [],
    };
    props.dataChange(newData);
    dispatch(
      editNode({
        ...currentNode,
        flowAction: newData,
      }),
    );
  };

  const handleFallbackChange = (text: string) => {
    const newFlowAction = { ...flowAction, fallbackMessage: text };
    dispatch(
      editNode({
        ...currentNode,
        flowAction: newFlowAction,
      }),
    );
  };

  const flowActionDataChange = (flowAction: SendPhotoFlowActionModel) => {
    dispatch(
      editNode({
        ...currentNode,
        flowAction: flowAction,
      }),
    );
  };

  const onCloseValidate = () => {
    dispatch(
      saveNode({
        flow: props.flow,
        node: props.node,
      }),
    );
  };

  return (
    <Drawer isOpen={true} placement="right" onClose={onCloseValidate} variant="dominoDrawer">
      <DrawerContent>
        <Loading scope="nodeValidate" />
        <DrawerHeader>{t("Video Message")}</DrawerHeader>
        <DrawerBody paddingInline="7px">
          <div className={s.popupWrapper}>
            <div className={s.popup}>
              <div className={s.wrapper}>
                <div onDragEnter={e => handleDrag(e, setDragging)} className={s.popupItem}>
                  <FormControl className={sm.itemFileWrp}>
                    <div className={`${s.popupItem}`}>
                      <Loading scope={`postFileEditor${props.node.id}`} />
                      <label className={s.input}>
                        <Icon as={VideoIcon} boxSize="28px" mb="8px" />
                        {flowAction.fileInfo ? (
                          <div data-pw="videoInput" className={sm.fileName}>
                            {flowAction.fileInfo.name}
                          </div>
                        ) : flowAction.file ? (
                          <div data-pw="videoFile" className={sm.fileName}>
                            {flowAction.file.name}
                          </div>
                        ) : (
                          <Flex textAlign="center" direction="column" className={sm.fileName}>
                            <Text>{t("editNodePopup.Upload Video")}</Text>
                            <Text variant="small">{t("editNodePopup.mp4, mkv, avi up to 20 mb")}</Text>
                          </Flex>
                        )}
                        <input
                          onChange={e => {
                            if (e.target.files) {
                              onDataChange(flowAction.text ?? "", e.target.files[0]);
                            }
                          }}
                          type="file"
                          data-pw="uploadVideo"
                          accept={fieldTypeExt}
                        />
                      </label>
                      {isDragging && !flowAction.fileId && (
                        <DragAndDropContainer
                          setDragging={setDragging}
                          handleDataChange={{ dispatch, currentNode, fieldTypeExt, fileSizeB }}
                        />
                      )}
                    </div>
                  </FormControl>
                  <TextFormatterWithExpandable
                    data-pw="message-text-area"
                    variables={variables ?? []}
                    handleChange={t => onDataChange(t, flowAction.file)}
                    maxLength={500}
                    placeholder={`${placeholder("Enter text here")} ...`}
                    value={flowAction.text ?? ""}
                    initialValue={initialValue}
                    minHeight={112}
                    maxHeight={500}
                    wrapperClassname={s.textFormatterWrapper}
                    autoFocus
                  />
                  <ButtonsList
                    node={props.node}
                    buttons={
                      (getButtonsWithExactType(flowAction?.replyMarkup, flowAction) as
                        | ButtonModel[][]
                        | ReplyKeyboardButtonModel[][]) ?? []
                    }
                    flowAction={flowAction}
                    addButton={props.addButton}
                    setButtons={props.setButtons}
                    editButton={props.editButton}
                    deleteButton={props.deleteButton}
                  />
                  <Fallback
                    validate={props.validate}
                    flowAction={flowAction}
                    dataChange={flowActionDataChange}
                    maxLength={500}
                    text={""}
                    onChange={handleFallbackChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </DrawerBody>
        {props.validationInfo.isError && (
          <DrawerFooter margin={"8px"} padding={"0"}>
            {props.validationInfo.isError && <NodeEditorFooter validationMessage={props.validationInfo.errors[0].message} />}
          </DrawerFooter>
        )}
        <Button variant="dominoViolet" w={"auto"} margin={"16px"} onClick={() => onCloseValidate()} data-pw="apply-button">
          {t("Apply")}
        </Button>
      </DrawerContent>
    </Drawer>
  );
};
