import s from "./formattedText.module.scss";
import { Entity } from "./entity";
import { useAppDispatch } from "../state/store";
import { notify } from "../notifications/NotificationSlice";
import { useTranslation } from "react-i18next";

export interface Props {
  text: string;
  entities: Entity[];
  offset?: number;
}

export const FormattedText = (props: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("translation", { keyPrefix: "commonWords" });
  const text = props.text;
  let entities = props.entities;

  if (entities.length === 0) {
    return (
      <span data-pw="formattedText" className={s.wrapper}>
        {text}
      </span>
    );
  }

  if (props.offset) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    entities = entities.map((x): Entity => ({ ...x, offset: x.offset - props.offset! }));
  }

  const sortedEntities = [...entities].sort((a, b) => a.offset - b.offset);

  let lastIndex = 0;
  const parts = [];

  let toSkip: Entity[] = [];

  sortedEntities.forEach((entity, index) => {
    if (toSkip.findIndex(x => x === entity) !== -1) return;
    const start = text.slice(lastIndex, entity.offset);
    const middle = text.slice(entity.offset, entity.offset + entity.length);
    lastIndex = entity.offset + entity.length;

    parts.push(start);

    const inner = sortedEntities.filter(
      x => x.offset >= entity.offset && x.offset < entity.offset + entity.length && x !== entity,
    );

    toSkip = inner;

    switch (entity.type.toLowerCase()) {
      case "bold":
        parts.push(
          <span className={s.bold}>
            <FormattedText text={middle} entities={inner} offset={entity.offset} />
          </span>,
        );
        break;
      case "italic":
        parts.push(
          <em>
            <FormattedText text={middle} entities={inner} offset={entity.offset} />
          </em>,
        );
        break;
      case "underline":
        parts.push(
          <u>
            <FormattedText text={middle} entities={inner} offset={entity.offset} />
          </u>,
        );
        break;
      case "strikethrough":
        parts.push(
          <s>
            <FormattedText text={middle} entities={inner} offset={entity.offset} />
          </s>,
        );
        break;
      case "code":
        parts.push(
          <code
            className={s.code}
            onClick={e => {
              navigator.clipboard.writeText(middle);
              dispatch(
                notify({
                  message: t("Copied to clipboard"),
                  type: "success",
                }),
              );
            }}
          >
            <FormattedText text={middle} entities={inner} offset={entity.offset} />
          </code>,
        );
        break;
      case "text_link":
        parts.push(
          <a title="text_link" href={entity.url} className={s.link} target="_blank" rel="noopener noreferrer">
            <FormattedText text={middle} entities={inner} offset={entity.offset} />
          </a>,
        );
        break;
      case "url":
        parts.push(
          <a title="url" href={middle} className={s.link} target="_blank" rel="noopener noreferrer">
            <FormattedText text={middle} entities={inner} offset={entity.offset} />
          </a>,
        );
        break;
      case "spoiler":
        parts.push(
          <span className={s.spoiler} onClick={ev => ev.currentTarget.classList.add(s.showSpoiler)}>
            <FormattedText text={middle} entities={inner} offset={entity.offset} />
          </span>,
        );
        break;
      default:
        parts.push(
          <span>
            <FormattedText text={middle} entities={inner} offset={entity.offset} />
          </span>,
        );
        break;
    }
  });

  parts.push(text.slice(lastIndex));

  return (
    <span data-pw="formattedText" className={s.wrapper}>
      {parts}
    </span>
  );
};
