import { ProfanityFilterData, ProfanityFilterDataTypeEnum } from "./ProfanityFilterData";
import { ProfanityFilterModel } from "./ProfanityFilterModel";
import { FilterTypeEnum } from "../_types/FilterRule";

export const convertProfanityFilter = {
  fromDataToModel: (data: ProfanityFilterData, isPrivateGroup: boolean): ProfanityFilterModel => {
    return {
      isEnabled: data.isEnabled,
      customProfanities: data.customProfanities,
      rules: data.rules.map(rule => {
        const { type, forLong } = rule.ruleAction;

        const convertedType =
          type === ProfanityFilterDataTypeEnum.Block
            ? isPrivateGroup
              ? FilterTypeEnum.Exclude
              : FilterTypeEnum.Block
            : (type as unknown as FilterTypeEnum);
        return {
          type: convertedType,
          forLong,
        };
      }),
    };
  },
  fromModelToData: (model: ProfanityFilterModel): ProfanityFilterData => {
    return {
      isEnabled: model.isEnabled,
      customProfanities: model.customProfanities[0] === "" ? [] : model.customProfanities,
      rules: model.rules.map((rule, index) => {
        const { type, forLong } = rule;

        return {
          violation: index + 1,
          ruleAction: {
            type:
              type === FilterTypeEnum.Exclude
                ? ProfanityFilterDataTypeEnum.Block
                : (type as unknown as ProfanityFilterDataTypeEnum),
            forLong,
          },
        };
      }),
    };
  },
};
