import {
  Box,
  Button,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Tooltip,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import boldIcon from "../../../../../assets/icons/bold.svg?react";
import italicIcon from "../../../../../assets/icons/italic.svg?react";
import monospaceIcon from "../../../../../assets/icons/monospace.svg?react";
import urlIcon from "../../../../../assets/icons/addlinkIcon.svg?react";
import { EditorType, TextMarks } from "../../types";
import { MarkButton } from "./types";
import { isMarkActive } from "../../utils";
import { AddLinkModal } from "../../../../../components/inputWrapper/components/formattingMenu/components/AddLinkModal";
import { useState } from "react";

interface Props {
  isDisabled?: boolean;
  onMarkClick: (id: TextMarks) => void;
  addLink: (text: string, href: string) => void;
  editor: EditorType;
}

export const MarkButtons = ({ isDisabled, onMarkClick, editor, addLink }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "commonWords" });
  const [isLinkPopoverShown, setIsLinkPopoverShown] = useState<boolean>(false);
  const onOpen = () => setIsLinkPopoverShown(true);
  const onClose = () => setIsLinkPopoverShown(false);

  const markButtons: MarkButton[] = [
    {
      id: TextMarks.Bold,
      label: t("Bold"),
      "aria-label": "Bold",
      "data-pw": "bold-button",
      icon: boldIcon,
    },
    {
      id: TextMarks.Italic,
      label: t("Italics"),
      "aria-label": "Italics",
      "data-pw": "italics-button",
      icon: italicIcon,
    },
    {
      id: TextMarks.Monospace,
      label: t("Monospace"),
      "aria-label": "Monospace",
      "data-pw": "monospace-button",
      icon: monospaceIcon,
    },
  ];

  const handleSubmit = (text: string, href: string) => {
    setIsLinkPopoverShown(false);
    addLink(text, href);
  };

  return (
    <Box style={{ paddingBottom: "1px" }}>
      {markButtons.map(markButton => {
        return (
          <Tooltip key={markButton.id} variant="dominoLight" placement="top" label={markButton.label} isDisabled={isDisabled}>
            <Button
              isDisabled={isDisabled}
              aria-label={markButton["aria-label"]}
              onClick={() => onMarkClick(markButton.id)}
              variant="dominoTextareaIcon"
              boxSize={{ base: "32px", lg: "24px" }}
              style={{ font: "Arial" }}
              mr="3px"
              data-pw={markButton["data-pw"]}
              isActive={isMarkActive(editor, markButton.id)}
            >
              <Icon as={markButton.icon}></Icon>
            </Button>
          </Tooltip>
        );
      })}
      <Popover variant="dominoLinkPopover" isOpen={isLinkPopoverShown} onOpen={onOpen} onClose={onClose}>
        <Tooltip variant="dominoLight" placement="top" label={t("Add link")} isDisabled={isDisabled}>
          <Box display="inline-block">
            <PopoverTrigger>
              <Button
                isDisabled={isDisabled}
                aria-label={"Add link"}
                variant="dominoTextareaIcon"
                boxSize={{ base: "32px", lg: "24px" }}
                style={{ font: "Arial" }}
                mr="3px"
                data-pw="add-link-button"
              >
                <Icon as={urlIcon}></Icon>
              </Button>
            </PopoverTrigger>
          </Box>
        </Tooltip>
        <Portal>
          <PopoverContent data-pw="add-link-modal">
            <PopoverArrow />
            <PopoverHeader>{t("Add link")}</PopoverHeader>
            <PopoverCloseButton data-pw="add-link-close-button" />
            <PopoverBody>
              <AddLinkModal onSubmit={handleSubmit} />
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </Box>
  );
};
