import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, select, take, takeEvery, takeLatest } from "redux-saga/effects";
import {
  ContactFilesData,
  ContactInfoData,
  ContactInfoFilesData,
  ContactVariablesData,
  UpdateContactInfoSignalData,
} from "./ContactInfoData";
import {
  contactInfoSlice,
  selectContactInfo,
  selectContactVariables,
  selectFiles,
  selectOperators,
  selectTeams,
} from "./ContactInfoSlice";
import * as contactInfoApi from "./ContactInfoApi";
import { handleException } from "../../common/SagaHelper";
import { ContactInfoModel, ContactVariableModel, ContactVariablesModel } from "./ContactInfoModel";
import { notificationSlice } from "../../common/notifications/NotificationSlice";
import { ValidationError } from "../../common/ErrorModel";
import { beginScope, completeScope } from "../../common/loading/LoadingStateActions";
import { signalRConnection } from "../../middlewares/signalRMiddleware";
import { store } from "../..";
import { selectCurrentBotId } from "../sidebar/SidebarSlice";
import { conversationSlice, selectConversationInfo } from "../conversation/ConversationSlice";
import { ConversationInfoModel } from "../conversation/ConversationModel";
import { formatVariableToData, formatDateTimeVariableToView } from "../../common/utils/variablesMapper";
import { OrganisationTeamsModel, OrganisationUsersModel } from "../organisation/OrganisationModel";
import { CustomVariableType } from "../modals/addVariable/AddVariableModel";
import { mapFilesListDataToModel } from "./ContactInfoMapper";

export function* contactInfoSaga() {
  yield takeLatest(contactInfoSlice.actions.getContactInfo, getContactInfo);
  yield takeLatest(contactInfoSlice.actions.getContactInfoFiles, getContactInfoFiles);
  yield takeLatest(contactInfoSlice.actions.getFiles, getFiles);
  yield takeLatest(contactInfoSlice.actions.getContactVariables, getContactVariables);
  yield takeLatest(contactInfoSlice.actions.changeContactVariable, changeContactVariable);
  yield takeLatest(contactInfoSlice.actions.stopFlow, stopFlow);
  yield takeLatest(contactInfoSlice.actions.getOperators, getOperators);
  yield takeLatest(contactInfoSlice.actions.getTeams, getTeams);

  yield takeEvery(contactInfoSlice.actions.updateContactInfoSignal, updateContactVariablesBySignal);

  signalRConnection.addHandler("receiveContactUpdate", (data: UpdateContactInfoSignalData) => {
    store.dispatch(contactInfoSlice.actions.updateContactInfoSignal(data));
  });
}

export function* getContactInfo(action: PayloadAction<{ contactId: string; botId: string; conversationId?: string }>) {
  try {
    if (action.payload.conversationId) {
      yield put(conversationSlice.actions.getConversationInfo({ conversationId: action.payload.conversationId }));

      const { contactInfoData, callback } = yield all({
        contactInfoData: call(contactInfoApi.getContactInfo, action.payload),
        callback: take(conversationSlice.actions.getConversationInfoSucceed),
      });

      const contactInfoModel: ContactInfoModel = { ...contactInfoData };
      const conversation: ConversationInfoModel = callback.payload;

      if (conversation && conversation.activeFlow) {
        contactInfoModel.activeFlow = {
          id: conversation.activeFlow.id,
          sourceFlowId: conversation.activeFlow.sourceFlowId,
          botId: conversation.botId,
          title: conversation.activeFlow.title,
        };
      }

      yield put(contactInfoSlice.actions.getContactInfoCompleted(contactInfoModel));
    } else {
      const contactInfoData: ContactInfoData = yield call(contactInfoApi.getContactInfo, action.payload);
      const contactInfoModel: ContactInfoModel = { ...contactInfoData };
      yield put(contactInfoSlice.actions.getContactInfoCompleted(contactInfoModel));
    }
  } catch (e: unknown) {
    yield handleException(e);
  }
}

function* getContactInfoFiles(action: PayloadAction<{ conversationId: string }>) {
  try {
    yield put(beginScope("getContactInfoFiles"));
    const data: ContactInfoFilesData = yield call(contactInfoApi.getContactInfoFiles, action.payload.conversationId);

    yield put(contactInfoSlice.actions.getContactInfoFilesCompleted(data));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("getContactInfoFiles"));
  }
}

function* getFiles(action: PayloadAction<{ conversationId: string; attachments: string; page?: number; size?: number }>) {
  try {
    yield put(beginScope("getFiles"));
    const currentFiles: ReturnType<typeof selectFiles> = yield select(selectFiles);
    const data: ContactFilesData = yield call(
      contactInfoApi.getFiles,
      action.payload.conversationId,
      action.payload.attachments,
      action.payload.page,
      action.payload.size,
    );
    const updatedData = mapFilesListDataToModel(data, currentFiles);
    yield put(contactInfoSlice.actions.getFilesCompleted(updatedData));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("getFiles"));
  }
}

export function* getContactVariables(action: PayloadAction<{ contactId: string; size: string; page: string }>) {
  try {
    const cuttentVariables: ReturnType<typeof selectContactVariables> = yield select(selectContactVariables);
    yield put(beginScope("getContactVariables"));
    const contactVariablesPaginationData: ContactVariablesData = yield call(
      contactInfoApi.getContactVariables,
      action.payload.contactId,
      action.payload.size,
      action.payload.page,
    );
    const contactVariablesData: ContactVariablesData = contactVariablesPaginationData;
    const itemsModel = contactVariablesData.items.map(el => formatDateTimeVariableToView(el));

    const contactVariablesModel: ContactVariablesModel = {
      ...contactVariablesData,
      items: cuttentVariables?.items ? [...cuttentVariables.items, ...itemsModel] : itemsModel,
    };
    yield put(contactInfoSlice.actions.getContactVariablesCompleted(contactVariablesModel));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("getContactVariables"));
  }
}

export function* changeContactVariable(
  action: PayloadAction<{
    contactId: string;
    contactVariable: ContactVariableModel;
  }>,
) {
  try {
    validateVariable(action.payload.contactVariable);
    yield call(
      contactInfoApi.changeContactVariable,
      action.payload.contactId,
      formatVariableToData(action.payload.contactVariable),
    );
    yield put(
      notificationSlice.actions.notify({
        message: "Value saved successfully!",
        type: "success",
      }),
    );
  } catch (e: unknown) {
    if (e instanceof ValidationError) {
      yield put(
        notificationSlice.actions.notify({
          message: e.validationData.errors[0].message,
          type: "error",
        }),
      );
      return;
    }
    yield handleException(e);
  }
}

export function* updateContactVariablesBySignal(action: PayloadAction<UpdateContactInfoSignalData>) {
  try {
    const currentContactInfo: ReturnType<typeof selectContactInfo> = yield select(selectContactInfo);
    const currentBotId: ReturnType<typeof selectCurrentBotId> = yield select(selectCurrentBotId);
    if (
      currentBotId &&
      currentContactInfo?.id === action.payload.contactId &&
      (currentBotId === action.payload.botId || action.payload.botId === null)
    ) {
      const conversation: ReturnType<typeof selectConversationInfo> = yield select(selectConversationInfo);
      const conversationId =
        conversation && conversation.contact && conversation.contact.id === action.payload.contactId
          ? conversation.id
          : undefined;

      yield put(
        contactInfoSlice.actions.getContactInfo({
          contactId: action.payload.contactId,
          botId: currentBotId,
          conversationId: conversationId,
        }),
      );
      yield put(contactInfoSlice.actions.getContactVariablesCompleted(undefined));

      yield put(
        contactInfoSlice.actions.getContactVariables({
          contactId: action.payload.contactId,
          size: "20",
          page: "1",
        }),
      );
    }
  } catch (e: unknown) {
    yield handleException(e);
  }
}

export function* stopFlow(action: PayloadAction<{ flowExecutionId: string }>) {
  try {
    const conversation: ReturnType<typeof selectConversationInfo> = yield select(selectConversationInfo);
    if (conversation) {
      yield call(contactInfoApi.stopFlow, conversation.id, action.payload.flowExecutionId);
    }
  } catch (e: unknown) {
    yield handleException(e);
  }
}

export function* getOperators(action: PayloadAction<{ page: number; filter?: string }>) {
  try {
    const prevOperatorList: OrganisationUsersModel = yield select(selectOperators);
    const operatorsData: OrganisationUsersModel = yield call(
      contactInfoApi.getOperators,
      action.payload.page,
      action.payload.filter,
    );

    const operatorItems = operatorsData.items.map(el => el);
    const prevOperatorItems = prevOperatorList?.items.map(el => el) ?? [];
    const concatedItems = action.payload.page > 1 ? prevOperatorItems.concat(operatorItems) : operatorItems;

    const operatorsModel = { ...operatorsData, items: concatedItems };
    yield put(contactInfoSlice.actions.getOperatorsCompleted(operatorsModel));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

export function* getTeams(action: PayloadAction<{ page: number; filter?: string }>) {
  try {
    const prevTeamList: OrganisationTeamsModel = yield select(selectTeams);
    const organisationTeamsData: OrganisationTeamsModel = yield call(
      contactInfoApi.getTeams,
      action.payload.page,
      action.payload.filter,
    );

    const teamsItems = organisationTeamsData.items.map(el => el);
    const prevTeamItems = prevTeamList?.items.map(el => el) ?? [];
    const concatedItems = action.payload.page > 1 ? prevTeamItems.concat(teamsItems) : teamsItems;

    const teamsModel = { ...organisationTeamsData, items: concatedItems };

    yield put(contactInfoSlice.actions.getTeamsCompleted(teamsModel));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

export const validateVariable = (variable: ContactVariableModel) => {
  if (!variable.value) {
    return;
  }
  switch (variable.type) {
    case CustomVariableType.Number: {
      if (variable.value.length >= 20) {
        throw new ValidationError({
          errors: [{ field: "error", message: "Max value exceeded" }],
        });
      }
      return;
    }
    case CustomVariableType.Text: {
      if (!variable.value.trim().length) {
        throw new ValidationError({
          errors: [{ field: "error", message: "Value can not contain only spaces" }],
        });
      }
      return;
    }
  }
};
