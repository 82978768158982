import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { Box, Flex, Link, Skeleton, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import ru from "date-fns/locale/ru";
import { format } from "date-fns";
import LeftArrow from "../../../../../assets/icons/arrowLeft.svg?react";
import CrossIcon from "../../../../../assets/icons/cross.svg?react";
import { getFiles, selectFiles } from "../../../ContactInfoSlice";
import { useAppDispatch, useAppSelector } from "../../../../../common/state/store";
import { AppSettings } from "../../../../../common/AppSettings";
import { useGetLoadingState } from "../../../../../common/loading/hooks/useGetLoadingState";
import s from "./ContactDocuments.module.scss";
import { formatBytes } from "../../../../../common/utils/formatBytes";
import { DOCS_PER_PAGE } from "../../../../../common/paginator/paginatorSizes";

declare const appSettings: AppSettings;
interface Props {
  conversationId: string;
  documentsCount: number | undefined;
  setOpenDocs: Dispatch<SetStateAction<boolean>>;
}

export default function ContactDocuments({ setOpenDocs, conversationId, documentsCount }: Props) {
  const { t } = useTranslation("translation", { keyPrefix: "contactInfo" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const documents = useAppSelector(selectFiles);
  const isLoading = useGetLoadingState("getFiles");
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const documentsRef = useRef<null | HTMLDivElement>(null);
  const array = Array.from({ length: documentsCount ?? 20 }, (_, index) => index + 1);

  useEffect(() => {
    dispatch(getFiles({ conversationId, attachments: "attachmentsDocs", page, size: DOCS_PER_PAGE }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId, page]);

  const formatDate = (dateString: string | number | Date) => {
    const date = new Date(dateString);
    return format(date, "d MMMM 'в' HH:mm", { locale: ru });
  };

  const handleDocumentsClose = () => {
    setOpenDocs(false);
  };

  const handleScroll = useCallback(
    (event: Event) => {
      if (isLoading) return;

      const target = event.target as HTMLDivElement;
      if (!target) return;

      const { scrollTop, clientHeight, scrollHeight } = target;

      if (documents && documents.totalPages > page) {
        if (scrollHeight - scrollTop - 20 <= clientHeight) {
          setPage(prevPage => prevPage + 1);
        }
      }
    },
    [documents, page, isLoading],
  );

  useEffect(() => {
    const documentsList = documentsRef.current;
    if (documentsList) {
      documentsList.addEventListener("scroll", handleScroll);
      return () => {
        documentsList.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  return (
    <Flex className={s.documentsContainer}>
      <Flex className={s.documentsGroup}>
        <Flex className={s.titleContainer}>
          <LeftArrow className={s.leftArrow} onClick={handleDocumentsClose} />
          <Text variant="largeBold">{t("Docs")}</Text>
        </Flex>
        <CrossIcon className={s.crossIcon} onClick={handleDocumentsClose} />
      </Flex>
      <Box className={s.container} ref={documentsRef}>
        {isLoading ? (
          array.map(el => <Skeleton className={s.sceletonMapper} key={el} />)
        ) : documents?.items.length === 0 ? (
          <Box className={s.noResults}>{ct("No results found")}</Box>
        ) : (
          documents?.items.map((item, index) => (
            <Flex className={s.fileContainer} key={index}>
              {isLoading ? (
                <Skeleton className={s.skeletonDocsLeft} />
              ) : (
                <Box className={s.fileType} objectFit="cover" layerStyle={"fill"}>
                  {item.fileName?.toLowerCase().split(".").pop()}
                </Box>
              )}
              {isLoading ? (
                <Skeleton className={s.skeletonDocsRight} />
              ) : (
                <>
                  <Flex className={s.filesItem} objectFit="cover" layerStyle={"fill"}>
                    <Flex flexDir="column" gap="2px">
                      <Link href={`${appSettings.apiBaseUrl}/file/${item.fileId}`} isExternal>
                        <Text className={s.fileNameText} variant="small">
                          {item.fileName}
                        </Text>
                      </Link>
                      <Text variant="small" color="darkGrey">
                        {formatBytes(item.size)}
                      </Text>
                    </Flex>
                    <Text variant="extraSmall" color="darkGrey">
                      {item.uploadDate ? formatDate(item.uploadDate) : ""}
                    </Text>
                  </Flex>
                </>
              )}
            </Flex>
          ))
        )}
      </Box>
    </Flex>
  );
}
