import { CSSProperties, forwardRef, useEffect, useRef } from "react";
import { TextFormatter, TextFormatterProps } from "./TextFormatter";
import { useCombinedRefs } from "../../../common/hooks/useCombinedRefs";
import { TEXT_FORMATTER_WRAPPER_ID } from "./consts";

export interface TextFormatterWithExpandableProps extends TextFormatterProps {
  minHeight: number;
  maxHeight: number;
}

export const TextFormatterWithExpandable = forwardRef<HTMLDivElement, TextFormatterWithExpandableProps>(
  function TextFormatterWithExpandable(props, ref) {
    const { maxHeight, minHeight, ...rest } = props;

    const inputRef = useRef<HTMLDivElement>(null);
    const combinedRef = useCombinedRefs(ref, inputRef);

    const styles: Pick<CSSProperties, "minHeight" | "maxHeight" | "height"> = {
      minHeight: minHeight + "px",
      maxHeight: maxHeight + "px",
      height: "initial",
    };

    useEffect(() => {
      const wrapper = document.getElementById(TEXT_FORMATTER_WRAPPER_ID);
      if (!wrapper) return;

      wrapper.style.height = "initial";
    }, []);

    return <TextFormatter ref={combinedRef} style={styles} {...rest} />;
  },
);
