import { CUSTOM_SPLITTER } from "./consts";
import { FilterRule, FilterTypeEnum } from "../../_types/FilterRule";
import {
  PhraseWhiteListType,
  TGUsersWhiteListType,
  UrlWhiteListType,
  ViolationNotificationType,
} from "../../AntispamFilter/AntispamFilterModel";
import {
  PhraseWhiteList,
  UrlWhiteList,
  UsersWhiteListNotification,
  ViolationNotification,
} from "../../_types/AntispamFilterTypes";

export const isFilterEqual = {
  rules: (srcRules: FilterRule[], copyRules: FilterRule[]): boolean => {
    if (srcRules.length !== copyRules.length) return false;

    let isEqual = true;
    let index = 0;

    while (isEqual && index < srcRules.length) {
      if (srcRules[index].type !== copyRules[index].type) {
        isEqual = false;
      }

      if (srcRules[index].type === FilterTypeEnum.Mute) {
        if (srcRules[index].forLong !== copyRules[index].forLong) {
          isEqual = false;
        }
      }

      index++;
    }

    return isEqual;
  },
  customProfanities: (srcProfanities: string[], copyProfanities: string): boolean => {
    return srcProfanities.join(CUSTOM_SPLITTER) === copyProfanities;
  },
  prohibitedTopics: (srcProhibitedTopics: string[], prohibitedTopics: string[]): boolean => {
    return srcProhibitedTopics.join(CUSTOM_SPLITTER) === prohibitedTopics.join(CUSTOM_SPLITTER);
  },
  violationNotification: (
    violationNotificationSettings: ViolationNotificationType,
    violationNotification: ViolationNotification,
  ): boolean => {
    return (
      violationNotificationSettings.tgUsernames.join(CUSTOM_SPLITTER) === violationNotification.tgUsernames &&
      violationNotificationSettings.isEnabled === violationNotification.isEnabled
    );
  },
  usersWhiteList: (tgUsersWhiteListSettings: TGUsersWhiteListType, usersWhiteList: UsersWhiteListNotification): boolean => {
    return (
      tgUsersWhiteListSettings.tgUsernames.join(CUSTOM_SPLITTER) === usersWhiteList.tgUsernames &&
      tgUsersWhiteListSettings.isEnabled === usersWhiteList.isEnabled
    );
  },
  urlWhiteList: (urlWhiteListSettings: UrlWhiteListType, urlWhiteList: UrlWhiteList): boolean => {
    return (
      urlWhiteListSettings.urls.join(CUSTOM_SPLITTER) === urlWhiteList.urls &&
      urlWhiteListSettings.isEnabled === urlWhiteList.isEnabled
    );
  },
  phraseWhiteList: (phraseWhiteListSettings: PhraseWhiteListType, phraseWhiteList: PhraseWhiteList): boolean => {
    return (
      phraseWhiteListSettings.phrases.join(CUSTOM_SPLITTER) === phraseWhiteList.phrases &&
      phraseWhiteListSettings.isEnabled === phraseWhiteList.isEnabled
    );
  },
};
