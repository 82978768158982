import s from "./MessageNodePopup.module.scss";
import {
  CustomVariablesModel,
  FlowActionModel,
  NodeModel,
  NodeValidationProp,
  SendTextFlowActionModel,
} from "../../../FlowBuilderModel";

import { Drawer, DrawerContent, DrawerHeader, DrawerBody, DrawerFooter, Button } from "@chakra-ui/react";
import { ButtonsList } from "../ButtonsList";
import { Loading } from "../../../../../common/loading/LoadingStateContainer";
import { NodeEditorFooter } from "../NodeEditorFooter/NodeEditorFooter";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../common/state/store";
import { selectCustomVariables } from "../../../FlowBuilderSlice";
import { ReplyKeyboardButtonModel, ButtonModel } from "../../../../../common/AppButtonsModel";
import { Fallback } from "../Fallback";
import { getButtonsWithExactType } from "../../../utils";
import { TextFormatterWithExpandable } from "../../../../../UI/molecules/TextFormatter/TextFormatterWithExpandable";
import { useMemo } from "react";
import { escapeMarkdown } from "../../../../../common/utils/escapeMarkdown";

interface Props {
  flowAction: FlowActionModel;
  node: NodeModel;
  dataChange: (flowAction: FlowActionModel) => void;
  validate: (flowAction: FlowActionModel) => void;
  validationInfo: NodeValidationProp;
  addButton: (button: ButtonModel | ReplyKeyboardButtonModel) => void;
  setButtons: (buttons: ButtonModel[][] | ReplyKeyboardButtonModel[][]) => void;
  deleteButton: (buttonId: string) => void;
  editButton: (button: ButtonModel | ReplyKeyboardButtonModel) => void;
  addCustomVariableText: (variable: CustomVariablesModel, variableMaxLength: number) => void;
  onClose: () => void;
}

export const MessageNodePopup = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const placeholder = useTranslation("translation", { keyPrefix: "flow.editNodePopup" }).t;
  const variables = useAppSelector(selectCustomVariables);
  const flowAction = props.flowAction as SendTextFlowActionModel;

  const initialValue = useMemo(() => {
    if (flowAction.entities && flowAction.entities.length > 0) {
      return { text: flowAction.text ?? "", entities: flowAction.entities };
    } else {
      const [text, entities] = escapeMarkdown(flowAction.text ?? "");
      return { text, entities };
    }
  }, [flowAction.entities, flowAction.text]);

  return (
    <>
      <Drawer isOpen={true} placement="right" onClose={props.onClose} variant="dominoDrawer">
        <DrawerContent>
          <Loading scope="nodeValidate" />
          <DrawerHeader>{t("Send Message")}</DrawerHeader>
          <DrawerBody paddingInline="7px">
            <div className={s.popupWrapper}>
              <div className={s.popup}>
                <div className={s.wrapper}>
                  <div className={s.popupItem}>
                    <TextFormatterWithExpandable
                      data-pw="message-text-area"
                      variables={variables ?? []}
                      value={flowAction.text ?? ""}
                      initialValue={initialValue}
                      handleChange={t =>
                        props.dataChange({
                          ...flowAction,
                          text: t,
                          entities: [],
                        } as SendTextFlowActionModel)
                      }
                      maxLength={2000}
                      placeholder={`${placeholder("Enter text here")} ...`}
                      wrapperClassname={s.textFormatterWrapper}
                      minHeight={112}
                      maxHeight={500}
                      autoFocus
                    />
                    <ButtonsList
                      node={props.node}
                      buttons={
                        (getButtonsWithExactType(flowAction?.replyMarkup, flowAction) as
                          | ButtonModel[][]
                          | ReplyKeyboardButtonModel[][]) ?? []
                      }
                      flowAction={flowAction}
                      setButtons={props.setButtons}
                      addButton={props.addButton}
                      deleteButton={props.deleteButton}
                      editButton={props.editButton}
                    />
                    <Fallback
                      validate={props.validate}
                      flowAction={flowAction}
                      dataChange={props.dataChange}
                      maxLength={500}
                      text={""}
                      onChange={e =>
                        props.dataChange({
                          ...flowAction,
                          fallbackMessage: e,
                        } as SendTextFlowActionModel)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </DrawerBody>
          {props.validationInfo.isError && (
            <DrawerFooter margin={"8px 8px 0 8px"} padding={"0"}>
              {props.validationInfo.isError && <NodeEditorFooter validationMessage={props.validationInfo.errors[0].message} />}
            </DrawerFooter>
          )}
          <Button
            variant="dominoViolet"
            w={"auto"}
            margin={"24px 16px 16px 16px"}
            onClick={() => props.onClose()}
            data-pw="apply-button"
          >
            {t("Apply")}
          </Button>
        </DrawerContent>
      </Drawer>
    </>
  );
};
