import { Box, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { TooltipWithTouch } from "../../../../../../../../../../common/tooltipWithTouch/TooltipWithTouch";
import { QuestionOutlineIcon } from "@chakra-ui/icons";

const LONG_DASH = "\u2014";
const RESTRICTIONS_DESCRIPTION = ["warning", "muting", "blocking"];

interface Props {
  title: string;
  description: string;
}

export const FilterInfo = ({ title, description }: Props) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "groupChat.groupChatInfo.ProfanityAndAntispamFilterPage",
  });

  const restrictionRenderer = RESTRICTIONS_DESCRIPTION.map((restriction, index) => {
    const key = t(`restrictions.${restriction}.key`);
    const value = t(`restrictions.${restriction}.value`);

    const Content = () => {
      return (
        <>
          <Text variant="smallBold" as="span" color="newBlack">
            {key}
          </Text>
          {!!value && (
            <Text variant="small" as="span" color="newBlack">
              {" "}
              {LONG_DASH} {value}
            </Text>
          )}
          {index + 1 < RESTRICTIONS_DESCRIPTION.length && (
            <Text variant="small" as="span" color="newBlack">
              ,{" "}
            </Text>
          )}
        </>
      );
    };

    return (
      <Text key={index} color="newBlack">
        <Content />
      </Text>
    );
  });

  const getTooltipDescription = () => {
    return (
      <Text variant="small" as="span" color="newBlack">
        {description} {restrictionRenderer}
      </Text>
    );
  };

  return (
    <Box display="inline">
      <Text variant="h3" color="newBlack" display="inline">
        {title}
      </Text>
      <Box display="inline" verticalAlign="middle">
        <TooltipWithTouch variant="dominoLight" display="inline-flex" label={getTooltipDescription()}>
          <QuestionOutlineIcon ml="5px" boxSize="20px" color="darkGrey" _hover={{ color: "darkPurple" }} />
        </TooltipWithTouch>
      </Box>
    </Box>
  );
};
