import { StatsigProvider, useClientAsyncInit } from "@statsig/react-bindings";
import { ReactElement, useEffect } from "react";
import { AppSettings } from "../../AppSettings";
import { useAppSelector } from "../../state/store";
import { selectUser } from "../../user/UserSlice";
import { selectIsAiAssistantCountInit } from "../../../components/sidebar/SidebarSlice";

declare const appSettings: AppSettings;

interface Props {
  children: ReactElement;
}

export const CustomStatsigProvider = ({ children }: Props) => {
  const user = useAppSelector(selectUser);
  const username = localStorage.getItem("username") || sessionStorage.getItem("username") || "";
  const usersOrganizationId = localStorage.getItem("usersOrganizationId");
  const orgIdFromStorage = usersOrganizationId && JSON.parse(usersOrganizationId)[username];
  const isAiAssitantCountInit = useAppSelector(selectIsAiAssistantCountInit);

  const { client } = useClientAsyncInit(appSettings.statsigClientKey, {
    userID: user.id,
    custom: {
      organizationId: orgIdFromStorage,
    },
  });

  useEffect(() => {
    if (user.id) {
      client.updateUserAsync({ userID: user.id, custom: { organizationId: orgIdFromStorage } });
    }
  }, [client, orgIdFromStorage, user.id, isAiAssitantCountInit]);
  return <StatsigProvider client={client}>{children}</StatsigProvider>;
};
