import { createContext, Dispatch, ReactElement, SetStateAction, useState } from "react";

interface Context {
  restrictionInfoNodeWidth: number;
  setRestrictionInfoNodeWidth: Dispatch<SetStateAction<number>>;
}
export const FilterContext = createContext<Context | null>(null);

interface Props {
  children: ReactElement;
}

export const FilterProvider = ({ children }: Props) => {
  const [width, setWidth] = useState<number>(0);

  return (
    <FilterContext.Provider value={{ restrictionInfoNodeWidth: width, setRestrictionInfoNodeWidth: setWidth }}>
      {children}
    </FilterContext.Provider>
  );
};
