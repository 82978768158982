import { Box, Button, Flex, Icon } from "@chakra-ui/react";
import { TextFormatterWithReplyProps } from "./TextFormatterWithReply";
import ReplyIcon from "../../../../assets/icons/replyIcon.svg?react";
import CrossIcon from "../../../../assets/icons/cross.svg?react";
import { useTranslation } from "react-i18next";
import s from "./TextFormatterWithReply.module.scss";

type Props = Pick<TextFormatterWithReplyProps, "replyMode" | "botName" | "onCloseReply">;

export const ReplyMode = ({ replyMode, botName, onCloseReply }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "conversation" });

  return (
    <Flex className={s.replyWrapper}>
      <Flex className={s.replyMessage}>
        <Flex className={s.replyMessageFlex}>
          <Icon as={ReplyIcon} className={s.replyIcon}></Icon>
          {replyMode.message?.photoFileId && (
            <span className={s.replyMessagePhoto}>
              <img alt="" className={s.photo} src={`${appSettings.apiBaseUrl}/file/${replyMode.message.photoFileId}`} />
            </span>
          )}
          <Box className={`${s.replyMessageContent} ${replyMode.message?.photoFileId ? s.replyMessageContentImage : ""}`}>
            <span className={s.replyMessageAuthor}>
              {replyMode.message?.author === "bot" ? botName : replyMode.message?.author}
            </span>
            {replyMode.message?.contact && !replyMode.message?.text ? (
              <Box>
                <span className={s.replyMessageContact}>{t("Contact")}</span>
              </Box>
            ) : (
              <></>
            )}
            {!!replyMode.message?.documentName ? (
              <span className={`${s.replyMessageText} ${s.description}`}>{replyMode.message?.documentName}</span>
            ) : (
              <>
                {replyMode.message?.photoFileId && !replyMode.message?.text ? (
                  <span className={`${s.replyMessageText} ${s.description}`}>{t("Image")}</span>
                ) : (
                  <span className={s.replyMessageText}>{replyMode.message?.text}</span>
                )}
              </>
            )}
          </Box>
        </Flex>
        <Button className={s.replyCancelButton} onClick={onCloseReply}>
          <Icon as={CrossIcon} className={s.icon}></Icon>
        </Button>
      </Flex>
    </Flex>
  );
};
