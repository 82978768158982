import { Box, Flex, Text } from "@chakra-ui/react";
import { GroupOfButtonsInputs } from "../../../../../../../../../../UI/atoms/groupOfButtonsInputs/GroupOfButtonsInputs";
import { MAXIMAL_TOPIC_COUNT, MINIMAL_SCREEN_SIZE_FOR_DEFAULT_LAYOUT } from "../../consts";
import { useNowWidthView } from "../../../../../../../../../layout/LayoutHelper/ResolutionHooks";
import { useContext } from "react";
import { FilterContext } from "../../FilterProvider";
import s from "./FilterProhibitedTopics.module.scss";
import { useTranslation } from "react-i18next";

interface Props {
  prohibitedTopics: string[];
  setProhibitedTopics: (prohibitedTopics: string[]) => void;
  isInvalidProhibitedTopics: boolean;
  setIsInvalidProhibitedTopics: (isInvalidProhibitedTopics: boolean) => void;
}

export const FilterProhibitedTopics = ({
  prohibitedTopics,
  setProhibitedTopics,
  isInvalidProhibitedTopics,
  setIsInvalidProhibitedTopics,
}: Props) => {
  const nowWidth = useNowWidthView();
  const context = useContext(FilterContext);
  const columnGap = (context ? context.restrictionInfoNodeWidth - 164 - 178 : 16) + "px";
  const { t } = useTranslation("translation", {
    keyPrefix: "groupChat.groupChatInfo.ProfanityAndAntispamFilterPage",
  });

  const handleSetProhibitedTopics = (topics: string[]) => {
    setIsInvalidProhibitedTopics(false);
    setProhibitedTopics(topics);
  };

  return (
    <Flex
      columnGap={columnGap}
      rowGap={nowWidth >= MINIMAL_SCREEN_SIZE_FOR_DEFAULT_LAYOUT ? "16px" : "12px"}
      justifyContent="space-between"
      direction={nowWidth >= MINIMAL_SCREEN_SIZE_FOR_DEFAULT_LAYOUT ? "row" : "column"}
    >
      <Text minW="164px" maxW={nowWidth >= MINIMAL_SCREEN_SIZE_FOR_DEFAULT_LAYOUT ? "164px" : "100%"}>
        {t("Prohibited topics", {
          count: MAXIMAL_TOPIC_COUNT,
          lineBreak: nowWidth >= MINIMAL_SCREEN_SIZE_FOR_DEFAULT_LAYOUT ? "\u2028" : "",
        })}
      </Text>
      <Flex direction="column" w="100%" gap="2">
        <Flex direction="column" gap="1">
          <Box className={`${s.groupOfButtonsInputsWrp} ${isInvalidProhibitedTopics && s.invalid}`}>
            <GroupOfButtonsInputs
              items={prohibitedTopics}
              itemQuantityLimit={MAXIMAL_TOPIC_COUNT}
              addNewItemButtonText={`+ ${t("Topic")}`}
              inputPlaceholder={t("Enter text")}
              maxItemLength={100}
              setItems={handleSetProhibitedTopics}
            />
          </Box>
          {isInvalidProhibitedTopics && (
            <Box color="mainRed">
              <Text minH="16px" variant="small">
                {t("errors.TopicList")}
              </Text>
            </Box>
          )}
        </Flex>

        <Box>
          <Text variant="small" color="darkGrey">
            {t("Here, you can add topics you want to prohibit")}
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};
