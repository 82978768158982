import { QuestionOutlineIcon } from "@chakra-ui/icons";
import {
  Text,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Input,
  Flex,
  Menu,
  MenuButton,
  Button,
  MenuList,
  Box,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Icon,
  Heading,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AssignFlowActionModel, FlowActionModel, FlowModel, NodeModel, NodeValidationProp } from "../../../FlowBuilderModel";
import { NodeEditorFooter } from "../NodeEditorFooter/NodeEditorFooter";
import DownArrowMenu from "../../../../../assets/images/downArrow.svg?react";
import CrossIcon from "../../../../../assets/icons/cross.svg?react";
import Magnifire from "../../../../../assets/images/magnifire.svg?react";
import s from "./AssignNodePopup.module.scss";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../common/state/store";
import { selectOperators, selectTeams } from "../../../FlowBuilderSlice";
import { CustomMenuItem } from "../../../../../UI/molecules/customMenuItem/CustomMenuItem";

interface Props {
  flow: FlowModel;
  node: NodeModel;
  getTeamPage: (page: number, filter?: string) => void;
  getOperatorPage: (page: number, filter?: string) => void;
  onDataChange: (flowAction: FlowActionModel) => void;
  validate: (flowAction: FlowActionModel) => void;
  validationInfo: NodeValidationProp;
  onClose: () => void;
}

export const AssignNodePopup = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const flowAction = props.node.flowAction as AssignFlowActionModel;
  const teams = useAppSelector(selectTeams);
  const operators = useAppSelector(selectOperators);
  const onTeamMenuClose = useDisclosure().onClose;
  const onOperatorMenuClose = useDisclosure().onClose;
  const [teamSearchValue, setTeamSearchValue] = useState("");
  const [operatorSearchValue, setOperatorSearchValue] = useState("");

  useEffect(() => {
    props.validate(flowAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowAction]);

  const onChange = (type: "Team" | "Operator", value: { id: string; name: string } | null) => {
    if (type === "Team") {
      props.onDataChange({ ...flowAction, teamId: value?.id, teamName: value?.name } as AssignFlowActionModel);
      return;
    }
    props.onDataChange({ ...flowAction, operatorId: value?.id, operatorName: value?.name } as AssignFlowActionModel);
  };

  const onSearchTextChange = (value: string, type: "Team" | "Operator") => {
    if (type === "Team") {
      setTeamSearchValue(value);
      props.getTeamPage(1, value);
      return;
    }
    setOperatorSearchValue(value);
    props.getOperatorPage(1, value);
  };

  const getAdditionalItems = (item: "Team" | "Operator") => {
    if (item === "Team" && teams?.currentPage) {
      props.getTeamPage(teams.currentPage + 1);
    }
    if (item === "Operator" && operators?.currentPage) {
      props.getOperatorPage(operators.currentPage + 1);
    }
  };

  const onMenuClose = (type: "Team" | "Operator") => {
    if (type === "Team") {
      onSearchTextChange("", "Team");
      onTeamMenuClose();
      return;
    }
    onSearchTextChange("", "Operator");
    onOperatorMenuClose();
  };

  return (
    <>
      <Drawer isOpen={true} placement="right" onClose={props.onClose} variant="dominoDrawer">
        <DrawerContent>
          <DrawerHeader>{t("Assign")}</DrawerHeader>
          <DrawerBody>
            <Flex mb="20px" alignItems="center">
              <Heading fontSize={"16px"} fontWeight={"400"}>
                {t("editNodePopup.Choose an Operator and/or Team")}
              </Heading>
              <Tooltip
                variant="dominoLight"
                placement="right"
                whiteSpace={"pre-wrap"}
                label={t("editNodePopup.The assigned operator/team will be responsible for continuing the conversation")}
              >
                <QuestionOutlineIcon ml={"4px"} boxSize={"15px"} color="darkGrey" _hover={{ color: "darkPurple" }} />
              </Tooltip>
            </Flex>

            <Heading fontSize={"16px"} fontWeight={"400"} mt="16px">
              {t("Assigned Team")}
            </Heading>
            <Menu
              preventOverflow={false}
              matchWidth={true}
              variant="dominoGroupedMenu"
              gutter={0}
              onClose={() => onMenuClose("Team")}
            >
              {({ isOpen, onClose }) => (
                <>
                  <MenuButton
                    _active={{ borderColor: "line", backgroundColor: "white" }}
                    as={Button}
                    className={s.menuButton}
                    variant="dominoDefaultMenuBtn"
                    isActive={isOpen}
                  >
                    {flowAction.teamName ? (
                      <Text fontSize={"15px"}>{flowAction.teamName}</Text>
                    ) : (
                      <Text fontSize={"15px"} color={"darkGrey"}>
                        {t("editNodePopup.Select")}
                      </Text>
                    )}
                  </MenuButton>
                  <MenuList w="100%" maxH="400px" position="absolute" top="-37px">
                    <Box className={s.dropdownSearchBox}>
                      <InputGroup w="auto">
                        <InputLeftElement pointerEvents="none">
                          <Magnifire />
                        </InputLeftElement>
                        <Input
                          isInvalid={false}
                          autoFocus
                          value={teamSearchValue}
                          placeholder={t(`editNodePopup.Search by Teams`) ?? ""}
                          onChange={e => onSearchTextChange(e.target.value, "Team")}
                        />
                        <InputRightElement right="10px" top="50%" mt={"-11px"} boxSize="22px">
                          {teamSearchValue && (
                            <CrossIcon className={s.crossIcon} cursor="pointer" onClick={() => onSearchTextChange("", "Team")} />
                          )}
                        </InputRightElement>
                      </InputGroup>
                    </Box>
                    <Box overflow="scroll" maxH="300px">
                      <CustomMenuItem
                        height={{ base: "40px", lg: "40px" }}
                        fontSize="15px"
                        lineHeight="18px"
                        onClick={() => {
                          onChange("Team", null);
                          onClose();
                        }}
                      >
                        {t("Not set")}
                      </CustomMenuItem>
                      {teams?.items.map((el, i) => {
                        return (
                          <CustomMenuItem
                            height={{ base: "40px", lg: "40px" }}
                            fontSize="15px"
                            lineHeight="18px"
                            key={i}
                            pr="0"
                            onClick={() => {
                              onChange("Team", { id: el.id, name: el.name });
                              onClose();
                            }}
                          >
                            <Flex overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" w="100%" h="17px">
                              {el.name}
                            </Flex>
                          </CustomMenuItem>
                        );
                      })}
                      {teams && teams.currentPage < teams.totalPages && (
                        <CustomMenuItem
                          closeOnSelect={false}
                          onClick={() => {
                            getAdditionalItems("Team");
                          }}
                          height={{ base: "40px", lg: "40px" }}
                          fontSize="15px"
                          lineHeight="18px"
                        >
                          <Flex alignItems={"center"} justifyContent={"center"} w={"100%"} cursor={"pointer"}>
                            <div>{t("editNodePopup.show more")}</div>
                            <Icon as={DownArrowMenu} boxSize="26px" color="midDeepBlue" />
                          </Flex>
                        </CustomMenuItem>
                      )}
                    </Box>
                  </MenuList>
                </>
              )}
            </Menu>

            <Heading fontSize={"16px"} fontWeight={"400"} mt="16px">
              {t("Assigned Operator")}
            </Heading>
            <Menu
              preventOverflow={false}
              matchWidth={true}
              variant="dominoGroupedMenu"
              gutter={0}
              onClose={() => onMenuClose("Operator")}
            >
              {({ isOpen, onClose }) => (
                <>
                  <MenuButton
                    _active={{ borderColor: "line", backgroundColor: "white" }}
                    as={Button}
                    variant="dominoDefaultMenuBtn"
                    className={s.menuButton}
                    isActive={isOpen}
                  >
                    {flowAction.operatorName ? (
                      <Text fontSize={"15px"}>{flowAction.operatorName}</Text>
                    ) : (
                      <Text fontSize={"15px"} color={"darkGrey"}>
                        {t("editNodePopup.Select")}
                      </Text>
                    )}
                  </MenuButton>
                  <MenuList w="100%" maxH="400px" position="absolute" top="-37px">
                    <Box className={s.dropdownSearchBox}>
                      <InputGroup w="auto">
                        <InputLeftElement pointerEvents="none">
                          <Magnifire />
                        </InputLeftElement>
                        <Input
                          isInvalid={false}
                          autoFocus
                          value={operatorSearchValue}
                          placeholder={t(`editNodePopup.Search by Operators`) ?? ""}
                          onChange={e => onSearchTextChange(e.target.value, "Operator")}
                        />
                        <InputRightElement right="10px" top="50%" mt={"-11px"} boxSize="22px">
                          {operatorSearchValue && (
                            <CrossIcon
                              className={s.crossIcon}
                              cursor="pointer"
                              onClick={() => onSearchTextChange("", "Operator")}
                            />
                          )}
                        </InputRightElement>
                      </InputGroup>
                    </Box>
                    <Box overflow="scroll" maxH="300px">
                      <CustomMenuItem
                        height={{ base: "40px", lg: "40px" }}
                        fontSize="15px"
                        lineHeight="18px"
                        onClick={() => {
                          onChange("Operator", null);
                          onClose();
                        }}
                      >
                        {t("Not set")}
                      </CustomMenuItem>
                      {operators?.items.map((el, i) => {
                        return (
                          <CustomMenuItem
                            height={{ base: "40px", lg: "40px" }}
                            fontSize="15px"
                            lineHeight="18px"
                            key={i}
                            onClick={() => {
                              onChange("Operator", { id: el.id, name: el.name });
                              onClose();
                            }}
                          >
                            {el.name}
                          </CustomMenuItem>
                        );
                      })}
                      {operators && operators.currentPage < operators.totalPages && (
                        <CustomMenuItem
                          closeOnSelect={false}
                          onClick={() => {
                            getAdditionalItems("Operator");
                          }}
                          height={{ base: "40px", lg: "40px" }}
                          fontSize="15px"
                          lineHeight="18px"
                        >
                          <Flex alignItems={"center"} justifyContent={"center"} w={"100%"} cursor={"pointer"}>
                            <div>{t("editNodePopup.show more")}</div>
                            <Icon as={DownArrowMenu} boxSize="26px" color="midDeepBlue" />
                          </Flex>
                        </CustomMenuItem>
                      )}
                    </Box>
                  </MenuList>
                </>
              )}
            </Menu>
          </DrawerBody>
          <DrawerFooter margin={"8px"} padding={"0"}>
            {props.validationInfo.isError && <NodeEditorFooter validationMessage={props.validationInfo.errors[0].message} />}
          </DrawerFooter>
          <Button variant="dominoViolet" w={"auto"} margin={"16px"} onClick={() => props.onClose()} data-pw="apply-button">
            {t("Apply")}
          </Button>
        </DrawerContent>
      </Drawer>
    </>
  );
};
