import { CUSTOM_SPLITTER } from "../../_components/FilterSettings/consts";

const phraseRegex = /^([a-zA-Zа-яА-ЯёЁ]+(?: [a-zA-Zа-яА-ЯёЁ]+)*(?:, (?:[a-zA-Zа-яА-ЯёЁ]+(?: [a-zA-Zа-яА-ЯёЁ]+)*))*)?$/;
const tgUsernameRegex = /^@[a-zA-Z0-9_]+$/;
const urlRegex =
  // eslint-disable-next-line max-len
  /^((https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,})|^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,})|(^www\.[a-zA-Z0-9]+\.[^\s]{2,})|(^www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}))$/;

export const validatePhraseWhiteList = (text: string) => {
  return phraseRegex.test(text);
};

export const validateUserList = (text: string) => {
  if (!text) return true;
  if (text.endsWith(CUSTOM_SPLITTER)) return false;

  const users = text.split(CUSTOM_SPLITTER);
  return users.every(user => {
    return tgUsernameRegex.test(user);
  });
};

export const validateLinkList = (text: string) => {
  if (!text) return true;
  if (text.endsWith(CUSTOM_SPLITTER)) return false;

  const urls = text.split(CUSTOM_SPLITTER);
  return urls.every(url => {
    return urlRegex.test(url);
  });
};
