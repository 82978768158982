import { Flex, Text, Icon, Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import plusIconTile from "../../../../../../assets/icons/plusIconTile.svg?react";
import StarPlan from "../../../../../../assets/icons/starPlan.svg?react";
import s from "./CreationMenuNodeButton.module.scss";
import { FlowActionType, NodeType, ViewNodeModel } from "../../../../FlowBuilderModel";
import { INodeTariffPlanFeature, TariffPlanFeatureTypeEnum } from "../../../../../../common/AppEnums";
import { AppSettings } from "../../../../../../common/AppSettings";
import { LimitStarPlanButton } from "../../../../../../UI/molecules/limitStarPlanButton/LimitStarPlanButton";
import { useSelectLimitPlan } from "../../../../../../common/hooks/useSelectLimitPlan";

declare const appSettings: AppSettings;

interface Props {
  node: ViewNodeModel;
  isDisabled?: boolean;
  addNode: (type: NodeType, typeDiscriminator: keyof typeof FlowActionType) => void;
}

export default function CreationMenuNodeButton({ node, addNode, isDisabled }: Props) {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });

  const typeIsAccessability = () => {
    switch (node.title) {
      case "HTTP request":
        return TariffPlanFeatureTypeEnum.NodeHttpRequest;
      case "Delay":
        return TariffPlanFeatureTypeEnum.NodeDelay;
      case "Start Flow":
        return TariffPlanFeatureTypeEnum.NodeStartFlow;
      case "AI Assistant":
        return TariffPlanFeatureTypeEnum.NodeAiAgent;
      case "Condition":
        return TariffPlanFeatureTypeEnum.NodeCondition;
      case "System Action":
        return TariffPlanFeatureTypeEnum.NodeSystemAction;
      case "Assign":
        return TariffPlanFeatureTypeEnum.NodeAssignment;
      case "Calendar":
        return TariffPlanFeatureTypeEnum.NodeAppointments;
      case "Payment":
        return TariffPlanFeatureTypeEnum.NodePayment;
      case "Catalog":
        return TariffPlanFeatureTypeEnum.NodeShoppingCart;
      default:
        break;
    }
  };
  const typeAccessability = typeIsAccessability();
  const accessibility = useSelectLimitPlan(typeAccessability) as INodeTariffPlanFeature;
  const isAccessability = typeAccessability ? accessibility : { IsAvailable: true };

  return (
    <>
      {!isAccessability["IsAvailable"] ? (
        <LimitStarPlanButton popoverBodyDescription="Please upgrade your plan to add more nodes in the Flow">
          <Flex className={s.accordionNodeButton} align="center">
            <Flex className={s.iconsFlex} minW="44px" w="44px" gap="32px" overflowX="hidden">
              <Icon className={s.iconLeft} boxSize="20px" as={plusIconTile} />
              <Icon className={s.iconRight} boxSize="20px" as={node.icon} />
            </Flex>
            <Text as="span" variant="medium" noOfLines={1} color="#3E5E95">
              {t(node.title)}
            </Text>
            <Flex justify="end" pr="8px" flex={1}>
              <Icon className={s.starSVG} boxSize="20px" as={StarPlan} />
            </Flex>
          </Flex>
        </LimitStarPlanButton>
      ) : (
        <Flex
          onClick={() => {
            addNode(NodeType.Action, node.typeDiscriminator);
          }}
          className={`${s.accordionNodeButton} ${isDisabled && s.disabled}`}
          align="center"
        >
          <Flex className={s.iconsFlex} minW="44px" w="44px" gap="32px" overflowX="hidden">
            <Icon className={s.iconLeft} boxSize="20px" as={plusIconTile} />
            <Icon className={s.iconRight} boxSize="20px" as={node.icon} />
          </Flex>
          {appSettings.newItemsPointer?.find(el => el === node.title) && <Box className={s.iconPointer}></Box>}
          <Text as="span" variant="medium" noOfLines={1} color="#3E5E95">
            {t(node.title)}
          </Text>
        </Flex>
      )}
    </>
  );
}
