import { Button, Box, Flex, Text } from "@chakra-ui/react";
import s from "./WebBotListItem.module.scss";
import PlusIcon from "../../../../assets/icons/plusIconTile.svg?react";
import { useTranslation } from "react-i18next";
import { OverflowEllipsesText } from "../../../../UI/atoms/textOverflow/OverflowEllipsesText";
import ClipboardText from "../../../../UI/atoms/clipboardText/ClipboardText";
import { BotModel } from "../../../botList/BotListModel";
import { useNavigate } from "react-router-dom";
import { LimitStarPlanButton } from "../../../../UI/molecules/limitStarPlanButton/LimitStarPlanButton";
import { ICountTariffPlanFeature, TariffPlanFeatureTypeEnum } from "../../../../common/AppEnums";
import { useSelectLimitPlan } from "../../../../common/hooks/useSelectLimitPlan";
import { selectOrganisation } from "../../../organisation/OrganisationSlice";
import { useAppSelector } from "../../../../common/state/store";

interface Props {
  info: BotModel;
  currentPage: number;
  createNewFlow: (botInfo: BotModel) => void;
  redirectToBotFlows: (bot: BotModel) => void;
}

export const WebBotListItem = ({ info, createNewFlow, currentPage, redirectToBotFlows }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "botList" });
  const navigate = useNavigate();
  const activeFlows = useSelectLimitPlan(TariffPlanFeatureTypeEnum.ActiveFlows) as ICountTariffPlanFeature;
  const organisation = useAppSelector(selectOrganisation);
  const canAddFlow = activeFlows.IsUnlimited || activeFlows.MaxCount > Number(organisation?.totalFlow);
  const tooltipLabe = t("Copy and paste this code onto every page of your website before the closing </body> tag.");

  const widgetScript = `<script src="${appSettings.chatWidgetScript}"></script> ${" "}
  <script type="text/javascript">window.dominoChatWidget.init("OrganizationId", "BotId", "${
    appSettings.chatWidgetUrl
  }")</script>`;

  return (
    <Box
      className={s.item}
      onClick={() => {
        navigate(`/bots/${info.id}`, { state: { page: currentPage, channel: info?.channel } });
      }}
    >
      <Box className={s.info}>
        <Text className={s.mobileTitle}>{t("Name")}:</Text>
        <Text>{info.name}</Text>
      </Box>
      <Box className={s.info}>
        <Text className={s.mobileTitle}>{t("Title")}:</Text>
        <Text>{info.title}</Text>
      </Box>
      <Box className={s.info}>
        <Text className={s.mobileTitle}>{t("Code")}:</Text>
        <OverflowEllipsesText
          text={"utmLink"}
          tooltip={false}
          content={
            <ClipboardText
              tooltipText={tooltipLabe}
              elemIsCopy={
                <Text fontSize="15px" fontWeight="400">
                  {widgetScript}
                </Text>
              }
              textIsCopy={"ss"}
              color="#3e5e95"
            />
          }
        />
      </Box>
      <Box className={s.info}>
        <Text className={s.mobileTitle}>{t("Flows")}:</Text>
        <Box
          className={s.flowLink}
          onClick={e => {
            e.stopPropagation();
            redirectToBotFlows(info);
          }}
        >
          {info.flowCount}
        </Box>
      </Box>
      <Box className={s.flowInfo} flexDirection={"column"} alignItems={"flex-end"} justifyContent={"space-between"}>
        {!canAddFlow ? (
          <LimitStarPlanButton
            buttonName="New Flow"
            keyPrefix="botList"
            popoverBodyDescription="Please upgrade your plan to create more Flows"
            variant="dominoDashedViolet"
            dataPw="new-flow-button"
            iconFill={"white"}
            iconStroke="#6d5bf7"
          />
        ) : (
          <Button
            variant="dominoDashedViolet"
            onClick={e => {
              e.stopPropagation();
              createNewFlow(info);
            }}
            data-pw="new-flow-button"
          >
            <Flex gap={"8px"} align={"center"}>
              <PlusIcon />
              {t("New Flow")}
            </Flex>
          </Button>
        )}
      </Box>
    </Box>
  );
};
