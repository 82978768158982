import { DIVIDER_BETWEEN_WORDS, TAG_SYMBOL } from "../consts";
import { Selection } from "../types";

interface ReturnValue {
  tagFill: string;
  isTagSelected: boolean;
}

export function checkTagSelected(selection: Selection): ReturnValue | undefined {
  if (!selection.selection) return;

  const path = selection.selection.anchor.path;
  const offset = selection.selection.anchor.offset;

  const element = selection.children[path[0]];
  if (!("children" in element)) return;

  const leaf = element.children[path[1]];
  if ("type" in leaf) return;
  const splittedLeaf = leaf.text.split(DIVIDER_BETWEEN_WORDS);

  let currentOffset = 0;
  let tagSelected = false;
  let tag = "";
  for (let i = 0; i < splittedLeaf.length; i++) {
    if (i > 0 && splittedLeaf[i] !== "") {
      currentOffset++;
    }

    const currItem = splittedLeaf[i];

    tagSelected = currItem.startsWith(TAG_SYMBOL) && currentOffset < offset && offset <= currentOffset + currItem.length;
    if (tagSelected) {
      tag = currItem;
      break;
    }
    currentOffset += currItem.length;
  }

  return { tagFill: tag, isTagSelected: tagSelected };
}
