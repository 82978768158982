import { Box, Button, Flex, Menu, MenuButton, MenuList, Text } from "@chakra-ui/react";
import ArrowDropIcon from "../../../assets/icons/arrowDrop.svg?react";
import CloseIcon from "../../../assets/icons/cross.svg?react";
import s from "./Select.module.scss";
import { CustomMenuItem } from "../../molecules/customMenuItem/CustomMenuItem";
export interface SelectProps {
  isDisabled?: boolean;
  value: string;
  placeholder?: string;
  options: string[];
  translattedOptions?: Record<string, string>; // Keys must be options;
  onChange: (option: string) => void;
  shownItems?: number;
  canBeCleaned?: boolean;
}

const ITEM_HEIGHT = 36;
const LIST_PADDINGS = 8;

export const Select = ({
  isDisabled,
  value,
  placeholder,
  options,
  translattedOptions,
  onChange,
  shownItems,
  canBeCleaned = false,
}: SelectProps) => {
  const heightLimiter = shownItems ? LIST_PADDINGS + ITEM_HEIGHT * shownItems : undefined;

  return (
    <Menu variant="dominoBroadcastMenu" matchWidth>
      {({ isOpen, onClose }) => (
        <>
          <MenuButton
            as={Button}
            variant="dominoDefaultMenuBtn"
            _active={{ borderColor: "mainPurple", borderBottom: "mainPurple", borderRadius: "8px" }}
            rightIcon={
              canBeCleaned && value ? (
                <Box className={s.boxcrossIcon}>
                  <CloseIcon
                    className={s.crossIcon}
                    onClick={e => {
                      e.stopPropagation();
                      onChange("");
                    }}
                    data-pw="clean"
                  />
                </Box>
              ) : (
                <ArrowDropIcon />
              )
            }
            isDisabled={isDisabled}
            _disabled={{
              cursor: "not-allowed",
              borderColor: "line",
            }}
            data-pw="select"
          >
            {value ? translattedOptions ? translattedOptions[value] : value : <Text color="darkGrey">{placeholder}</Text>}
          </MenuButton>
          <MenuList maxH={heightLimiter} overflowY="auto" minWidth={0}>
            {options.map((option, index) => (
              <CustomMenuItem
                p="8px 16px"
                key={option + index}
                onClick={() => {
                  onChange(option);
                  onClose();
                }}
                data-pw={translattedOptions ? translattedOptions[option] : option}
              >
                <Flex align="center" w="100%">
                  <Text color="newBlack" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                    {translattedOptions ? translattedOptions[option] : option}
                  </Text>
                </Flex>
              </CustomMenuItem>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  );
};
