import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import PlusIcon from "../../../../assets/icons/plusIconTile.svg?react";
import { LimitStarPlanButton } from "../../../../UI/molecules/limitStarPlanButton/LimitStarPlanButton";
import { selectCurrentBot } from "../../../sidebar/SidebarSlice";
import { selectAIAssistantList } from "../../AIAssistantListSlice";
import { ICountTariffPlanFeature, TariffPlanFeatureTypeEnum } from "../../../../common/AppEnums";
import { useAppSelector } from "../../../../common/state/store";
import { useDocumentTitle } from "../../../../common/hooks/useDocumentTitle";
import { NewAIAssistantModal } from "../newAIAssistantModal/NewAIAssistantModal";
import s from "./AIAssistantListHeader.module.scss";
import { useSelectLimitPlan } from "../../../../common/hooks/useSelectLimitPlan";

export const AIAssistantListHeader = () => {
  const { t } = useTranslation("translation", { keyPrefix: "AI-assistant" });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const currentBot = useAppSelector(selectCurrentBot);
  const aiAssistantList = useAppSelector(selectAIAssistantList);
  const aiAssistantsCount = aiAssistantList?.length;
  const hasAIAssistant = !!(aiAssistantList && aiAssistantList.length);
  const limitInfo = useSelectLimitPlan(TariffPlanFeatureTypeEnum.AiAgentCount);
  const { IsUnlimited, MaxCount, isLoaded } = limitInfo as ICountTariffPlanFeature;

  const isOverLimit = !IsUnlimited && MaxCount <= Number(aiAssistantsCount);

  useDocumentTitle(t("AI Assistant"), "AI Assistant");

  return (
    <Flex className={s.flexContainer}>
      <Box className={s.headerContainer}>
        <Box className={s.main}>
          <Text variant="h2" color="midDeepBlue" className={s.container_title}>
            {t("AI Assistant")}
          </Text>
        </Box>
        {currentBot && hasAIAssistant && (
          <>
            {!isLoaded || isOverLimit ? (
              <LimitStarPlanButton
                buttonName="Add Assistant"
                keyPrefix="AI-assistant"
                popoverBodyDescription="Please upgrade your plan to add more AI Assistant"
                dataPw="addAssistantPopover"
                variant="dominoViolet"
                iconFill="white"
              />
            ) : (
              <Button gap="8px" size="sm" variant="dominoViolet" onClick={onOpen} data-pw="add-assistant">
                <PlusIcon />
                {t("Add Assistant")}
              </Button>
            )}
          </>
        )}
      </Box>
      <NewAIAssistantModal newAIAssistantState={isOpen} onClose={onClose} />
    </Flex>
  );
};
