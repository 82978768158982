import { FilterInfo } from "../../_components/FilterSettings/components/FilterInfo/FilterInfo";
import { FilterRules } from "../../_components/FilterSettings/components/FilterRules/FilterRules";
import { FilterButtons } from "../../_components/FilterSettings/components/FilterButtons/FilterButtons";
import { FilterSettings } from "../../_components/FilterSettings/FilterSettings";
import { useEffect, useState } from "react";
import { FilterRule } from "../../_types/FilterRule";
import { useAppDispatch, useAppSelector } from "../../../../../../../../common/state/store";
import { FilterProhibitedTopics } from "../../_components/FilterSettings/components/FilterProhibitedTopics/FilterProhibitedTopics";
import { FilterSwitchableList } from "../../_components/FilterSettings/components/FilterSwitchableList/FilterSwitchableList";
import { useTranslation } from "react-i18next";
import { antispamFilterButtonsHandlers } from "../../_components/FilterSettings/handlers";
import { isFilterEqual } from "../../_components/FilterSettings/utils";
import {
  ANTISPAM_FILTER_DEFAULT,
  CUSTOM_SPLITTER,
  MAXIMAL_RESTRICTION_COUNT,
  MINIMAL_SCREEN_SIZE_FOR_DEFAULT_LAYOUT,
} from "../../_components/FilterSettings/consts";
import { selectAntispamFilter, selectAntispamFilterIsLoadedData } from "../AntispamFilterSlice";
import { useGetLoadingState } from "../../../../../../../../common/loading/hooks/useGetLoadingState";
import {
  PhraseWhiteList,
  UrlWhiteList,
  UsersWhiteListNotification,
  ViolationNotification,
} from "../../_types/AntispamFilterTypes";
import { TooltipWithTouch } from "../../../../../../../../common/tooltipWithTouch/TooltipWithTouch";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { Box } from "@chakra-ui/react";
import { selectCurrentBot } from "../../../../../../../sidebar/SidebarSlice";
import { useNowWidthView } from "../../../../../../../layout/LayoutHelper/ResolutionHooks";

export const AntispamFilterSettings = () => {
  const dispatch = useAppDispatch();
  const antispamFilter = useAppSelector(selectAntispamFilter);
  const isLoadData = useAppSelector(selectAntispamFilterIsLoadedData);
  const isSaveChangesLoading = useGetLoadingState("antispamFilter/saveChanges");
  const currentBot = useAppSelector(selectCurrentBot);
  const nowWidth = useNowWidthView();
  const { t } = useTranslation("translation", {
    keyPrefix: "groupChat.groupChatInfo.ProfanityAndAntispamFilterPage",
  });

  useEffect(() => {
    setRules(structuredClone(antispamFilter.rules));
    setProhibitedTopics(antispamFilter.prohibitedTopics);
    setViolationNotification({
      tgUsernames: antispamFilter.violationNotificationSettings.tgUsernames.join(CUSTOM_SPLITTER),
      isEnabled: antispamFilter.violationNotificationSettings.isEnabled,
    });
    setUsersWhiteList({
      tgUsernames: antispamFilter.tgUsersWhiteListSettings.tgUsernames.join(CUSTOM_SPLITTER),
      isEnabled: antispamFilter.tgUsersWhiteListSettings.isEnabled,
    });
    setUrlWhiteList({
      urls: antispamFilter.urlWhiteListSettings.urls.join(CUSTOM_SPLITTER),
      isEnabled: antispamFilter.urlWhiteListSettings.isEnabled,
    });
    setPhraseWhiteList({
      phrases: antispamFilter.phraseWhiteListSettings.phrases.join(CUSTOM_SPLITTER),
      isEnabled: antispamFilter.phraseWhiteListSettings.isEnabled,
    });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [antispamFilter]);

  const [rules, setRules] = useState<FilterRule[]>(structuredClone(antispamFilter.rules));
  const [prohibitedTopics, setProhibitedTopics] = useState<string[]>(antispamFilter.prohibitedTopics);
  const [violationNotification, setViolationNotification] = useState<ViolationNotification>({
    tgUsernames: "",
    isEnabled: false,
  });
  const [usersWhiteList, setUsersWhiteList] = useState<UsersWhiteListNotification>({ tgUsernames: "", isEnabled: false });
  const [urlWhiteList, setUrlWhiteList] = useState<UrlWhiteList>({ urls: "", isEnabled: false });
  const [phraseWhiteList, setPhraseWhiteList] = useState<PhraseWhiteList>({ phrases: "", isEnabled: false });
  const [isInvalidPhraseWhiteList, setIsInvalidPhraseWhiteList] = useState<boolean>(false);
  const [isInvalidViolationNotification, setIsInvalidViolationNotification] = useState<boolean>(false);
  const [isInvalidUsersWhiteList, setIsInvalidUsersWhiteList] = useState<boolean>(false);
  const [isInvalidLinkWhitelist, setIsInvalidLinkWhitelist] = useState<boolean>(false);
  const [isInvalidProhibitedTopics, setIsInvalidProhibitedTopics] = useState<boolean>(false);

  const [isRulesWasChanged, setIsRulesWasChanged] = useState<boolean>(false);
  const [isProhibitedTopicsWasChanged, setIsProhibitedTopicsWasChanged] = useState<boolean>(false);
  const [isViolationNotificationWasChanged, setIsViolationNotificationWasChanged] = useState<boolean>(false);
  const [isUsersWhiteListWasChanged, setIsUsersWhiteListWasChanged] = useState<boolean>(false);
  const [isUrlWhiteListWasChanged, setIsUrlWhiteListWasChanged] = useState<boolean>(false);
  const [isPhraseWhiteListWasChanged, setIsPhraseWhiteListWasChanged] = useState<boolean>(false);
  const [wasReset, setWasReset] = useState<boolean>(false);

  useEffect(() => {
    setIsRulesWasChanged(!isFilterEqual.rules(antispamFilter.rules, rules));
  }, [antispamFilter.rules, rules]);

  useEffect(() => {
    setIsProhibitedTopicsWasChanged(!isFilterEqual.prohibitedTopics(antispamFilter.prohibitedTopics, prohibitedTopics));
  }, [antispamFilter.prohibitedTopics, prohibitedTopics]);

  useEffect(() => {
    setIsViolationNotificationWasChanged(
      !isFilterEqual.violationNotification(antispamFilter.violationNotificationSettings, violationNotification),
    );
  }, [antispamFilter.violationNotificationSettings, violationNotification]);

  useEffect(() => {
    setIsUsersWhiteListWasChanged(!isFilterEqual.usersWhiteList(antispamFilter.tgUsersWhiteListSettings, usersWhiteList));
  }, [antispamFilter.tgUsersWhiteListSettings, usersWhiteList]);

  useEffect(() => {
    setIsUrlWhiteListWasChanged(!isFilterEqual.urlWhiteList(antispamFilter.urlWhiteListSettings, urlWhiteList));
  }, [antispamFilter.urlWhiteListSettings, urlWhiteList]);

  useEffect(() => {
    setIsPhraseWhiteListWasChanged(!isFilterEqual.phraseWhiteList(antispamFilter.phraseWhiteListSettings, phraseWhiteList));
  }, [antispamFilter.phraseWhiteListSettings, phraseWhiteList]);

  const wasChanges =
    isRulesWasChanged ||
    isProhibitedTopicsWasChanged ||
    isViolationNotificationWasChanged ||
    isUsersWhiteListWasChanged ||
    isUrlWhiteListWasChanged ||
    isPhraseWhiteListWasChanged ||
    wasReset;

  useEffect(() => {
    if (!isLoadData) {
      setIsRulesWasChanged(false);
      setIsProhibitedTopicsWasChanged(false);
      setIsViolationNotificationWasChanged(false);
      setIsUsersWhiteListWasChanged(false);
      setIsUrlWhiteListWasChanged(false);
      setIsPhraseWhiteListWasChanged(false);
    }
  }, [isLoadData]);

  const buttonsHandlers = antispamFilterButtonsHandlers({
    antispamFilter,
    rules,
    setRules,
    prohibitedTopics,
    setProhibitedTopics,
    violationNotification,
    setViolationNotification,
    usersWhiteList,
    setUsersWhiteList,
    urlWhiteList,
    setUrlWhiteList,
    phraseWhiteList,
    setPhraseWhiteList,
    setWasReset,
    setIsInvalidViolationNotification,
    setIsInvalidUsersWhiteList,
    setIsInvalidLinkWhitelist,
    setIsInvalidPhraseWhiteList,
    setIsInvalidProhibitedTopics,
    dispatch,
  });

  const onSwitchViolationNotifications = () => {
    setViolationNotification({ ...violationNotification, isEnabled: !violationNotification.isEnabled });
  };
  const onSwitchApprovedLinks = () => {
    setUsersWhiteList({ ...usersWhiteList, isEnabled: !usersWhiteList.isEnabled });
  };
  const onSwitchLinkWhitelist = () => {
    setUrlWhiteList({ ...urlWhiteList, isEnabled: !urlWhiteList.isEnabled });
  };
  const onSwitchWhitelistWords = () => {
    setPhraseWhiteList({ ...phraseWhiteList, isEnabled: !phraseWhiteList.isEnabled });
  };

  const setViolationNotifications = (value: string) => {
    setIsInvalidViolationNotification(false);
    setViolationNotification({ ...violationNotification, tgUsernames: value });
  };
  const setApprovedLinks = (value: string) => {
    setIsInvalidUsersWhiteList(false);
    setUsersWhiteList({ ...usersWhiteList, tgUsernames: value });
  };
  const setLinkWhitelist = (value: string) => {
    setIsInvalidLinkWhitelist(false);
    setUrlWhiteList({ ...urlWhiteList, urls: value });
  };
  const setWhitelistWords = (value: string) => {
    setIsInvalidPhraseWhiteList(false);
    setPhraseWhiteList({ ...phraseWhiteList, phrases: value });
  };

  const isDisabledSaveButton =
    !wasChanges ||
    isSaveChangesLoading ||
    isInvalidProhibitedTopics ||
    isInvalidViolationNotification ||
    isInvalidUsersWhiteList ||
    isInvalidLinkWhitelist ||
    isInvalidPhraseWhiteList;
  const isDisabledResetButton =
    wasChanges ||
    (isFilterEqual.rules(antispamFilter.rules, ANTISPAM_FILTER_DEFAULT.rules) &&
      isFilterEqual.prohibitedTopics(antispamFilter.prohibitedTopics, ANTISPAM_FILTER_DEFAULT.prohibitedTopics) &&
      isFilterEqual.violationNotification(antispamFilter.violationNotificationSettings, {
        tgUsernames: ANTISPAM_FILTER_DEFAULT.violationNotificationSettings.tgUsernames.join(CUSTOM_SPLITTER),
        isEnabled: ANTISPAM_FILTER_DEFAULT.violationNotificationSettings.isEnabled,
      }) &&
      isFilterEqual.usersWhiteList(antispamFilter.tgUsersWhiteListSettings, {
        tgUsernames: ANTISPAM_FILTER_DEFAULT.tgUsersWhiteListSettings.tgUsernames.join(CUSTOM_SPLITTER),
        isEnabled: ANTISPAM_FILTER_DEFAULT.tgUsersWhiteListSettings.isEnabled,
      }) &&
      isFilterEqual.urlWhiteList(antispamFilter.urlWhiteListSettings, {
        urls: ANTISPAM_FILTER_DEFAULT.urlWhiteListSettings.urls.join(CUSTOM_SPLITTER),
        isEnabled: ANTISPAM_FILTER_DEFAULT.urlWhiteListSettings.isEnabled,
      }) &&
      isFilterEqual.phraseWhiteList(antispamFilter.phraseWhiteListSettings, {
        phrases: ANTISPAM_FILTER_DEFAULT.phraseWhiteListSettings.phrases.join(CUSTOM_SPLITTER),
        isEnabled: ANTISPAM_FILTER_DEFAULT.phraseWhiteListSettings.isEnabled,
      }));

  const getViolationNotificationsTitle = () => {
    return (
      <>
        {t("Violation notifications")}
        <Box display="inline" verticalAlign="middle">
          <TooltipWithTouch
            variant="dominoLight"
            display="inline-flex"
            label={
              <Box
                dangerouslySetInnerHTML={{
                  __html: t("Selected participants must be subscribed to bot", { botName: currentBot?.name }),
                }}
              />
            }
          >
            <QuestionOutlineIcon ml="5px" boxSize="16px" color="darkGrey" _hover={{ color: "darkPurple" }} />
          </TooltipWithTouch>
        </Box>
      </>
    );
  };

  const blocks = [
    <FilterInfo
      key="AntispamFilterInfo"
      title={t("To prevent ads and unwanted topics in groups")}
      description={t("The bot will delete messages containing prohibited topics", { count: MAXIMAL_RESTRICTION_COUNT })}
    />,
    <FilterRules key="AntispamFilterRules" rules={rules} setRules={setRules} />,
    <FilterProhibitedTopics
      key="AntispamFilterProhibitedTopics"
      prohibitedTopics={prohibitedTopics}
      setProhibitedTopics={setProhibitedTopics}
      isInvalidProhibitedTopics={isInvalidProhibitedTopics}
      setIsInvalidProhibitedTopics={setIsInvalidProhibitedTopics}
    />,
    <FilterSwitchableList
      key="AntispamFilterViolationNotifications"
      title={getViolationNotificationsTitle()}
      description={t("Violation notifications for admins")}
      isEnabled={violationNotification.isEnabled}
      onChangeSwitch={onSwitchViolationNotifications}
      isDisabled={false}
      list={violationNotification.tgUsernames}
      setList={setViolationNotifications}
      isInvalid={isInvalidViolationNotification}
      invalidText={t("Enter username separated by commas and spaces")}
      placeholderText={t("Enter username separated by commas and spaces")}
    />,
    <FilterSwitchableList
      key="AntispamFilterApprovedLinks"
      title={t("Approved links")}
      description={t("Users exempt from checks")}
      isEnabled={usersWhiteList.isEnabled}
      onChangeSwitch={onSwitchApprovedLinks}
      isDisabled={false}
      list={usersWhiteList.tgUsernames}
      setList={setApprovedLinks}
      isInvalid={isInvalidUsersWhiteList}
      invalidText={t("Enter username separated by commas and spaces")}
      placeholderText={t("Enter username separated by commas and spaces")}
    />,
    <FilterSwitchableList
      key="AntispamFilterLinkWhitelist"
      title={t("Link whitelist", {
        lineBreak: nowWidth >= MINIMAL_SCREEN_SIZE_FOR_DEFAULT_LAYOUT ? "\u2028" : "",
      })}
      description={t("Links exempt from blocking")}
      isEnabled={urlWhiteList.isEnabled}
      onChangeSwitch={onSwitchLinkWhitelist}
      isDisabled={false}
      list={urlWhiteList.urls}
      setList={setLinkWhitelist}
      isInvalid={isInvalidLinkWhitelist}
      invalidText={t("Enter links separated by commas and spaces")}
      placeholderText={t("Enter links separated by commas and spaces")}
    />,
    <FilterSwitchableList
      key="AntispamFilterWhitelistWords"
      title={t("Whitelist of phrases or words", {
        lineBreak: nowWidth >= MINIMAL_SCREEN_SIZE_FOR_DEFAULT_LAYOUT ? "\u2028\u2028" : "",
      })}
      description={t("Allows specific words or phrases to be excluded from anti-spam checks")}
      isEnabled={phraseWhiteList.isEnabled}
      onChangeSwitch={onSwitchWhitelistWords}
      isDisabled={false}
      list={phraseWhiteList.phrases}
      setList={setWhitelistWords}
      isInvalid={isInvalidPhraseWhiteList}
      invalidText={t("Enter words or phrases separated by commas and spaces")}
      placeholderText={t("Enter words or phrases separated by commas and spaces")}
    />,
    <FilterButtons
      key="AntispamFilterButtons"
      wasChanges={wasChanges}
      buttonsHandlers={buttonsHandlers}
      isDisabledResetButton={isDisabledResetButton}
      isDisabledSaveButton={isDisabledSaveButton}
    />,
  ];

  return <FilterSettings blocks={blocks} />;
};
