import { IconButton, Menu, MenuButton, MenuList, Tooltip } from "@chakra-ui/react";
import s from "./ConditionToFilterMenu.module.scss";
import CustomFilterIcon from "../../../../assets/icons/customFilterIcon.svg?react";
import { FieldParamModel, FilterParamTypes, GroupItemModel, MenuButtonInfo, MenuButtonTypes } from "../../ComplexFilterModel";
import { CustomMenuItem } from "../../../../UI/molecules/customMenuItem/CustomMenuItem";
import { useEffect, useState } from "react";

interface Props {
  filterFields: GroupItemModel[];
  onSetFilterParam: (filterParamType: FilterParamTypes, fieldParamValue: FieldParamModel, id: string) => void;
  menuButtonProps: MenuButtonInfo;
  menuStyle?: string;
  id: string;
}

export const ConditionToFilterMenu = (props: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (!isMenuOpen && !props.menuButtonProps.title) setIsMenuOpen(true);
    // eslint-disable-next-line
  }, []);

  return (
    <Menu
      gutter={15}
      variant="dominoGroupedMenu"
      placement={props.menuButtonProps.buttonType === MenuButtonTypes.Icon ? "left" : "bottom"}
      isOpen={isMenuOpen}
      onClose={() => setIsMenuOpen(false)}
    >
      {props.menuButtonProps.buttonType === MenuButtonTypes.Icon ? (
        <MenuButton
          as={IconButton}
          disabled={props.menuButtonProps.isMenuButtonDisabled}
          icon={<CustomFilterIcon />}
          aria-label="Options"
          variant="dominoFillIconButton"
          boxSize="24px"
          mt="16px"
          data-pw="condition-to-filter-menu"
        />
      ) : (
        <MenuButton
          type="button"
          aria-label="Titles"
          _active={{
            backgrounColor: "white",
            borderColor: "blueLink",
            color: "mainPurple",
          }}
          className={s.triggerLink}
          data-pw="condition-to-filter-menu"
          onClick={() => setIsMenuOpen(true)}
        >
          {props.menuButtonProps.title?.length ? props.menuButtonProps.title : <pre>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</pre>}
        </MenuButton>
      )}

      {isMenuOpen && (
        <MenuList className={`${s.addVariableModal} ${props.menuStyle === "conditionNode" && s.conditionNodeVariableModal}`}>
          {props.filterFields.map((elem, index) => {
            return (
              <CustomMenuItem
                key={index}
                onClick={() => {
                  setIsMenuOpen(false);
                  props.onSetFilterParam(
                    FilterParamTypes.condition,
                    {
                      title: elem.fieldName,
                      value: elem.fieldValue,
                    },
                    props.id,
                  );
                }}
                data-pw={elem.fieldName}
              >
                <Tooltip placement="top" label={elem.description} fontSize="md">
                  {elem.fieldName}
                </Tooltip>
              </CustomMenuItem>
            );
          })}
        </MenuList>
      )}
    </Menu>
  );
};
