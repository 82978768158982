import { getBotMenuOnboardingStep } from "./toursConstants";
import { OnboardingToursContainer } from "./OnboardingToursContainer";
import { TourNames } from "./OnboardingTourEnums";
import { useTranslation } from "react-i18next";
import { EventCategories } from "../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import { useAppSelector } from "../../common/state/store";
import { selectSwitchElement } from "./OnboardingToursSlice";

const tooltipWidth = "328px";

export const BotMenuCommandOnboardingTour = () => {
  const { t } = useTranslation("translation", { keyPrefix: "onboardingTour" });
  const targetSwitchElement = useAppSelector(selectSwitchElement);
  const steps = getBotMenuOnboardingStep(t, targetSwitchElement);

  return (
    <OnboardingToursContainer
      currentTourName={TourNames.BotMenuTourName}
      tourLevelNameForGAEvent={EventCategories.OnboardingBotMenuCommandTrigger}
      steps={steps}
      tooltipWidth={tooltipWidth}
    />
  );
};
