import { Box, Flex, Input, InputGroup, Switch, Text } from "@chakra-ui/react";

interface Props {
  title: React.ReactNode;
  description: string;
  isEnabled: boolean;
  onChangeSwitch: () => void;
  isDisabled: boolean;
  list: string;
  setList: (list: string) => void;
  isInvalid: boolean;
  invalidText: string;
  placeholderText: string;
}

export const AdaptiveSwitchableListLayout = ({
  title,
  description,
  isEnabled,
  onChangeSwitch,
  isDisabled,
  list,
  setList,
  isInvalid,
  invalidText,
  placeholderText,
}: Props) => {
  return (
    <Flex direction="column" gap="2">
      <Flex direction="row" justifyContent="space-between" alignItems="center">
        <Text lineHeight="20px">{title}</Text>
        <Switch variant="dominoDefaultGreen" isChecked={isEnabled} onChange={onChangeSwitch} disabled={isDisabled} />
      </Flex>

      <InputGroup flexDirection="column" gap="1">
        <Input
          onChange={e => setList(e.target.value)}
          value={list}
          disabled={isDisabled}
          isInvalid={isInvalid}
          placeholder={placeholderText}
        />
        {isInvalid && (
          <Box color="mainRed">
            <Text minH="16px" variant="small">
              {invalidText}
            </Text>
          </Box>
        )}
      </InputGroup>

      <Box>
        <Text variant="small" color="darkGrey">
          {description}
        </Text>
      </Box>
    </Flex>
  );
};
