import { useState, useEffect } from "react";

export const useSmoothLoading = (loadingStates: boolean[], delay = 100): boolean => {
  const [isSmoothLoading, setIsSmoothLoading] = useState(true);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const isAnyLoading = loadingStates.some(state => state);

    if (!isAnyLoading) {
      timeoutId = setTimeout(() => {
        setIsSmoothLoading(false);
      }, delay);
    } else {
      setIsSmoothLoading(true);
    }

    return () => clearTimeout(timeoutId);
  }, [loadingStates, delay]);

  return isSmoothLoading;
};
