import { MenuItemProps, Box, SystemStyleObject, Flex, FlexProps } from "@chakra-ui/react";
import { ReactNode } from "react";
import s from "./CustomMenuItem.module.scss";

interface CustomMenuItemProps extends MenuItemProps {
  children: ReactNode;
  sx?: SystemStyleObject;
  iconStyle?: FlexProps;
}

export const CustomMenuItem: React.FC<CustomMenuItemProps> = ({ children, sx, iconStyle, ...props }: CustomMenuItemProps) => {
  return (
    <Box
      className={`${s.item} ${props.isDisabled && s.disabled}`}
      {...props}
      onClick={!props.isDisabled && props.onClick}
      sx={{ ...sx }}
    >
      {props.icon && (
        <Flex align="center" justify="center" {...iconStyle}>
          {props.icon}
        </Flex>
      )}
      {children}
    </Box>
  );
};
