import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../../../common/state/store";
import { useGetLoadingState } from "../../../../../../../common/loading/hooks/useGetLoadingState";
import { Box, Flex, Icon, Switch, Text } from "@chakra-ui/react";
import s from "../Tabs.module.scss";
import LeftArrow from "../../../../../../../assets/icons/arrowLeft.svg?react";
import {
  selectIsOpenAntispamFilter,
  toggleIsEnabled,
  setIsOpenAntispamFilter,
  selectIsAntispamFilterEnabled,
  selectAntispamFilterIsLoadedData,
} from "./AntispamFilterSlice";
import { AntispamFilterSettings } from "./components/AntispamFilterSettings";

export const AntispamFilterContainer = () => {
  const groupChatInfoTranslation = useTranslation("translation", { keyPrefix: "groupChat.groupChatInfo" }).t;
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectIsOpenAntispamFilter);
  const isEnabled = useAppSelector(selectIsAntispamFilterEnabled);
  const isToggleLoading = useGetLoadingState("antispamFilter/toggleLoading");
  const isLoadData = useAppSelector(selectAntispamFilterIsLoadedData);

  const onChangeSwitch = () => {
    dispatch(toggleIsEnabled(!isEnabled));
  };

  return (
    <Flex className={`${s.container} ${isOpen ? s.open : ""}`}>
      <Flex className={s.top}>
        <Flex gap="8px" align="center">
          <Icon
            onClick={() => dispatch(setIsOpenAntispamFilter(false))}
            cursor="pointer"
            color="darkGrey"
            m="4px"
            as={LeftArrow}
            boxSize="24px"
          />
          <Text variant="extraLargeBold" color="newBlack">
            {groupChatInfoTranslation("AI anti-spam for groups or channels")}
          </Text>
        </Flex>
        <Switch variant="dominoDefaultGreen" isChecked={isEnabled} onChange={onChangeSwitch} disabled={isToggleLoading} />
      </Flex>

      {isLoadData && (
        <Box className={s.tabWrapper}>
          <AntispamFilterSettings />
        </Box>
      )}
    </Flex>
  );
};
