import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import s from "./FilterButtons.module.scss";
import { useMobileView } from "../../../../../../../../../layout/LayoutHelper/ResolutionHooks";
import { ButtonsHandlersReturnedValue } from "../../handlers";

interface Props {
  wasChanges: boolean;
  buttonsHandlers: ButtonsHandlersReturnedValue;
  isDisabledResetButton: boolean;
  isDisabledSaveButton: boolean;
}

export const FilterButtons = ({ wasChanges, buttonsHandlers, isDisabledResetButton, isDisabledSaveButton }: Props) => {
  const { cancelChanges, resetToDefault, saveChanges } = buttonsHandlers;
  const t = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const isMobile = useMobileView();

  return (
    <Flex gap="8px 16px" direction={isMobile ? "column" : "row"}>
      <Flex gap="8px 16px" flex="1" direction={isMobile ? "column" : "row"}>
        <Button
          variant="dominoOutlineRed"
          w="100%"
          borderColor="var(--chakra-colors-mainRed)"
          className={`${s.cancelButton} ${wasChanges ? s.visible : ""}`}
          onClick={cancelChanges}
        >
          <Text>{t("Cancel")}</Text>
        </Button>
        <Button variant="dominoOutlineViolet" w="100%" onClick={resetToDefault} isDisabled={isDisabledResetButton}>
          <Text>{t("Reset everything")}</Text>
        </Button>
      </Flex>
      <Box flex="1">
        <Button variant="dominoViolet" w="100%" isDisabled={isDisabledSaveButton} onClick={saveChanges}>
          {t("Save")}
        </Button>
      </Box>
    </Flex>
  );
};
