import { ChangeEvent, memo, useCallback, useEffect, useRef, useState } from "react";
import { Box, Flex, Text, Checkbox, useDisclosure, Button, Skeleton } from "@chakra-ui/react";
import { BroadcastDirection, ChatGroupModel, ContactModel } from "../../BroadcastModel";
import { useTranslation } from "react-i18next";
import { selectBroadcast, selectBroadcastIsNew, setInititalDisabledButtons, setTitleStatusIsNew } from "../../BroadcastSlice";
import { ImportRecipientsModal } from "../Broadcast/importRecipient/ImportRecipientsModal";
//import { selectImportResult } from "../../../contactList/ContactListSlice";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import { InputSearch } from "../../../../UI/molecules/inputSearch/InputSearch";
import {
  ComplexFilterFieldTypes,
  ComplexFilterGroupTypes,
  ConditionsByField,
  FilterParams,
} from "../../../complexFilter/ComplexFilterModel";
import s from "./RecipientList.module.scss";
import { mapFilterArrayToQueryString } from "../../../complexFilter/ComplexFilterMapper";
import useDebounce from "../../../../common/hooks/useDebounce";
import {
  changeSelectAll,
  checkedRecipientListContact,
  clearFilter,
  clearStateRecipient,
  getChatGroupsList,
  getContactList,
  getDraftChatGroupsList,
  getDraftContactList,
  selectBroadcastDirection,
  selectFilterList,
  selectImportRecipientList,
  selectIsSelectAll,
  selectRecipientCount,
  selectRecipientList,
  setBroadcastDirection,
  setImportRecipientList,
  setIsSelectAll,
} from "./RecipientListSlice";
import { ComplexFilter } from "../../../complexFilter/ComplexFilter";
import {
  CustomVariableModel,
  CustomVariableScope,
  ICountTariffPlanFeature,
  TariffPlanFeatureTypeEnum,
} from "../../../../common/AppEnums";
import ReactDOM from "react-dom";
import { useMobileView, useNowWidthView } from "../../../layout/LayoutHelper/ResolutionHooks";
import { selectCurrentBotId } from "../../../sidebar/SidebarSlice";
import { useParams } from "react-router-dom";
import { RecipientsCounter } from "./RecipientsCounter";
import { useGetLoadingState } from "../../../../common/loading/hooks/useGetLoadingState";
import { useSelectLimitPlan } from "../../../../common/hooks/useSelectLimitPlan";
import { SkeletonRecipientsList } from "../../../../UI/organisms/SkeletonRecipientsList";

interface Props {
  broadcastRecipientsFilter?: FilterParams[];
  setBroadcastFilter?: (brodcastRecipientFilters: FilterParams[]) => void;
  variables: CustomVariableModel[] | undefined;
  isActive?: boolean;
}

export const RecipientList = memo(function RecipientList({ broadcastRecipientsFilter, variables, isActive }: Props) {
  const { t } = useTranslation("translation", { keyPrefix: "broadcast" });

  const brodcastDirection = useAppSelector(selectBroadcastDirection);
  const broadcast = useAppSelector(selectBroadcast);
  const direction = broadcast.direction;

  const isDraftForContact = direction === BroadcastDirection.TelegramContact;
  const isDraftForGroup = direction === BroadcastDirection.TelegramGroup;
  const isBroadcastForContact = brodcastDirection === BroadcastDirection.TelegramContact;
  const isBroadcastForGroup = brodcastDirection === BroadcastDirection.TelegramGroup;

  const recipientList = useAppSelector(selectRecipientList);
  const filterList = useAppSelector(selectFilterList);
  const currentBotId = useAppSelector(selectCurrentBotId);
  const count = useAppSelector(selectRecipientCount);
  const importRecipientList = useAppSelector(selectImportRecipientList);
  const tariff = useSelectLimitPlan(TariffPlanFeatureTypeEnum.BroadcastContacts) as ICountTariffPlanFeature;
  const accessibility =
    isBroadcastForGroup || isDraftForGroup
      ? { IsUnlimited: true, MaxCount: 0 }
      : { IsUnlimited: tariff.IsUnlimited, MaxCount: tariff.MaxCount };
  const { MaxCount, IsUnlimited } = accessibility;
  const isRecipientsLoading = useGetLoadingState("contactList2");

  const isSelectAll = useAppSelector(selectIsSelectAll);
  const { broadcastId } = useParams();
  const dispatch = useAppDispatch();
  const isBroadcastNew = useAppSelector(selectBroadcastIsNew);
  const [isFilterActive] = useState(false);
  const isMobile = useMobileView();

  const inputSearchPlaceholder = isBroadcastForContact ? t("Search by name") : isBroadcastForGroup ? t("Search by group") : "";
  const contactListRef = useRef<null | HTMLDivElement>(null);
  const [searchContactValue, setSearchContactValue] = useState<string>("");
  const debouncedSearchText = useDebounce(searchContactValue, 500);
  const recipientListItems = recipientList?.items;
  const isListEmpty = !recipientListItems || Object.keys(recipientListItems).length === 0;
  const filterContainer = document.querySelector("#filter-container");
  const nowWidth = useNowWidthView();
  const [prevQueryString, setPrevQueryString] = useState<{ filter: string | undefined; params: string | undefined }>({
    filter: undefined,
    params: undefined,
  });

  useEffect(() => {
    const isNewBroadcast = broadcastId === undefined;
    const queryParams = "size=20&page=1";
    dispatch(setTitleStatusIsNew(!broadcastId));

    if (currentBotId) {
      if (isNewBroadcast) {
        dispatch(clearStateRecipient());
        if (isBroadcastForContact) {
          dispatch(
            getContactList({
              botId: currentBotId,
              queryFilterParams: queryParams,
              accessability: { MaxCount: Number(MaxCount), IsUnlimited: Boolean(IsUnlimited) },
            }),
          );
        } else if (isBroadcastForGroup) {
          dispatch(getChatGroupsList({ botId: currentBotId, queryFilterParams: queryParams }));
        }
      } else if (direction) {
        if (isDraftForContact) {
          dispatch(
            getDraftContactList({
              botId: currentBotId,
              broadcastId,
              queryFilterParams: queryParams,
              accessability: { MaxCount: Number(MaxCount), IsUnlimited: Boolean(IsUnlimited) },
            }),
          );
        } else if (isDraftForGroup) {
          dispatch(getDraftChatGroupsList({ botId: currentBotId, broadcastId, queryFilterParams: queryParams }));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [broadcastId, brodcastDirection, direction, isBroadcastNew]);

  useEffect(() => {
    return () => {
      dispatch(clearStateRecipient());
      dispatch(setBroadcastDirection(BroadcastDirection.TelegramContact));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getContacts = useCallback(
    (
      queryFilterString: string | undefined,
      queryParamsString: string | undefined,
      isFilterActive: boolean,
      isOnScrollPayload?: boolean,
    ) => {
      if (currentBotId) {
        if (isFilterActive && prevQueryString.params?.startsWith("size=20&page=")) {
          prevQueryString.params = "";
        }
        let queryFilterFinalString = queryFilterString;
        let queryParamsFinalString = queryParamsString;
        if (queryFilterString === undefined) {
          queryFilterFinalString = prevQueryString.filter;
        }
        if (queryParamsString === undefined) {
          queryParamsFinalString = prevQueryString.params;
        }
        const queryString = (queryFilterFinalString ?? "") + (queryParamsFinalString ?? "");

        setPrevQueryString({ filter: queryFilterFinalString, params: queryParamsFinalString });
        if (broadcastId) {
          if (isBroadcastForContact) {
            dispatch(
              getDraftContactList({
                botId: currentBotId,
                queryFilterParams: queryString ?? "size=20&page=1",
                broadcastId,
                accessability: { MaxCount: Number(MaxCount), IsUnlimited: Boolean(IsUnlimited) },
              }),
            );
          } else if (isBroadcastForGroup) {
            dispatch(
              getDraftChatGroupsList({ botId: currentBotId, queryFilterParams: queryString ?? "size=20&page=1", broadcastId }),
            );
          }
        } else if (isBroadcastForContact) {
          dispatch(
            getContactList({
              botId: currentBotId,
              ...(queryString ? { queryFilterParams: queryString } : { queryFilterParams: "size=20&page=1" }),
              isOnScrollPayload,
              accessability: { MaxCount: Number(MaxCount), IsUnlimited: Boolean(IsUnlimited) },
            }),
          );
        } else {
          dispatch(
            getChatGroupsList({
              botId: currentBotId,
              ...(queryString ? { queryFilterParams: queryString } : { queryFilterParams: "size=20&page=1" }),
              isOnScrollPayload,
            }),
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentBotId, dispatch, prevQueryString, isBroadcastForContact],
  );

  // const pageRecipients = useAppSelector(selectScrolledRecipients);
  // const pageAllRecipients = useAppSelector(selectNonScrolledRecipients);

  //const importResult = useAppSelector(selectImportResult);
  const { isOpen, onOpen, onClose } = useDisclosure();

  //const accessability = getTariffPlanAccessability(TariffPlanFeatureTypeEnum.BroadcastContacts);
  //const { MaxCount, IsUnlimited } = accessability;
  const isEmpty = broadcastRecipientsFilter?.[0] && Object.keys(broadcastRecipientsFilter[0]).length === 0;
  useEffect(() => {
    if (debouncedSearchText !== undefined) {
      if (debouncedSearchText === "") {
        dispatch(clearFilter());
      } else {
        getContactsWithParams(debouncedSearchText);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText]);

  const onContactListChange = (e: ChangeEvent<HTMLInputElement>, id: string) => {
    dispatch(
      checkedRecipientListContact({
        contactId: id,
        isChecked: e.target.checked,
        accessibility: { MaxCount: Number(MaxCount), IsUnlimited: Boolean(IsUnlimited) },
      }),
    );
    dispatch(setInititalDisabledButtons());
  };

  useEffect(() => {
    setSearchContactValue("");
  }, [importRecipientList?.availableRecipients]);

  const toggleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setIsSelectAll({
        isChecked: e.target.checked,
        accessibility: { MaxCount: Number(MaxCount), IsUnlimited: Boolean(IsUnlimited) },
      }),
    );
    dispatch(changeSelectAll());
  };

  const createFieldsToFilter = () => {
    let contactList = [
      { fieldName: "Id", fieldValue: "id" },
      { fieldName: "Username", fieldValue: "username" },
      { fieldName: "First Name", fieldValue: "firstName" },
      { fieldName: "Last Name", fieldValue: "lastName" },
      { fieldName: "Phone Number", fieldValue: "phoneNumber" },
    ];
    contactList = contactList.map(el => {
      return {
        ...el,
        type: ComplexFilterFieldTypes.Text,
        fieldName: t(`fieldToFilter.${el.fieldName}`),
        groupType: ComplexFilterGroupTypes.ContactFields,
      };
    });

    const groupList = [{ label: t("Contacts"), items: contactList }];

    if (variables?.length) {
      const contactVariableList = variables
        ?.filter(el => ConditionsByField.allowedFieldTypes.includes(el.type) && el.scope === CustomVariableScope.Contact)
        .map(el => {
          return {
            fieldName: el.key,
            fieldValue: el.key,
            type: el.type,
            groupType: ComplexFilterGroupTypes.CustomVariables,
            description: el.description,
            id: el.id,
          };
        });
      groupList.push({ label: t("Custom Contact Fields"), items: contactVariableList });
    }

    return { groups: groupList };
  };

  const getContactsWithParams = (searchValue: string, page?: number, isOnScrollPayload?: boolean, filters?: FilterParams[]) => {
    const queryString = createQueryString(searchValue, page);
    let filterQueryString = undefined;
    if (filters && filters?.[0] && Object.keys(filters[0]).length !== 0) {
      filterQueryString = mapFilterArrayToQueryString(filters);
    }
    getContacts(filterQueryString, queryString, false, isOnScrollPayload);
  };

  const getNextPageContacts = (page: number, isOnScrollPayload?: boolean) => {
    const queryString = `size=${20}&page=${page}`;
    getContacts(undefined, queryString, false, isOnScrollPayload);
  };

  const getAdditionalContactsOnScroll = () => {
    if (importRecipientList?.recipients) return;
    if (contactListRef.current) {
      const currentScroll = contactListRef.current.scrollTop;
      const currentHeight = contactListRef.current?.clientHeight;
      const isBottomPosition = currentHeight + currentScroll > contactListRef.current?.scrollHeight - 2;

      if (isActive) {
        contactListRef.current.scrollTop = (contactListRef.current?.scrollHeight - 2) / 2;
        return;
      }

      if (isBottomPosition && recipientList) {
        if (recipientList.currentPage >= recipientList.totalPages) {
          return;
        }
        const lastHeight = (contactListRef.current?.scrollHeight - 2) / 2;

        if (searchContactValue === "" && isEmpty) {
          getNextPageContacts(recipientList.currentPage + 1, true);
        } else {
          getContactsWithParams(searchContactValue ?? "", recipientList.currentPage + 1, true, broadcastRecipientsFilter);
        }
        if (!isFilterActive) {
          contactListRef.current.scrollTop = lastHeight;
        }
        return;
      }
    }
  };

  const setSearchText = (value: string) => {
    setSearchContactValue(value);
  };

  const createQueryString = (searchValue: string, page?: number) => {
    const searchParams = searchValue || "" ? `&filter=${searchValue}` : "";
    const pageParams = `size=${20}&page=${page ?? 1}`;
    if (searchParams && pageParams && !isFilterActive) {
      return `&${pageParams}${searchParams}`;
    }
    if (pageParams && !searchParams) {
      return `&${pageParams}`;
    }
    if (searchParams && pageParams) {
      return `${searchParams}&${pageParams}`;
    }
    return "";
  };

  const getContactTitle = (contact: ContactModel | ChatGroupModel) => {
    if ("title" in contact) {
      const groupContact = contact as ChatGroupModel;
      return groupContact.title;
    } else {
      const currentContact = contact as ContactModel;

      if (currentContact.firstName || currentContact.lastName) {
        return `${currentContact.firstName ?? ""} ${currentContact.lastName ?? ""}`;
      } else if (currentContact.username) {
        return currentContact.username;
      } else {
        return currentContact.externalId;
      }
    }
  };
  // function recipientsFilterUnique(array: RecipientFormModel[]) {
  //   const cloneArray = array.concat();
  //   for (let i = 0; i < cloneArray.length; ++i) {
  //     for (let j = i + 1; j < cloneArray.length; ++j) {
  //       if (cloneArray[i].contactId === cloneArray[j].contactId) cloneArray.splice(j--, 1);
  //     }
  //   }
  //   return cloneArray;
  // }

  useEffect(() => {
    setSearchText("");
  }, [brodcastDirection]);

  const handleCancelClick = () => {
    dispatch(setImportRecipientList({ blocked: null, availableRecipients: null, recipients: null }));
  };

  const isChecked = (contact: ContactModel) => {
    if (IsUnlimited) {
      if (isSelectAll) {
        return isSelectAll;
      } else {
        return contact.isSelected;
      }
    } else {
      if (isSelectAll) {
        return contact.isSelected;
      } else {
        return contact.isSelected;
      }
    }
  };

  const isDisabled = (contact: ContactModel) => {
    if (isBroadcastNew && isSelectAll) return true;
    if (IsUnlimited) {
      return false;
    } else {
      if (isSelectAll) {
        return !contact.isSelected;
      } else {
        if (count >= Number(MaxCount)) {
          return !contact.isSelected;
        } else {
          return false;
        }
      }
    }
  };

  return (
    <Box className={s.broadastContactList}>
      {!recipientListItems ? (
        <>
          <Box className={`${s.broadastContactListHeader} ${s.broadastContactListGrid}`}>
            <Box display="flex" alignItems="center">
              <Skeleton
                width={"20px"}
                height={"20px"}
                mr={"6px"}
                speed={0.5}
                borderRadius="4px"
                startColor="line"
                endColor="bgLight"
              />
              <Skeleton width={"85px"} height={"22px"} speed={0.5} borderRadius="12px" startColor="line" endColor="bgLight" />
            </Box>

            {!isMobile ? (
              <Skeleton
                width={"200px"}
                height={"36px"}
                ml="auto"
                speed={0.5}
                borderRadius="12px"
                startColor="line"
                endColor="bgLight"
              />
            ) : (
              <Skeleton width={"100%"} height={"36px"} speed={0.5} borderRadius="12px" startColor="line" endColor="bgLight" />
            )}
          </Box>

          <SkeletonRecipientsList count={5} />

          <Box display="flex" alignItems="center" className={`${s.broadastContactListFooter}`}>
            <Skeleton height="20px" width="35%" speed={0.5} borderRadius="12px" startColor="line" endColor="bgLight" />
            <Skeleton height="36px" width="50%" speed={0.5} borderRadius="12px" startColor="line" endColor="bgLight" />
          </Box>
        </>
      ) : !isListEmpty ? (
        <>
          <Box className={`${s.broadastContactListHeader} ${s.broadastContactListGrid}`}>
            {importRecipientList?.recipients ? (
              <Checkbox
                data-pw="contact-list-select-all"
                cursor="not-allowed"
                isChecked={true}
                variant="dominoGreen"
                isDisabled
                sx={{
                  ".chakra-checkbox__control[data-disabled]": {
                    bg: "blue",
                    color: "red",
                    bgColor: "white",
                  },
                }}
                p="4px 0 4px"
                mx="4px"
              >
                <Text color="black">{t("Selected from file")}</Text>
              </Checkbox>
            ) : (
              <Checkbox
                data-pw="contact-list-select-all"
                cursor="pointer"
                isDisabled={Boolean(searchContactValue)}
                isChecked={isSelectAll}
                onChange={toggleSelectAll}
                variant="dominoGreen"
                p="4px 0 4px"
                mx="4px"
              >
                <Text color="black">{t("Select all")}</Text>
              </Checkbox>
            )}
            <InputSearch
              placeholder={inputSearchPlaceholder}
              setSearchText={setSearchText}
              searchText={searchContactValue}
              isDisabled={importRecipientList?.availableRecipients ? true : false}
            />
            {/* заменить 0 на 800 */}
            {filterContainer &&
              nowWidth < 0 &&
              ReactDOM.createPortal(
                <ComplexFilter
                  //placement={isMobile ? "bottom-end" : undefined}
                  // classNameIcon={
                  //   !IsUnlimited && contactList?.totalItems && contactList?.totalItems > MaxCount ? s.iconFilterTarif : s.iconFilter
                  // }
                  filterFields={createFieldsToFilter()}
                  getItemsWithFilter={getContacts}
                  isActive={true}
                  // setIsActive={setFilterActive}
                  componentSlice="Broadcast"
                  //broadcastRecipientsFilters={broadcastRecipientsFilter}
                  //setBroadcastFilter={setBroadcastFilter}
                />,
                filterContainer,
              )}
          </Box>
          <Box ref={contactListRef} onScroll={getAdditionalContactsOnScroll} className={`${s.broadastContactListScroll}`}>
            {isRecipientsLoading ? (
              <SkeletonRecipientsList count={5} />
            ) : importRecipientList?.recipients ? (
              importRecipientList?.recipients?.map(contact => {
                return (
                  <Box key={contact.contactId} className={`${s.broadastContactListFlex}`}>
                    <Checkbox
                      id={`checkbox_${contact.contactId}`}
                      data-pw="contact-list-select-item"
                      variant="dominoGreen"
                      p="4px 8px"
                      width="100%"
                      isChecked
                      isDisabled
                      sx={{
                        ".chakra-checkbox__control[data-disabled]": {
                          bg: "blue",
                          color: "red",
                          bgColor: "white",
                        },
                      }}
                    >
                      <Text data-pw="contact-list-username">
                        {contact.name}
                        {contact.username && <span className={s.contactUserName}>{` @${contact.username}`}</span>}
                      </Text>
                    </Checkbox>
                  </Box>
                );
              })
            ) : (
              <>
                {filterList ? (
                  <>
                    {filterList.items &&
                      Object.keys(filterList.items)?.map((recipientId, index) => {
                        const contact = filterList.items[recipientId];
                        return (
                          <Box key={recipientId} className={`${s.broadastContactListFlex}`}>
                            <Checkbox
                              id={`checkbox_${recipientId}`}
                              data-pw="contact-list-select-item"
                              variant="dominoGreen"
                              p="4px 8px"
                              width="100%"
                              _hover={{ backgroundColor: "defaultGrey" }}
                              isInvalid={false}
                              isReadOnly={false}
                              isChecked={isChecked(contact)}
                              isDisabled={isDisabled(contact)}
                              onChange={e => onContactListChange(e, recipientId)}
                              sx={{
                                ".chakra-checkbox__control[data-disabled]": {
                                  bg: "blue",
                                  color: "red",
                                  bgColor: "white",
                                },
                              }}
                            >
                              <Text data-pw={contact.username}>
                                {getContactTitle(contact)}
                                {contact.username && <span className={s.contactUserName}>{` @${contact.username}`}</span>}
                              </Text>
                            </Checkbox>
                          </Box>
                        );
                      })}
                  </>
                ) : (
                  <>
                    {recipientListItems &&
                      Object.keys(recipientListItems)?.map((recipientId, index) => {
                        const contact = recipientListItems[recipientId];
                        return (
                          <Box key={recipientId} className={`${s.broadastContactListFlex}`}>
                            <Checkbox
                              id={`checkbox_${recipientId}`}
                              data-pw="contact-list-select-item"
                              variant="dominoGreen"
                              p="4px 8px"
                              width="100%"
                              _hover={{ backgroundColor: "defaultGrey" }}
                              isInvalid={false}
                              isReadOnly={false}
                              isChecked={isChecked(contact)}
                              isDisabled={isDisabled(contact)}
                              onChange={e => onContactListChange(e, recipientId)}
                              sx={{
                                ".chakra-checkbox__control[data-disabled]": {
                                  bg: "blue",
                                  color: "red",
                                  bgColor: "white",
                                },
                              }}
                            >
                              <Text data-pw={contact.username}>
                                {getContactTitle(contact)}
                                {contact.username && <span className={s.contactUserName}>{` @${contact.username}`}</span>}
                              </Text>
                            </Checkbox>
                          </Box>
                        );
                      })}
                  </>
                )}
              </>
            )}
          </Box>
          <Box className={`${s.broadastContactListFooter} {values.isRecipient && !values.recipients.length && s.invalid}`}>
            <Flex wrap={"wrap"} gap={"4px"}>
              <Text>
                <span data-pw="total-contacts-count">
                  {importRecipientList?.availableRecipients
                    ? importRecipientList?.availableRecipients
                    : filterList
                    ? filterList?.totalItems
                    : recipientList?.totalItems}{" "}
                </span>
                {`${isFilterActive || searchContactValue ? t("Found") : t("Total")},`}
              </Text>
              <RecipientsCounter />
            </Flex>
            <>
              {isBroadcastForContact &&
                (!importRecipientList?.recipients ? (
                  <Button variant="dominoOutlineViolet" onClick={onOpen} data-pw="import-contacts-button" disabled={false}>
                    {t("Import Recipients")}
                  </Button>
                ) : (
                  <Button
                    bgColor="white"
                    color="mainRed"
                    padding="0 24px"
                    onClick={handleCancelClick}
                    data-pw="import-contacts-button"
                    disabled={false}
                    className={s.cancelButton}
                  >
                    {t("Cancel")}
                  </Button>
                ))}
              <ImportRecipientsModal isOpen={isOpen} onClose={onClose} />
            </>
          </Box>
        </>
      ) : (
        !isRecipientsLoading &&
        !broadcastId && (
          <Box color="darkGrey" textAlign="center" fontSize="15px" mx="53px" my="48px">
            {isBroadcastForContact ? t("No contacts in broadcast") : t("No group in broadcast")}
          </Box>
        )
      )}
    </Box>
  );
});
