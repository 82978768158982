import { Box, Button, Flex, Input, Menu, MenuButton, MenuList, Text } from "@chakra-ui/react";
import s from "./UserManagementContainer.module.scss";
import { useTranslation } from "react-i18next";
import { forwardRef, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../common/state/store";
import {
  getAllBillingPlans,
  getAllOrganisation,
  impersonate,
  selectBillingPlans,
  selectUserOrganisations,
  setSubscription,
} from "./UserManagementSlice";
import { selectIsSuperAdmin, selectRoles } from "../../common/user/UserSlice";
import { Navigate } from "react-router-dom";
import { haveSpaces } from "../../common/validation/defaultValidators";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { DatePicker, customDateInput } from "../../UI/atoms/datePicker/DatePicker";
import useDebounce from "../../common/hooks/useDebounce";
import dayjs from "dayjs";
import { notify } from "../../common/notifications/NotificationSlice";
import { OrganisationUserModel } from "./UserManagementModel";
import { CustomMenuItem } from "../../UI/molecules/customMenuItem/CustomMenuItem";

export const UserManagementContainer = () => {
  const { t } = useTranslation("translation", { keyPrefix: "userManagement" });
  const isSuperAdmin = useAppSelector(selectIsSuperAdmin);
  const userOrganisations: OrganisationUserModel[] | undefined = useAppSelector(selectUserOrganisations);
  const billingPlans = useAppSelector(selectBillingPlans);
  const roles = useAppSelector(selectRoles);
  const [impUsername, setImpUsername] = useState("");
  const CustomInput = forwardRef(customDateInput);
  const [billingUserName, setBillingUserName] = useState("");
  const debouncedValue = useDebounce(billingUserName, 2000);
  const [billingUserOrganisation, setBillingUserOrganisation] = useState<{ name: string; id: string | null }>({
    name: "Organisation",
    id: null,
  });
  const [selectedBillingPlan, setSelectedBillingPlan] = useState<{ name: string; id: string | null }>({
    name: "Billing Plans",
    id: null,
  });
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const handleChange = (e: Date | null) => {
    setIsOpen(!isOpen);
    setEndDate(e);
  };
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!billingPlans) {
      dispatch(getAllBillingPlans());
    }
  }, [billingPlans, dispatch]);

  useEffect(() => {
    let orgName = debouncedValue;
    if (debouncedValue.startsWith("@") && debouncedValue.length > 1) {
      orgName = debouncedValue.substring(1);
    }
    dispatch(getAllOrganisation(orgName));
  }, [debouncedValue, dispatch]);

  if (!!roles.length && !isSuperAdmin) {
    return <Navigate to="/" />;
  }

  const handleKeyDown = (key: string) => {
    if (key === "Enter") {
      executeImpersonate();
    }
  };

  const onChangeUsernameInput = (text: string) => {
    if (haveSpaces()(text)) {
      text = text.trim().replace(" ", "");
    }
    setImpUsername(text);
  };

  const onChangeBillingUsernameInput = (text: string) => {
    if (haveSpaces()(text)) {
      text = text.trim().replace(" ", "");
    }
    setBillingUserName(text);
    if (!text) {
      setBillingUserOrganisation({
        name: "Organisation",
        id: null,
      });
    }
  };

  function executeImpersonate() {
    if (!impUsername) return;

    const username = impUsername.startsWith("@") ? impUsername.substring(1) : impUsername;

    dispatch(impersonate({ username }));
  }

  const changeBillingPlan = () => {
    if (billingUserOrganisation.id && selectedBillingPlan.id && endDate) {
      dispatch(
        setSubscription({
          organizationId: billingUserOrganisation.id,
          BillingPlanId: selectedBillingPlan.id,
          EndDate: endDate,
        }),
      );
      setBillingUserName("");
      setBillingUserOrganisation({
        name: "Organisation",
        id: null,
      });
      dispatch(
        notify({
          message: `The billing plan for the ${billingUserOrganisation.name} organization has been changed`,
          type: "success",
        }),
      );
    }
  };

  return (
    <Box className={s.userProfileLayout}>
      <div className={s.impersonateWrapper}>
        <div className={s.impersonateContent}>
          <div className={s.contentItem}>
            <Text variant="h2">{t("Impersonate")}</Text>
          </div>
          <div className={s.impersonateItem}>
            <Text py="8px" width="100%" maxWidth="300px" variant="large">
              {t("Username")}
            </Text>
            <Input
              className={s.impersonateInput}
              onKeyDown={e => handleKeyDown(e.key)}
              maxWidth="380px"
              mr="32px"
              placeholder={`${t("Input Username")}`}
              value={impUsername}
              onChange={e => onChangeUsernameInput(e.target.value)}
            />
            <Button
              isDisabled={!impUsername.length}
              size="sm"
              minW="130px"
              variant="dominoViolet"
              onClick={() => executeImpersonate()}
            >
              {t("Impersonate")}
            </Button>
          </div>
        </div>
      </div>
      <div className={s.impersonateWrapper}>
        <div className={s.impersonateContent}>
          <div className={s.contentItem}>
            <Text variant="h2">{t("Change billing plan")}</Text>
          </div>
          <div className={s.changeBillingItem}>
            <Flex gap="4px" flexDirection="column">
              <Input
                className={s.inputChangeBilling}
                placeholder={`${t("Input Username")}`}
                value={billingUserName}
                onChange={e => onChangeBillingUsernameInput(e.target.value)}
              />
              {userOrganisations &&
                userOrganisations.find(el => el.name === billingUserOrganisation.name)?.futureSubscriptions.length !== 0 &&
                billingUserOrganisation.id !== null && (
                  <Text maxW="265px" variant="small" color="grey">
                    {`User has a future subscription for the ${billingUserOrganisation.name} organization`}
                  </Text>
                )}
            </Flex>

            <Menu>
              {({ isOpen, onClose }) => (
                <>
                  <MenuButton
                    isDisabled={!userOrganisations}
                    textAlign="left"
                    isActive={isOpen}
                    minW="130px"
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                  >
                    {billingUserOrganisation.name}
                  </MenuButton>
                  <MenuList maxH="200px" overflowY="auto">
                    {userOrganisations &&
                      userOrganisations?.map(el => (
                        <CustomMenuItem
                          onClick={() => {
                            setBillingUserOrganisation({ name: el.name, id: el.id });
                            onClose();
                          }}
                          key={el.id}
                        >
                          {el.name}
                        </CustomMenuItem>
                      ))}
                  </MenuList>
                </>
              )}
            </Menu>

            <Menu>
              {({ isOpen, onClose }) => (
                <>
                  <MenuButton isActive={isOpen} textAlign="left" minW="130px" as={Button} rightIcon={<ChevronDownIcon />}>
                    {selectedBillingPlan.name}
                  </MenuButton>
                  <MenuList maxH="200px" overflowY="auto">
                    {billingPlans &&
                      billingPlans.items.map(el => (
                        <CustomMenuItem
                          onClick={() => {
                            setSelectedBillingPlan({ name: el.name, id: el.id });
                            onClose();
                          }}
                          key={el.id}
                        >
                          <Text textDecoration={!el?.isActual ? "line-through" : "inherit"}>{el.name}</Text>
                        </CustomMenuItem>
                      ))}
                  </MenuList>
                </>
              )}
            </Menu>
            <Flex className={s.flexDatePicker}>
              <Button className={s.datePickerButton} onClick={handleClick}>
                {endDate ? dayjs(endDate).format("DD.MM.YYYY") : "End Date"}
              </Button>
              {isOpen && (
                <Box className={s.datePicker}>
                  <DatePicker
                    inline
                    locale="En"
                    onChange={date => handleChange(date)}
                    dateFormat="MMM dd yyyy"
                    selected={endDate ? endDate : null}
                    minDate={new Date()}
                    customInput={<CustomInput />}
                  />
                </Box>
              )}
            </Flex>

            <Button
              minW="170px"
              isDisabled={!(!!selectedBillingPlan.id && !!billingUserName.length && !!billingUserOrganisation.id && !!endDate)}
              size="sm"
              variant="dominoViolet"
              onClick={changeBillingPlan}
            >
              {t("Change billing plan")}
            </Button>
          </div>
        </div>
      </div>
    </Box>
  );
};
