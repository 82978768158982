import { Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { AddNewItemButton } from "./components/AddNewItemButton/AddNewItemButton";
import { ButtonInput } from "./components/ButtonInput/ButtonInput";

interface Props {
  items: string[];
  itemQuantityLimit?: number;
  addNewItemButtonText: string;
  inputPlaceholder: string;
  maxItemLength: number;
  setItems: (items: string[]) => void;
}

interface CurrentItem {
  isActive: boolean;
  value: string;
}

export const GroupOfButtonsInputs = ({
  items,
  itemQuantityLimit,
  addNewItemButtonText,
  inputPlaceholder,
  maxItemLength,
  setItems,
}: Props) => {
  const [currentItems, setCurrentItems] = useState<CurrentItem[]>([]);

  useEffect(() => {
    const newItems = items.map((item, index) => {
      return { isActive: !!currentItems[index]?.isActive, value: item };
    });
    setCurrentItems(newItems);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [items]);

  const handleSetItems = (updatedValues: CurrentItem[]) => {
    const newItems = updatedValues.map(el => el.value);
    setItems(newItems);
  };

  const addNewItem = () => {
    const updatedValues = [...currentItems, { isActive: true, value: "" }];
    setCurrentItems(updatedValues);
    handleSetItems(updatedValues);
  };

  const deleteItem = (index: number) => {
    const updatedValues = [...currentItems];
    updatedValues.splice(index, 1);
    setCurrentItems(updatedValues);
    handleSetItems(updatedValues);
  };

  const setItem = (value: string, index: number, isActive: boolean) => {
    let updatedValues = [...currentItems];
    const currentItem = updatedValues[index];
    currentItem.isActive = isActive;
    currentItem.value = value;
    updatedValues = updatedValues.filter(el => el.isActive || (!el.isActive && el.value.trim().length));

    setCurrentItems(updatedValues);
    handleSetItems(updatedValues);
  };

  return (
    <Flex w="100%" alignItems="center" flexWrap="wrap">
      {currentItems.map((item, index) => (
        <ButtonInput
          key={`item_${index + 1}`}
          item={item}
          index={index}
          setItem={setItem}
          maxItemLength={maxItemLength}
          inputPlaceholder={inputPlaceholder}
          deleteItem={deleteItem}
        />
      ))}

      {(!itemQuantityLimit || currentItems.length < itemQuantityLimit) && (
        <AddNewItemButton addNewItem={addNewItem} addNewItemButtonText={addNewItemButtonText} />
      )}
    </Flex>
  );
};
