import { Descendant, Editor, Element, Range, Transforms } from "slate";
import { CustomText, EditorType, ElementTypes, LinkElementType, ParagraphElementType, TextMarks } from "./types";
import { CONFLICTED_GROUPS, DIVIDER_BETWEEN_PARAGRAPHS, DIVIDER_BETWEEN_WORDS, FORMATS_BORDERS } from "./consts";
import { Entity } from "../../../common/formattedText/entity";
import { ReactEditor } from "slate-react";

export function isMarkActive(editor: EditorType, format: TextMarks) {
  try {
    return !!Editor.marks(editor)?.[format];
  } catch (e) {} // Library bug, appearance when type text without formatting and ctrl+a > shift+enter
}

export function toggleMark(editor: EditorType, format: TextMarks) {
  const isActive = isMarkActive(editor, format);
  if (isActive) editor.removeMark(format);
  else {
    const wasChanges = resolveFormatConflicts(editor, format);
    if (editor.charactersLeft >= 4 || wasChanges) editor.addMark(format, true);
  }
}

export const insertLink = (editor: EditorType, text: string, href: string) => {
  if (editor.selection) {
    wrapLink(editor, text, href);
  }
};

export const wrapLink = (editor: EditorType, text: string, href: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link: LinkElementType = {
    type: ElementTypes.Link,
    href,
    children: isCollapsed ? [{ text: text }] : [],
  };
  const wordDivider: ParagraphElementType = {
    type: ElementTypes.Paragraph,
    children: [{ text: DIVIDER_BETWEEN_WORDS }],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, [link, wordDivider]);
    ReactEditor.focus(editor);
    Editor.end(editor, []);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: "end" });
  }
};

const unwrapLink = (editor: EditorType) => {
  Transforms.unwrapNodes(editor, {
    match: n => !Editor.isEditor(n) && Element.isElement(n) && n.type === ElementTypes.Link,
  });
};

const isLinkActive = (editor: EditorType) => {
  const iterator = Editor.nodes(editor, {
    match: n => !Editor.isEditor(n) && Element.isElement(n) && n.type === ElementTypes.Link,
  });

  const { value: link } = iterator.next();
  return !!link;
};

const withFormats = (text: string, formats: Record<TextMarks, boolean>): string => {
  let returnedValue = text;
  for (const format in formats) {
    if (formats[format as TextMarks]) {
      returnedValue = FORMATS_BORDERS[format as TextMarks] + returnedValue + FORMATS_BORDERS[format as TextMarks];
    }
  }

  return returnedValue;
};

export const convertText = {
  fromNodesToText: (editor: EditorType): string => {
    let resultString = "";

    editor.children.forEach((element, index) => {
      if (!("children" in element)) return;

      if (index > 0) {
        resultString += DIVIDER_BETWEEN_PARAGRAPHS;
      }

      element.children.forEach(leaf => {
        if ("text" in leaf && leaf.text.length === 0) return;

        if (Element.isElement(leaf) && leaf.type === ElementTypes.Link) {
          const [text, href] = [leaf.children[0].text, leaf.href];
          if (text[0] === DIVIDER_BETWEEN_WORDS) resultString += text[0];
          resultString += `[${text.trim()}](${href})`;
          if (text[text.length - 1] === DIVIDER_BETWEEN_WORDS) resultString += text[text.length - 1];
        } else if ("children" in leaf) {
          const children = leaf.children as CustomText[];
          if (children.length > 0 && "text" in children[0]) {
            resultString += children[0].text;
          }
        } else {
          const { text, ...textMarks } = leaf;
          if (text === DIVIDER_BETWEEN_WORDS) return (resultString += text);

          if (text[0] === DIVIDER_BETWEEN_WORDS) resultString += text[0];
          resultString += withFormats(text.trim(), textMarks as Record<TextMarks, boolean>);
          if (text[text.length - 1] === DIVIDER_BETWEEN_WORDS) resultString += text[text.length - 1];
        }
      });
    });

    return resultString.trim();
  },
  fromTextToNodes: (text: string, entities?: Entity[]): Descendant[] => {
    const entitiesCopy = entities ? [...entities] : [];
    const nodes: Descendant[] = [];

    let processedOffset = 0;
    const splittedByParagraphs = text.split(DIVIDER_BETWEEN_PARAGRAPHS);
    splittedByParagraphs.forEach(paragraph => {
      const element: ParagraphElementType = { type: ElementTypes.Paragraph, children: [] };

      if (entitiesCopy.length === 0) {
        element.children.push({ text: paragraph });
        return nodes.push(element);
      }

      const paragraphLength = paragraph.length;
      const entitiesByParagraph = entitiesCopy
        .filter(entity => entity.offset >= processedOffset && entity.offset + entity.length <= paragraphLength + processedOffset)
        .sort((a, b) => a.offset - b.offset);

      const mergedEntities: (Omit<Entity, "type"> & { types: string[] })[] = [];
      entitiesByParagraph.forEach(entity => {
        const { type, ...rest } = entity;

        const lastEntity = mergedEntities[mergedEntities.length - 1];
        if (lastEntity && lastEntity.offset === entity.offset && lastEntity.length === entity.length) {
          lastEntity.types.push(entity.type.toLowerCase());
        } else {
          return mergedEntities.push({ ...rest, types: [type.toLowerCase()] });
        }
      });

      let lastIndex = 0;
      mergedEntities.forEach(entity => {
        const [offset, length] = [entity.offset - processedOffset, entity.length];
        const beforeText = paragraph.slice(lastIndex, offset);
        if (beforeText) {
          if (beforeText === DIVIDER_BETWEEN_WORDS && element.children.length > 0) {
            element.children[element.children.length - 1].text += DIVIDER_BETWEEN_WORDS;
          } else {
            element.children.push({ text: beforeText });
          }
        }

        const entityText = paragraph.slice(offset, offset + length);
        if (entity.types[0] === ElementTypes.Link) {
          const linkElement: LinkElementType = {
            type: ElementTypes.Link,
            href: entity.url ?? "",
            children: [{ text: entityText }],
          };
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          element.children.push({ text: "" }, linkElement, { text: "" });
        } else {
          const customEntityText: CustomText = { text: entityText };

          entity.types.forEach(type => {
            customEntityText[type as TextMarks] = true;
          });

          element.children.push(customEntityText);
        }

        lastIndex = offset + length;
      });

      const remainingText = paragraph.slice(lastIndex);
      if (remainingText) element.children.push({ text: remainingText });

      if (!element.children.length) {
        element.children.push({ text: "" });
      }

      nodes.push(element);
      processedOffset += paragraphLength + DIVIDER_BETWEEN_PARAGRAPHS.length;
    });

    return nodes;
  },
};

export function getTextBySubstring(text: string, substring: number): string {
  return text.slice(0, substring > 1 && substring < text.length ? substring - 1 : substring);
}

function resolveFormatConflicts(editor: EditorType, newFormat: TextMarks): boolean {
  const groupsWithoutNewFormat = CONFLICTED_GROUPS.filter(group => !group.includes(newFormat));
  let wasChanges = false;

  groupsWithoutNewFormat.forEach(group => {
    group.forEach(format => {
      if (isMarkActive(editor, format)) {
        toggleMark(editor, format);
        wasChanges = true;
      }
    });
  });

  return wasChanges;
}
