import { httpGet } from "../../../../common/BaseApi";

export const getGroupConversationList = (botId: string, queryString: string) => {
  return httpGet(`/api/conversation/group/bot/${botId}${queryString}`);
};

export const getGroupConversationCount = (botId: string, queryString: string) => {
  return httpGet(`/api/conversation/group/bot/${botId}/count${queryString}`);
};

export const getOverLimitConversations = (botId: string) => {
  return httpGet(`/api/conversation/getOverLimit/${botId}/groups`);
};
