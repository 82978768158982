import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../common/state/store";
import { Tour, OnboardingState } from "./OnboardingToursState";

export const initialState: OnboardingState = {};

export const onboardingSlice = createSlice({
  name: "onboardingSlice",
  initialState,
  reducers: {
    setTour: (state, action: PayloadAction<Tour[]>) => {
      return {
        ...state,
        tours: action.payload,
      };
    },
    startTour: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        tourName: action.payload,
      };
    },
    setTourStep: (state, action: PayloadAction<Tour>) => {
      return {
        ...state,
        tourName: action.payload.tourName,
        step: action.payload.step,
      };
    },
    skipTour: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        tourName: action.payload,
      };
    },
    completeTour: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        tourName: action.payload,
      };
    },
    setFlowBuilderTourInitialAction: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isFlowBuilderTourInitialAction: action.payload,
      };
    },
    setSwitchElement: (state, action: PayloadAction<HTMLElement>) => {
      return {
        ...state,
        switchElement: action.payload,
      };
    },
  },
});

export const { startTour, completeTour, setTour, skipTour, setTourStep, setFlowBuilderTourInitialAction, setSwitchElement } =
  onboardingSlice.actions;

export const selectTours = (state: RootState) => state.app.onboardingState.tours;
export const selectSwitchElement = (state: RootState) => state.app.onboardingState.switchElement;
export const selectIsFlowBuilderTourInitialAction = (state: RootState) =>
  state.app.onboardingState.isFlowBuilderTourInitialAction;

export default onboardingSlice.reducer;
