import { Box, Icon, Menu } from "@chakra-ui/react";
import sp from "../../../../common/tariffPlan/TariffPlanStarPlan.module.scss";
import s from "../../Sidebar.module.scss";
import { useTranslation } from "react-i18next";
import StarPlan from "../../../../assets/icons/starPlan.svg?react";
import { LimitStarPlanButton } from "../../../../UI/molecules/limitStarPlanButton/LimitStarPlanButton";
import { CustomMenuItem } from "../../../../UI/molecules/customMenuItem/CustomMenuItem";

export const SideBarBotsLimitPopover = () => {
  const { t } = useTranslation("translation", { keyPrefix: "sidebar" });
  return (
    <LimitStarPlanButton popoverBodyDescription="Please upgrade your plan to add more Bots">
      <Box className={sp.boxHover}>
        <Menu>
          <CustomMenuItem height={{ base: "48px", md: "60px" }} className={s.addBotBtn}>
            <Icon
              as={StarPlan}
              p="8px"
              boxSize={{ base: "24px", md: "37px" }}
              color="midDeepBlue"
              mr="12px"
              className={s.addBotIcon}
            />
            {t("Create new bot")}
          </CustomMenuItem>
        </Menu>
      </Box>
    </LimitStarPlanButton>
  );
};
