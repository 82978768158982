import { Box, FormControl, Menu, MenuButton, MenuList, Textarea, Input, Text, Flex, Icon, useDisclosure } from "@chakra-ui/react";
import { BotVariableModel } from "../../../BotModel";
import s from "./BotVariableItem.module.scss";
import RedTrashIcon from "../../../../../assets/icons/trash.svg?react";
import { ChangeEvent, forwardRef, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { BooleanVariableValues, CustomVariableType } from "../../../../../common/AppEnums";
import { customDateInput, DatePicker } from "../../../../../UI/atoms/datePicker/DatePicker";
import dayjs from "dayjs";
import { useMobileView } from "../../../../layout/LayoutHelper/ResolutionHooks";
import DotMenuContainer from "../../../../../UI/atoms/DotMenu/DotMenuContainer";
import arrayTypeIcon from "../../../../../assets/icons/variableTypes/arrayTypeIcon.svg?react";
import textTypeIcon from "../../../../../assets/icons/variableTypes/textTypeIcon.svg?react";
import dateTypeIcon from "../../../../../assets/icons/variableTypes/dateTypeIcon.svg?react";
import numberTypeIcon from "../../../../../assets/icons/variableTypes/numberTypeIcon.svg?react";
import booleanTypeIcon from "../../../../../assets/icons/variableTypes/booleanTypeIcon.svg?react";
import catalogTypeIcon from "../../../../../assets/icons/variableTypes/catalogTypeIcon.svg?react";
import { OverflowEllipsesText } from "../../../../../UI/atoms/textOverflow/OverflowEllipsesText";
import useGA from "../../../../../common/ga/GAEventTracker";
import { BotFieldsEvents } from "../../../../../common/ga/gaEventsEnums.ts/BotGAEventsEnums";
import { EventCategories } from "../../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import { CustomMenuItem } from "../../../../../UI/molecules/customMenuItem/CustomMenuItem";
import { numberValidated } from "../../../../contact/components/EditContact/EditContactValidation";

interface Props {
  item: BotVariableModel;
  onVariableEdit: (variable: BotVariableModel) => void;
  onVariableDescriptionEdit: (variable: BotVariableModel) => void;
  onVariableDelete: (variableId: BotVariableModel) => void;
}

export const BotVariableItem = ({ item, onVariableEdit, onVariableDelete, onVariableDescriptionEdit }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "bot" });
  const vt = useTranslation("translation", { keyPrefix: "variableTypes" }).t;
  const cwt = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const [isValidValue, setIsValidValue] = useState<boolean>(true);
  const [editItem, setEditItem] = useState<BotVariableModel | null>(null);
  const CustomInput = forwardRef(customDateInput);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const isMobile = useMobileView();
  const locale = localStorage.getItem("i18nextLng");
  const trackEvent = useGA(EventCategories.Bot);
  const { isOpen, onClose, onOpen } = useDisclosure();

  useEffect(() => {
    if (textareaRef.current) {
      const maxHeightWithoutScroll = 500;
      textareaRef.current.style.height = "0px";
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + "px";
      // needed for correct scroll appearing
      if (maxHeightWithoutScroll && scrollHeight >= maxHeightWithoutScroll) {
        textareaRef.current.style.overflow = "scroll";
      } else {
        textareaRef.current.style.overflow = "hidden";
      }
    }
    // eslint-disable-next-line
  }, [editItem?.description]);

  useEffect(() => {
    setEditItem(item);
  }, [item]);

  const onSaveClick = (editItem: BotVariableModel | null) => {
    if (!editItem || !isValidValue) return;
    if (editItem.description === item.description && editItem.value === item.value) return;
    if (editItem.value && editItem.value?.length >= 20) {
      setEditItem({ ...editItem, value: item.value ?? "" });
    }
    if (editItem.value && !editItem.value.trim().length) {
      setEditItem({ ...editItem, value: item.value ?? "" });
    }
    if (editItem.description && !editItem.description.trim().length) {
      setEditItem({ ...editItem, description: item.description ?? "" });
      return;
    }
    if (editItem.description === item.description) {
      onVariableEdit({
        ...editItem,
        value: editItem.value && editItem.value.trim(),
        description: editItem.description && editItem.description.trim(),
      });
    } else {
      onVariableDescriptionEdit({
        ...editItem,
        value: editItem.value && editItem.value.trim(),
        description: editItem.description && editItem.description.trim(),
      });
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && e.shiftKey) return;
    if (e.key === "Enter") {
      const target = e.target as HTMLInputElement;
      target.blur();
    }
  };

  const validateValue = (value: string) => {
    const type = item.type;
    let isValid = true;
    if (value) {
      if (type === CustomVariableType.Boolean) {
        isValid = ["true", "false"].includes(value);
      }
      if (type === CustomVariableType.Number) {
        isValid = numberValidated(value);
      }
    }
    setIsValidValue(isValid);
  };

  const getVariableValuePlaceholde = (type: CustomVariableType) => {
    if (type === CustomVariableType.Array) {
      return t("Enter array values, separated by commas");
    }
    if (type === CustomVariableType.Number) {
      return t("Enter digits");
    }
    return t("Enter text");
  };

  const handleChangeValue = (botVariable: BotVariableModel) => (e: ChangeEvent<HTMLInputElement>) => {
    if (botVariable.type === CustomVariableType.Number) {
      if (!numberValidated(e.target.value) && e.target.value) {
        e.target.value = botVariable.value ?? "";
        return;
      }
    }

    setEditItem({
      ...botVariable,
      value:
        botVariable.type === CustomVariableType.Text || botVariable.type === CustomVariableType.Array
          ? e.target.value
          : e.target.value.replace(/\s/g, ""),
    });
    validateValue(e.target.value);
  };

  const getValueInput = () => {
    switch (editItem?.type) {
      case CustomVariableType.DateTime: {
        return (
          <DatePicker
            wrapperClassName={s.contactDatePicker}
            dateFormat={locale !== "en" ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy h:mm a"}
            selected={editItem.value ? new Date(editItem.value) : null}
            onChange={scheduledDate => {
              setEditItem({
                ...editItem,
                value: dayjs(scheduledDate).isValid() ? dayjs(scheduledDate).format("YYYY-MM-DDTHH:mmZZ") : undefined,
              });
            }}
            placeholderText={cwt("Not Set") ?? ""}
            showTimeSelect
            strictParsing
            customInput={
              <CustomInput
                ml="-16px"
                borderColor="transparent"
                placeholdertext={cwt("placeholderDateTime")}
                height={isMobile ? "30px" : "36px"}
              />
            }
            onCalendarClose={() => onSaveClick(editItem)}
          />
        );
      }
      case CustomVariableType.Date: {
        return (
          <DatePicker
            wrapperClassName={s.contactDatePicker}
            dateFormat="dd.MM.yyyy"
            selected={editItem.value ? new Date(editItem.value) : null}
            onChange={scheduledDate => {
              setEditItem({
                ...editItem,
                value: dayjs(scheduledDate).isValid() ? dayjs(scheduledDate).format("YYYY-MM-DD") : undefined,
              });
            }}
            strictParsing
            placeholderText={cwt("Not Set") ?? ""}
            customInput={
              <CustomInput
                height={isMobile ? "30px" : "36px"}
                ml="-16px"
                borderColor="transparent"
                placeholdertext={cwt("placeholderDate")}
              />
            }
            onCalendarClose={() => onSaveClick(editItem)}
          />
        );
      }
      case CustomVariableType.Boolean: {
        return (
          <Menu
            onClose={onClose}
            isOpen={isOpen}
            onOpen={onOpen}
            preventOverflow={false}
            matchWidth={true}
            variant="dominoDotsMenu"
            gutter={0}
          >
            <MenuButton
              className={s.boolInputBtn}
              color={!item.value ? "var(--chakra-colors-gray-500)" : "black"}
              ml="-16px"
              _hover={{ borderColor: "mainPurple" }}
              w="100%"
              onClick={e => e.stopPropagation()}
            >
              <Flex justifyContent={"space-between"} alignItems={"center"}>
                <Flex w={"90%"} alignItems={"center"} ml={"16px"}>
                  {editItem.value?.length ? cwt(editItem.value) : cwt("Not Set")}
                </Flex>
              </Flex>
            </MenuButton>
            <MenuList>
              {Object.keys(BooleanVariableValues).map((el, i) => {
                return (
                  <CustomMenuItem
                    key={i}
                    value={el}
                    onClick={() => {
                      onSaveClick({
                        ...editItem,
                        value: el === BooleanVariableValues["Not Set"] ? "" : el,
                      });
                      setEditItem({
                        ...editItem,
                        value: el === BooleanVariableValues["Not Set"] ? "" : el,
                      });
                      onClose();
                    }}
                  >
                    {cwt(el)}
                  </CustomMenuItem>
                );
              })}
            </MenuList>
          </Menu>
        );
      }
      case CustomVariableType.Number: {
        return (
          <Input
            ml="-16px"
            fontSize={"15px !important"}
            borderColor="transparent"
            backgroundColor={"white"}
            placeholder={`${cwt("Not Set")}`}
            size="md"
            height={isMobile ? "30px" : "36px"}
            isInvalid={!isValidValue}
            resize={"none"}
            value={editItem.value}
            type={"text"}
            onKeyDown={e => handleKeyPress(e)}
            onFocus={e => (e.target.placeholder = getVariableValuePlaceholde(editItem.type))}
            onChange={handleChangeValue(editItem)}
            onBlur={e => {
              e.target.placeholder = cwt("Not Set");
              onSaveClick({
                ...editItem,
                value: editItem.value,
              });
            }}
          />
        );
      }
      case CustomVariableType.Array:
      case CustomVariableType.Text: {
        return (
          <Input
            ml="-16px"
            fontSize={"15px !important"}
            borderColor="transparent"
            backgroundColor={"white"}
            placeholder={`${cwt("Not Set")}`}
            size="md"
            isInvalid={!isValidValue}
            height={isMobile ? "30px" : "36px"}
            resize={"none"}
            value={editItem.value}
            type={editItem.type}
            onKeyDown={e => handleKeyPress(e)}
            onFocus={e => (e.target.placeholder = getVariableValuePlaceholde(editItem.type))}
            onChange={handleChangeValue(editItem)}
            onBlur={e => {
              e.target.placeholder = cwt("Not Set");
              onSaveClick({
                ...editItem,
                value: editItem.value,
              });
            }}
          />
        );
      }
    }
  };

  const variableTypeIcons = {
    [CustomVariableType.Text]: textTypeIcon,
    [CustomVariableType.Array]: arrayTypeIcon,
    [CustomVariableType.Number]: numberTypeIcon,
    [CustomVariableType.Date]: dateTypeIcon,
    [CustomVariableType.DateTime]: dateTypeIcon,
    [CustomVariableType.Boolean]: booleanTypeIcon,
    [CustomVariableType.Order]: catalogTypeIcon,
  };

  return (
    <Box className={s.row}>
      <Box overflow={"hidden"}>
        {isMobile && <Text className={s.mobileColName}>{t("Name")}</Text>}
        <OverflowEllipsesText text={item.key} content={<Text className={s.rowText}>{item.key}</Text>} />
      </Box>
      <Box>
        {isMobile && <Text className={s.mobileColName}>{t("Type")}</Text>}
        <Flex flexDirection={"row"} className={s.type}>
          <Icon mr="10px" fill={"#8592A3"} boxSize="14px" as={variableTypeIcons[item.type]} marginTop={!isMobile ? "8px" : "0"} />
          <Text className={s.rowText}>{vt(item.type)}</Text>
        </Flex>
      </Box>
      <Box>
        {isMobile && <Text className={`${s.mobileColName} ${s.value}`}>{t("Value")}</Text>}
        <Text pl="2px">{getValueInput()}</Text>
      </Box>
      <Box>
        {isMobile && <Text className={s.mobileColName}>{t("Description")}</Text>}
        <FormControl>
          <OverflowEllipsesText
            text={editItem?.description}
            tooltip={false}
            content={
              <Textarea
                className={s.textarea}
                placeholder={`${cwt("Not Set")}`}
                borderColor="transparent"
                size="md"
                variant="dominoOutline"
                rows={5}
                ref={textareaRef}
                fontSize={"15px !important"}
                resize={"none"}
                maxLength={250}
                value={editItem?.description}
                _focus={{ height: "100px" }}
                onKeyDown={e => handleKeyPress(e)}
                onFocus={e => (e.target.placeholder = t("Enter field's description"))}
                onChange={e => {
                  setEditItem(editItem ? { ...editItem, description: e.target.value } : editItem);
                }}
                onBlur={e => {
                  e.target.placeholder = `${cwt("Not Set")}`;
                  onSaveClick(editItem);
                }}
              />
            }
          />
        </FormControl>
      </Box>
      <Box>
        <DotMenuContainer
          menuItems={[
            {
              MenuItem: {
                icon: <RedTrashIcon width="18px" height="18px" />,
                text: cwt("Delete"),
                color: "mainRed",
                clickEvent: e => {
                  onVariableDelete(item);
                  e.stopPropagation();
                  trackEvent(BotFieldsEvents.BotFieldsDelete);
                },
              },
            },
          ]}
        />
      </Box>
    </Box>
  );
};
