import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { ProtectedHeaderContainer } from "../../../headers/protectedHeaderContainer/ProtectedHeaderContainer";
import { OrganisationContainer } from "../../../organisation/OrganisationContainer";
import { OrganisationHeader } from "../../../organisation/components/OrganisationHeader/OrganisationHeader";

export const OrganisationPage = () => {
  return (
    <Flex flexDirection={"column"} height={"100%"}>
      <ProtectedHeaderContainer>
        <OrganisationHeader />
      </ProtectedHeaderContainer>
      <Box>
        <OrganisationContainer />
      </Box>
    </Flex>
  );
};
