import { Text } from "@chakra-ui/react";
import { RenderElementProps } from "slate-react";
import { LinkElement } from "./LinkElement/LinkElement";
import { ElementTypes } from "../../types";

export const RenderElements = ({ attributes, children, element }: RenderElementProps) => {
  switch (element.type) {
    case ElementTypes.Link: {
      return (
        <LinkElement attributes={attributes} element={element}>
          {children}
        </LinkElement>
      );
    }
    default:
      return (
        <Text variant="large" {...attributes}>
          {children}
        </Text>
      );
  }
};
