import { ContactFilesData } from "./ContactInfoData";
import { ContactVariableModel } from "./ContactInfoModel";

export function mapVariableValuesToObject(contactVariables: ContactVariableModel[] | undefined) {
  return contactVariables?.reduce(
    (variablesObject, currentVariable) => ({
      ...variablesObject,
      [currentVariable.id]: (() => {
        return currentVariable.value ?? "";
      })(),
    }),
    {},
  );
}

export const mapFilesListDataToModel = (newMediaFilesList: ContactFilesData, data?: ContactFilesData) => {
  if (!data || newMediaFilesList.currentPage === 1) {
    return newMediaFilesList;
  }

  if (newMediaFilesList && data.currentPage !== newMediaFilesList.currentPage) {
    const model: ContactFilesData = {
      ...data,
      items: data.items.concat(newMediaFilesList.items),
    };

    return model;
  }

  const model: ContactFilesData = {
    ...data,
  };

  return model;
};
