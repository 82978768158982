import { Box, Icon, Text } from "@chakra-ui/react";
import { Handle, Position } from "reactflow";
import { useTranslation } from "react-i18next";
import {
  ButtonModel,
  InlineKeyboardMarkupModel,
  ReplyKeyboardButtonModel,
  ReplyKeyboardMarkupModel,
} from "../../../../../common/AppButtonsModel";
import { formatTelegramText } from "../../../../../common/utils/formatTelegramText";
import { ButtonTypeDiscriminator, FlowMarkupTypeDiscriminator } from "../../../../../common/AppEnums";
import {
  ButtonsContainerFlowAction,
  FlowActionType,
  SendPhotoFlowActionModel,
  SendTextFlowActionModel,
  PaymentFlowActionModel,
} from "../../../FlowBuilderModel";
import { ActionNodeProps } from "../ActionNodeProps";
import LinkIcon from "../../../../../assets/icons/linkIcon.svg?react";
import PhoneIcon from "../../../../../assets/icons/phoneIcon.svg?react";
import CatalogIcon from "../../../../../assets/icons/CatalogNodeIcon.svg?react";
import DynamicIcon from "../../../../../assets/icons/dynamicIcon.svg?react";
import s from "./MessageButtonBlock.module.scss";
import { FormattedText } from "../../../../../common/formattedText/formattedText";

interface Props {
  actionData: ActionNodeProps;
  imageType?: boolean;
  textType?: string;
  borderRadius?: string;
}

export const MessageButtonBlock = ({ actionData, imageType, textType, borderRadius }: Props) => {
  const bt = useTranslation("translation", { keyPrefix: "broadcast" }).t;
  const fnt = useTranslation("translation", { keyPrefix: "flow.editNodePopup" }).t;
  const data = actionData.node.flowAction as SendTextFlowActionModel;
  const dataFile = actionData.node.flowAction as SendPhotoFlowActionModel;
  const description = actionData.node.flowAction as PaymentFlowActionModel;
  function checkIsReplyKeyboards(): boolean {
    const action = data as ButtonsContainerFlowAction;
    return action.replyMarkup?.typeDiscriminator === FlowMarkupTypeDiscriminator.ReplyKeyboardMarkup;
  }
  function isReplyButton(button: ReplyKeyboardButtonModel) {
    return button.typeDiscriminator === ButtonTypeDiscriminator.FlowReplyKeyboardButton;
  }
  function checkIsStaticButton(button: ButtonModel): boolean {
    return (
      button.typeDiscriminator === ButtonTypeDiscriminator.FlowMessageCallbackButton ||
      button.typeDiscriminator === ButtonTypeDiscriminator.FlowMessageUrlButton
    );
  }

  function getReplyKeyboardButtons() {
    const buttonData = data as ButtonsContainerFlowAction;
    const replyData = buttonData?.replyMarkup as ReplyKeyboardMarkupModel;
    return replyData.buttons;
  }
  function getReplyKeyboardButton(button: ReplyKeyboardButtonModel, i: number) {
    return (
      <>
        <Box p={button.requestOrder ? "0 10px !important" : ""} className={s.nodeButtonReply} key={i} data-pw="button-reply">
          {button.requestContact && <PhoneIcon className={s.phoneIcon} />}
          {button.requestOrder && <CatalogIcon className={s.phoneIcon} />}
          <Box pr={button.requestOrder ? "0 !important" : ""} className={s.title}>
            {button.label}
          </Box>
          {button.id && !button.requestOrder && (
            <Handle
              key={i}
              className={`${s.buttonConnector} ${actionData.isEdgeInButtonHandle(button.id) ? s.active : ""}`}
              type="source"
              position={Position.Right}
              id={`${button.id}`}
              isValidConnection={actionData.isValidButtonConnection}
              isConnectable={!actionData.isEdgeInButtonHandle(button.id)}
              data-pw="button-connector"
            />
          )}
        </Box>
      </>
    );
  }

  function setStyleForReplyTextArea() {
    if ((data?.replyMarkup as ReplyKeyboardMarkupModel)?.typeDiscriminator === FlowMarkupTypeDiscriminator.ReplyKeyboardMarkup) {
      const isSendMessageNode = data.typeDiscriminator === FlowActionType.SendTextFlowAction;
      return data.text || isSendMessageNode
        ? `${s.wrapper} ${imageType && s.messageImage}`
        : `${textType === "fileUrl" && s.wrapper} ${imageType && s.messageImage}`;
    }
    return `${s.wrapper} ${imageType && s.messageImage}`;
  }

  return (
    <div className={s.nodeContent}>
      <Box borderRadius={borderRadius || ""} className={setStyleForReplyTextArea()}>
        {(data.text || description.description) && !textType ? (
          <Box style={{ resize: "none" }} className={s.message}>
            {data.text ? (
              <Text
                color={
                  data.typeDiscriminator === FlowActionType.SendPaymentFlowAction ? "#3E5E95 !important" : "black !important"
                }
                data-pw="message-text"
              >
                {data.entities && data.entities.length > 0 ? (
                  <FormattedText text={data.text} entities={data.entities ?? []} />
                ) : (
                  formatTelegramText(data.text)
                )}
              </Text>
            ) : (
              <></>
            )}
            {description.description ? (
              <Text color="black !important" mt="4px">
                {formatTelegramText(description.description)}
              </Text>
            ) : (
              <></>
            )}
          </Box>
        ) : (
          textType === "fileUrl" &&
          dataFile.fileUrl && (
            <div style={{ resize: "none" }} className={`${s.message} ${s.messageFileUrl}`}>
              <h4 className={s.messageTitle}>{`${fnt("Image URL")}:`} </h4>
              {formatTelegramText(dataFile?.fileUrl)}
            </div>
          )
        )}
        {data?.replyMarkup &&
          textType !== "fileUrl" &&
          (data?.replyMarkup as InlineKeyboardMarkupModel)?.buttons.map(elements =>
            // eslint-disable-next-line array-callback-return
            elements.map((button: ButtonModel, i: number) => {
              if (checkIsStaticButton(button)) {
                return (
                  <div className={s.nodeButton} key={i}>
                    {button.typeDiscriminator === ButtonTypeDiscriminator.FlowMessageUrlButton && (
                      <Icon color="midDeepBlue" boxSize="16px" ml={"8px"} as={LinkIcon} />
                    )}
                    <Box className={s.title} data-pw="button-title">
                      {button.label}
                    </Box>
                    {button.typeDiscriminator === ButtonTypeDiscriminator.FlowMessageCallbackButton && (
                      <Handle
                        key={i}
                        className={`${s.buttonConnector} ${actionData.isEdgeInButtonHandle(button.id) ? s.active : ""}`}
                        type="source"
                        position={Position.Right}
                        id={`${button.id}`}
                        isValidConnection={actionData.isValidButtonConnection}
                        isConnectable={!actionData.isEdgeInButtonHandle(button.id)}
                        data-pw="inside-handle"
                      />
                    )}
                  </div>
                );
              }
              if (button.typeDiscriminator === ButtonTypeDiscriminator.FlowMessageDynamicButton) {
                return (
                  <div className={s.nodeButton} key={i}>
                    <div className={s.titleWrapper}>
                      <Icon color="inherit" fill="inherit" boxSize="20px" mr="4px" as={DynamicIcon} />
                      <div className={s.title}>{bt("Dynamic buttons")}</div>
                    </div>
                    <Handle
                      key={i}
                      className={`${s.buttonConnector} ${actionData.isEdgeInButtonHandle(button.id) ? s.active : ""}`}
                      type="source"
                      position={Position.Right}
                      id={`${button.id}`}
                      isValidConnection={actionData.isValidButtonConnection}
                      isConnectable={!actionData.isEdgeInButtonHandle(button.id)}
                    />
                  </div>
                );
              }
            }),
          )}
      </Box>
      {checkIsReplyKeyboards() && textType !== "fileUrl" && (
        <div className={`${s.wrapperReply}`}>
          {getReplyKeyboardButtons().map(elements => {
            // eslint-disable-next-line array-callback-return
            return elements.map((button: ReplyKeyboardButtonModel, i: number) => {
              if (isReplyButton(button)) {
                return getReplyKeyboardButton(button, i);
              }
            });
          })}
        </div>
      )}
    </div>
  );
};
