import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TariffPlanModel } from "./TariffPlanModel";
import { TariffPlanState } from "./TariffPlanState";
import { RootState } from "../state/store";

const initialState: TariffPlanState = {
  name: "Free TEST",
  features: [],
  currentAntispamGroupCount: 0,
  isLoadedTariffPlan: false,
};

export const tariffPlanSlice = createSlice({
  name: "TariffPlan",
  initialState,
  reducers: {
    getPlan: state => {
      return {
        ...state,
      };
    },
    getPlanCompleted: (state, action: PayloadAction<TariffPlanModel>) => {
      return {
        ...state,
        name: action.payload.name,
        features: action.payload.features,
        currentAntispamGroupCount: action.payload.currentAntispamGroupCount,
        isLoadedTariffPlan: true,
      };
    },
    setCurrentAntispamGroupCount: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        currentAntispamGroupCount: action.payload,
      };
    },
    clearPlan: state => {
      return {
        ...initialState,
      };
    },
  },
});

export const { getPlan, getPlanCompleted, clearPlan } = tariffPlanSlice.actions;

export default tariffPlanSlice.reducer;

export const selectTariffPlan = (state: RootState) => state.tariffPlanState;
export const selectTariffPlanName = (state: RootState) => state.tariffPlanState.name;
export const selectCurrentAntispamGroupCount = (state: RootState) => state.tariffPlanState.currentAntispamGroupCount;
