import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { begin, beginScope, complete, completeScope } from "../../common/loading/LoadingStateActions";
import { PayloadAction } from "@reduxjs/toolkit";
import { handleException } from "../../common/SagaHelper";
import * as botApi from "./BotApi";
import { botSlice, selectBotMenuCommands, selectBotUtmTags } from "./BotSlice";
import {
  BotData,
  BotFlowData,
  BotMenuCommandsData,
  BotUtmTagsData,
  BotVariableData,
  CreateBotVariableData,
  reorderCommandsData,
  switchCommandData,
} from "./BotData";
import { mapBotDataToBotModel, mapDataBotFlowModel } from "./BotMapper";
import {
  BotMenuCommandsModel,
  BotMenuCreateModel,
  BotModel,
  BotUtmTagCreateModel,
  BotUtmTagModel,
  BotUtmTagsModel,
  BotVariableModel,
  MenuCommandModel,
} from "./BotModel";
import { notificationSlice } from "../../common/notifications/NotificationSlice";
import { routerSlice } from "../../common/router/RouterSlice";
import { sidebarSlice } from "../sidebar/SidebarSlice";
import { deleteBotFromStorage } from "../sidebar/SidebarSaga";
import { store } from "../..";
import { t } from "i18next";
import { formatDateTimeVariableToView, formatVariableToData } from "../../common/utils/variablesMapper";
import { CustomVariableType } from "../../common/AppEnums";
import { NotFoundRequestError, ValidationError } from "../../common/ErrorModel";
import { BOT_MENU_COMMANDS_SIZE, BOT_UTM_TAGS_SIZE } from "../../common/paginator/paginatorSizes";
import { validateCommandDescription, validateCommandName } from "../../common/validation/botCommandsValidation";
import GTM from "../../common/ga/GAEventTracker";
import {
  BotCommandsEvents,
  BotDeleteEvents,
  BotFieldsEvents,
  BotInteractionEvents,
} from "../../common/ga/gaEventsEnums.ts/BotGAEventsEnums";
import { EventCategories } from "../../common/ga/gaEventCategoryEnums/EventCategoryEnums";

const trackEvent = GTM(EventCategories.Bot);

export function* botSaga() {
  yield takeLatest(botSlice.actions.getBotInfo, getBotInfo);
  yield takeLatest(botSlice.actions.deleteBot, deleteBot);
  yield takeLatest(botSlice.actions.getBotVariables, getBotVariables);
  yield takeLatest(botSlice.actions.createBotVariable, createBotVariable);
  yield takeLatest(botSlice.actions.deleteBotVariable, deleteBotVariable);
  yield takeLatest(botSlice.actions.editBotVariableValue, editBotVariableValue);
  yield takeLatest(botSlice.actions.editBotVariableDescription, editBotVariableDescription);
  yield takeLatest(botSlice.actions.checkVariableUsage, checkVariableUsage);
  yield takeLatest(botSlice.actions.changeBotToken, changeBotToken);
  yield takeLatest(botSlice.actions.getBotMenuCommands, getBotMenuCommands);
  yield takeLatest(botSlice.actions.deleteBotMenuCommand, deleteBotMenuCommand);
  yield takeLatest(botSlice.actions.addBotMenuCommand, addBotMenuCommand);
  yield takeLatest(botSlice.actions.editBotMenuCommand, editMenuCommand);
  yield takeLatest(botSlice.actions.switchBotMenuCommands, switchBotMenuCommands);
  yield takeEvery(botSlice.actions.reorderCommandsItems, reorderMenuCommands);
  yield takeLatest(botSlice.actions.getBotUtmTags, getBotUtmTags);
  yield takeLatest(botSlice.actions.addBotUtmTag, addBotUtmTag);
  yield takeLatest(botSlice.actions.deleteBotUtmTag, deleteBotUtmTag);
}

export function* getBotInfo(action: PayloadAction<{ botId: string }>) {
  try {
    yield put(begin());
    const botInfoData: BotData = yield call(botApi.getBotData, action.payload.botId);
    const botModel = mapBotDataToBotModel(botInfoData);
    yield put(botSlice.actions.getBotInfoSucceed(botModel));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

export function* changeBotToken(action: PayloadAction<{ botInfo: BotModel; token: string }>) {
  try {
    yield put(begin());
    yield call(botApi.editBot, {
      ...action.payload.botInfo,
      accessToken: action.payload.token,
    });
    yield call(botApi.setBotWebHook, action.payload.botInfo.id);
    yield put(
      notificationSlice.actions.notify({
        message: t("bot.Token refreshed successfully"),
        type: "success",
      }),
    );
    trackEvent(BotInteractionEvents.BotRefresh_Token_Completed);
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

export function* deleteBot(action: PayloadAction<string>) {
  try {
    yield put(begin());
    yield call(botApi.deleteBot, action.payload);
    yield put(sidebarSlice.actions.getBots({ page: 1 }));
    const currentBot = store.getState().app.sidebarState.bot;
    if (currentBot?.id === action.payload) {
      yield call(deleteBotFromStorage);
      yield put(sidebarSlice.actions.removeSelectedBot());
      trackEvent(BotDeleteEvents.BotDeleteCompleted);
    }
    yield put(routerSlice.actions.redirect("/bots"));
  } catch (e: unknown) {
    yield handleException(e);
    trackEvent(BotDeleteEvents.BotDeleteFailed);
  } finally {
    yield put(complete());
  }
}

export function* getBotVariables(action: PayloadAction<{ botId: string }>) {
  try {
    yield put(begin());
    const botVariablesData: BotVariableData[] = yield call(botApi.getBotVariables, action.payload.botId);
    const botVariableModel: BotVariableModel[] = botVariablesData.map(el => formatDateTimeVariableToView(el));
    yield put(botSlice.actions.getBotVariablesSucceed(botVariableModel));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

function* createBotVariable(action: PayloadAction<{ variable: BotVariableModel; botId: string }>) {
  try {
    yield put(begin());
    const createdVariable: CreateBotVariableData = yield call(botApi.createVariable, action.payload.variable);
    const { ...rest } = createdVariable;
    const variable = { ...rest, value: action.payload.variable.value ?? "" };
    yield call(botApi.editVariableValue, action.payload.botId, formatVariableToData(variable));
    yield put(
      notificationSlice.actions.notify({
        message: "Created successfully!",
        type: "success",
      }),
    );
    trackEvent(BotFieldsEvents.BotFieldsCreateComplete);
    yield put(botSlice.actions.getBotVariables({ botId: action.payload.botId }));
  } catch (e: unknown) {
    yield handleException(e);
    trackEvent(BotFieldsEvents.BotFieldsCreateFailed);
  } finally {
    yield put(complete());
  }
}

function* deleteBotVariable(action: PayloadAction<{ variableId: string; botId: string }>) {
  try {
    yield put(begin());
    yield call(botApi.deleteVariable, action.payload.variableId);
    trackEvent(BotFieldsEvents.BotFieldsDeleteComplete);
    yield put(botSlice.actions.getBotVariables({ botId: action.payload.botId }));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

function* editBotVariableValue(
  action: PayloadAction<{
    variableId: string;
    variable: BotVariableModel;
    botId: string;
  }>,
) {
  try {
    validateVariable(action.payload.variable);

    yield put(begin());
    const { ...rest } = action.payload.variable;
    const variableData = rest as BotVariableData;
    yield call(botApi.editVariableValue, action.payload.botId, formatVariableToData(variableData));
    yield put(
      notificationSlice.actions.notify({
        message: "Value saved successfully!",
        type: "success",
      }),
    );
    yield put(botSlice.actions.getBotVariables({ botId: action.payload.botId }));
  } catch (e: unknown) {
    if (e instanceof ValidationError) {
      yield put(
        notificationSlice.actions.notify({
          message: e.validationData.errors[0].message,
          type: "error",
        }),
      );
      return;
    }
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

function* editBotVariableDescription(
  action: PayloadAction<{
    variableId: string;
    variable: BotVariableModel;
    botId: string;
  }>,
) {
  try {
    yield put(begin());
    const { ...rest } = action.payload.variable;

    yield call(botApi.editVariableDefenition, action.payload.variableId, rest);
    yield put(
      notificationSlice.actions.notify({
        message: "Value saved successfully!",
        type: "success",
      }),
    );
    yield put(botSlice.actions.getBotVariables({ botId: action.payload.botId }));
  } catch (e: unknown) {
    if (e instanceof ValidationError) {
      yield put(
        notificationSlice.actions.notify({
          message: e.validationData.errors[0].message,
          type: "error",
        }),
      );
      return;
    }
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

function* checkVariableUsage(action: PayloadAction<string>) {
  try {
    yield put(beginScope("checkVariableUsage"));
    const useInfo: BotFlowData = yield call(botApi.getIsVariableInUse, action.payload);
    const flows = mapDataBotFlowModel(useInfo);
    yield put(botSlice.actions.checkVariableUsageComplete(flows));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("checkVariableUsage"));
  }
}

export const validateVariable = (variable: BotVariableModel) => {
  if (!variable.value) {
    return;
  }
  switch (variable.type) {
    case CustomVariableType.Number: {
      if (variable.value.length >= 20) {
        throw new ValidationError({
          errors: [{ field: "error", message: "Max value exceeded" }],
        });
      }
      return;
    }
    case CustomVariableType.Text: {
      if (!variable.value.trim().length) {
        throw new ValidationError({
          errors: [{ field: "error", message: "Value can not contain only spaces" }],
        });
      }
      return;
    }
  }
};

function* getBotMenuCommands(action: PayloadAction<{ botId: string; page?: number; size?: number }>) {
  try {
    const prevCommandList: BotMenuCommandsModel = yield select(selectBotMenuCommands);
    const currentPage = action.payload.page ?? 1;
    const currentSize = action.payload.size ?? BOT_MENU_COMMANDS_SIZE;
    const botMenuCommandsData: BotMenuCommandsData = yield call(
      botApi.getBotMenuCommands,
      action.payload.botId,
      currentPage,
      currentSize,
    );
    const commandItemsData = botMenuCommandsData.items;
    const prevCommandItems: MenuCommandModel[] = prevCommandList?.items.map(el => el) ?? [];
    const concatedItems =
      action.payload.page && action.payload.page > 1 ? concatCommands(commandItemsData, prevCommandItems) : commandItemsData;

    const commandModel: BotMenuCommandsModel = {
      items: concatedItems.sort((a, b) => a.order - b.order),
      totalItems: botMenuCommandsData.totalItems,
      totalPages: botMenuCommandsData.totalPages,
      currentPage: botMenuCommandsData.currentPage,
    };
    yield put(botSlice.actions.getBotMenuCommandsCompleted(commandModel));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

export const concatCommands = (model: MenuCommandModel[], prevModel: MenuCommandModel[]) => {
  const commandsIds = model.map(el => el.id);
  const tuplePrevModel = prevModel.filter(el => !commandsIds.includes(el.id));
  const newModel = [...model];
  newModel.push(...tuplePrevModel);
  return newModel;
};

function* deleteBotMenuCommand(action: PayloadAction<{ botId: string; commandId: string }>) {
  try {
    const commandList: BotMenuCommandsModel = yield select(selectBotMenuCommands);

    yield put(begin());
    yield call(botApi.deleteBotMenuCommand, action.payload.botId, action.payload.commandId);
    trackEvent(BotCommandsEvents.BotCommandsDeleteComplete);
    yield put(
      botSlice.actions.getBotMenuCommands({
        botId: action.payload.botId,
        page: 1,
        size: commandList.totalItems,
      }),
    );
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

function* addBotMenuCommand(action: PayloadAction<{ botId: string; data: BotMenuCreateModel }>) {
  try {
    yield put(begin());
    const nameError = validateCommandName(action.payload.data.command, t);
    const descriptionError = validateCommandDescription(action.payload.data.description, t);
    if (nameError || descriptionError) {
      yield put(
        notificationSlice.actions.notify({
          message: nameError || descriptionError,
          type: "error",
        }),
      );
      return;
    }
    yield call(botApi.addBotMenuCommandData, action.payload.botId, action.payload.data);
    yield put(
      notificationSlice.actions.notify({
        message: "Created successfully!",
        type: "success",
      }),
    );
    trackEvent(BotCommandsEvents.BotCommandsNewCompleted);
    yield put(
      botSlice.actions.getBotMenuCommands({
        botId: action.payload.botId,
        page: 1,
        size: BOT_MENU_COMMANDS_SIZE,
      }),
    );
  } catch (e: unknown) {
    trackEvent(BotCommandsEvents.BotCommandsCreateFailed);
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

function* editMenuCommand(action: PayloadAction<{ botId: string; data: MenuCommandModel }>) {
  const { data } = action.payload;
  const { command, description } = data;

  const commandNameError = validateCommandName(command, t);
  if (commandNameError) {
    yield put(
      notificationSlice.actions.notify({
        message: commandNameError,
        type: "error",
      }),
    );
    yield put(botSlice.actions.editBotMenuCommandFailed());
    return;
  }

  const commandDescriptionError = validateCommandDescription(description, t);
  if (commandDescriptionError) {
    yield put(
      notificationSlice.actions.notify({
        message: commandDescriptionError,
        type: "error",
      }),
    );
    yield put(botSlice.actions.editBotMenuCommandFailed());
    return;
  }

  try {
    yield put(begin());
    yield call(botApi.editBotMenuCommand, action.payload.botId, action.payload.data);
    yield put(botSlice.actions.editBotMenuCommandSucceded(action.payload.data));
    yield put(
      notificationSlice.actions.notify({
        message: "Saved successfully!",
        type: "success",
      }),
    );
  } catch (e: unknown) {
    yield put(botSlice.actions.editBotMenuCommandFailed());
    if (e instanceof ValidationError) {
      yield put(
        notificationSlice.actions.notify({
          message: e.validationData.errors[0].message,
          type: "error",
        }),
      );
      return;
    }
    if (!(e instanceof NotFoundRequestError)) {
      yield handleException(e);
    }
  } finally {
    const commandList: BotMenuCommandsModel = yield select(selectBotMenuCommands);

    yield put(
      botSlice.actions.getBotMenuCommands({
        botId: action.payload.botId,
        page: 1,
        size: commandList.totalItems,
      }),
    );
    yield put(complete());
  }
}

function* switchBotMenuCommands(action: PayloadAction<{ botId: string; data: switchCommandData }>) {
  try {
    yield call(botApi.switchBotMenuCommands, action.payload.botId, action.payload.data);
    yield put(
      botSlice.actions.getBotMenuCommands({
        botId: action.payload.botId,
        page: 1,
        size: BOT_MENU_COMMANDS_SIZE,
      }),
    );
  } catch (e: unknown) {
    yield handleException(e);
  }
}

function* reorderMenuCommands(action: PayloadAction<{ botId: string; data: reorderCommandsData }>) {
  try {
    const commandList: BotMenuCommandsModel = yield select(selectBotMenuCommands);

    yield call(botApi.reorderCommandsItems, action.payload.botId, action.payload.data);
    yield put(
      notificationSlice.actions.notify({
        message: "Saved successfully!",
        type: "success",
      }),
    );
    yield put(
      botSlice.actions.getBotMenuCommands({
        botId: action.payload.botId,
        page: 1,
        size: commandList.totalItems,
      }),
    );
  } catch (e: unknown) {
    yield handleException(e);
  }
}

function* getBotUtmTags(action: PayloadAction<{ botId: string; page?: number; size?: number }>) {
  try {
    const prevUtmTagsList: BotUtmTagsModel = yield select(selectBotUtmTags);
    const currentPage = action.payload.page ?? 1;
    const currentSize = action.payload.size ?? BOT_UTM_TAGS_SIZE;
    const botUtmTagsData: BotUtmTagsData = yield call(botApi.getBotUtmTags, action.payload.botId, currentPage, currentSize);
    const utmTagItemsData = botUtmTagsData.items;
    const prevUtmTagItems: BotUtmTagModel[] = prevUtmTagsList?.items.map(el => el) ?? [];
    const concatedItems =
      action.payload.page && action.payload.page > 1 ? [...prevUtmTagItems, ...utmTagItemsData] : utmTagItemsData;
    const utmTagsModel: BotUtmTagsModel = {
      items: concatedItems,
      totalItems: botUtmTagsData.totalItems,
      totalPages: botUtmTagsData.totalPages,
      currentPage: botUtmTagsData.currentPage,
    };
    yield put(botSlice.actions.getBotUtmTagsCompleted(utmTagsModel));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

function* deleteBotUtmTag(action: PayloadAction<{ botId: string; tagId: string }>) {
  try {
    yield put(begin());
    yield call(botApi.deleteBotUtmTag, action.payload.botId, action.payload.tagId);
    yield put(
      botSlice.actions.getBotUtmTags({
        botId: action.payload.botId,
        page: 1,
        size: BOT_MENU_COMMANDS_SIZE,
      }),
    );
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

function* addBotUtmTag(action: PayloadAction<{ botId: string; data: BotUtmTagCreateModel }>) {
  try {
    yield put(begin());
    yield call(botApi.addBotUtmTag, action.payload.botId, action.payload.data);
    yield put(
      notificationSlice.actions.notify({
        message: "Created successfully!",
        type: "success",
      }),
    );
    yield put(
      botSlice.actions.getBotUtmTags({
        botId: action.payload.botId,
        page: 1,
        size: BOT_UTM_TAGS_SIZE,
      }),
    );
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}
