import { Box, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import i18n from "../../../common/i18next/i18n";
import s from "./AuthPrivacyTextContainer.module.scss";
import { AppSettings } from "../../../common/AppSettings";

declare const appSettings: AppSettings;
interface Props {
  authPrivacyTextView: boolean;
}

export const AuthPrivacyTextContainer = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "auth" });
  const langPostfix = i18n.language === "en";

  return (
    <Box className={`${s.authBottomText} ${!props.authPrivacyTextView && s.authBottomTextOpacity}`}>
      <Text className={s.authLeftText}>
        {t("By signing up, you agree to Domino’s")}
        <a
          className={s.authTextLink}
          href={`${langPostfix ? appSettings.landingUrlEn : appSettings.landingUrlRu}/docs/terms-of-use`}
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          {t("Terms of Service")}{" "}
        </a>
        {t("and")}
        <a
          className={s.authTextLink}
          href={`${langPostfix ? appSettings.landingUrlEn : appSettings.landingUrlRu}/docs/privacy-policy`}
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          {t("Privacy Policy")}{" "}
        </a>{" "}
        {t("Privacy Policy Domino CRM")}
      </Text>
    </Box>
  );
};
