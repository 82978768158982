import { CustomVariableType } from "../../../../common/AppEnums";

export const getPlaceholder = (type: CustomVariableType) => {
  if (type === CustomVariableType.Array) {
    return "Enter array values, separated by commas";
  }
  if (type === CustomVariableType.Number) {
    return "Enter digits";
  }
  return "Enter text";
};

export const numberValidated = (text: string) => {
  const numberRegex = /^-?(\d*)$|^-\d*$/;
  return numberRegex.test(text);
};
