import { Box, Button, Flex, Heading, Image, Text } from "@chakra-ui/react";
import s from "./BotListItem.module.scss";
import telegramImage from "../../../../assets/images/telegramImage.svg";
import PlusIcon from "../../../../assets/icons/plusIconTile.svg?react";
import { BotModel } from "../../BotListModel";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGAWithCustomParameters } from "../../../../common/ga/GAEventTracker";
import { BotFlowEvents, BotInteractionEvents } from "../../../../common/ga/gaEventsEnums.ts/BotGAEventsEnums";
import { EventCategories } from "../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import { LimitStarPlanButton } from "../../../../UI/molecules/limitStarPlanButton/LimitStarPlanButton";
import { ICountTariffPlanFeature, TariffPlanFeatureTypeEnum } from "../../../../common/AppEnums";
import { useSelectLimitPlan } from "../../../../common/hooks/useSelectLimitPlan";
import { selectOrganisation } from "../../../organisation/OrganisationSlice";
import { useAppSelector } from "../../../../common/state/store";

interface Props {
  info: BotModel;
  currentPage: number;
  createNewFlow: (botInfo: BotModel) => void;
  redirectToBotFlows: (bot: BotModel) => void;
}

export const BotListItem = ({ info, createNewFlow, currentPage, redirectToBotFlows }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "botList" });
  const navigate = useNavigate();

  const trackEvent = useGAWithCustomParameters(EventCategories.Bot);
  const activeFlows = useSelectLimitPlan(TariffPlanFeatureTypeEnum.ActiveFlows) as ICountTariffPlanFeature;
  const organisation = useAppSelector(selectOrganisation);
  const canAddFlow = activeFlows.IsUnlimited || activeFlows.MaxCount > Number(organisation?.totalFlow);

  return (
    <Box
      className={s.botListItem}
      justifyContent={"space-between"}
      onClick={() => {
        trackEvent(BotInteractionEvents.BotView);
        navigate(`/bots/${info.id}`, { state: { page: currentPage, channel: info?.channel } });
      }}
      data-pw="botListItem"
    >
      <Box className={s.botInfo} flexDirection={"column"} justifyContent={"space-between"}>
        <Text className={s.mobileTitle}>{t("Bot")}:</Text>
        <Heading fontSize={"16px"} lineHeight={"20px"} fontWeight={"400"} className={s.mobTextEllips}>
          {info.name}
        </Heading>
      </Box>
      <Box className={s.botInfo}>
        <Text className={s.mobileTitle}>{t("Username")}:</Text>
        <Flex alignItems={"center"} overflow={{ base: "hidden", md: "visible" }}>
          <Image w={"20px"} h={"20px"} mr={"8px"} src={telegramImage} />
          <Box fontSize={"15px"} className={s.mobTextEllips}>
            {" "}
            @{info.username}
          </Box>
        </Flex>
      </Box>

      <Box className={s.botInfo}>
        <Text className={s.mobileTitle}>{t("Flows")}:</Text>
        <Box
          className={s.flowLink}
          onClick={e => {
            e.stopPropagation();
            redirectToBotFlows(info);
          }}
        >
          {info.flowCount}
        </Box>
      </Box>
      <Box className={s.flowInfo} flexDirection={"column"} alignItems={"flex-end"} justifyContent={"space-between"}>
        {!canAddFlow ? (
          <LimitStarPlanButton
            buttonName="New Flow"
            keyPrefix="botList"
            popoverBodyDescription="Please upgrade your plan to create more Flows"
            variant="dominoDashedViolet"
            dataPw="new-flow-button"
            iconFill={"white"}
            iconStroke="#6d5bf7"
          />
        ) : (
          <Button
            variant="dominoDashedViolet"
            onClick={e => {
              e.stopPropagation();
              trackEvent(BotFlowEvents.BotNewFlow);
              createNewFlow(info);
            }}
            data-pw="new-flow-button"
          >
            <Flex gap={"8px"} align={"center"}>
              <PlusIcon />
              {t("New Flow")}
            </Flex>
          </Button>
        )}
      </Box>
    </Box>
  );
};
