import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuList,
  Text,
} from "@chakra-ui/react";
import s from "../../../../ContactInfo.module.scss";
import { useTranslation } from "react-i18next";
import { AssignedItemModel } from "../../../../../conversation/ConversationModel";
import Magnifire from "../../../../../../assets/images/magnifire.svg?react";
import CrossIcon from "../../../../../../assets/icons/cross.svg?react";
import DownArrowMenu from "../../../../../../assets/images/downArrow.svg?react";
import { Dispatch, SetStateAction } from "react";
import { OrganisationTeamsModel } from "../../../../../organisation/OrganisationModel";
import { CustomMenuItem } from "../../../../../../UI/molecules/customMenuItem/CustomMenuItem";

interface Props {
  onMenuClose: (type: "Team" | "Operator") => void;
  assignState: { team?: AssignedItemModel | undefined; operator?: AssignedItemModel | undefined };
  teamSearchValue: string;
  onSearchTextChange: (value: string, type: "Team" | "Operator") => void;
  getAdditionalItems: (item: "Team" | "Operator") => void;
  setAssignState: Dispatch<SetStateAction<{ team?: AssignedItemModel | undefined; operator?: AssignedItemModel | undefined }>>;
  teams: OrganisationTeamsModel | undefined;
  isDisabled?: boolean;
}

export const ContactInfoAssignedTeam = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "contactInfo" });
  return (
    <>
      <Heading fontSize={"16px"} fontWeight={"400"}>
        {t("Assigned Team")}
      </Heading>
      <Menu
        preventOverflow={false}
        matchWidth={true}
        variant="dominoGroupedMenu"
        gutter={0}
        onClose={() => props.onMenuClose("Team")}
      >
        {({ isOpen, onClose }) => (
          <>
            <MenuButton
              _active={{ borderColor: "line", backgroundColor: "white" }}
              as={Button}
              isDisabled={props.isDisabled}
              className={s.menuButton}
              variant="dominoDefaultMenuBtn"
              isActive={isOpen}
              data-pw="assigned-team-dropdown"
            >
              {props.assignState.team?.name ? (
                <Text className={s.text} fontSize={"15px"}>
                  {props.assignState.team.name}
                </Text>
              ) : (
                <Text fontSize={"15px"} color={"darkGrey"}>
                  {t("Select")}
                </Text>
              )}
            </MenuButton>
            <MenuList w="100%" position="absolute" top="-37px">
              <Box className={s.dropdownSearchBox}>
                <InputGroup w="auto">
                  <InputLeftElement pointerEvents="none">
                    <Magnifire />
                  </InputLeftElement>
                  <Input
                    isInvalid={false}
                    autoFocus
                    value={props.teamSearchValue}
                    placeholder={t(`Search by Teams`) ?? ""}
                    onChange={e => props.onSearchTextChange(e.target.value, "Team")}
                    data-pw="search-teams"
                  />
                  <InputRightElement right="10px" top="50%" mt={"-11px"} boxSize="22px">
                    {props.teamSearchValue && (
                      <CrossIcon className={s.crossIcon} cursor="pointer" onClick={() => props.onSearchTextChange("", "Team")} />
                    )}
                  </InputRightElement>
                </InputGroup>
              </Box>
              <Box overflow="scroll" maxH="300px">
                <CustomMenuItem
                  h="40px"
                  fontSize="15px"
                  lineHeight="18px"
                  onClick={() => {
                    props.setAssignState(prevState => {
                      return { ...prevState, team: { id: "", name: "" } };
                    });
                    onClose();
                  }}
                  data-pw="not-set"
                >
                  {t("Not set")}
                </CustomMenuItem>
                {props.teams?.items.map((el, i) => {
                  return (
                    <CustomMenuItem
                      key={i}
                      pr="0"
                      h="40px"
                      fontSize="15px"
                      lineHeight="18px"
                      onClick={() => {
                        props.setAssignState(prevState => {
                          return { ...prevState, team: { id: el.id, name: el.name } };
                        });
                        onClose();
                      }}
                      data-pw={el.name}
                    >
                      <Flex overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" w="100%" h="17px">
                        {el.name}
                      </Flex>
                    </CustomMenuItem>
                  );
                })}
                {props.teams && props.teams.currentPage < props.teams.totalPages && (
                  <CustomMenuItem
                    closeOnSelect={false}
                    onClick={() => props.getAdditionalItems("Team")}
                    height={{ base: "40px", lg: "40px" }}
                    fontSize="15px"
                    lineHeight="18px"
                    data-pw="show-more-teams"
                  >
                    <Flex alignItems={"center"} justifyContent={"center"} w={"100%"} cursor={"pointer"}>
                      <div>{t("Show more")}</div>
                      <Icon as={DownArrowMenu} boxSize="26px" color="midDeepBlue" />
                    </Flex>
                  </CustomMenuItem>
                )}
              </Box>
            </MenuList>
          </>
        )}
      </Menu>
    </>
  );
};
