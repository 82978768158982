import { Box, Skeleton } from "@chakra-ui/react";
import React from "react";

interface Props {
  count: number;
}

export const SkeletonRecipientsList: React.FC<Props> = ({ count }) => {
  return (
    <Box padding={"0 16px"}>
      {Array(count)
        .fill(1)
        .map((_, index) => (
          <Box key={index} display="flex" alignItems="center" mb="8px">
            <Skeleton
              width={"20px"}
              height={"20px"}
              mr={"6px"}
              speed={0.5}
              borderRadius="4px"
              startColor="line"
              endColor="bgLight"
            />
            <Skeleton
              width={"85px"}
              height={"22px"}
              mr={"6px"}
              speed={0.5}
              borderRadius="12px"
              startColor="line"
              endColor="bgLight"
            />
            <Skeleton width={"75px"} height={"22px"} speed={0.5} borderRadius="12px" startColor="line" endColor="bgLight" />
          </Box>
        ))}
    </Box>
  );
};
