import { Box, Button, Flex, Text, Skeleton } from "@chakra-ui/react";
import s from "../../Organisation.module.scss";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../common/state/store";
import { selectBillingPlan, selectLanguage } from "../../../../common/user/UserSlice";
import { AppSettings } from "../../../../common/AppSettings";
import dayjs from "dayjs";
import { useGetLoadingState } from "../../../../common/loading/hooks/useGetLoadingState";

declare const appSettings: AppSettings;

export const CurrentPlanInfo = () => {
  const billingPlan = useAppSelector(selectBillingPlan);
  const { t } = useTranslation("translation", { keyPrefix: "organisation" });
  const link = appSettings.billingPortalUrl;
  const locale = useAppSelector(selectLanguage);
  const isGetProfileLoading = useGetLoadingState("getProfile");

  const cleanTime = (time: string) => {
    const date = dayjs(time);
    const formatDate = date.locale(locale).format(locale === "ru" ? "DD MMMM, YYYY" : "MMMM DD, YYYY");
    return locale === "ru"
      ? formatDate.slice(0, 3) + formatDate.slice(3, 4).toUpperCase() + formatDate.slice(4, formatDate.length)
      : formatDate;
  };

  return (
    <Box className={s.currentPlanInfo}>
      <Skeleton isLoaded={!isGetProfileLoading} speed={0.5} startColor="line" endColor="bgLight" borderRadius="8px">
        <Flex className={s.mainFlexContainer}>
          <Box borderBottom={"1px solid"} borderColor="bgMiddle" py="16px" className={`${s.userListItemGrid}`}>
            <Flex className={s.currentPlanFlex}>
              <Flex className={s.tariffBlock}>
                <Text style={{ textWrap: "nowrap" }} variant="h2">
                  {t("Your Plan:")}
                </Text>
                <Text fontWeight={700} variant="h2" noOfLines={1}>
                  {billingPlan?.billingPlanName}
                </Text>
              </Flex>
              <Flex gap="4px">
                <Text style={{ textWrap: "nowrap" }} variant="medium">
                  {!billingPlan?.endDate ? t("Unlimited") : t("Active until:")}
                </Text>
                <Text fontWeight={700} variant="medium" noOfLines={1}>
                  {billingPlan?.endDate ? cleanTime(billingPlan?.endDate) : ""}
                </Text>
              </Flex>
            </Flex>
            <Box className={s.button} mr="24px">
              <Button size="sm" variant="dominoOutlineViolet" onClick={() => window.open(link, "_blank")}>
                {t("Manage your plan")}
              </Button>
            </Box>
          </Box>
        </Flex>
      </Skeleton>
    </Box>
  );
};
