import isUrl from "is-url";
import { EditorType, ElementTypes, LinkElementType } from "./types";
import { convertText, getTextBySubstring, wrapLink } from "./utils";
import { Operation } from "slate";
import { SLATE_INITIAL_VALUE } from "./consts";

export const withInlines = (editor: EditorType) => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = element => [ElementTypes.Link].includes(element.type) || isInline(element);

  editor.insertText = text => {
    if (text && isUrl(text)) {
      wrapLink(editor, text, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = data => {
    const text = data.getData("text/plain");

    if (text && isUrl(text)) {
      wrapLink(editor, text, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

export const withMaxLength = (editor: EditorType, maxLength: number) => {
  const { apply } = editor;
  editor.charactersLeft = maxLength;
  editor.maxLength = maxLength;

  editor.apply = operation => {
    const isInsertText = operation.type === "insert_text";
    const isInsertNode = operation.type === "insert_node";
    const isInsert = isInsertText || isInsertNode;

    const substr = editor.charactersLeft;

    if (isInsert) {
      if (substr > 0) {
        if (isInsertText) {
          operation.text = getTextBySubstring(operation.text, substr);
          apply(operation);
        }
        if (isInsertNode) {
          if ("text" in operation.node) {
            operation.node.text = getTextBySubstring(operation.node.text, substr);
          } else if ("children" in operation.node && "text" in operation.node.children[0]) {
            operation.node.children[0].text = getTextBySubstring(operation.node.children[0].text, substr);
          }

          apply(operation);
        }
      } else {
        apply(operation);
        apply(Operation.inverse(operation));
        apply(Operation.inverse(editor.operations[editor.operations.length - 3]));
      }
    } else {
      apply(operation);
    }

    // Delete link element if link text empty
    if (operation.type === "remove_text") {
      if (operation.path.length === 3) {
        const element = editor.children[operation.path[0]];
        if ("children" in element) {
          const linkLeaf = element.children[operation.path[1]] as unknown as LinkElementType;
          if ("children" in linkLeaf) {
            const linkText = linkLeaf.children[operation.path[2]].text;
            if (!linkText.length) {
              apply({ type: "remove_node", node: linkLeaf, path: [operation.path[0], operation.path[1]] });
            }
          }
        }
      }
    }

    editor.charactersLeft = editor.maxLength - convertText.fromNodesToText(editor).length;

    if (!editor.children.length) {
      editor.children = SLATE_INITIAL_VALUE;
    }
  };

  return editor;
};
