import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const dominoViolet = defineStyle({
  px: "24px",
  borderRadius: "8px",
  fontSize: "15px",
  backgroundColor: "mainPurple",
  color: "white",
  fontWeight: "400",

  _hover: {
    bg: "darkPurple",
    color: "white",

    _disabled: {
      opacity: "0.4",
      backgroundColor: "mainPurple",
      transform: "none",
    },
  },
});

const dominoGhostViolet = defineStyle({
  px: "24px",
  borderRadius: "8px",
  fontSize: "15px",
  backgroundColor: "transparent",
  color: "mainPurple",
  fontWeight: "400",

  // _hover: {
  //   bg: 'darkPurple',
  //   color: 'white',

  //   _disabled: {
  //     opacity: '0.4',
  //     backgroundColor: 'mainPurple',
  //     transform: 'none'
  //   },
  // },
});

const dominoDashedViolet = defineStyle({
  width: "100%",
  border: "1px dashed var(--chakra-colors-line)",
  borderRadius: "8px",
  fontSize: "15px",
  backgroundColor: "white",
  color: "#6D5BF7",
  fontWeight: "400",

  _hover: {
    bg: "defaultGrey",
    borderColor: "#4F3DED",
    color: "#4F3DED",
    ".starPlanHover": {
      fill: "#4F3DED",
      stroke: "#4F3DED",
    },
  },
  _active: {
    bg: "defaultGrey",
    borderColor: "darkPurple",
    color: "darkPurple",
  },
  _focusVisible: {
    bg: "defaultGrey",
    borderColor: "darkPurple",
    color: "darkPurple",
  },
});

const dominoOutlineViolet = defineStyle({
  px: "24px",
  border: "1px solid #6D5BF7",
  borderRadius: "8px",
  fontSize: "15px",
  backgroundColor: "white",
  color: "#6D5BF7",
  fontWeight: "400",

  _hover: {
    bg: "defaultGrey",
    color: "#4F3DED",
    borderColor: "#4F3DED",
    ".starPlanHover": {
      fill: "#4F3DED",
      stroke: "#4F3DED",
    },
  },
});

const dominoOrganizationPlanOutlineViolet = defineStyle({
  px: "24px",
  fontSize: "15px",
  backgroundColor: "mainPurple",
  color: "white",
  fontWeight: "400",
  borderRadius: "0px 0px 12px 12px",
  _hover: {
    bg: "darkPurple",
    color: "white",

    _disabled: {
      opacity: "0.4",
      backgroundColor: "mainPurple",
      transform: "none",
    },
  },
});

const dominoTariffPlanBlack = defineStyle({
  marginBottom: "10px",
  px: "24px",
  borderRadius: "8px",
  fontSize: "15px",
  backgroundColor: "#232B39",
  color: "white",
  fontWeight: "400",

  _hover: {
    bg: "#050D1B",
  },
});

const dominoOutlineGray = defineStyle({
  px: "24px",
  border: "1px solid #F1F4F9",
  borderRadius: "8px",
  fontSize: "15px",
  backgroundColor: "white",
  color: "black",
  fontWeight: "400",

  _hover: {
    border: "1px solid #6D5BF7",
  },
});

const dominoPrimaryRed = defineStyle({
  px: "24px",
  borderRadius: "8px",
  fontSize: "15px",
  backgroundColor: "mainRed",
  color: "white",
  fontWeight: "400",

  _hover: {
    bg: "#CC4631",
    _disabled: {
      bg: "#CC4631",
    },
  },
});

const dominoOutlineRed = defineStyle({
  px: "24px",
  border: "1px solid",
  borderColor: "mainRed",
  borderRadius: "8px",
  fontSize: "15px",
  backgroundColor: "white",
  color: "mainRed",
  fontWeight: "400",

  _hover: {
    bg: "#F2F5F7",
  },

  _disabled: {
    opacity: "1",
    bg: "white",
    color: "#BCC3CD",
    borderColor: "line",
    _hover: {
      borderColor: "line",
    },
  },
});

const sideBar = defineStyle({
  color: "midDeepBlue",
  alignItems: "center",
  justifyContent: "flex-start",
  borderRadius: "8px",
  height: "36px",
  fontSize: "15px",
  padding: "8px 12px",
  lineHeight: "143%",
  fill: "midDeepBlue",
  fontWeight: 400,

  _hover: {
    color: "darkPurple",
    fill: "darkPurple",

    _disabled: {
      color: "midDeepBlue",
      fill: "midDeepBlue",
    },
  },

  _active: {
    color: "darkPurple",
    fill: "darkPurple",
  },
});

const dashboardBar = defineStyle({
  color: "black",
  alignItems: "center",
  justifyContent: "flex-start",
  borderRadius: "8px",
  height: "36px",
  fontSize: "15px",
  padding: "0px",
  lineHeight: "143%",
  fill: "midDeepBlue",
  fontWeight: 700,

  _hover: {
    color: "black",
    fill: "black",
  },

  _active: {
    color: "black",
    fill: "black",
  },
});

const profileButton = defineStyle({
  color: "black",
  minW: "250px",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "8px",
  border: "1px solid var(--chakra-colors-line)",
  height: "36px",
  fontSize: "15px",
  padding: "8px",
  textAlign: "left",
  lineHeight: "20px",
  fill: "midDeepBlue",
  fontWeight: 400,

  _hover: {
    color: "black",
    fill: "black",
  },

  _active: {
    color: "black",
    fill: "black",
  },
});

const broadcastBar = defineStyle({
  color: "black",
  alignItems: "center",
  justifyContent: "flex-start",
  borderRadius: "8px",
  height: "36px",
  fontSize: "15px",
  padding: "0px",
  lineHeight: "143%",
  fill: "midDeepBlue",
  fontWeight: 700,

  _hover: {
    color: "darkPurple",
    fill: "darkPurple",
  },

  _active: {
    color: "black",
    fill: "black",
  },
});

const dominoMenuBtn = defineStyle({
  backgroundColor: "#FFFFFF",
  display: "flex",
  height: "60px",
  fontWeight: "400",
  borderRadius: "0",
  fontSize: "15px",
  lineHeight: "123%",
  width: "100%",
  padding: "14px",
  justifyContent: "flex-start",
  stroke: "midDeepBlue",
  fill: "midDeepBlue",

  _hover: {
    backgroundColor: "bgLight",
  },

  _active: {
    backgroundColor: "defaultGrey",
  },
});

const dominoSwitchBtn = defineStyle({
  backgroundColor: "#FFFFFF",
  display: "flex",
  height: "60px",
  fontWeight: "400",
  borderRadius: "0",
  fontSize: "15px",
  lineHeight: "123%",
  width: "100%",
  padding: "14px",
  justifyContent: "flex-start",
  stroke: "midDeepBlue",
  fill: "midDeepBlue",

  _hover: {
    backgroundColor: "bgLight",
    color: "mainPurple",
  },

  _active: {
    backgroundColor: "line",
    color: "darkPurple",
    stroke: "darkPurple",
  },
});

const dominoGhost = defineStyle({
  fontSize: "15px",
  lineHeight: "20px",
  fontWeight: "400",
  height: "20px",
  color: "darkGrey",
  _active: {
    color: "mainPurple",
    _hover: {
      color: "darkPurple",
    },
  },
  _hover: {
    color: "#515964",
  },
});

const dominoGhostLng = defineStyle({
  fontSize: "15px",
  lineHeight: "20px",
  fontWeight: "400",
  color: "#C1B9FF",
  padding: "8px 16px",
  borderRadius: "8px",
  _active: {
    color: "mainPurple",
    bg: "bgMiddle",
  },
  _hover: {
    color: "mainPurple",
  },
});

const dominoGhostPaginator = defineStyle({
  width: "24px",
  height: "24px",
  fontSize: "15px",
  lineHeight: "20px",
  fontWeight: "400",
  color: "darkGrey",
  padding: "7px 10px",
  gap: "4px",
  borderRadius: "4px",
  _active: {
    color: "newMiddleDeepBlue",
    bg: "lightDeepBlue",
  },
  _hover: {
    bg: "white",
    color: "darkGrey",
    border: "1px solid var(--chakra-colors-line)",
  },
});

const dominoBlueGhost = defineStyle({
  fontSize: "15px",
  lineHeight: "20px",
  fontWeight: "400",
  height: "20px",
  color: "midDeepBlue",
  fill: "midDeepBlue",
  _active: {
    color: "deepBlue",
    _hover: {
      color: "deepBlue",
      fill: "deepBlue",
    },
  },
  _hover: {
    color: "deepBlue",
    fill: "deepBlue",
  },
});

const dominoTextareaIcon = defineStyle({
  backgroundColor: "transparent",
  color: "darkGrey",
  fill: "darkGrey",
  minWidth: "24px",

  _active: {
    color: "white",
    fill: "white",
    bgColor: "accentBlue",
  },

  _expanded: {
    bg: "accentBlue",
    color: "white",
    fill: "white",
  },
  "@media(hover: hover)": {
    _hover: {
      bg: "accentBlue",
      color: "white",
      fill: "white",
      _disabled: {
        color: "darkGrey",
        fill: "darkGrey",
      },
    },
  },
});

const dominoFillIconButton = defineStyle({
  backgroundColor: "transparent",
  color: "darkGrey",
  fill: "darkGrey",
  minWidth: "24px",

  _hover: {
    color: "blueLink",
    fill: "blueLink",
  },

  _active: {
    color: "blueLink",
    fill: "blueLink",
  },

  _disabled: {
    opacity: 1,
    color: "blueLink",
    fill: "blueLink",
  },
});

const dominoDefaultMenuBtn = defineStyle({
  backgroundColor: "#FFFFFF",
  display: "flex",
  height: "36px",
  fontWeight: "400",
  fontSize: "15px",
  lineHeight: "123%",
  width: "100%",
  padding: "14px 8px 14px 14px",
  justifyContent: "flex-start",
  stroke: "darkGrey",
  fill: "darkGrey",
  borderRadius: "8px",
  borderBottomColor: "line",
  border: "1px solid",
  borderColor: "line",
  textAlign: "left",

  _hover: {
    backgroundColor: "bgLight",
  },

  _active: {
    backgroundColor: "defaultGrey",
    borderBottom: "0",
    borderRadius: "8px 8px 0 0",
    borderColor: "blueLink",
    borderBottomColor: "line",
  },

  _disabled: {
    opacity: 1,
    borderColor: "line",
    color: "darkGrey",
    fill: "darkGrey",
    backgroundColor: "#FFFFFF",
  },

  span: {
    overflow: "hidden",
  },
});

const iconDotTheme = defineStyle({
  bg: "transparent",
  borderRadius: "8px",
  fill: "midDeepBlue",
  width: "32px",
  height: "32px",
  lineHeight: "32px",
  alignItems: "center",

  _hover: {
    bg: "defaultGrey",
    fill: "midDeepBlue",
  },
  _active: {
    bg: "defaultGrey",
    fill: "midDeepBlue",
  },
});

const langButtonTheme = defineStyle({
  bg: "transparent",
  borderRadius: "8px",
  fill: "midDeepBlue",
  color: "midDeepBlue",
  alignItems: "center",
  fontWeight: 400,
  fontSize: "15px",
  px: "8px",

  _hover: {
    bg: "lightDeepBlue",
    color: "mainPurple",
    fill: "mainPurple",
  },
  _active: {
    bg: "lightDeepBlue",
    color: "mainPurple",
    fill: "mainPurple",
  },
});

const messageButton = defineStyle({
  color: "#fff",
  backgroundColor: "darkGrey",
  borderRadius: "4px",
  padding: "0 16px",
  fontWeight: "400",
  fontSize: "15px",
  lineHeight: "20px",
  cursor: "default",
});

const containerCloseButton = defineStyle({
  cursor: "pointer",
  fill: "darkGrey",

  _hover: {
    fill: "mainPurple",
  },
});

const connectBot = defineStyle({
  color: "midDeepBlue",
  fill: "midDeepBlue",

  _hover: {
    color: "darkPurple",
    fill: "darkPurple",
    bg: "lightDeepBlue",
  },
});

const searchCircleButton = defineStyle({
  height: "32px",
  width: "32px",
  backgroundColor: "bgMiddle",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "1px solid line",
  borderRadius: "100px",
  color: "midDeepBlue",

  _hover: {
    color: "mainPurple",
    borderColor: "mainPurple",
  },
});

const noteFilterButton = defineStyle({
  height: "32px",
  width: "32px",
  fill: "darkGrey",
  backgroundColor: "newYellow",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "100px",

  _hover: {
    fill: "white",
    backgroundColor: "#FFC300",
  },
});

const cLoseButton = defineStyle({
  minWidth: "auto",
  cursor: "pointer",
  color: "midDeepBlue",
  borderRadius: "50%",
  backgroundColor: "white",

  _hover: {
    backgroundColor: "defaultGrey",
    color: "mainPurple",
  },
});

const md = defineStyle({
  height: "52px",
});

const sm = defineStyle({
  height: "36px",
});

const dominoMd = defineStyle({
  height: "36px",
});

const dominoSm = defineStyle({
  height: "32px",
  px: "16px",
  fontSize: "14px",
});

export const buttonTheme = defineStyleConfig({
  defaultProps: {
    size: "sm",
  },
  variants: {
    dominoTariffPlanBlack,
    dominoViolet,
    dominoDashedViolet,
    dominoGhostViolet,
    dominoOutlineViolet,
    dominoOrganizationPlanOutlineViolet,
    dominoOutlineGray,
    dominoOutlineRed,
    sideBar,
    dashboardBar,
    broadcastBar,
    dominoMenuBtn,
    dominoSwitchBtn,
    dominoGhost,
    dominoBlueGhost,
    dominoGhostLng,
    dominoGhostPaginator,
    dominoPrimaryRed,
    dominoTextareaIcon,
    dominoFillIconButton,
    dominoDefaultMenuBtn,
    iconDotTheme,
    langButtonTheme,
    messageButton,
    containerCloseButton,
    connectBot,
    searchCircleButton,
    noteFilterButton,
    cLoseButton,
    profileButton,
  },
  sizes: { md, sm, dominoSm, dominoMd },
});
