import { useAppDispatch, useAppSelector } from "../state/store";
import s from "./SystemNotification.module.scss";
import { clearNotify, selectSystemNotification } from "./SystemNotificationSlice";
import { useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useBoolean,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { isIOS } from "react-device-detect";
import { selectIsImpersonated } from "../user/UserSlice";

export const SystemNotificationContainer = () => {
  const notification = useAppSelector(selectSystemNotification);
  const dispatch = useAppDispatch();
  const isImpersonated = useAppSelector(selectIsImpersonated);
  const [isOpen, setOpen] = useBoolean(false);
  const { t } = useTranslation("translation", { keyPrefix: "Notification" });

  const requestPermissionAndNotify = () => {
    Notification.requestPermission().then(prem => {
      if (prem === "granted") {
        notify();
      } else if (prem === "denied") {
        setOpen.off();
      }
      localStorage.setItem("allowNotifications", "granted");
    });
  };

  const onCloseClick = () => {
    localStorage.setItem("allowNotifications", "close");
    setOpen.off();
  };

  const onDenyClick = () => {
    localStorage.setItem("allowNotifications", "deny");
    setOpen.off();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const notify = () => {
    navigator.serviceWorker.getRegistrations().then(registrations => {
      if (notification && registrations?.at(0)) {
        registrations.at(0)?.showNotification(notification.title, {
          body: notification.message ?? undefined,
          image: notification.imageUrl ?? undefined,
          icon: notification.iconUrl ?? undefined,
          badge: "/favicon.ico",
          data: notification.openPath ?? undefined,
          tag: notification.tag ?? undefined,
          renotify: false,
        });
      }
      dispatch(clearNotify());
      setOpen.off();
    });
  };

  const isPermissionGranted = async (): Promise<string> => {
    const prem = await window.navigator.permissions.query({ name: "notifications" });
    return prem.state;
  };

  useEffect(() => {
    let allowed = localStorage.getItem("allowNotifications") ?? "true";
    if (!(allowed === "deny" || allowed === "close" || allowed === "granted")) {
      localStorage.removeItem("allowNotifications");
      allowed = "true";
    }
    if (notification && (allowed === "true" || allowed === "granted")) {
      isPermissionGranted().then(granted => {
        if (granted === "granted") {
          notify();
        } else if (granted === "denied") {
        } else {
          if (!isImpersonated) {
            setOpen.on();
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  return (
    <>
      {!isIOS && (
        <Modal closeOnOverlayClick={false} closeOnEsc={false} size={"lg"} isOpen={isOpen} onClose={setOpen.off}>
          <ModalOverlay />
          <ModalContent className={s.modal}>
            <ModalHeader>{t("Allow notifications")}?</ModalHeader>
            <ModalCloseButton onClick={onCloseClick} />
            <ModalBody pt="0">{t("Enable browser notifications to avoid missing new messages from contacts")}</ModalBody>

            <ModalFooter justifyContent={"left"}>
              <Button variant="dominoViolet" mr={3} onClick={requestPermissionAndNotify}>
                {t("Allow")}
              </Button>
              <Button variant="dominoGhostViolet" onClick={onDenyClick}>
                {t("Deny")}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};
