import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../common/state/store";
import { getPluginToken, selectPluginToken } from "./PluginAuthSlice";
import { getOrganisation, selectOrganisation } from "../organisation/OrganisationSlice";
import { selectAuth } from "../../common/auth/AuthSlice";
import { AppSettings } from "../../common/AppSettings";

declare const appSettings: AppSettings;
interface Props {
  pluginName: string;
  origin: string;
}

export const PluginAuthContainer = ({ pluginName, origin }: Props) => {
  const dispatch = useAppDispatch();
  const pluginToken = useAppSelector(selectPluginToken);
  const organisation = useAppSelector(selectOrganisation);
  const auth = useAppSelector(selectAuth);
  const plugin = organisation?.plugins?.find(x => x.name === pluginName);

  useEffect(() => {
    if (!organisation) {
      dispatch(getOrganisation());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisation]);

  useEffect(() => {
    if (!pluginToken && plugin) {
      dispatch(getPluginToken({ pluginName }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pluginToken, plugin]);

  useEffect(() => {
    if (pluginToken && plugin) {
      const message = {
        type: "tokenMessage",
        message: pluginToken,
      };

      const url = new URL(plugin.url || "");
      window.parent.postMessage(message, url.origin);
    }

    if (appSettings.apiBaseUrl in auth && !auth[appSettings.apiBaseUrl]) {
      const message = {
        type: "tokenMessage",
        message: {},
      };
      window.parent.postMessage(message, origin);
    }

    // if (!(appSettings.apiBaseUrl in auth)) {
    //   const message = {
    //     type: "tokenMessage",
    //     message: { token: "noToken" },
    //   };

    //   window.parent.postMessage(message, origin);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pluginToken, auth]);

  return <>AUTH</>;
};
