import { Flex } from "@chakra-ui/react";
import { FilterProvider } from "./FilterProvider";
import { ContentWrapper } from "./components/ContentWrapper/ContentWrapper";
import { ReactElement } from "react";

interface Props {
  blocks: ReactElement[];
}

export const FilterSettings = ({ blocks }: Props) => {
  return (
    <Flex overflowY="auto">
      <FilterProvider>
        <ContentWrapper blocks={blocks} />
      </FilterProvider>
    </Flex>
  );
};
