import React, { useState } from "react";
import { Box, Link, Skeleton, Text, useDisclosure } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AIAssistantModel } from "../AIAssistantListModel";
import DotMenuContainer from "../../../UI/atoms/DotMenu/DotMenuContainer";
import { useAppDispatch, useAppSelector } from "../../../common/state/store";
import {
  deleteAIAssistant,
  getAIAssistant,
  getFlowsInAIAssistant,
  selectAIAssistantList,
  selectFlowsInAIAssistant,
} from "../AIAssistantListSlice";
import { DeleteModal } from "../../../UI/molecules/deleteModal/DeleteModal";
import EditIcon from "../../../assets/icons/editIcon.svg?react";
import RedTrashIcon from "../../../assets/icons/trash.svg?react";
import { AppSettings } from "../../../common/AppSettings";
import s from "../AIAssistantListContainer.module.scss";
import { useGetLoadingState } from "../../../common/loading/hooks/useGetLoadingState";

declare const appSettings: AppSettings;

interface Props {
  onNewAIAssistant: () => void;
  onEditAIAssistantItem: () => void;
}

export const AIAssistantList = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "AI-assistant" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const dispatch = useAppDispatch();
  const deleteModal = useDisclosure();
  const flowsInAIAssistant = useAppSelector(selectFlowsInAIAssistant);
  const aiAssistantList = useAppSelector(selectAIAssistantList);
  const [aiAssistant, setAIAssistant] = useState<AIAssistantModel>();
  const hasAIAssistantInFlow = Boolean(flowsInAIAssistant?.length);
  const isLoadingAssistantInFlow = useGetLoadingState("getFlowsInAIAssistant");

  const onOpenEditModal = (aiAssistant: AIAssistantModel) => {
    setAIAssistant(aiAssistant);
    dispatch(getAIAssistant(aiAssistant?.id));
    props.onEditAIAssistantItem();
  };

  const onEditAIAssistantItem = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.TouchEvent<HTMLButtonElement> | React.DragEvent<HTMLButtonElement>,
    aiAssistant: AIAssistantModel,
  ) => {
    e.stopPropagation();
    dispatch(getAIAssistant(aiAssistant?.id));
    props.onEditAIAssistantItem();
  };

  const onOpenDeleteModal = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.TouchEvent<HTMLButtonElement> | React.DragEvent<HTMLButtonElement>,
    aiAssistant: AIAssistantModel,
  ) => {
    e.stopPropagation();
    dispatch(getFlowsInAIAssistant(aiAssistant.id));
    setAIAssistant(aiAssistant);
    deleteModal.onOpen();
  };

  const onDeleteAIAssistantItem = () => {
    if (!hasAIAssistantInFlow && aiAssistant) {
      dispatch(deleteAIAssistant(aiAssistant.id));
    }
  };

  const deleteModalSkeleton = <Skeleton startColor="line" endColor="bgLight" borderRadius="8px" height="36px" />;

  const deleteModalText = (
    <Text>
      {t("Are you sure you want to delete the assistant")} <b>{aiAssistant?.name}</b>?
    </Text>
  );

  const confirmModalText = (
    <Text>
      {t("Assistant name")}
      <b>{aiAssistant?.name}</b>
      {t("assistant cannot be deleted:")}
    </Text>
  );

  const flowsInAIAssistantText = flowsInAIAssistant?.map((flow, index) => (
    <React.Fragment key={flow.id}>
      <Link href={`/automation/flows/${flow.id}`} isExternal={true}>
        <b>{flow.title}</b>
      </Link>
      <br />
    </React.Fragment>
  ));

  const additionalModalText = <Text>{t("Please remove the assistant from the scenario(s) and try again")}</Text>;

  return (
    <div className={s.aiAssistantListContainerWrapper}>
      {aiAssistantList?.map(aiAssistant => (
        <Box key={aiAssistant.id} className={s.aiAssistantItem} onClick={() => onOpenEditModal(aiAssistant)}>
          <Text variant="medium" mobile-title={t("Title")} className={s.aiAssistantItemText}>
            <span className={s.aiAssistantItemTextSpan}>{aiAssistant.name}</span>
          </Text>
          <Text variant="medium" mobile-title={t("Description")} className={s.aiAssistantItemText}>
            <span className={s.aiAssistantItemTextSpan}>{aiAssistant.description}</span>
          </Text>
          <Text variant="medium" mobile-title={t("Answers File")} className={`${s.aiAssistantItemText} ${s.fileName}`}>
            <span className={s.aiAssistantItemTextSpan}>
              <Link href={`${appSettings.aiAssistantUrl}/DataFiles/file/${aiAssistant.fileId}`} isExternal>
                {aiAssistant.fileName}
              </Link>
            </span>
          </Text>
          <Box className={s.aiAssistantMenu}>
            <DotMenuContainer
              menuItems={[
                {
                  MenuItem: {
                    icon: <EditIcon className={s.menuIcon} />,
                    text: ct("Edit"),
                    clickEvent: e => onEditAIAssistantItem(e, aiAssistant),
                  },
                },

                {
                  MenuItem: {
                    icon: <RedTrashIcon className={s.menuIcon} />,
                    text: ct("Delete"),
                    clickEvent: e => onOpenDeleteModal(e, aiAssistant),
                    color: "mainRed",
                  },
                },
              ]}
            />
          </Box>
        </Box>
      ))}
      <DeleteModal
        title={t("Delete the support assistant")}
        itemTitle={isLoadingAssistantInFlow ? deleteModalSkeleton : hasAIAssistantInFlow ? confirmModalText : deleteModalText}
        isOpen={deleteModal.isOpen}
        onClose={deleteModal.onClose}
        onDelete={onDeleteAIAssistantItem}
        isDisabled={hasAIAssistantInFlow}
      >
        {!isLoadingAssistantInFlow && hasAIAssistantInFlow && (
          <>
            <Text pt="8px" pb="16px">
              {flowsInAIAssistantText}
            </Text>
            {additionalModalText}
          </>
        )}
      </DeleteModal>
    </div>
  );
};
