import { Box, Button, Flex, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import s from "./ButtonInput.module.scss";

interface CurrentItem {
  isActive: boolean;
  value: string;
}

interface Props {
  item: CurrentItem;
  index: number;
  setItem: (value: string, index: number, isActive: boolean) => void;
  maxItemLength: number;
  inputPlaceholder: string;
  deleteItem: (index: number) => void;
}

export const ButtonInput = ({ item, index, setItem, maxItemLength, inputPlaceholder, deleteItem }: Props) => {
  return (
    <Button
      position={"relative"}
      className={`${s.button} ${item.isActive ? s.buttonActive : ""}`}
      onClick={() => setItem(item.value, index, true)}
      data-pw="item-button"
    >
      <InputGroup>
        {item.isActive ? (
          <InputGroup>
            <Input
              onChange={e => setItem(e.target.value, index, true)}
              onBlur={e => setItem(e.target.value, index, false)}
              value={item.value}
              cursor={"pointer"}
              autoFocus
              maxLength={maxItemLength}
              className={s.input}
              placeholder={inputPlaceholder}
              data-pw="item-input"
              onKeyDown={e => {
                if (e.key === "Enter") setItem(item.value, index, false);
              }}
            />
          </InputGroup>
        ) : (
          <Flex className={s.itemValueWrp}>
            <Box className={s.itemValue}>{item.value}</Box>
          </Flex>
        )}

        <InputRightElement className={s.closeIconWrp}>
          <CloseIcon
            className={s.closeIcon}
            onMouseDown={e => e.preventDefault()}
            onClick={e => {
              e.stopPropagation();
              deleteItem(index);
            }}
            data-pw="close-icon"
          />
        </InputRightElement>
      </InputGroup>
    </Button>
  );
};
