import { IBaseTariffPlanFeature, TariffPlanFeatureTypeEnum } from "../AppEnums";
import { useAppSelector } from "../state/store";
import { selectTariffPlan } from "../tariffPlan/TariffPlanSlice";
import { TariffPlanPreferenceModel } from "../tariffPlan/TariffPlanModel";

export function useSelectLimitPlan(name?: TariffPlanFeatureTypeEnum): IBaseTariffPlanFeature {
  const tariff = useAppSelector(selectTariffPlan);
  if (!name) {
    return {
      MaxCount: 10,
      IsUnlimited: true,
      IsAvailable: true,
      IsExportAvailable: true,
      IsImportAvailable: true,
      isLoaded: tariff?.isLoadedTariffPlan || false,
    };
  }
  const tariffFeature = tariff?.features?.find(feature => feature.featureType === name);
  const tariffPreference = tariffFeature?.preferences ?? [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const accessibility: { [key: string]: string | number | boolean | null } = {};

  tariffPreference.map((preference: TariffPlanPreferenceModel) => (accessibility[preference.key] = preference.value));

  const returnVlue: IBaseTariffPlanFeature = { ...accessibility, isLoaded: tariff?.isLoadedTariffPlan || false };

  return returnVlue;
}
