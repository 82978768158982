import { Button, Flex } from "@chakra-ui/react";
import NoteIcon from "../../../../../../../../assets/icons/noteIcon.svg?react";
import PaperClipIcon from "../../../../../../../../assets/icons/paperClipIcon.svg?react";
import ImageIcon from "../../../../../../../../assets/icons/imageChatIcon.svg?react";
import s from "./GroupChatTextAreaBlock.module.scss";
import { useEffect, useRef, useState, ClipboardEvent } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../../../common/state/store";
import { IMAGE_FILE_EXTS } from "../../../../../../../../common/AppEnums";
import { useTranslation } from "react-i18next";
import {
  selectConversationMessage,
  sendConversationMessage,
  setConversationMessage,
  uploadMessageFile,
} from "../GroupChatMessagesList/GroupChatMessagesListSlice";
import { useParams } from "react-router-dom";
import { ConversationSendMessageModel, FileUploadType } from "../../../../../../../conversation/ConversationModel";
import { Attachments } from "../Attachments/Attachments";
import { Loading } from "../../../../../../../../common/loading/LoadingStateContainer";
import { selectReplyMode, setReplyModeState } from "./GroupChatTextAreaBlockSlice";
import { selectCurrentBot } from "../../../../../../../sidebar/SidebarSlice";
import { useGetLoadingState } from "../../../../../../../../common/loading/hooks/useGetLoadingState";
import { EventCategories } from "../../../../../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import useGA from "../../../../../../../../common/ga/GAEventTracker";
import { GroupsChatEvents } from "../../../../../../../../common/ga/gaEventsEnums.ts/GroupGAEventsEnums";
import { TextFormatterWithReply } from "../../../../../../../../UI/molecules/TextFormatter/TextFormatterWithReply/TextFormatterWithReply";

export const GroupChatTextAreaBlock = () => {
  const groupChatTextAreaTranslation = useTranslation("translation", { keyPrefix: "groupChat.groupChatTextArea" }).t;
  const { t } = useTranslation("translation", { keyPrefix: "conversation" });

  const [messageText, setMessageText] = useState("");
  const message = useAppSelector(selectConversationMessage);
  const botInfo = useAppSelector(selectCurrentBot);
  const isLoadingFile = useGetLoadingState("messageFileUpload");
  const trackEvent = useGA(EventCategories.Group);

  const inputMaxLength = message.photo == null && message.document == null ? 2000 : 1024;
  const replyMode = useAppSelector(selectReplyMode);

  const textareaRef = useRef<HTMLDivElement>(null);
  if (replyMode.enabled) {
    textareaRef.current?.focus();
  }
  const dispatch = useAppDispatch();
  const params = useParams();
  const id = params["conversationId"];

  useEffect(() => {
    if (replyMode.enabled) {
      dispatch(setReplyModeState({ enabled: false, message: undefined }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSendMessage = (message: ConversationSendMessageModel) => {
    if (!id) return;

    dispatch(sendConversationMessage({ conversationId: id, message }));

    if (message.photo) {
      trackEvent(GroupsChatEvents.GroupsChatMessageMediaSent);
    }
    if (message.document) {
      trackEvent(GroupsChatEvents.GroupsChatMessageAttachmentSent);
    }
  };

  const uploadFile = (file: File, type: FileUploadType) => {
    dispatch(uploadMessageFile({ file, type }));
  };

  const handlePasteFromClipBoard = (e: ClipboardEvent<HTMLDivElement>) => {
    const { clipboardData } = e;
    if (clipboardData && clipboardData.files.length > 0) {
      const file = clipboardData?.files[0];

      if (file && !message.document && !message.photo) {
        e.preventDefault();
        if (file.type.match("^image/")) {
          uploadFile(file, FileUploadType.photo);
        } else {
          uploadFile(file, FileUploadType.document);
        }
      }
    }
  };

  const setMessage = (message: ConversationSendMessageModel, cursorPosition?: number) => {
    dispatch(setConversationMessage(message));
  };

  const onSend = () => {
    if (replyMode.enabled) {
      onSendMessage({
        ...message,
        text: messageText,
        replyToMessageId: replyMode.message?.id,
      });
      dispatch(setReplyModeState({ enabled: false, message: undefined }));
    } else {
      onSendMessage({
        ...message,
        text: messageText,
      });
    }

    setMessageText("");
  };

  const removeAttachedFile = () => {
    const updatedMessage = {
      ...message,
      photo: undefined,
      document: undefined,
    };
    setMessage(updatedMessage);
  };

  return (
    <Flex className={s.container}>
      <TextFormatterWithReply
        data-pw="groupChat-message-textarea"
        onPaste={handlePasteFromClipBoard}
        placeholder={replyMode.enabled ? t("Reply here") : groupChatTextAreaTranslation("Write a message...")}
        ref={textareaRef}
        value={messageText}
        handleChange={value => setMessageText(value)}
        maxLength={inputMaxLength}
        replyMode={replyMode}
        onCloseReply={() => dispatch(setReplyModeState({ enabled: false, message: undefined }))}
        wrapperClassname={s.inputWrapper}
        botName={botInfo?.name}
        handleSubmit={onSend}
      />
      <Loading scope="messageFileUpload" />
      {(message.document || message.photo) && <Attachments removeAttachedFile={removeAttachedFile} message={message} />}

      <Flex className={s.boxActions}>
        <Flex className={s.actions}>
          <label
            data-pw="image-btn"
            className={`${s.input} ${message.document || message.photo || isLoadingFile ? s.disabledInput : ""}`}
          >
            <ImageIcon
              className={`${s.actionIconItem} ${message.document || message.photo || isLoadingFile ? s.disabled : ""}`}
            />
            <input
              className={s.hidden}
              onChange={e => {
                if (e.target.files) {
                  uploadFile(e.target.files[0], FileUploadType.photo);
                }
              }}
              value=""
              type="file"
              accept={IMAGE_FILE_EXTS}
            />
          </label>
          <label
            data-pw="document-btn"
            className={`${s.input} ${message.document || message.photo || isLoadingFile ? s.disabledInput : ""}`}
          >
            <PaperClipIcon
              className={`${s.actionIconItem} ${message.document || message.photo || isLoadingFile ? s.disabled : ""}`}
            />
            <input
              className={s.hidden}
              onChange={e => {
                if (e.target.files) {
                  uploadFile(e.target.files[0], FileUploadType.document);
                }
              }}
              value=""
              type="file"
            />
          </label>
          {false && <NoteIcon className={`${s.noteIcon} ${false && s.noteActive}`} />}
        </Flex>
        <Button
          isDisabled={(!messageText.trim() && !message.document && !message.photo) || messageText.length > inputMaxLength}
          onClick={() => onSend()}
          size={"sm"}
          variant="dominoViolet"
          data-pw="send-btn"
          isLoading={false}
          loadingText={groupChatTextAreaTranslation("Send")}
          spinnerPlacement="end"
        >
          <span>{groupChatTextAreaTranslation("Send")}</span>
        </Button>
      </Flex>
    </Flex>
  );
};
