import { Flex, Text } from "@chakra-ui/react";
import s from "./Toolbar.module.scss";
import { EmojiPropsModel } from "../../../../../components/inputWrapper/InputWrapperModel";
import { EmojiMenu } from "../../../../../components/inputWrapper/components/emojiMenu/EmojiMenu";
import { CustomVariableContextMenu } from "../../../../../components/modals/CustomVariableContextMenu/CustomVariableContextMenu";
import { CustomVariableContextMenuType } from "../../../../../components/modals/CustomVariableContextMenu/CustomVariableContextMenuModel";
import { TextMarks } from "../../types";
import { useSlate } from "slate-react";
import { MarkButtons } from "../MarkButtons/MarkButtons";
import { CustomVariableModel } from "../../../../../common/AppEnums";

interface ToolbarProps {
  charactersLeft: number;
  onMarkClick: (id: TextMarks) => void;
  addLink: (text: string, href: string) => void;
  customButton?: JSX.Element;
  isDisabled?: boolean;
  emojiProps?: EmojiPropsModel;

  isFormattingDisabled?: boolean;
  isEmojiDisabled?: boolean;
  isNoVariablesButton?: boolean;

  variables?: CustomVariableModel[];
  addVariable: (variable: CustomVariableModel) => void;
  customVariablesMenuView?: CustomVariableContextMenuType;
}

export const Toolbar = ({
  charactersLeft,
  customButton,
  isDisabled,
  onMarkClick,
  emojiProps,
  isNoVariablesButton,
  customVariablesMenuView,
  addLink,
  addVariable,
  variables,
  isEmojiDisabled,
  isFormattingDisabled,
}: ToolbarProps) => {
  const editor = useSlate();
  const charactersLeftStyles = charactersLeft >= 0 ? s.charactersLeft : s.charactersLeft_red;

  return (
    <Flex className={s.toolbar}>
      {customButton}
      <Text variant="medium" className={charactersLeftStyles} aria-disabled={isDisabled} data-pw="characters-left">
        {charactersLeft}
      </Text>
      <MarkButtons editor={editor} onMarkClick={onMarkClick} addLink={addLink} isDisabled={isDisabled || isFormattingDisabled} />
      {emojiProps && <EmojiMenu isDisabled={isDisabled || isEmojiDisabled} addEmoji={emojiProps.addEmoji} />}
      {variables && !isNoVariablesButton && (
        <CustomVariableContextMenu
          disableBtn={isDisabled}
          type={customVariablesMenuView}
          selectCustomVariable={addVariable}
          variables={variables}
        />
      )}
    </Flex>
  );
};
