import { useTranslation } from "react-i18next";
import { useDocumentTitle } from "../../../../common/hooks/useDocumentTitle";
import { Button, Flex, Link, Text } from "@chakra-ui/react";
import PlusIcon from "../../../../assets/icons/plusIconTile.svg?react";
import { useAppSelector } from "../../../../common/state/store";
import { selectCurrentBot } from "../../../sidebar/SidebarSlice";
import s from "./GroupChatPage.module.scss";
import {
  selectGroupChatList,
  selectOverLimitedGroupConversations,
  selectSearchGroupChatList,
  selectTotalItems,
} from "../../../groupChat/components/GroupChatList/GroupChatListSlice";
import useGA from "../../../../common/ga/GAEventTracker";
import { EventCategories } from "../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import { GroupsChatEvents } from "../../../../common/ga/gaEventsEnums.ts/GroupGAEventsEnums";
import { LimitStarPlanButton } from "../../../../UI/molecules/limitStarPlanButton/LimitStarPlanButton";
import { useSelectLimitPlan } from "../../../../common/hooks/useSelectLimitPlan";
import { TariffPlanFeatureTypeEnum, ICountTariffPlanFeature } from "../../../../common/AppEnums";
import { useSmallMobileView } from "../../LayoutHelper/ResolutionHooks";

export const GroupChatPageHeader = () => {
  const { t } = useTranslation("translation", { keyPrefix: "groupChatHeader" });
  useDocumentTitle(t("Group Chats", "Group Chats"));
  const currentBot = useAppSelector(selectCurrentBot);
  const groupList = useAppSelector(selectGroupChatList);
  const groupListSearch = useAppSelector(selectSearchGroupChatList);
  const groupChatListCount = useAppSelector(selectTotalItems);
  const overLimitConversations = useAppSelector(selectOverLimitedGroupConversations);
  const trackEvent = useGA(EventCategories.Group);
  const isSmallMobile = useSmallMobileView();

  const overLimitCount = overLimitConversations?.length ?? 0;
  const isOverLimit = overLimitCount > 0;

  const limitInfo = useSelectLimitPlan(TariffPlanFeatureTypeEnum.GroupConversationCount);
  const { IsUnlimited, MaxCount, isLoaded } = limitInfo as ICountTariffPlanFeature;

  return (
    <Flex className={s.groupChatHeaderContainer}>
      <Text noOfLines={1} className={s.hedaerText} variant="h2">
        {t("Group Chats")}
      </Text>
      {currentBot && !(groupChatListCount === 0 && groupListSearch === "" && groupList?.items.length === 0 && !isOverLimit) && (
        <>
          {!IsUnlimited && (isOverLimit || isLoaded || (groupList && groupList?.totalItems >= MaxCount)) ? (
            <LimitStarPlanButton
              popoverBodyDescription="Please upgrade your plan to create more group chats"
              buttonName={isSmallMobile ? "Add" : "Add Group"}
              keyPrefix="groupChatHeader"
              variant="dominoViolet"
              dataPw="add-group-chat-button"
              iconFill="white"
            />
          ) : (
            <Link
              isExternal
              onClick={() => trackEvent(GroupsChatEvents.GroupsChatAdd)}
              href={`https://t.me/${currentBot?.username}?startgroup&admin=change_info+post_messages+edit_messages+delete_messages+restrict_members+invite_users+
pin_messages+manage_topics+promote_members+manage_chat`}
            >
              <Button variant="dominoViolet">
                <Flex className={s.hedaerButton}>
                  <PlusIcon />
                  {isSmallMobile ? t("Add") : t("Add Group")}
                </Flex>
              </Button>
            </Link>
          )}
        </>
      )}
    </Flex>
  );
};
