import { forwardRef, KeyboardEventHandler, TextareaHTMLAttributes, useEffect, useRef, useState } from "react";
import { createEditor, Point, Range, Transforms } from "slate";
import { Editable, ReactEditor, Slate, withReact } from "slate-react";
import { Box } from "@chakra-ui/react";
import { CustomElement, CustomText, EditorType, Selection, TextFormatterInitialValue, TextMarks } from "./types";
import { convertText, insertLink, toggleMark } from "./utils";
import s from "./TextFormatter.module.scss";
import { FORMAT_HOTKEYS, SLATE_INITIAL_VALUE, TEXT_FORMATTER_WRAPPER_ID } from "./consts";
import isHotkey, { isKeyHotkey } from "is-hotkey";
import { EmojiPropsModel } from "../../../components/inputWrapper/InputWrapperModel";
import { Toolbar } from "./components/Toolbar/Toolbar";
import { RenderLeafs } from "./components/RenderLeafs/RenderLeafs";
import { RenderElements } from "./components/RenderElements/RenderElements";
import { RenderPlaceholder } from "./components/RenderPlaceholder/RenderPlaceholder";
import { CustomVariableModel } from "../../../common/AppEnums";
import { withInlines, withMaxLength } from "./HOFs";
import { useCombinedRefs } from "../../../common/hooks/useCombinedRefs";
import { withHistory } from "slate-history";

export interface TextFormatterProps extends TextareaHTMLAttributes<HTMLDivElement> {
  placeholder: string;
  maxLength: number;
  value: string;
  handleChange: (text: string) => void;

  initialValue?: TextFormatterInitialValue;
  isDisabled?: boolean;
  variables?: CustomVariableModel[];
  wrapperClassname?: string;
  handleSubmit?: () => void;
  handleSelection?: (selection: Selection) => void;

  isFormattingDisabled?: boolean;
  isEmojiDisabled?: boolean;
  isNoVariablesButton?: boolean;
}

declare module "slate" {
  interface CustomTypes {
    Editor: EditorType;
    Element: CustomElement;
    Text: CustomText;
  }
}

export const TextFormatter = forwardRef<HTMLDivElement, TextFormatterProps>(function TextFormatter(props, ref) {
  const {
    maxLength,
    placeholder,
    autoFocus,
    isDisabled,
    wrapperClassname,
    variables,
    isNoVariablesButton,
    isEmojiDisabled,
    isFormattingDisabled,
    value,
    initialValue,
    handleChange,
    handleSubmit,
    handleSelection,
    ...rest
  } = props;

  const [editor] = useState(() => withMaxLength(withInlines(withReact(withHistory(createEditor()))), maxLength));
  const [innerValue, setInnerValue] = useState<string>(convertText.fromNodesToText(editor));
  const editableRef = useRef<HTMLDivElement>(null);
  const combinedRef = useCombinedRefs<HTMLDivElement>(ref, editableRef);
  const isChanged = useRef<boolean>(false);

  const onHotKey: KeyboardEventHandler<HTMLDivElement> = event => {
    const isEnter = event.key === "Enter" && !event.shiftKey;
    if (handleSubmit && isEnter) {
      event.preventDefault();
      return handleSubmit();
    }

    if (!isFormattingDisabled) {
      for (const hotkey in FORMAT_HOTKEYS) {
        if (isHotkey(hotkey, event)) {
          event.preventDefault();
          const mark = FORMAT_HOTKEYS[hotkey as keyof typeof FORMAT_HOTKEYS];
          toggleMark(editor, mark);
        }
      }
    }

    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const { nativeEvent } = event;
      if (isKeyHotkey("left", nativeEvent)) {
        event.preventDefault();
        Transforms.move(editor, { unit: "offset", reverse: true });
        return;
      }
      if (isKeyHotkey("right", nativeEvent)) {
        event.preventDefault();
        Transforms.move(editor, { unit: "offset" });
        return;
      }
    }
  };

  const emoji: EmojiPropsModel = {
    addEmoji: emojiData => editor.insertText(emojiData),
  };
  const onMarkClick = (id: TextMarks) => {
    toggleMark(editor, id);
  };
  const addLink = (text: string, href: string) => {
    insertLink(editor, text, href);
  };
  const addVariable = (variable: CustomVariableModel) => {
    const parsedVar = "@{" + variable.scope + ":" + variable.key + "}";

    if (parsedVar.length < charactersLeft) {
      editor.insertText(parsedVar);
    }
  };

  const [charactersLeft, setCharactersLeft] = useState(editor.charactersLeft);
  useEffect(() => {
    const charactersLeft = maxLength - innerValue.length;
    setCharactersLeft(charactersLeft);
    editor.charactersLeft = charactersLeft;
    editor.maxLength = maxLength;

    isChanged.current && handleChange(innerValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerValue, maxLength]);

  // Need for "touch" input that Slate remembered last selection position
  useEffect(() => {
    if (autoFocus) {
      ReactEditor.focus(editor);
    } else {
      editableRef.current?.focus();
      (document.activeElement as HTMLElement).blur();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Clear input after clearing outer state
  useEffect(() => {
    if (value.length === 0 && isChanged.current) {
      const point: Point = { path: [0, 0], offset: 0 };
      editor.selection = { anchor: point, focus: point };
      editor.children = SLATE_INITIAL_VALUE;
      setInnerValue(convertText.fromNodesToText(editor));
    }
  }, [editor, value]);
  // Initial value can arrive late
  useEffect(() => {
    if (!isChanged.current && initialValue) {
      editor.children =
        initialValue.text.length > 0
          ? convertText.fromTextToNodes(initialValue.text, initialValue.entities)
          : SLATE_INITIAL_VALUE;

      const value = convertText.fromNodesToText(editor);
      setInnerValue(value);
    }
  }, [editor, initialValue, autoFocus, maxLength]);

  return (
    <Slate
      editor={editor}
      initialValue={SLATE_INITIAL_VALUE}
      onValueChange={() => {
        isChanged.current = true;
        setInnerValue(convertText.fromNodesToText(editor));
      }}
      onChange={() => {
        handleSelection &&
          handleSelection({
            children: editor.children,
            selection: editor.selection,
            insertText: editor.insertText,
            editor: editor,
          });
      }}
    >
      <Box className={`${s.wrapper} ${wrapperClassname ? [wrapperClassname] : ""}`} id={TEXT_FORMATTER_WRAPPER_ID}>
        <Editable
          placeholder={placeholder}
          renderElement={RenderElements}
          renderLeaf={RenderLeafs}
          renderPlaceholder={RenderPlaceholder}
          onKeyDown={onHotKey}
          className={s.input}
          ref={combinedRef}
          readOnly={isDisabled}
          {...rest}
        />
        <Toolbar
          charactersLeft={charactersLeft}
          onMarkClick={onMarkClick}
          addLink={addLink}
          emojiProps={emoji}
          addVariable={addVariable}
          variables={variables}
          isNoVariablesButton={isNoVariablesButton}
          isEmojiDisabled={isEmojiDisabled}
          isDisabled={isDisabled}
          isFormattingDisabled={isFormattingDisabled}
        />
      </Box>
    </Slate>
  );
});
