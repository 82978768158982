import { FilterTypeEnum } from "../../_types/FilterRule";
import { ProfanityFilterModel } from "../../ProfanityFilter/ProfanityFilterModel";
import { AntispamFilterModel } from "../../AntispamFilter/AntispamFilterModel";

export const MINIMAL_SCREEN_SIZE_FOR_DEFAULT_LAYOUT = 768;
export const MAXIMAL_RESTRICTION_COUNT = 5;
export const MAXIMAL_TOPIC_COUNT = 20;
export const CUSTOM_SPLITTER = ", ";

export const PROFANITY_FILTER_DEFAULT: ProfanityFilterModel = {
  isEnabled: false,
  rules: [
    {
      type: FilterTypeEnum.Warning,
    },
  ],
  customProfanities: [],
};

export const ANTISPAM_FILTER_DEFAULT: AntispamFilterModel = {
  isEnabled: false,
  rules: [
    {
      type: FilterTypeEnum.Warning,
    },
  ],
  prohibitedTopics: [],
  violationNotificationSettings: { tgUsernames: [], isEnabled: false },
  tgUsersWhiteListSettings: { tgUsernames: [], isEnabled: false },
  urlWhiteListSettings: { urls: [], isEnabled: false },
  phraseWhiteListSettings: { phrases: [], isEnabled: false },
};
