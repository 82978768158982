import { Button, Container, Flex, FormControl } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  createNewBroadcast,
  deleteBroadcast,
  editBroadcast,
  selectBroadcastIsInitialDisabledButtons,
  selectBroadcastIsNew,
  selectBroadcastState,
  selectBroadcastValidation,
  selectCurrentFile,
  selectShowBoradcastLeave,
} from "../../../../BroadcastSlice";
import { useGAWithCustomParameters } from "../../../../../../common/ga/GAEventTracker";
import s from "../../Broadcast.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../../../common/state/store";
import { selectCurrentBot } from "../../../../../sidebar/SidebarSlice";
import {
  selectBroadcastDirection,
  selectImportRecipientList,
  selectRecipientCount,
  selectTotalContacts,
} from "../../../ContactList/RecipientListSlice";
import { DeleteBroadcastPopup } from "../../../DeleteBroadcastPopup/DeleteBroadcastPopup";
import { useState } from "react";
import { LeaveConfirmationPopup } from "../../../LeaveConfirmationPopup/LeaveConfirmationPopup";
import { EventCategories } from "../../../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import { useSelectLimitPlan } from "../../../../../../common/hooks/useSelectLimitPlan";
import { ICountTariffPlanFeature, TariffPlanFeatureTypeEnum } from "../../../../../../common/AppEnums";
import { LimitStarPlanButton } from "../../../../../../UI/molecules/limitStarPlanButton/LimitStarPlanButton";
import { BroadcastDirection } from "../../../../BroadcastModel";

interface CommonProps {
  messageInvalid: boolean;
  dirty: boolean;
}

export const BroadcastFormButtons = (props: CommonProps) => {
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const { t } = useTranslation("translation", { keyPrefix: "broadcast" });
  const trackEvent = useGAWithCustomParameters(EventCategories.Broadcast);
  const dispatch = useAppDispatch();
  const broadcast = useAppSelector(selectBroadcastState).broadcast;
  const isBroadcastNew = useAppSelector(selectBroadcastIsNew);
  const currentBotId = useAppSelector(selectCurrentBot);
  const { isError } = useAppSelector(selectBroadcastValidation);
  const recipientsCount = useAppSelector(selectRecipientCount);
  const totalItemsCount = useAppSelector(selectTotalContacts);
  const isInitialDisabledButtons = useAppSelector(selectBroadcastIsInitialDisabledButtons);
  const importRecipientsList = useAppSelector(selectImportRecipientList);
  const showLeaveBroadcastPopup = useAppSelector(selectShowBoradcastLeave);
  const currentFile = useAppSelector(selectCurrentFile);
  const messageMaxLength = currentFile == null ? 4000 : 1024;

  const limitInfo = useSelectLimitPlan(
    broadcast.direction === BroadcastDirection.TelegramContact
      ? TariffPlanFeatureTypeEnum.BroadcastContacts
      : TariffPlanFeatureTypeEnum.GroupConversationCount,
  );
  const { IsUnlimited, MaxCount } = limitInfo as ICountTariffPlanFeature;

  const brodcastDirection = useAppSelector(selectBroadcastDirection);

  const [deletePopupState, setDeletePopupState] = useState<{ id: string; itemName: string } | null>(null);
  const onDeletePopupClose = () => setDeletePopupState(null);
  const deleteBroadcastDispatch = (broadcastId: string) => {
    setIsOnClickButton(true);
    dispatch(deleteBroadcast({ broadcastId }));
  };
  const [isOnClickButton, setIsOnClickButton] = useState(false);

  const isDisabledButton =
    importRecipientsList && importRecipientsList?.recipients
      ? isInitialDisabledButtons || Number(importRecipientsList?.availableRecipients) <= 0 || isError
      : isInitialDisabledButtons || recipientsCount <= 0 || isError;

  const isDisabledButtonStartDraft =
    importRecipientsList && importRecipientsList?.recipients
      ? Number(importRecipientsList?.availableRecipients) <= 0 || isError
      : recipientsCount <= 0 || isError;

  const renderButtons = () => {
    if (isBroadcastNew) {
      return (
        <>
          <Button
            size="sm"
            variant="dominoOutlineViolet"
            type="submit"
            isDisabled={isDisabledButton || broadcast?.message?.text.length > messageMaxLength}
            onClick={() => {
              trackEvent("Broadcast", "", {
                broadcast_event: "Save",
                total_contacts_count: totalItemsCount,
                select_contacts_count: recipientsCount,
                count: broadcast?.message.buttons.length,
              });
              dispatch(createNewBroadcast({ execute: false, botId: currentBotId?.id ?? "", direction: brodcastDirection ?? "" }));
              setIsOnClickButton(true);
            }}
            data-pw="broadcast-save-draft-button"
          >
            {t("Save draft")}
          </Button>
          <Button
            data-pw="broadcast-start-button"
            ml="16px"
            size="sm"
            variant="dominoViolet"
            type="submit"
            isDisabled={isDisabledButton || broadcast?.message?.text.length > messageMaxLength}
            onClick={() => {
              trackEvent("Broadcast", "", { broadcast_event: "Start" });
              dispatch(createNewBroadcast({ execute: true, botId: currentBotId?.id ?? "", direction: brodcastDirection ?? "" }));
              setIsOnClickButton(true);
            }}
          >
            {broadcast?.isScheduled ? t("Schedule broadcast") : t("Start broadcast")}
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button
            isDisabled={isDisabledButton || broadcast?.message?.text.length > messageMaxLength}
            size="sm"
            variant="dominoOutlineViolet"
            type="submit"
            onClick={() => {
              trackEvent("Broadcast", "", {
                broadcast_event: "Save",
                total_contacts_count: totalItemsCount,
                select_contacts_count: recipientsCount,
                count: broadcast?.message.buttons.length,
              });
              dispatch(
                editBroadcast({
                  execute: false,
                  botId: broadcast?.botId ?? "",
                }),
              );
              setIsOnClickButton(true);
            }}
            data-pw="broadcast-save-button"
          >
            {ct("Save")}
          </Button>
          {IsUnlimited || MaxCount > 0 ? (
            <Button
              data-pw="broadcast-start-button"
              isDisabled={isDisabledButtonStartDraft || broadcast?.message?.text.length > messageMaxLength}
              size="sm"
              ml="16px"
              variant="dominoViolet"
              type="submit"
              onClick={() => {
                trackEvent("Broadcast", "", {
                  broadcast_event: "Start",
                  total_contacts_count: totalItemsCount,
                  select_contacts_count: recipientsCount,
                  count: broadcast?.message.buttons.length,
                });
                setIsOnClickButton(true);
                dispatch(
                  editBroadcast({
                    execute: true,
                    botId: broadcast?.botId ?? "",
                  }),
                );
              }}
            >
              {broadcast?.isScheduled ? t("Schedule broadcast") : t("Start broadcast")}
            </Button>
          ) : (
            <LimitStarPlanButton popoverBodyDescription="Please upgrade your plan to create and send broadcasts to group chats">
              <Button data-pw="broadcast-start-button" isDisabled={true} size="sm" ml="16px" variant="dominoViolet" type="submit">
                {broadcast?.isScheduled ? t("Schedule broadcast") : t("Start broadcast")}
              </Button>
            </LimitStarPlanButton>
          )}

          <Button
            size="sm"
            variant="dominoOutlineRed"
            ml="16px"
            onClick={e => {
              trackEvent("Broadcast", "", { broadcast_event: "Delete" });
              e.stopPropagation();
              setDeletePopupState({ id: broadcast.id, itemName: broadcast.name });
            }}
            data-pw="broadcast-remove-button"
          >
            {ct("Remove")}
          </Button>
        </>
      );
    }
  };

  return (
    <Container backgroundColor="none" variant="dominoContentBlock">
      <FormControl
        style={{ paddingTop: "0px" }}
        className={`${s.broadcastListItemGrid} ${s.broadcastListItemGridStart} ${s.broadcastAdaptiveButton}`}
      >
        <div />
        <Flex className={s.broadcastFlexButton}>{renderButtons()}</Flex>
      </FormControl>
      <DeleteBroadcastPopup
        deleteBroadcastState={deletePopupState}
        onDelete={deleteBroadcastDispatch}
        onClose={onDeletePopupClose}
      />
      <LeaveConfirmationPopup dirty={showLeaveBroadcastPopup && !isOnClickButton} />
    </Container>
  );
};
