import { Box, Flex, Menu, MenuButton, MenuList, Skeleton } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import { FileUploadType } from "../../BroadcastModel";
import { selectCurrentFile, setCurrentFile, uploadBroadcastFile } from "../../BroadcastSlice";
import PaperClipIcon from "../../../../assets/icons/paperClipIcon.svg?react";
import ImageIcon from "../../../../assets/icons/imageChatIcon.svg?react";
import CloseIcon from "../../../../assets/images/messageCloseIcon.svg?react";
import SysFieldsIcon from "../../../../assets/images/messageSysFieldsIcon.svg?react";
import { AppSettings } from "../../../../common/AppSettings";
import s from "./Attachments.module.scss";
import { ChangeEvent, useEffect } from "react";
import { CustomVariableModel } from "../../../../common/AppEnums";
import { TooltipWithTouch } from "../../../../common/tooltipWithTouch/TooltipWithTouch";
import { FileNameSplitter } from "../../../../UI/atoms/fileNameSplitter/FileNameSplitter";
import { CustomMenuItem } from "../../../../UI/molecules/customMenuItem/CustomMenuItem";
import { useGetLoadingState } from "../../../../common/loading/hooks/useGetLoadingState";

const IMAGE_FILE_EXTENSIONS = ".jpeg,.jpg,.png,.gif";
declare const appSettings: AppSettings;

interface Props {
  disabled?: boolean;
  variables?: CustomVariableModel[];
  addVariableText?: (variable: CustomVariableModel) => void;
}

export const Attachments = ({ variables, addVariableText, disabled = false }: Props) => {
  const currentFile = useAppSelector(selectCurrentFile);
  const dispatch = useAppDispatch();
  const isFileLoading = useGetLoadingState("uploadBroadcastFile");

  const removeFile = () => {
    dispatch(setCurrentFile(null));
  };

  const addFile = (type: FileUploadType) => (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      dispatch(uploadBroadcastFile({ file: e.target.files[0], type }));
    }
  };

  useEffect(() => {
    //validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFile]);

  return (
    <>
      {isFileLoading && <Skeleton m={"8px 0"} w={"145px"} h={"34px"} startColor="line" endColor="bgLight" borderRadius="8px" />}
      {currentFile && currentFile.type === FileUploadType.photo && (
        <Flex>
          <Box className={s.photoPreview} data-pw="image">
            <img alt="" className={s.photo} src={`${appSettings.apiBaseUrl}/file/${currentFile?.file.fileId}`} />
            {!disabled && (
              <Box className={s.shade} onClick={removeFile} data-pw="remove-image-btn">
                <CloseIcon className={s.close} />
              </Box>
            )}
          </Box>
        </Flex>
      )}
      {currentFile && currentFile.type === FileUploadType.document && (
        <Box className={s.documentPreview} data-pw="file">
          <Box className={s.label}>
            <PaperClipIcon className={s.clipIcon} />
            <FileNameSplitter fileName={currentFile.file.fileName} variant="normal" />
          </Box>
          {!disabled && (
            <Box className={s.closeButton} onClick={removeFile} data-pw="remove-file-btn">
              <CloseIcon className={s.closeIcon} />
            </Box>
          )}
        </Box>
      )}
      <Flex mt={currentFile ? "8px" : "16px"}>
        {variables && (
          <Flex mr="16px">
            xsxs
            <Menu placement="top">
              <MenuButton as={SysFieldsIcon} className={s.actionIconItem} aria-label="Options" data-pw="sys-fields"></MenuButton>
              <MenuList className={s.menuList}>
                {variables.map((el, i) => {
                  return (
                    <CustomMenuItem key={i} onClick={() => addVariableText?.(el)} data-pw={el.key}>
                      <TooltipWithTouch placement="top" closeOnPointerDown={true} label={el.description} fontSize="sm">
                        {el.key}
                      </TooltipWithTouch>
                    </CustomMenuItem>
                  );
                })}
              </MenuList>
            </Menu>
          </Flex>
        )}
        <Flex gap={"4px"}>
          <label
            data-pw={currentFile ? "disabled-image-button" : "image-button"}
            className={`${s.input} ${disabled || currentFile ? s.disabledInput : ""}`}
          >
            <ImageIcon className={`${s.actionIconItem} ${disabled || currentFile ? s.disabled : ""}`} />
            {!currentFile && !disabled && (
              <input className={s.hidden} onChange={addFile(FileUploadType.photo)} type="file" accept={IMAGE_FILE_EXTENSIONS} />
            )}
          </label>
          <label
            data-pw={currentFile ? "disabled-document-button" : "document-button"}
            className={`${s.input} ${disabled || currentFile ? s.disabledInput : ""}`}
          >
            <PaperClipIcon className={`${s.actionIconItem} ${disabled || currentFile ? s.disabled : ""}`} />
            {!currentFile && !disabled && (
              <input className={s.hidden} onChange={addFile(FileUploadType.document)} type="file" accept="" />
            )}
          </label>
        </Flex>
      </Flex>
    </>
  );
};
