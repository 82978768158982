import { call, put, take, takeLatest } from "redux-saga/effects";
import * as webBotApi from "./WebBotApi";
import { webBotSlice } from "./WebBotSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { beginScope, complete, completeScope } from "../../common/loading/LoadingStateActions";
import { handleException } from "../../common/SagaHelper";
import { CreateWebBotType } from "./WebBotApi";
import { BotModel } from "../bot/BotModel";
import { routerSlice } from "../../common/router/RouterSlice";
import { sidebarSlice } from "../sidebar/SidebarSlice";
import { botSlice } from "../bot/BotSlice";
import { organisationSlice } from "../organisation/OrganisationSlice";

export function* webBotSaga() {
  yield takeLatest(webBotSlice.actions.createWebBot, createWebBot);
  yield takeLatest(webBotSlice.actions.updateWebBot, updateWebBot);
}

function* createWebBot(action: PayloadAction<CreateWebBotType>) {
  try {
    yield put(beginScope("createWebBot"));
    const data: BotModel = yield call(webBotApi.createWebBot, action.payload);
    yield put(webBotSlice.actions.createWebBotCompleted(data));
    yield put(sidebarSlice.actions.selectBot(data));
    yield put(sidebarSlice.actions.getBots({ page: 1 }));
    yield take(sidebarSlice.actions.getBotsCompleted);
    yield put(organisationSlice.actions.getOrganisation());
    yield put(routerSlice.actions.redirect("/automation/flows"));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("createWebBot"));
  }
}

export function* updateWebBot(action: PayloadAction<BotModel>) {
  try {
    yield put(beginScope("updateWebBot"));
    const data: BotModel = yield call(webBotApi.updateWebBot, action.payload);
    yield put(webBotSlice.actions.updateWebBotCompleted(data));
    yield put(botSlice.actions.getBotInfoSucceed(data));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}
