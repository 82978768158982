/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  ButtonType,
  ButtonTypeDiscriminator,
  CustomVariableModel,
  MessageDirection,
  MarkupTypeDiscriminator,
  MessageOriginType,
} from "../../common/AppEnums";
import { ConversationMessageEntity, MessageTypeDiscriminator, OutgoingTypeDiscriminator } from "./ConversationData";

export interface ConversationModel {
  conversationInfo: ConversationInfoModel;
  messages: ConversationMessagesModel;
}

export interface LastMessageModel {
  text: string | null;
  date: string | null;
  isPhoto: boolean;
  isDocument: boolean;
  isContact: boolean;
}

export interface ConversationInfoModel {
  id: string;
  botId: string;
  contact:
    | {
        id: string;
        username: string;
        firstName: string;
        lastName: string;
        avatars: AvatarModel[][];
        fullName?: string;
      }
    | undefined;
  name: string;
  status: string;
  lastMessage: LastMessageModel;
  unreadMessageCount: string;
  flowExecutionId: string;
  groupId?: string;
  isNew?: boolean;
  isBlocked: boolean;
  activeFlow?: ConversationFlowModel;
  assignedTeam?: AssignedItemModel;
  assignedOperator?: AssignedItemModel;
  createDate: string;
  action?: string;
  conversationType: ConversationTypeEnum;
}

export interface AssignedItemModel {
  id: string;
  name: string;
}

export interface ConversationFlowModel {
  id: string;
  sourceFlowId: string;
  title: string;
}

export interface ConversationMessagesModel {
  items: MessageModel[];
  totalItems: number;
  lastId: string;
  isNextSearch: boolean;
  currentSearch?: string;
  totalSearchItems?: number;
}

export interface BaseMessageModel {
  id: string;
  conversationId: string;
  conversation: ConversationInfoModel;
  text: string;
  date: Date;
  typeDiscriminator: MessageTypeDiscriminator;
}

export interface InlineKeyboardMarkupModel {
  typeDiscriminator: MarkupTypeDiscriminator.InlineKeyboardMarkup;
  buttons: InlineKeyboardButtonModel[][];
}

export interface InlineKeyboardButtonModel {
  typeDiscriminator: ButtonTypeDiscriminator.InlineKeyboardButton;
  callbackData?: string;
  url?: string;
  payload: string;
  label: string;
  type: ButtonType;
}

export interface ReplyKeyboardMarkupModel {
  typeDiscriminator: MarkupTypeDiscriminator.ReplyKeyboardMarkup;
  buttons: ReplyKeyboardButtonModel[][];
}

export interface ReplyKeyboardButtonModel {
  typeDiscriminator: ButtonTypeDiscriminator.ReplyKeyboardButton;
  label: string;
}

export interface ReplyMessageModel {
  id: string;
  text: string;
  author: string;
  photoFileId: boolean;
  documentName: boolean;
  contact?: SharedContactModel | null;
  isDeleted?: boolean;
}

export interface ConversationMessageModel extends BaseMessageModel {
  typeDiscriminator: MessageTypeDiscriminator.Message;
  direction: MessageDirection;
  status: string;
  customVariables?: CustomVariableModel[];
  photos: ConversationMessagePhotoModel[];
  shoppingCartOrder: unknown | null;
  documents: ConversationMessageDocumentModel[];
  replyMarkup?: InlineKeyboardMarkupModel | ReplyKeyboardMarkupModel;
  replyToMessage?: ReplyMessageModel;
  errorCode?: string;
  entities?: ConversationMessageEntity[];
  origin?: MessageOrigin;
  contact?: SharedContactModel;
  externalId: string;
  payment: {
    orderInfo: orderInfoModel;
    providerChargeId: string;
    telegramChargeId: string;
    currency: string;
    amount: string;
  };
}

export interface orderInfoModel {
  name: string;
  email: string;
  phoneNumber: string;
  shippingAddress: {
    countryCode: string;
    state: string;
    city: string;
    streetLine1: string;
    streetLine2: string;
    postCode: string;
  };
}

export interface SystemMessageModel extends BaseMessageModel {
  typeDiscriminator: MessageTypeDiscriminator.SystemMessage;
  externalId: string;
}

export interface TaggedOperatorModel {
  id: string;
  username: string;
}

export interface ConversationNoteModel extends BaseMessageModel {
  typeDiscriminator: MessageTypeDiscriminator.NoteTextConversation;
  taggedOperatorsNames: TaggedOperatorModel[];
  authorName: string;
  externalId: string;
  entities?: ConversationMessageEntity[];
}

export type MessageModel = ConversationMessageModel | SystemMessageModel | ConversationNoteModel;

export interface ConversationMessagePhotoModel {
  fileId: string;
  fileSize: number;
  fileUniqueId: string;
  fileName: string;
  height: number;
  width: number;
  url: string;
  extension: string;
}

export interface ConversationMessageDocumentModel {
  fileId: string;
  fileSize: number;
  fileName: string;
  extension: string;
}

export interface ConversationSendMessageModel {
  text: string;
  replyToMessageId?: string;
  document?: FileModel;
  photo?: FileModel;
  typeDiscriminator: OutgoingTypeDiscriminator;
}

export interface FileModel {
  id: string;
  name: string;
  extension: string;
  contentType: string;
  externalId: string;
}

export interface AvatarModel {
  fileId: string;
  fileSize: number;
  width: number;
  height: number;
}

export interface MessageOrigin {
  avatar?: AvatarModel;
  type: MessageOriginType;
  name: string | null;
  id: string | null;
}

export enum MessageStatusEnum {
  "Sent" = "Sent",
  "Failed" = "Failed",
}

export enum FileUploadType {
  "photo" = "Photo",
  "document" = "Document",
}

export enum ConversationTypeEnum {
  "Private" = "Private",
  "Group" = "Group",
  "Supergroup" = "Supergroup",
}

export interface SharedContactModel {
  phoneNumber: string;
  firstName: string;
  lastName: string | null;
  externalUserId: string;
}
