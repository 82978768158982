import { QuestionOutlineIcon } from "@chakra-ui/icons";
import {
  Text,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Input,
  Flex,
  Menu,
  MenuButton,
  Button,
  MenuList,
  Box,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Icon,
  Heading,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FlowActionModel, FlowModel, NodeModel, NodeValidationProp, StartFlowActionModel } from "../../../FlowBuilderModel";
import { NodeEditorFooter } from "../NodeEditorFooter/NodeEditorFooter";
import DownArrowMenu from "../../../../../assets/images/downArrow.svg?react";
import CrossIcon from "../../../../../assets/icons/cross.svg?react";
import Magnifire from "../../../../../assets/images/magnifire.svg?react";
import s from "./StartFlowNodePopup.module.scss";
import { useEffect, useState, useRef } from "react";
import FollowLinkIcon from "../../../../../assets/images/followLinkIcon.svg?react";
import { useAppSelector } from "../../../../../common/state/store";
import { selectFlowList } from "../../../FlowBuilderSlice";
import { CustomMenuItem } from "../../../../../UI/molecules/customMenuItem/CustomMenuItem";

interface Props {
  flow: FlowModel;
  node: NodeModel;
  getFlowListPage: (page: number, filter?: string) => void;
  onDataChange: (flowAction: FlowActionModel) => void;
  validate: (flowAction: FlowActionModel) => void;
  validationInfo: NodeValidationProp;
  onClose: () => void;
}

export const StartFlowNodePopup = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const flowAction = props.node.flowAction as StartFlowActionModel;
  const flowList = useAppSelector(selectFlowList);
  const { onClose } = useDisclosure();
  const [flowSearchValue, setFlowSearchValue] = useState("");
  const [focusInput, setFocusInput] = useState(true);
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    props.validate(flowAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowAction]);

  const redirectToFlow = (flowId: string) => {
    window.open(`${window.location.origin}/automation/flows/${flowId}`);
  };

  const onChange = (value: { id: string; title: string }) => {
    props.onDataChange({ ...flowAction, flowId: value.id, title: value.title } as StartFlowActionModel);
  };

  const onSearchTextChange = (value: string) => {
    setFlowSearchValue(value);
    props.getFlowListPage(1, value);
  };

  const getAdditionalItems = () => {
    if (flowList?.currentPage) {
      props.getFlowListPage(flowList.currentPage + 1);
    }
  };

  const onMenuClose = () => {
    onSearchTextChange("");
    onClose();
  };

  const onFocusInput = () => {
    /* focus on input since the chakra cannot */
    if (focusInput) {
      setTimeout(() => ref.current?.focus(), 10);
    }
  };

  return (
    <>
      <Drawer isOpen={true} placement="right" onClose={props.onClose} variant="dominoDrawer">
        <DrawerContent>
          <DrawerHeader>{t("Start Flow")}</DrawerHeader>
          <DrawerBody>
            <Flex mb="20px" alignItems="center">
              <Heading fontSize={"16px"} fontWeight={"400"}>
                {t("editNodePopup.Choose a Flow")}
              </Heading>
              <Tooltip
                variant="dominoLight"
                placement="right"
                whiteSpace={"pre-wrap"}
                label={t("editNodePopup.Start Flow Tooltip")}
              >
                <QuestionOutlineIcon ml={"4px"} boxSize={"15px"} color="darkGrey" _hover={{ color: "darkPurple" }} />
              </Tooltip>
            </Flex>

            <Menu
              defaultIsOpen={!flowAction.flowId}
              preventOverflow={false}
              matchWidth={true}
              variant="dominoGroupedMenu"
              gutter={0}
              onClose={onMenuClose}
            >
              {({ isOpen, onClose }) => (
                <>
                  <MenuButton
                    onClick={() => setTimeout(() => ref.current?.focus(), 10)}
                    _active={{ borderColor: "line", backgroundColor: "white" }}
                    as={Button}
                    className={s.menuButton}
                    isActive={isOpen}
                    variant="dominoDefaultMenuBtn"
                  >
                    {flowAction ? (
                      <Text fontSize={"15px"}>{flowAction.title}</Text>
                    ) : (
                      <Text fontSize={"15px"} color={"darkGrey"}>
                        {t("editNodePopup.Select")}
                      </Text>
                    )}
                  </MenuButton>
                  <MenuList w="100%" maxH="400px" position="absolute" top="-37px">
                    <Box className={s.dropdownSearchBox}>
                      <InputGroup w="auto">
                        <InputLeftElement pointerEvents="none">
                          <Magnifire />
                        </InputLeftElement>
                        <Input
                          onMouseOver={() => setFocusInput(true)}
                          ref={ref}
                          isInvalid={false}
                          onFocus={onFocusInput}
                          onBlur={onFocusInput}
                          autoFocus={focusInput}
                          value={flowSearchValue}
                          placeholder={t(`editNodePopup.Search by Flows`) ?? ""}
                          onChange={e => onSearchTextChange(e.target.value)}
                          data-pw="flow-search-input"
                        />
                        <InputRightElement right="10px" top="50%" mt={"-11px"} boxSize="22px">
                          {flowSearchValue && (
                            <CrossIcon className={s.crossIcon} cursor="pointer" onClick={() => onSearchTextChange("")} />
                          )}
                        </InputRightElement>
                      </InputGroup>
                    </Box>
                    <Box overflow="scroll" maxH="300px">
                      {flowList?.items.map((el, i) => {
                        return (
                          <CustomMenuItem
                            key={i}
                            pr="0"
                            h="40px"
                            onClick={() => {
                              onChange({ id: el.id, title: el.title });
                              onClose();
                            }}
                          >
                            <Flex
                              /*changing the boolean that allows you to focus the input*/
                              onMouseOver={() => setFocusInput(false)}
                              overflow="hidden"
                              textOverflow="ellipsis"
                              whiteSpace="nowrap"
                              w="100%"
                              h="17px"
                              fontSize="15px"
                              lineHeight="17px"
                            >
                              {el.title}
                            </Flex>
                          </CustomMenuItem>
                        );
                      })}
                      {flowList && flowList.currentPage < flowList.totalPages && (
                        <CustomMenuItem
                          closeOnSelect={false}
                          onClick={() => {
                            getAdditionalItems();
                            onClose();
                          }}
                          height={{ base: "40px", lg: "40px" }}
                          fontSize="15px"
                          lineHeight="17px"
                        >
                          <Flex alignItems={"center"} justifyContent={"center"} w={"100%"} cursor={"pointer"}>
                            <div>{t("editNodePopup.show more")}</div>
                            <Icon as={DownArrowMenu} boxSize="26px" color="midDeepBlue" />
                          </Flex>
                        </CustomMenuItem>
                      )}
                      {!flowList?.items.length && (
                        <Flex alignItems={"center"} justifyContent={"center"} w={"96%"} m="5px" color="darkGrey">
                          <div>{t("editNodePopup.No Flows Found")}</div>
                        </Flex>
                      )}
                    </Box>
                  </MenuList>
                </>
              )}
            </Menu>
            <Button className={s.openFlowButton} variant="dominoOutlineViolet" onClick={() => redirectToFlow(flowAction.flowId)}>
              {t("editNodePopup.Open this Flow")}
              <FollowLinkIcon className={s.icon} />
            </Button>
          </DrawerBody>
          <DrawerFooter margin={"8px"} padding={"0"}>
            {props.validationInfo.isError && <NodeEditorFooter validationMessage={props.validationInfo.errors[0].message} />}
          </DrawerFooter>

          <Button variant="dominoViolet" w={"auto"} margin={"16px"} onClick={() => props.onClose()} data-pw="apply-button">
            {t("Apply")}
          </Button>
        </DrawerContent>
      </Drawer>
    </>
  );
};
