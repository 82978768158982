import { FORMATS_BORDERS } from "../../UI/molecules/TextFormatter/consts";
import { ElementTypes, TextMarks } from "../../UI/molecules/TextFormatter/types";
import { Entity } from "../formattedText/entity";

export type FormatActionType = "bold" | "italic" | "code" | "link";

export type FormatAction = {
  type: FormatActionType;
  text?: string;
  url?: string;
};

export const defaultFormatting = {
  bold: "**",
  italic: "__",
  code: "``",
  url: (text: string, url: string) => {
    return `[${text}](${url})`;
  },
};

const formats = [FORMATS_BORDERS[TextMarks.Bold], FORMATS_BORDERS[TextMarks.Italic], FORMATS_BORDERS[TextMarks.Monospace]];
const linkRegex = /\[([^\]]+)\]\((https?:\/\/[^\s)]+)\)/g;

interface StyledChar {
  char: string;
  type: (TextMarks | ElementTypes.Link)[];
  url?: string;
}
const toStyledCharArray = (input: string): StyledChar[] => {
  const chars: StyledChar[] = [];
  const currentFormat: TextMarks[] = [];

  for (let i = 0; i < input.length; i++) {
    const substring = input.slice(i);

    if (substring.startsWith("****")) {
      const charItem: StyledChar = { char: "*", type: [] };
      chars.push(charItem, charItem, charItem, charItem);
      i += 3;
      continue;
    }
    if (substring.startsWith("____")) {
      const charItem: StyledChar = { char: "_", type: [] };
      chars.push(charItem, charItem, charItem, charItem);
      i += 3;
      continue;
    }
    if (substring.startsWith("````")) {
      const charItem: StyledChar = { char: "`", type: [] };
      chars.push(charItem, charItem, charItem, charItem);
      i += 3;
      continue;
    }

    if (substring.startsWith("[")) {
      const regexpCopy = new RegExp(linkRegex);
      const match = regexpCopy.exec(substring);

      if (match && match.index === 0) {
        const urlText = match[1];
        const url = match[2];

        try {
          new URL(url);
        } catch (e) {
          continue;
        }

        const charItem: StyledChar = {
          char: urlText,
          type: [ElementTypes.Link],
          url: url,
        };

        chars.push(charItem);

        i += match[0].length - 1;
        continue;
      }
    }

    if (input[i] === "*" && input[i + 1] === "*") {
      const idx = currentFormat.lastIndexOf(TextMarks.Bold);
      if (idx >= 0) {
        i++;
        currentFormat.splice(idx, 1);
        continue;
      } else if (input.slice(i + 1).includes("**")) {
        currentFormat.push(TextMarks.Bold);
        i++;
        continue;
      }
    }
    if (input[i] === "_" && input[i + 1] === "_") {
      const idx = currentFormat.lastIndexOf(TextMarks.Italic);
      if (idx >= 0) {
        i++;
        currentFormat.splice(idx, 1);
        continue;
      } else if (input.slice(i + 1).includes("__")) {
        currentFormat.push(TextMarks.Italic);
        i++;
        continue;
      }
    }
    if (input[i] === "`" && input[i + 1] === "`") {
      const idx = currentFormat.lastIndexOf(TextMarks.Monospace);
      if (idx >= 0) {
        i++;
        currentFormat.splice(idx, 1);
        continue;
      } else if (input.slice(i + 1).includes("``")) {
        currentFormat.push(TextMarks.Monospace);
        i++;
        continue;
      }
    }

    chars.push({ char: input[i], type: [...currentFormat] });
  }

  return chars;
};

export const escapeMarkdown = (str: string): [string, Entity[]] => {
  if (!str || (!formats.some(mark => str.includes(mark)) && !str.match(linkRegex))) {
    return [str, []];
  }

  const input = toStyledCharArray(str);
  let text = "";
  const entities: Entity[] = [];
  let currentEntity: Entity | null = null;

  input.forEach(styledChar => {
    text += styledChar.char;

    styledChar.type.forEach(type => {
      if (
        currentEntity === null ||
        currentEntity.type !== type ||
        currentEntity.offset + currentEntity.length !== text.length - 1
      ) {
        const existsEntity = entities.find(
          entity => entity.offset + entity.length === text.length - 1 && entity.type !== ElementTypes.Link,
        );

        if (existsEntity) {
          existsEntity.length++;
          return;
        }

        if (currentEntity) {
          entities.push(currentEntity);
        }

        currentEntity = {
          offset: text.length - styledChar.char.length,
          length: styledChar.char.length,
          type: type,
          url: styledChar.url,
        };
      } else {
        currentEntity.length++;
      }
    });
  });

  if (currentEntity) {
    const existsEntity = entities.find(
      entity => entity.offset + entity.length === entity.length - 1 && entity.type === currentEntity?.type,
    );

    if (existsEntity) {
      existsEntity.length++;
    } else {
      entities.push(currentEntity);
    }
  }

  return [text, entities];
};
