import { Box, Flex } from "@chakra-ui/react";
import s from "../../Conversation.module.scss";
import { ConversationMessageModel, ConversationTypeEnum, MessageStatusEnum } from "../../ConversationModel";
import { AppSettings } from "../../../../common/AppSettings";
import { ErrorMessageItem } from "./ErrorMessageItem";
import ImageViewer from "../../../../UI/atoms/imageViewer/ImageViewer";
import React from "react";

declare const appSettings: AppSettings;

interface Props {
  message: ConversationMessageModel;
  conversationType: ConversationTypeEnum;
}

export const PhotoMessageItem = React.memo(function PhotoMessageItem(props: Props) {
  const timeString = new Date(props?.message?.date).toLocaleTimeString(navigator.language, {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    hourCycle: "h23",
  });

  let displayImageIndex = 1;
  const messageStatus = props.message.status;
  const messageTextLength = props.message.text?.length;
  const messagePhotoLength = props.message.photos.length;
  const bestResolutionPhotoIndex = messagePhotoLength >= 1 ? messagePhotoLength - 1 : 0;
  if (messagePhotoLength <= displayImageIndex) {
    displayImageIndex = messagePhotoLength - 1;
  }

  return (
    <Box>
      {messagePhotoLength && messageStatus === MessageStatusEnum.Failed ? (
        <Box className={s.messageImgItem}>
          <ImageViewer
            alt=""
            loading="lazy"
            ViewerProps={{
              images: [
                { src: `${appSettings.apiBaseUrl}/file/${props.message.photos[bestResolutionPhotoIndex].fileId}`, alt: "" },
              ],
            }}
            src={`${appSettings.apiBaseUrl}/file/${props.message.photos[displayImageIndex].fileId}`}
            width={props.message.photos[displayImageIndex].width}
            height={props.message.photos[displayImageIndex].height}
            className={`
            ${s.lazyImage} 
            ${messageTextLength || props.message.replyMarkup !== null ? s.imageWithText : s.imageOne} 
            ${props.message.replyToMessage !== null ? s.replyWithImage : ""}`}
          />
          {!messageTextLength && (
            <Box mb={-1.5} mr={6} className={`${s.imageDate} ${messageTextLength && s.dateText}`}>
              {timeString}
            </Box>
          )}
          {!messageTextLength && (
            <Flex justify="flex-end" mt={-6} mr={2} pos="relative">
              <ErrorMessageItem errorCode={props.message.errorCode} conversationType={props.conversationType} />
            </Flex>
          )}
        </Box>
      ) : messagePhotoLength ? (
        <Box>
          <Box className={s.messageImgItem}>
            <ImageViewer
              alt=""
              loading="lazy"
              ViewerProps={{
                images: [
                  { src: `${appSettings.apiBaseUrl}/file/${props.message.photos[bestResolutionPhotoIndex].fileId}`, alt: "" },
                ],
              }}
              src={`${appSettings.apiBaseUrl}/file/${props.message.photos[displayImageIndex].fileId}`}
              width={props.message.photos[displayImageIndex].width}
              height={props.message.photos[displayImageIndex].height}
              className={`
                ${s.lazyImage} 
                ${messageTextLength || props.message.replyMarkup !== null ? s.imageWithText : s.imageOne} 
                ${props.message.replyToMessage !== null ? s.replyWithImage : ""}`}
            />
            <Box className={`${s.imageDate} ${messageTextLength && s.dateText}`}>{timeString}</Box>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
});
