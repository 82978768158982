import { httpGet, httpPost, httpPut } from "../BaseApi";
import { UserData } from "./UserData";

export const getUserProfile = () => {
  return httpGet(`/api/user/profile`);
};

export const changeUserProfile = (userProfile: UserData) => {
  return httpPut(`/api/user/profile`, userProfile);
};

export const syncUserLocale = (locale: string) => {
  return httpPost(`/api/user/syncLocale?locale=${locale}`, null);
};
