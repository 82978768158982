/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../common/state/store";
import { UpdateContactInfoSignalData } from "./ContactInfoData";
import {
  ContactInfoFiles,
  ContactInfoModel,
  ContactFilesModel,
  ContactVariableModel,
  ContactVariablesModel,
} from "./ContactInfoModel";
import { OrganisationTeamsModel, OrganisationUsersModel } from "../organisation/OrganisationModel";

export interface ContactInfoState {
  contactInfo?: ContactInfoModel;
  files?: ContactFilesModel;
  mediaFilesCounter?: number;
  docsCounter?: number;
  contactVariables?: ContactVariablesModel;
  operators?: OrganisationUsersModel;
  teams?: OrganisationTeamsModel;
}

const initialState = {};

export const contactInfoSlice = createSlice({
  name: "contactInfoSlice",
  initialState,
  reducers: {
    getContactInfo: (state, action: PayloadAction<{ contactId: string; botId: string; conversationId?: string }>) => {
      return {
        ...state,
        contactInfo: undefined,
      };
    },
    getContactInfoCompleted: (state, action: PayloadAction<ContactInfoModel>) => {
      return {
        ...state,
        contactInfo: action.payload,
      };
    },
    getContactInfoFiles: (state, action: PayloadAction<{ conversationId: string }>) => {
      return {
        ...state,
        contactInfoFiles: undefined,
        mediaFilesCounter: 0,
        docsCounter: 0,
      };
    },
    getContactInfoFilesCompleted: (state, action: PayloadAction<ContactInfoFiles>) => {
      return {
        ...state,
        mediaFilesCounter: action.payload?.mediaFiles,
        docsCounter: action.payload?.docs,
      };
    },
    getFiles: (state, action: PayloadAction<{ conversationId: string; attachments: string; page?: number; size?: number }>) => {
      return {
        ...state,
      };
    },
    getFilesCompleted: (state, action: PayloadAction<ContactFilesModel>) => {
      return {
        ...state,
        files: action.payload,
      };
    },
    incMediaFilesCompleted: (state: ContactInfoState) => {
      return {
        ...state,
        mediaFilesCounter: state?.mediaFilesCounter ? 1 + state?.mediaFilesCounter : 1,
      };
    },
    incDocFilesCompleted: (state: ContactInfoState) => {
      return {
        ...state,
        docsCounter: state?.docsCounter ? 1 + state?.docsCounter : 1,
      };
    },
    getContactVariables: (state: ContactInfoState, action: PayloadAction<{ contactId: string; size: string; page: string }>) => {
      return {
        ...state,
        contactVariables: action.payload.page === "1" ? undefined : state.contactVariables,
      };
    },
    getContactVariablesCompleted: (state, action: PayloadAction<ContactVariablesModel | undefined>) => {
      return {
        ...state,
        contactVariables: action.payload,
      };
    },
    changeContactVariable: (
      state,
      action: PayloadAction<{
        contactId: string;
        contactVariable: ContactVariableModel;
      }>,
    ) => {
      return {
        ...state,
      };
    },
    updateContactInfoSignal: (state, action: PayloadAction<UpdateContactInfoSignalData>) => {
      return {
        ...state,
      };
    },
    stopFlow: (state, action: PayloadAction<{ flowExecutionId: string }>) => {
      return {
        ...state,
      };
    },
    getOperators: (state, action: PayloadAction<{ page: number; filter?: string }>) => {
      return {
        ...state,
      };
    },
    getOperatorsCompleted: (state, action: PayloadAction<OrganisationUsersModel>) => {
      return {
        ...state,
        operators: action.payload,
      };
    },
    getTeams: (state, action: PayloadAction<{ page: number; filter?: string }>) => {
      return {
        ...state,
      };
    },
    getTeamsCompleted: (state, action: PayloadAction<OrganisationTeamsModel>) => {
      return {
        ...state,
        teams: action.payload,
      };
    },
    clearContactInfoState: state => {
      return {
        ...state,
        contactVariables: null,
      };
    },
  },
});

export const {
  getContactInfo,
  getContactInfoCompleted,
  getContactInfoFiles,
  getFiles,
  getContactInfoFilesCompleted,
  getContactVariables,
  getContactVariablesCompleted,
  changeContactVariable,
  stopFlow,
  getOperators,
  getOperatorsCompleted,
  getTeams,
  getTeamsCompleted,
  clearContactInfoState,
} = contactInfoSlice.actions;

export const selectContactInfo = (state: RootState) => state.app.contactInfoState.contactInfo;
export const selectContactInfoMediaFiles = (state: RootState) => state.app.contactInfoState.mediaFilesCounter;
export const selectContactInfoDocFiles = (state: RootState) => state.app.contactInfoState.docsCounter;
export const selectFiles = (state: RootState) => state.app.contactInfoState.files;
export const selectContactVariables = (state: RootState) => state.app.contactInfoState.contactVariables;
export const selectOperators = (state: RootState) => state.app.contactInfoState.operators;
export const selectTeams = (state: RootState) => state.app.contactInfoState.teams;

export default contactInfoSlice.reducer;
