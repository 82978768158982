import { Box, Button, Flex, Input, Skeleton, Text, useDisclosure } from "@chakra-ui/react";
import s from "./OrganisationHeader.module.scss";
import h from "../../../../components/headers/HeaderStyles.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import { useEffect, useRef, useState } from "react";
import { selectOrganisation, updateOrganisation } from "../../OrganisationSlice";
import EditInputIcon from "../../../../assets/icons/editInput.svg?react";
import { useGetLoadingState } from "../../../../common/loading/hooks/useGetLoadingState";
import { selectIsOwner } from "../../../../common/user/UserSlice";
import { useDocumentTitle } from "../../../../common/hooks/useDocumentTitle";
import { useTranslation } from "react-i18next";
import { NewTeamModal } from "../NewTeamModal/NewTeamModal";
import { Route, Routes } from "react-router-dom";
import { ICountTariffPlanFeature, TariffPlanFeatureTypeEnum } from "../../../../common/AppEnums";
import { NewOrganisationModal } from "../NewOrganisationModal/NewOrganisationModal";
import { LimitStarPlanButton } from "../../../../UI/molecules/limitStarPlanButton/LimitStarPlanButton";
import { useSelectLimitPlan } from "../../../../common/hooks/useSelectLimitPlan";

export const OrganisationHeader = () => {
  const { t } = useTranslation("translation", { keyPrefix: "organisation" });
  const organisation = useAppSelector(selectOrganisation);
  const isOwner = useAppSelector(selectIsOwner);
  const loadingOrganisation = useGetLoadingState("getOrganisation");
  const dispatch = useAppDispatch();
  const [isInputFocused, setInputFocused] = useState(false);
  const [orgName, setOrgName] = useState(organisation?.name);
  const { isOpen: isTeamModalOpen, onOpen: onNewTeam, onClose: onTeamModalClose } = useDisclosure();
  const { isOpen: isOrganisationModalOpen, onOpen: onNewOrganisation, onClose: onOrganisationModalClose } = useDisclosure();
  const first = useRef(null);

  const { MaxCount, IsUnlimited } = useSelectLimitPlan(TariffPlanFeatureTypeEnum.TeamsCount) as ICountTariffPlanFeature;

  useEffect(() => {
    setOrgName(organisation?.name.replace(/ +/g, " ").trim());
  }, [organisation?.name]);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      (e.target as HTMLInputElement).blur();
    }
  };

  const updateOrgName = () => {
    if (organisation?.name === orgName) return;
    const organizationName = orgName?.replace(/ +/g, " ").trim() ?? "";
    if (organizationName === "") {
      setOrgName(organisation?.name.replace(/ +/g, " ").trim());
      return;
    }
    setOrgName(organizationName);
    dispatch(
      updateOrganisation({
        id: organisation?.id,
        name: organizationName,
        totalFlow: organisation?.totalFlow,
        totalBots: organisation?.totalBots,
        totalContacts: organisation?.totalContacts,
        totalMembers: organisation?.totalMembers,
        totalTeams: organisation?.totalTeams,
        totalTeamsMembers: organisation?.totalTeamsMembers,
      }),
    );
  };

  useDocumentTitle(t("Organisation"), "Organisation");

  return (
    <>
      <Box className={`${h.headerMiddle}  ${!isOwner ? s.noBorder : ""}`}>
        <Flex className={`${s.inputGroup} ${h.titleBlock}`} fill="darkGrey" _hover={{ fill: "darkPurple" }}>
          {loadingOrganisation ? (
            <Skeleton
              speed={0.5}
              startColor="line"
              endColor="bgLight"
              border="1px solid"
              borderColor="line"
              mr="10px"
              w={"200px"}
              maxWidth="200px"
              height="27px"
            ></Skeleton>
          ) : (
            <Flex fill="inherit" visibility={isInputFocused ? "hidden" : "visible"} className={s.preview}>
              <Text wordBreak="break-all" noOfLines={1} variant="h3" color="midDeepBlue" className={h.title}>
                {organisation?.name}
              </Text>
              {isOwner && <EditInputIcon fill="inherit" />}
            </Flex>
          )}

          {isOwner && (
            <Input
              ref={first}
              className={s.input}
              variant="inline"
              size="lg"
              opacity={!isInputFocused ? 0 : 1}
              width={`${(orgName?.length ?? 0) * 14}px`}
              value={orgName ?? ""}
              onFocus={() => {
                setInputFocused(true);
              }}
              onBlur={e => {
                setInputFocused(false);
                updateOrgName();
              }}
              onChange={e => setOrgName(e.target.value)}
              onKeyDown={e => {
                handleKeyPress(e);
              }}
            />
          )}
        </Flex>
        <div className={`${s.buttonsContainer} ${h.InputSearchBlock}`}>
          {!IsUnlimited && organisation?.totalTeams && organisation?.totalTeams >= Number(MaxCount) ? (
            <Routes>
              <Route
                path="/organisation/teams"
                element={
                  <LimitStarPlanButton
                    popoverBodyDescription="Please upgrade your plan to invite more Operators"
                    buttonName="New Team"
                    keyPrefix="organisation"
                    variant="dominoOutlineViolet"
                    dataPw="generate-link-button"
                    iconFill="#6D5BF7"
                  />
                }
              />
            </Routes>
          ) : (
            <Routes>
              <Route
                path="/organisation/teams"
                element={
                  <Button size="sm" variant="dominoOutlineViolet" onClick={onNewTeam}>
                    + {t("New Team")}
                  </Button>
                }
              />
            </Routes>
          )}
          <Button size="sm" variant="dominoViolet" onClick={onNewOrganisation} className={s.newOrganisation}>
            + {t("New Organisation")}
          </Button>
        </div>
      </Box>
      <NewOrganisationModal isOpen={isOrganisationModalOpen} onClose={onOrganisationModalClose} />
      <NewTeamModal isOpen={isTeamModalOpen} onClose={onTeamModalClose} />
    </>
  );
};
