import { useCallback } from "react";
import { Box, Flex, Skeleton, Tab, TabList, Tabs } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../common/state/store";
import { selectOrganisation } from "./OrganisationSlice";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { selectIsOwner } from "../../common/user/UserSlice";
import { ICountTariffPlanFeature, TariffPlanFeatureTypeEnum } from "../../common/AppEnums";
import s from "./Organisation.module.scss";
import { CurrentPlanInfo } from "./components/CurrentPlanInfo/CurrentPlanInfo";
import { GenerateLinkButton } from "./components/GenerateLinkButton/GenerateLinkButton";
import { useSelectLimitPlan } from "../../common/hooks/useSelectLimitPlan";
import { useGetLoadingState } from "../../common/loading/hooks/useGetLoadingState";

export const OrganisationContainer = () => {
  const { t } = useTranslation("translation", { keyPrefix: "organisation" });
  const navigate = useNavigate();
  const location = useLocation();
  const isOwner = useAppSelector(selectIsOwner);
  const organisation = useAppSelector(selectOrganisation);
  const isGetProfileLoading = useGetLoadingState("getProfile");

  const { MaxCount, IsUnlimited } = useSelectLimitPlan(
    TariffPlanFeatureTypeEnum.OrganisationMembersCount,
  ) as ICountTariffPlanFeature;

  const getIndex = useCallback(() => {
    if (location.pathname.includes("members")) {
      return 0;
    }
    if (location.pathname.includes("teams")) {
      return 1;
    }
    return 0;
  }, [location.pathname]);

  return (
    <Box className={s.organisationContainer}>
      <Flex className={s.organisationTopFlex}>
        <CurrentPlanInfo />
        {isGetProfileLoading ? (
          <Skeleton minW={"49%"} flex={1} speed={0.5} startColor="line" endColor="bgLight" borderRadius="8px" />
        ) : isOwner ? (
          <GenerateLinkButton MaxCount={Number(MaxCount)} IsUnlimited={Boolean(IsUnlimited)} organisation={organisation} />
        ) : (
          <></>
        )}
      </Flex>
      <Tabs className={s.tabsContainer} index={getIndex()} isManual>
        <TabList mx="0">
          <Tab pl={0} onClick={() => navigate("/organisation/operators")}>
            {t("Operators")}
          </Tab>
          <Tab onClick={() => navigate("/organisation/teams")}>{t("Teams")}</Tab>
        </TabList>
      </Tabs>
      <Box className={isOwner ? s.boxContainerOwner : s.boxContainerNotOwner}>
        <Outlet />
      </Box>
    </Box>
  );
};
