import { Box, Container, FormControl, FormErrorMessage, FormLabel, Skeleton, Text } from "@chakra-ui/react";
import { BroadcastDirection, BroadcastValidationField, BroadcatMessageModel, FileUploadType } from "../../../../BroadcastModel";
import s from "../../Broadcast.module.scss";
import { CustomVariableModel, IMAGE_FILE_EXTS } from "../../../../../../common/AppEnums";
import { addButton, deleteButton, editButton, setButtons } from "../../../ButtonsList/utils";
import { Attachments } from "../../../Attachments/Attachments";
import React, { DragEvent, useState, ClipboardEvent } from "react";
import { useTranslation } from "react-i18next";
import {
  selectBroadcastState,
  selectBroadcastValidation,
  selectCurrentFile,
  setBroadcastMessage,
  setValidationError,
} from "../../../../BroadcastSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../common/state/store";
import { ButtonsList } from "../../../ButtonsList/ButtonsList";
import { selectBroadcastDirection } from "../../../ContactList/RecipientListSlice";
import { TextFormatter } from "../../../../../../UI/molecules/TextFormatter/TextFormatter";
import { useGetLoadingState } from "../../../../../../common/loading/hooks/useGetLoadingState";
import { useSmoothLoading } from "../../../../../../common/hooks/useSmoothLoading";

interface Props {
  variables: CustomVariableModel[] | undefined;
  uploadFile: (file: File, type: FileUploadType) => void;
  textareaRef: React.RefObject<HTMLDivElement>;
  messageInvalid: boolean;
}

export const BroadcastFormMessage = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "broadcast" });
  const tr = useTranslation("translation", { keyPrefix: "conversation" }).t;
  const validationState = useAppSelector(selectBroadcastValidation);

  const currentFile = useAppSelector(selectCurrentFile);
  const broadcast = useAppSelector(selectBroadcastState).broadcast;
  const brodcastDirection = useAppSelector(selectBroadcastDirection);
  const isBroadcastForGroup = brodcastDirection === BroadcastDirection.TelegramGroup;
  const isDefaultLoading = useGetLoadingState("default");
  const isBroadcastLoading = useGetLoadingState("Broadcast");
  const isLoading = useSmoothLoading([isDefaultLoading, isBroadcastLoading]);

  const messageMaxLength = currentFile == null ? 4000 : 1024;
  const dispatch = useAppDispatch();

  const [isDragging, setDragging] = useState(false);
  const [messageFirstInit, setMessageFirstInit] = useState(true);

  const onChangeMessageValidation = (text: string) => {
    setMessageFirstInit(false);
    if (!text.length !== !!validationState.validationState.message) {
      dispatch(
        setValidationError({
          key: BroadcastValidationField.message,
          message: Boolean(text.length) ? null : t("Message field can't be empty"),
        }),
      );
    }
    const newMessage: BroadcatMessageModel | undefined = broadcast?.message
      ? { ...broadcast?.message, text: text ?? "", entities: [] }
      : broadcast?.message;
    dispatch(setBroadcastMessage(newMessage));
  };

  const onDropFile = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);
    const file = event.dataTransfer?.files[0];
    if (file) {
      const [type, ext] = file.type.split("/");
      if (type === "image" && IMAGE_FILE_EXTS.includes(ext)) {
        props.uploadFile(file, FileUploadType.photo);
      } else {
        props.uploadFile(file, FileUploadType.document);
      }
    }
  };

  const handleDrag = function (e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragging(true);
    } else if (e.type === "dragleave" || e.type === "dragend") {
      setDragging(false);
    }
  };

  const handlePasteFromClipBoard = (e: ClipboardEvent<HTMLDivElement>) => {
    const { clipboardData } = e;
    if (clipboardData && clipboardData.files.length > 0) {
      const file = clipboardData?.files[0];

      if (file && !currentFile) {
        e.preventDefault();
        if (file.type.match("^image/")) {
          props.uploadFile(file, FileUploadType.photo);
        } else {
          props.uploadFile(file, FileUploadType.document);
        }
      }
    }
  };

  const initialValue = {
    text: broadcast?.message.text,
    entities: broadcast?.message.entities,
  };

  return (
    <Container position={"relative"} onDragEnter={handleDrag} variant="dominoContentBlock">
      <FormControl
        isInvalid={messageFirstInit ? false : props.messageInvalid}
        className={`${s.broadcastListItemGrid} ${s.broadcastListItemGridStart} ${s.broadcastAdaptiveMessage}`}
      >
        <FormLabel mr={0}>{t("Broadcast message")}</FormLabel>
        <Box width="100%">
          <Skeleton isLoaded={!isLoading} speed={0.5} borderRadius={"12px 12px 0 0"} startColor="line" endColor="bgLight">
            <TextFormatter
              data-pw="broadcast-message-textarea"
              value={broadcast?.message.text}
              initialValue={initialValue}
              maxLength={messageMaxLength}
              ref={props.textareaRef}
              onPaste={handlePasteFromClipBoard}
              placeholder={t("Broadcast message placeholder") ?? ""}
              isNoVariablesButton={isBroadcastForGroup}
              variables={props.variables}
              handleChange={onChangeMessageValidation}
              wrapperClassname={s.textFormatter}
            />
          </Skeleton>
          <ButtonsList
            buttons={broadcast?.message.buttons || []}
            addButton={addButton(broadcast, dispatch, setBroadcastMessage)}
            editButton={editButton(broadcast, dispatch, setBroadcastMessage)}
            deleteButton={deleteButton(broadcast, dispatch, setBroadcastMessage)}
            setButtons={setButtons(broadcast, dispatch, setBroadcastMessage)}
            isNoTriggerButtonCreate={isBroadcastForGroup}
          />
          <Attachments />
        </Box>
        <FormErrorMessage data-pw="broadcast-message-error">
          {validationState.validationState.message !== null ? t("Message field can't be empty") : ""}
        </FormErrorMessage>
      </FormControl>
      {isDragging && (
        <div
          className={s.dragFileElement}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={onDropFile}
        ></div>
      )}
      {isDragging && (
        <div className={s.dragFileContainer}>
          <div className={s.dragFileContainerText}>
            <Text variant="largeBold" color="white" textAlign="center">
              {tr("Drag and drop the file here to attach to the message")}
            </Text>
          </div>
        </div>
      )}
    </Container>
  );
};
