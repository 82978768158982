/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../common/state/store";
import {
  BroadcastDirection,
  ChatGroupModel,
  ContactListModel,
  ContactModel,
  RecipientListModel,
  RecipientsImportModel,
} from "../../BroadcastModel";

export interface RecipientListState {
  contactList?: ContactListModel;

  falseRecipientList?: RecipientListModel;
  trueRecipientList?: RecipientListModel;
  recipientList?: RecipientListModel;
  falseFilterList?: RecipientListModel;
  trueFilterList?: RecipientListModel;
  filterList?: RecipientListModel;
  includedRecipients: { [key: string]: ContactModel } | { [key: string]: ChatGroupModel };
  excludedRecipients: { [key: string]: ContactModel } | { [key: string]: ChatGroupModel };

  recipientCounter: number;
  isIncludAndExcludeEmpty: boolean;
  isSelectAll: boolean;
  isFilter: boolean;
  initialIsSelectAll: boolean;
  isUnCheckedFlag: boolean;
  importRecipientList?: RecipientsImportModel | null;
  isCheckedUncheckedFlag: boolean;
  isChangeSelectAll: boolean;
  direction: BroadcastDirection;
}

const initialState: RecipientListState = {
  recipientCounter: 0,
  isSelectAll: false,
  initialIsSelectAll: false,
  isUnCheckedFlag: false,
  isCheckedUncheckedFlag: false,
  isFilter: false,
  isIncludAndExcludeEmpty: true,
  excludedRecipients: {},
  includedRecipients: {},
  isChangeSelectAll: false,
  direction: BroadcastDirection.TelegramContact,
};

export const recipientListSlice = createSlice({
  name: "recipientListSlice",
  initialState,
  reducers: {
    getContactList: (
      state,
      action: PayloadAction<{
        botId: string;
        queryFilterParams: string;
        isOnScrollPayload?: boolean;
        accessability: { MaxCount: number; IsUnlimited: boolean };
      }>,
    ) => {
      return {
        ...state,
      };
    },
    getChatGroupsList: (
      state,
      action: PayloadAction<{
        botId: string;
        queryFilterParams: string;
        isOnScrollPayload?: boolean;
      }>,
    ) => {
      return {
        ...state,
      };
    },
    getDraftContactList: (
      state,
      action: PayloadAction<{
        botId: string;
        broadcastId: string;
        queryFilterParams: string;
        accessability: { MaxCount: number; IsUnlimited: boolean };
      }>,
    ) => {
      return {
        ...state,
      };
    },
    getContactListCompleted: (state, action: PayloadAction<ContactListModel>) => {
      return {
        ...state,
        contactList: action.payload,
      };
    },
    getDraftChatGroupsList: (
      state,
      action: PayloadAction<{
        botId: string;
        broadcastId: string;
        queryFilterParams: string;
      }>,
    ) => {
      return {
        ...state,
      };
    },
    getRecipientListCompleted: (state, action: PayloadAction<RecipientListModel>) => {
      return {
        ...state,
        recipientList: action.payload,
      };
    },
    checkedRecipientListContact: (
      state,
      action: PayloadAction<{ contactId: string; isChecked: boolean; accessibility: { MaxCount: number; IsUnlimited: boolean } }>,
    ) => {
      if (state.isSelectAll && !action.payload.isChecked) {
        state.isUnCheckedFlag = true;
        state.isIncludAndExcludeEmpty = true;
        state.includedRecipients = {};
        state.excludedRecipients = {};
        state.isSelectAll = false;
      }
      const newContact = state.filterList
        ? state.filterList.items[action.payload.contactId]
        : state.recipientList?.items[action.payload.contactId];
      if (action.payload.isChecked) {
        if (newContact) {
          state.includedRecipients[action.payload.contactId] = { ...newContact, isSelected: true };
          delete state.excludedRecipients[action.payload.contactId];
          state.recipientCounter++;
          state.isIncludAndExcludeEmpty = false;
        }
      } else {
        if (newContact) {
          state.excludedRecipients[action.payload.contactId] = { ...newContact, isSelected: false };
          delete state.includedRecipients[action.payload.contactId];
          state.recipientCounter--;
          state.isIncludAndExcludeEmpty = false;
        }
      }

      if (state.filterList) {
        state.filterList.items[action.payload.contactId].isSelected = action.payload.isChecked;
      }
      if (state.recipientList && state.recipientList.items[action.payload.contactId]) {
        state.recipientList.items[action.payload.contactId].isSelected = action.payload.isChecked;
      }
    },
    importRecipientsByFile: (state, action: PayloadAction<{ botId: string | undefined; file: File | null }>) => {
      return {
        ...state,
      };
    },
    setImportRecipientList: (state, action: PayloadAction<RecipientsImportModel | null>) => {
      return {
        ...state,
        importRecipientList: action.payload,
      };
    },
    setIsSelectAll: (
      state,
      action: PayloadAction<{ isChecked: boolean; accessibility?: { MaxCount: number; IsUnlimited: boolean } }>,
    ) => {
      const countFilterIfTrue = action.payload.accessibility?.IsUnlimited
        ? state.filterList?.totalItems
        : Number(state.filterList?.totalItems) > Number(action.payload.accessibility?.MaxCount)
        ? Number(action.payload.accessibility?.MaxCount)
        : Number(state.filterList?.totalItems);
      const countBaseIfTrue = action.payload.accessibility?.IsUnlimited
        ? state.contactList?.totalItems
        : Number(state.recipientList?.totalItems) > Number(action.payload.accessibility?.MaxCount)
        ? Number(action.payload.accessibility?.MaxCount)
        : Number(state.recipientList?.totalItems);
      const countIfTrue = state.filterList ? countFilterIfTrue : countBaseIfTrue;

      const newCount = action.payload.isChecked ? countIfTrue : 0;
      return {
        ...state,
        isSelectAll: action.payload.isChecked,
        recipientList: action.payload.isChecked ? state.trueRecipientList : state.falseRecipientList,
        filterList: action.payload.isChecked ? state.trueFilterList : state.falseFilterList,
        recipientCounter: newCount || 0,
        excludedRecipients: {},
        includedRecipients: {},
        isIncludAndExcludeEmpty: true,
        isCheckedUncheckedFlag: !action.payload.isChecked && !state.initialIsSelectAll ? true : false,
        isUnCheckedFlag: false,
      };
    },
    setInitialIsSelectAll: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        initialIsSelectAll: action.payload,
      };
    },
    setInitialRecipientList: state => {
      return {
        ...state,
        recipientList: state.contactList && { ...state.contactList },
        recipientCounter: 0,
      };
    },
    setRecipientCounter: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        recipientCounter: action.payload,
      };
    },

    setFalseRecipientList: (state, action: PayloadAction<RecipientListModel>) => {
      return {
        ...state,
        falseRecipientList: action.payload,
      };
    },
    setTrueRecipientList: (state, action: PayloadAction<RecipientListModel>) => {
      return {
        ...state,
        trueRecipientList: action.payload,
      };
    },
    clearStateRecipient: state => {
      return {
        ...state,
        contactList: undefined,
        excludedRecipients: {},
        falseRecipientList: undefined,
        importRecipientList: undefined,
        includedRecipients: {},
        initialIsSelectAll: false,
        isSelectAll: false,
        isUnCheckedFlag: false,
        recipientCounter: 0,
        recipientList: undefined,
        recipientsFilter: undefined,
        trueRecipientList: undefined,
        filterList: undefined,
        isIncludAndExcludeEmpty: true,
        isChangeSelectAll: false,
        isCheckedUncheckedFlag: false,
      };
    },
    getFilterListCompleted: (state, action: PayloadAction<RecipientListModel | undefined>) => {
      return {
        ...state,
        filterList: action.payload,
      };
    },
    setFalseFilterList: (state, action: PayloadAction<RecipientListModel | undefined>) => {
      return {
        ...state,
        falseFilterList: action.payload,
      };
    },
    setTrueFilterList: (state, action: PayloadAction<RecipientListModel | undefined>) => {
      return {
        ...state,
        trueFilterList: action.payload,
      };
    },
    clearFilter: state => {
      return {
        ...state,
        trueFilterList: undefined,
        falseFilterList: undefined,
        filterList: undefined,
        isFilter: false,
      };
    },
    changeSelectAll: state => {
      return {
        ...state,
        isChangeSelectAll: true,
      };
    },
    setRecipientListCompletedByBroadcast: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        initialIsSelectAll: action.payload,
        isSelectAll: action.payload,
      };
    },
    setBroadcastDirection: (state, action: PayloadAction<BroadcastDirection>) => {
      return {
        ...state,
        direction: action.payload,
      };
    },
  },
});

export const {
  getContactList,
  getChatGroupsList,
  getContactListCompleted,
  importRecipientsByFile,
  setImportRecipientList,
  setIsSelectAll,
  getRecipientListCompleted,
  checkedRecipientListContact,
  getDraftContactList,
  getDraftChatGroupsList,
  setInitialRecipientList,
  setRecipientCounter,
  setInitialIsSelectAll,
  setFalseRecipientList,
  setTrueRecipientList,
  clearStateRecipient,
  getFilterListCompleted,
  setFalseFilterList,
  setTrueFilterList,
  clearFilter,
  changeSelectAll,
  setRecipientListCompletedByBroadcast,
  setBroadcastDirection,
} = recipientListSlice.actions;

export const selectContactList = (state: RootState) => state.app.brodcastRecipientListState.contactList;
export const selectRecipientList = (state: RootState) => state.app.brodcastRecipientListState.recipientList;
export const selectFilterList = (state: RootState) => state.app.brodcastRecipientListState.filterList;
export const selectFalseRecipientList = (state: RootState) => state.app.brodcastRecipientListState.falseRecipientList;
export const selectTrueRecipientList = (state: RootState) => state.app.brodcastRecipientListState.trueRecipientList;
export const selectImportRecipientList = (state: RootState) => state.app.brodcastRecipientListState.importRecipientList;
export const selectIsSelectAll = (state: RootState) => state.app.brodcastRecipientListState.isSelectAll;
export const selectInitialIsSelectAll = (state: RootState) => state.app.brodcastRecipientListState.initialIsSelectAll;
export const selectIsUnCheckedFlag = (state: RootState) => state.app.brodcastRecipientListState.isUnCheckedFlag;
export const selectExcludedRecipients = (state: RootState) => state.app.brodcastRecipientListState.excludedRecipients;
export const selectIncludedRecipients = (state: RootState) => state.app.brodcastRecipientListState.includedRecipients;
export const selectRecipientCount = (state: RootState) => state.app.brodcastRecipientListState.recipientCounter;
export const selectIsIncludeAndExcludeEmpty = (state: RootState) => state.app.brodcastRecipientListState.isIncludAndExcludeEmpty;
export const selectTotalContacts = (state: RootState) => state.app.brodcastRecipientListState.contactList?.totalItems;
export const selectIsChangeSelectAll = (state: RootState) => state.app.brodcastRecipientListState.isChangeSelectAll;
export const selectBroadcastDirection = (state: RootState) => state.app.brodcastRecipientListState.direction;

export default recipientListSlice.reducer;
